/** @format */

import React from "react";
import PropTypes from "prop-types";
import { cloneDeep, map, compact } from "lodash";
import { connect } from "react-redux";
import Input from "../../../components/Input";
import appConfig from "../../../config/app";
import timezones from "../../../config/timezone";
import { withTranslation } from "react-i18next";
import ModalImageVariant from "../../products/components/ModalEdit/components/ImageVariant";
import Image from "../../../components/Image";
import Modal from "../../../components/Modal";
import Dropdown from "../../../components/Dropdown";
import NumberInput from "../../../components/NumberInput";
import SelectOption from "../../../components/SelectOption";
import { appLocalStorage } from "../../../localforage";
import baseHelper from "../../../helpers/BaseHelper";
import DatePicker from "../../../components/DatePicker/CustomDatePicker";

import fileActions from "../../../redux/futures/file/actions";
import * as fileTypes from "../../../redux/futures/file/types";
import LoadingScene from "../../../components/LoadingScene";

class ModalTicket extends React.Component {
  static propTypes = {
    currentStore: PropTypes.object,
    mode: PropTypes.string,
    currentVariantInfo: PropTypes.object,
    images: PropTypes.array,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    visible: PropTypes.bool,
    isLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.moment = baseHelper.getMoment();

    this.state = {
      variant: {
        option_value_value: "",
        price: "",
        price_compare: "",
        src: "",
        sku: "",
        quantity: "",
        start_date: baseHelper.momentToDate(this.moment()),
        end_date: baseHelper.momentToEventDate(this.moment()),
        timezone: "Asia/Bangkok",
        max_buy: null,
        min_buy: 1,
        description: "",
        total_sold: 0,
        status: appConfig.TICKET_STATUS.NORMAL.CODE,
        total_quantity: props.variantReducer.ticket_creation_form == appConfig.TICKET_CREATION_FORM.IMPORT.CODE ? 0 : 1,
        inventory_checked: appConfig.STATUS.ACTIVE,
      },
      userInfo: {
        currentStore: {},
      },
      isShowAdvanced: false,
      ticketCreationForm: props.variantReducer.ticket_creation_form || appConfig.TICKET_CREATION_FORM.AUTO.CODE,
    };

    this.ref = React.createRef();
    this.inputsRef = new Set();
  }

  componentWillMount() {
    appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
      this.setState({
        userInfo: result,
      });
    });
  }

  componentDidMount() {
    window.LadiUI.init();
    window.LadiUI.customInit();

    if (this.props.currentVariantInfo) {
      this.init(this.props);
    }
  }

  componentDidUpdate() {
    window.LadiUI.init();
    window.LadiUI.customInit();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (this.props.currentVariantInfo !== nextProps.currentVariantInfo) {
      this.init(nextProps);
    }

    if (this.props.fileReducer.action != nextProps.fileReducer.action) {
      if (nextProps.fileReducer.action == fileTypes.UPLOAD_VARIANT_IMAGE) {
        if (nextProps.fileReducer.status) {
          this.setState({
            variant: {
              ...this.state.variant,
              src: nextProps.fileReducer.ticket_images[0],
            },
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.fileReducer.message, "OK");
        }
      }
    }
  }

  init = (props) => {
    if (props.mode == appConfig.FORM_MODE.EDIT) {
      const variant = cloneDeep(props.currentVariantInfo) || {};

      if (variant.start_date) {
        variant.start_date = baseHelper.momentToDate(this.moment(variant.start_date));
      }

      if (variant.end_date) {
        variant.end_date = baseHelper.momentToDate(this.moment(variant.end_date));
      }

      this.setState({
        variant,
      });
    }
  };

  onChangeInput = (event) => {
    this.setState({
      variant: {
        ...this.state.variant,
        [event.target.name]: event.target.value,
      },
    });
  };

  onChangeDateTime = (value, name) => {
    const { variant } = this.state;
    if (variant.end_date) {
      if (name == "start_date") {
        if (value.getTime() >= variant.end_date.getTime()) {
          window.LadiUI.toastCustom("danger", "", this.props.t("VALIDATION.END_DATE_AFTER_START_DATE"));
          return;
        }
      } else {
        if (variant.start_date.getTime() >= value.getTime()) {
          window.LadiUI.toastCustom("danger", "", this.props.t("VALIDATION.END_DATE_AFTER_START_DATE"));
          return;
        }
      }
    }
    variant[name] = value;
    this.setState({
      variant,
    });
  };

  selectTimezone = (selectedItem) => {
    this.setState({
      variant: {
        ...this.state.variant,
        timezone: selectedItem.CODE,
      },
    });
  };

  fileChangedHandler = (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const form = baseHelper.getFormDataUpload(files);
      if (form) {
        this.props.uploadTicketImage(form);
      }
    }
  };

  onSubmit = (event) => {
    event.preventDefault();
    // Validate
    let errors = [];
    const _inputRef = compact(Array.from(this.inputsRef));
    map(_inputRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });
    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    if (!baseHelper.isEmpty(this.state.variant.max_buy)) {
      if (baseHelper.parseInt(this.state.variant.min_buy) > baseHelper.parseInt(this.state.variant.max_buy)) {
        window.LadiUI.toastCustom("danger", "", this.props.t("PRODUCT_EVENTS.TICKET_MIN_BUY_NEEDED_LESS_MAX_BUY"));
        return;
      }
    }

    const _variant = {
      ...this.state.variant,
      price: this.state.variant.price || 0,
    };

    this.props.onSubmit(_variant);
  };

  render() {
    const { t, currentProductInfo } = this.props;
    const { variant } = this.state;
    const { currency_code: currencyCode, currency_symbol: currencySymbol } = this.state.userInfo.currentStore;
    const isLoadingUploadImage = this.props.fileReducer.loading && this.props.fileReducer.waiting == fileTypes.UPLOAD_VARIANT_IMAGE;
    const title =
      this.props.mode === appConfig.FORM_MODE.CREATE ? t("PRODUCTS.LB_ADD_VARIANT_TICKET") : t("PRODUCTS.LB_EDIT_VARIANT_TICKET");

    return (
      <Modal
        id="modal-event"
        title={title}
        onCancel={this.props.onCancel}
        onOk={(event) => this.onSubmit(event)}
        isLoading={this.props.isLoading}
        visible={this.props.visible}
        width={900}
      >
        {isLoadingUploadImage && <LoadingScene blur={true} />}
        <div>
          <div className="ladiui form-group">
            <label className="ladiui-label">{t("PRODUCTS.VARIANT_TICKET_DEFAULT")}</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              validationName={t("PRODUCTS.VARIANT_TICKET_DEFAULT")}
              value={variant.option_value_value}
              name="option_value_value"
              onChange={this.onChangeInput}
              placeholder={"Tên loại vé"}
              validations={{ isRequired: true }}
            />
          </div>

          <div className="ladiui form-group group-date flex">
            <div className="date-item">
              <label className="ladiui-label">{t("PRODUCTS.PRICE")}</label>
              <div className="ladiui-input-group">
                <NumberInput name="price" prefix={currencySymbol} value={variant.price} onChange={this.onChangeInput} />
              </div>
            </div>

            <div className="date-item">
              <label className="ladiui-label">{t("PRODUCTS.PRICE_COMPARE")}</label>
              <NumberInput name="price_compare" prefix={currencySymbol} value={variant.price_compare} onChange={this.onChangeInput} />
            </div>
          </div>

          <div className="ladiui form-group">
            <label className="ladiui-label">{t("PRODUCTS.SKU_CODE")}</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              validationName={t("PRODUCTS.ENTER_SKU")}
              value={variant.sku}
              name="sku"
              onChange={this.onChangeInput}
              placeholder={"Nhập mã SKU"}
              validations={{ isRequired: true }}
            />
          </div>

          <div className="ladiui form-group group-date flex">
            <div className="date-item">
              <label className="ladiui-label">
                {t("PRODUCT_EVENTS.QUANTITY")}
                {this.props.mode === appConfig.FORM_MODE.EDIT && (
                  <span className="buy-information">{`(${t(
                    "PRODUCT_EVENTS.LB_BUY_INFORMATION",
                    variant.total_sold,
                    variant.total_quantity - variant.total_sold >= 0 ? variant.total_quantity - variant.total_sold : 0
                  )})`}</span>
                )}
              </label>
              {currentProductInfo.ticket_creation_form == appConfig.TICKET_CREATION_FORM.AUTO.CODE ? (
                <NumberInput
                  ref={(ref) => this.inputsRef.add(ref)}
                  name="total_quantity"
                  value={variant.total_quantity}
                  onChange={this.onChangeInput}
                  onlyNumber={true}
                  thousandSeparator={false}
                  decimalScale={0}
                  allowNegative={false}
                  validationName={t("PRODUCT_EVENTS.QUANTITY")}
                  validations={{ isRequired: true }}
                />
              ) : (
                <Input value={variant.total_quantity} disabled={true} />
              )}
            </div>

            <div className="date-item">
              <label className="ladiui-label">{t("COMMON.TIMEZONE")}</label>
              <Dropdown data={timezones} onSelectItem={this.selectTimezone} currentKey={variant.timezone} _key={"CODE"} _value={"NAME"} />
            </div>
          </div>

          <div className="ladiui form-group group-date flex">
            <div className="date-item">
              <label className="ladiui-label">{t("PRODUCT_EVENTS.OPEN_DATE")}</label>
              <DatePicker
                name="start_date"
                minDate={baseHelper.momentToDate(this.moment())}
                selected={variant.start_date}
                onChange={(value) => this.onChangeDateTime(value, "start_date")}
                showTimeSelect
                timeFormat={appConfig.DEFAUT_TIME}
                timeIntervals={15}
                dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                timeCaption="Time"
              />
            </div>

            <div className="date-item">
              <label className="ladiui-label">{t("PRODUCT_EVENTS.CLOSE_DATE")}</label>
              <DatePicker
                name="end_date"
                minDate={variant.start_date}
                selected={variant.end_date}
                onChange={(value) => this.onChangeDateTime(value, "end_date")}
                showTimeSelect
                timeFormat={appConfig.DEFAUT_TIME}
                timeIntervals={15}
                dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                timeCaption="Time"
              />
            </div>
          </div>
          {this.state.isShowAdvanced && (
            <div className="mt-24">
              <div className="ladiui form-group group-date flex mb-24">
                <div className="date-item">
                  <label className="ladiui-label">{t("PRODUCTS.LB_MIN_BUY")}</label>
                  <NumberInput
                    name="min_buy"
                    value={variant.min_buy}
                    onChange={this.onChangeInput}
                    onlyNumber={true}
                    thousandSeparator={false}
                    decimalScale={0}
                    allowNegative={true}
                    validations={{
                      min: 1,
                    }}
                    ref={(ref) => this.inputsRef.add(ref)}
                    validationName={t("PRODUCTS.LB_MIN_BUY")}
                  />
                </div>

                <div className="date-item">
                  <label className="ladiui-label">{t("PRODUCTS.LB_MAX_BUY")}</label>
                  <NumberInput
                    name="max_buy"
                    value={variant.max_buy}
                    onChange={this.onChangeInput}
                    onlyNumber={true}
                    thousandSeparator={false}
                    decimalScale={0}
                    allowNegative={true}
                  />
                </div>
              </div>

              <div style={{ display: "inline-block", width: "100%" }}>
                <div className="ladiui-col-7 pull-left">
                  <div className="ladiui form-group mb-0">
                    <label className="ladiui-label">{t("PRODUCTS.DESCRIPTION")}</label>
                    <textarea
                      style={{ height: 250 }}
                      name="description"
                      className="ladiui textarea"
                      value={variant.description}
                      onChange={this.onChangeInput}
                    />
                  </div>
                </div>

                <div className="ladiui-col-3 pull-right text-center image setting-block-image mt-24">
                  <div style={{ width: "100%", height: 250 }}>
                    <Image width={250} height={250} resize={appConfig.RESIZE.SMALL} src={variant.src} />

                    <div className="ladiui-button-upload image block-upload-image">
                      <div className="block-overlay" style={{ marginTop: "90px" }}>
                        <button type="button" className="ladiui btn btn-primary icon">
                          <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-gallery.svg" alt="" />
                          <p>
                            Bấm vào đây để chọn ảnh sản phẩm <br /> từ máy tính của bạn
                          </p>
                        </button>
                        <input
                          type="file"
                          name="file"
                          accept="image/*"
                          onChange={this.fileChangedHandler}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="ladiui-button-upload image">
                                        <button type="button" className="ladiui button primary icon"><i className="ladi-icon icon-data-upload" />{t('COMMON.CHOOSE_FILES')}</button>
                                        <input
                                            type="file" name="file"
                                            accept="image/*"
                                            multiple="multiple"
                                            onChange={this.fileChangedHandler}
                                            onClick={(event) => {
                                                event.target.value = null
                                            }}
                                        />

                                    </div> */}
                </div>
              </div>

              <div className="ladiui form-group">
                <label className="ladiui-label">{t("COMMON.STATUS")}</label>
                <SelectOption
                  currentKey={variant.status}
                  items={appConfig.TICKET_STATUS}
                  _key={"CODE"}
                  _value={"NAME"}
                  onSelectItem={(item) => {
                    this.setState({
                      variant: {
                        ...this.state.variant,
                        status: item.CODE,
                      },
                    });
                  }}
                />
              </div>
            </div>
          )}

          <button
            onClick={() =>
              this.setState({
                isShowAdvanced: !this.state.isShowAdvanced,
              })
            }
            className="ladiui btn btn-secondary btn-sm"
          >
            {this.state.isShowAdvanced ? t("COMMON.HIDE") : t("PRODUCT_EVENTS.BTN_TICKET_SHOW_ADVANCED")}
          </button>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadTicketImage: (form) => dispatch(fileActions.uploadTicketImage(form)),
  };
};

const mapStateToProps = (state) => ({
  variantReducer: { ...state.variant },
  fileReducer: { ...state.file },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation("translation", { withRef: true })(ModalTicket));
