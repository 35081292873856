import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import baseHelper from "../../../../helpers/BaseHelper";
import Image from "../../../../components/Image";
// eslint-disable-next-line max-lines-per-function
function ModalAddProduct(props) {
  const {
    t,
    onClose,
    isShow,
    listVariant,
    currency_symbol,
    openModalNewProduct,
    selectVariant,
    searchVariant,
    onFocusVariantInput,
    onChangeSearchVariantInput,
    searchVariantValue,
    innerStyle,
    selectedProducts = [],
    isSelectedTypeProduct,
    titleModal,
    placeholder,
    isFeaturedCourse,
    isCategory,
  } = props;
  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const styleInvalid = { border: "1px solid var(--danger-color)" };
  const styleValid = { boder: "1px solid var(--main-line)" };

  const defaultValidated = {
    fullname: false,
    email: false,
    phone: false,
    status: false,
  };
  const defaultIsValidated = {
    fullname: true,
    email: true,
    phone: true,
    status: true,
  };

  const dispatch = useDispatch();

  // const [affiliate, setAffiliate] = useState(dataAffiliate);

  const [valid, setValid] = useState(defaultValidated);

  const eventCancel = () => {
    onClose(false);
  };

  return (
    <div className={`ladiui modal ${isShow ? "show" : ""}`} id="add-product">
      <div className="ladiui modal-dialog modal-dialog-centered w-600" ref={wrapperRef}>
        <div className="ladiui modal-content ">
          <div className="ladiui modal-header ">
            <h2 className="ladiui modal-title "> {titleModal ? titleModal : t("PRODUCTS.ADD_TITLE")}</h2>
            <button data-dismiss="modal" data-target="add-product" onClick={eventCancel} className="ladiui modal-close">
              <i className="ladiui icon icon-ldp-close"></i>
            </button>
          </div>
          <div className="ladiui modal-body">
            <div className="ladiui search-group">
              <input
                className="ladiui search-field dropdown-toggle form-control"
                value={searchVariantValue}
                placeholder={placeholder ? placeholder : "Tìm kiếm sản phẩm"}
                data-toggle="dropdown"
                aria-expanded="false"
                onFocus={onFocusVariantInput}
                onChange={onChangeSearchVariantInput}
              />
              <i className="ladiui icon icon-search" onClick={searchVariant}></i>
            </div>
            <div className="cursor-pointer" style={innerStyle}>
              <a className="flex-center-left cursor-pointer my-24 pd-0" onClick={openModalNewProduct}>
                <img className="mr-8" src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg"} alt="" />
                <div> {t("PRODUCTS.ADD_NEW")}</div>
              </a>
            </div>

            <div className={`list-product ${innerStyle ? "mt-24" : ""}`}>
              {listVariant.map((item, index) => {
                let isSelected = selectedProducts.some((value) => {
                  if (value.product_variant_id) {
                    return selectedProducts.some((value) => value.product_variant_id == item.product_variant_id);
                  } else if (value.variant_id) {
                    return (isSelected = selectedProducts.some((value) => value.variant_id == item.product_variant_id));
                  } else if (value.product_category_id) {
                    return selectedProducts.some((value) => value.product_category_id == item.product_category_id);
                  } else {
                    return selectedProducts.some((value) => value.product_id == item.product_id);
                  }
                });
                return (
                  <div key={index} className="flex item-product-add-new space-between">
                    <div className="flex">
                      <div className="w-70">
                        <Image src={item.src} width={48} height={48} />
                      </div>
                      <div className="w-310 product-name">{item.name}</div>
                      {isCategory && item.isShow == 0 ? <span style={{ fontSize: "12px", color: "#D94F45" }}>(Danh mục ẩn)</span> : ""}
                    </div>
                    {!isSelectedTypeProduct && <div className="w-100">{baseHelper.formatMoneyPostFix(item.price, currency_symbol)}</div>}
                    <div>
                      {baseHelper.isAvailableVariant(item) ? (
                        isSelected ? (
                          <span style={{ color: "#d94f45" }}>{t("COMMON.SELECTED")}</span>
                        ) : (
                          <button className="ladiui btn btn-outline-primary btn-sm" onClick={() => selectVariant(item)}>
                            {t("ACTIONS.SELECT")}
                          </button>
                        )
                      ) : (
                        <span style={{ color: "#d94f45" }}>{t("COMMON.SOLD_OUT")}</span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="ladiui modal-footer ">
            <div className="ladiui footer-modal-btn">
              <button
                type="button"
                data-dismiss="modal"
                data-target="add-product"
                className="ladiui btn btn-secondary bold"
                onClick={eventCancel}
              >
                {t("ACTIONS.DONE")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(ModalAddProduct);
