import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import { endpoint } from "../../../config/app";

const overviewReport = (fromDate, toDate) => ({
  type: types.OVERVIEW_REPORT,
  meta: {
    endpoint: endpoint.OVERVIEW_REPORT,
    method: REQUEST_METHOD.POST,
    body: {
      from_date: fromDate,
      to_date: toDate,
    },
    hasAuth: true,
  },
});

const orderOverTimeReport = (data) => ({
  type: types.ORDER_OVERTIME_REPORT,
  meta: {
    endpoint: endpoint.ORDER_OVERTIME_REPORT,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const topProductReport = (data) => ({
  type: types.REPORT_TOP_PRODUCT,
  meta: {
    endpoint: endpoint.REPORT_TOP_PRODUCT,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

export default {
  overviewReport,
  orderOverTimeReport,
  topProductReport,
};
