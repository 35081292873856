import React from "react";

import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { map, includes, find, remove } from "lodash";
import moment from "moment";

import appConfig from "../../config/app";
import Pagination from "react-js-pagination";
import baseHelper from "../../helpers/BaseHelper";

import * as types from "../../redux/futures/checkout/types";
import checkoutActions from "../../redux/futures/checkout/actions";

import LoadingTable from "../../components/LoadingTable";
import { withTranslation } from "react-i18next";

import OrderDateRangePicker from "../../components/OrderDateRangePicker";
import SelectOption from "../../components/SelectOption";
import ConfirmModal from "../../components/ConfirmModal";
import LadiDropdownMenu from "../../components/LadiDropdownMenu";
import LadiPagination from "../../components/LadiPagination";
import FilterConditionCheckout from "./component/FilterConditionCheckout";

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.moment = baseHelper.getMoment();
    const defaultConditions = {
      page: 1,
      limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    };

    this.state = {
      activePage: 1,
      conditionProp: {
        status: {
          value: appConfig.ORDER_STATUS.OPEN.CODE,
          name: appConfig.ORDER_STATUS.OPEN.NAME,
          condition: "=",
        },
      },
      searchName: "",
      searchEmailStatus: appConfig.CHECKOUT.EMAIL_STATUS.ALL.CODE,
      // searchOrderStatus: '',
      fromDate: null,
      toDate: null,
      selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
      checkoutSelected: {},
      conditions: defaultConditions,
      selectedIDs: [],
      totalAmount: 0,
    };

    this.sortBy = {};
  }

  componentDidMount() {
    this.list();
  }

  componentDidUpdate() {
    window.LadiUI.init();
    window.LadiUI.customInit();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (this.props.checkoutReducer.action != nextProps.checkoutReducer.action) {
      if (nextProps.checkoutReducer.action === types.DELETE_CHECKOUT) {
        if (nextProps.checkoutReducer.status) {
          this.list();

          window.LadiUI.closeModal("confirm-checkout-deletes");
          window.LadiUI.toastCustom("success", "", nextProps.checkoutReducer.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.checkoutReducer.message, "OK");
        }
      }

      if (nextProps.checkoutReducer.action === types.LIST_CHECKOUT) {
        if (nextProps.checkoutReducer.status) {
          this.setState({
            totalAmount: nextProps.checkoutReducer.total,
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.checkoutReducer.message, "OK");
        }
      }
    }
  }

  /**
   * list order
   */
  list = (resetActivePageFlag = true) => {
    if (document.getElementById("checkAllItem")) {
      document.getElementById("checkAllItem").checked = false;
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    const { searchName, selectedLimit, fromDate, toDate, searchEmailStatus, searchOrderStatus, conditions } = this.state;
    let activePage = conditions.page;

    if (resetActivePageFlag) {
      activePage = 1;

      conditions.page = 1;
      this.setState({
        conditions,
      });
    }

    // Reset selectedIDs
    // this.selectedIDs = [];
    this.setState({
      selectedIDs: [],
    });

    const data = {
      search: {
        name: searchName,
        email_status: searchEmailStatus,
        from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
        to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
      },
      paged: activePage,
      limit: conditions.limit,
      sort: this.sortBy,
    };

    this.props.list(data);
  };

  /*******************************SEARCH***************************/
  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  /**
   * Tim kiem theo name
   */
  onKeyDownInput = (event) => {
    if (event.key === "Enter") {
      this.list();
    }
  };

  /****************************************************************/

  /*******************************PAGINATION***************************/
  /**
   * Change limit
   */
  onChangeLimit = (option) => {
    let { conditions } = this.state;
    conditions.limit = option.value;

    // this.setState({ selectedLimit: option.value }, () => {
    this.setState({ conditions: conditions }, () => {
      this.list();
    });
  };

  /**
   * Change page
   */
  onPageChange = (pageNumber) => {
    let { conditions } = this.state;
    conditions.page = pageNumber;

    this.setState({ conditions }, () => {
      this.list(false);
    });
  };
  /****************************************************************/

  /*******************************CHECK AND ACTIONS***************************/
  checkAllItem = (event) => {
    const { checked } = event.target;

    let selectedIDsTg = [];
    if (checked) {
      selectedIDsTg = map(this.props.checkoutReducer.checkouts, (item) => item.checkout_id);

      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = true;
      });
    } else {
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    this.setState({
      selectedIDs: selectedIDsTg,
    });
  };

  checkItem = (event, checkoutID) => {
    const { checked } = event.target;
    const { selectedIDs } = this.state;

    if (checked) {
      if (!includes(selectedIDs, checkoutID)) {
        selectedIDs.push(checkoutID);
      }

      if (selectedIDs.length == this.props.checkoutReducer.checkouts.length) {
        document.getElementById("checkAllItem").checked = true;
      }
    } else {
      document.getElementById("checkAllItem").checked = false;
      remove(selectedIDs, (item) => item == checkoutID);
    }

    this.setState({
      selectedIDs: selectedIDs,
    });
  };

  getEmailStatus = (emailStatus) => {
    const found = find(appConfig.CHECKOUT.EMAIL_STATUS, (item) => item.CODE == emailStatus);
    if (found) {
      return found.NAME;
    }
  };

  openModalDeletes = () => {
    if (this.state.selectedIDs.length <= 0) {
      window.LadiUI.toastCustom("danger", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
      return;
    }

    window.LadiUI.showModal("confirm-checkout-deletes");
  };

  eventSelectAction = (action) => {
    switch (action) {
      case "DELETE":
        this.openModalDeletes();
        break;
      // case 'MARK_SPAM':
      //     this.openMarkSpamModal();
      //     break;
      // case 'CANCEL_ORDER':
      //     this.openModalCancel();
      //     break;
      // case 'CONFIRM_PAYMENT':
      //     this.openModalConfirmPayment();
      //     break;
      // case 'PRINT':
      //     this.prints();
      //     break;
      default:
    }
  };

  clearFilter = (callback) => {
    this.setState(
      {
        searchEmailStatus: "",
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  filterActionProp = async (conditions) => {
    this.clearFilter();

    // let filter = {
    //     tabSelected: appConfig.ORDER_FILTER_TYPE.SEARCH_ALL,
    //     searchName: ""
    // }

    for await (const condition of conditions) {
      switch (condition.attributeFilter.value) {
        case "email_status":
          // filter.searchRule = condition.listFilter.map(item => item.value);
          this.setState({
            searchEmailStatus: condition.valueFilter.value,
          });
          break;
        default:
          break;
      }
    }

    // this.setState({
    //     ...filter
    // }, () => {
    //     this.list();
    // })
    this.list();
  };

  /****************************************************************/
  render() {
    const { t } = this.props;
    const { totalRecord } = this.props.checkoutReducer;
    const { activePage, selectedLimit, searchName, searchEmailStatus, searchOrderStatus, conditions, conditionProp } = this.state;

    let fromItem = 0;
    let toItem = 0;
    if ((this.props.checkoutReducer.checkouts || []).length > 0) {
      fromItem = (activePage - 1) * selectedLimit + 1;
      toItem = fromItem + this.props.checkoutReducer.checkouts.length - 1;
    }

    const msgShowItem = `${t("PAGINATION.SHOW")} ${fromItem} ${t("PAGINATION.TO")} ${toItem} ${t(
      "PAGINATION.OF"
    )} ${baseHelper.formatNumber(totalRecord)} ${t("PAGINATION.ITEMS")}`;

    const isLoadingData = this.props.checkoutReducer.loading && this.props.checkoutReducer.waiting == types.LIST_CHECKOUT;
    const isLoadingDelete = this.props.checkoutReducer.loading && this.props.checkoutReducer.waiting == types.DELETE_CHECKOUT;

    const listAction = [
      { value: "DELETE", name: t("ACTIONS.DELETE") },
      // { value: 'REMOVE_MARK_SPAM', name: t('ORDERS.REMOVE_MARK_SPAM') },
      // { value: 'MARK_SPAM', name: t('ORDERS.MARK_SPAM') },
      // { value: 'CANCEL_ORDER', name: t('ORDERS.CANCEL_ORDER') },
      // { value: 'CONFIRM_PAYMENT', name: t('ORDERS.CONFIRM_PAYMENT') },
    ];

    return (
      <div className="page-content">
        <div>
          <div className="ladiui-table-actions">
            <div
              className="ladiui block-filter-condition"
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div className="ladiui btn-group block-filter-header">
                <FilterConditionCheckout filterActionProp={this.filterActionProp} conditionProp={conditionProp} />
              </div>
              <div className="ladiui search-group mr-24">
                <input
                  id="keyword_search"
                  className="ladiui search-field dropdown-toggle form-control search-width"
                  name="searchName"
                  placeholder={t("CHECKOUTS.SEARCH_ORDER")}
                  aria-expanded="false"
                  value={searchName}
                  onChange={this.onChangeInput}
                  onKeyDown={this.onKeyDownInput}
                />
                <i className="ladiui icon icon-search" onClick={this.list}></i>
              </div>
              <div className="date-filter">
                <OrderDateRangePicker
                  startDateId="fromDate"
                  endDateId="toDate"
                  startDate={this.state.fromDate}
                  endDate={this.state.toDate}
                  onDatesChange={({ startDate, endDate }) => {
                    this.setState(
                      {
                        fromDate: baseHelper.getFromDate(startDate),
                        toDate: baseHelper.getToDate(endDate),
                      },
                      () => {
                        if ((startDate && endDate) || (!startDate && !endDate)) {
                          this.list();
                        }
                      }
                    );
                  }}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={(focusedInput) => {
                    this.setState({ focusedInput });
                  }}
                  showDefaultInputIcon={true}
                  inputIconPosition="after"
                  small={true}
                  hideKeyboardShortcutsPanel={true}
                  customInputIcon={<i className="ladi-icon icon-calendar-date-2" />}
                  startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                  endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                  isOutsideRange={() => false}
                  isDayHighlighted={(date) => {
                    return this.moment().isSame(date, "day");
                  }}
                  minimumNights={0}
                  showClearDates={true}
                />
              </div>
            </div>
          </div>
          <div className="order-tab"></div>
          {isLoadingData ? (
            <LoadingTable />
          ) : (
            <div>
              <div className="ladi-card ladiui-table-responsive">
                <table className="ladiui table text-left order-table table-order">
                  <thead style={{ position: "relative" }}>
                    <tr className="ladiui table-vertical header">
                      <th scope="col" className="ladiui checkall">
                        <input
                          id="checkAllItem"
                          onClick={this.checkAllItem}
                          type="checkbox"
                          className="ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                        />
                      </th>
                      {this.state.selectedIDs.length <= 0 ? (
                        <th scope="col">{t("ORDERS.REF_NO")}</th>
                      ) : (
                        <th scope="col">
                          {t("ORDERS.REF_NO")}
                          <LadiDropdownMenu
                            labelDropdown={"Chọn hành động"}
                            listProp={listAction}
                            defaultTextProp={t("ACTIONS.TITLE")}
                            hideLabel={true}
                            cbProp={this.eventSelectAction}
                            classSize="btn-sm "
                            classWidth="w-180"
                            classDropdown="w-180 action-record-table"
                          />
                        </th>
                      )}
                      <th scope="col">{t("CHECKOUTS.LB_CUSTOMER")}</th>
                      <th scope="col">{t("COMMON.CREATED_AT")}</th>
                      <th scope="col">{t("CHECKOUTS.LB_EMAIL_STATUS")}</th>
                      <th scope="col">{t("ORDERS.TOTAL")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(this.props.checkoutReducer.checkouts, (item) => {
                      return (
                        <tr key={item.checkout_id} className={`ladiui table-vertical main`}>
                          <td>
                            <input
                              type="checkbox"
                              onClick={(event) => this.checkItem(event, item.checkout_id)}
                              className="ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                            />
                          </td>
                          <td style={{ position: "relative" }}>
                            <NavLink className="order-table-item" to={`/checkouts/${item.checkout_id}`}>{`#${item.checkout_id}`}</NavLink>
                          </td>
                          <td>{`${baseHelper.getText(item.customer_email)}` || t("COMMON.NO_EMAIL")}</td>
                          <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                          <td>{this.getEmailStatus(item.email_status)}</td>
                          <td>
                            {baseHelper.formatMoneyPostFix(item.total, this.props.storeReducer.userInfo.currentStore.currency_symbol)}
                          </td>
                        </tr>
                      );
                    })}
                    {totalRecord <= 0 && (
                      <tr className="text-center">
                        <td colSpan="100%">{t("NO_DATA")}</td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={5}>
                        <strong>{t("ORDERS.TOTAL_AMOUNT")}</strong>
                      </td>
                      <td>
                        <strong className="pl-0">{baseHelper.formatMoneyPostFix(this.state.totalAmount, this.props.storeReducer.userInfo.currentStore.currency_symbol)}</strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              {totalRecord > 0 && (
                <LadiPagination
                  conditionsProp={conditions}
                  listName={t("CUSTOM_FIELD.ORDER")}
                  message={""}
                  items={this.props.checkoutReducer.checkouts}
                  total={totalRecord}
                  limitAction={this.onChangeLimit}
                  pageAction={this.onPageChange}
                />
              )}
            </div>
          )}
        </div>

        <ConfirmModal
          id="confirm-checkout-deletes"
          title={t("CHECKOUTS.MSG_DELETE_CHECKOUT_TITLE")}
          content={t("CHECKOUTS.MSG_DELETE_CHECKOUTS_CONTENT")}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={() => this.props.deletes({ checkout_ids: this.state.selectedIDs })}
          isLoading={isLoadingDelete}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    list: (data) => dispatch(checkoutActions.list(data)),
    show: (checkoutID) => dispatch(checkoutActions.show(checkoutID)),
    deletes: (data) => dispatch(checkoutActions.deletes(data)),
  };
};

const mapStateToProps = (state) => ({
  checkoutReducer: { ...state.checkout },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Checkout));
