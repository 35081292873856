/**
 * Import default package
 *
 * @format
 */

/** Import from third party */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../assets/css/ladiui.gallery.css';
import ConfirmModal from '../../../../components/ConfirmModal';
/** Import component from my app */
import Input from '../../../../components/Input';
import LoadingScene from '../../../../components/LoadingScene';
import Modal from '../../../../components/Modal';
/** Import redux */

import productImageActions from '../../../../redux/futures/product_image/actions';
import * as productImageTypes from '../../../../redux/futures/product_image/types';

import productTagActions from '../../../../redux/futures/product_tag/actions';
import * as productTagTypes from '../../../../redux/futures/product_tag/types';

import fileActions from '../../../../redux/futures/file/actions';
import * as fileTypes from '../../../../redux/futures/file/types';

import productActions from '../../../../redux/futures/product/actions';
import * as productTypes from '../../../../redux/futures/product/types';

import PanelCreateVariant from '../ModalCreate/PanelCreateVariant';
import PanelEditVariant from './components/PanelEditVariant';
import Tag from '../../../../components/Tag';

import appConfig from '../../../../config/app';
import NumberInput from '../../../../components/NumberInput';

import * as productCategoryTypes from '../../../../redux/futures/product_category/types';
import productCategoryActions from '../../../../redux/futures/product_category/actions';

import _, { remove, cloneDeep, map, compact, includes, pick, find } from 'lodash';
import SelectOption from '../../../../components/SelectOption';
import baseHelper from '../../../../helpers/BaseHelper';
import RelateProduct from '../AttachedProduct';
import produce from 'immer';
import PanelProductCheckout from '../../../../components/PanelProductCheckout';
import PanelCustomField from '../../../../components/PanelCustomField';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import ListImageProduct from '../../../../components/ListImageProduct';
import { Editor } from '@tinymce/tinymce-react';
import PanelProductSellSocial from '../../../../components/PanelProductSeoSocial';
import ValidatorHelper from '../../../../helpers/ValidatorHelper';

import SelectManyDropdown from '../../../../components/SelectManyDropdown';

import ModalAddProduct from '../../../orders/OrderCreate/components/ModalAddProduct';

class ModalEditProduct extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        visible: PropTypes.bool,
        loading: PropTypes.bool,
        openVariantID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    constructor(props) {
        super(props);

        this.state = {
            isCopy: false,
            isReloadIframe: false,
            categories: [],
            product_category_ids: [],
            product: {
                product_id: '',
                product_variant_id: '',
                name: '',
                alias_name: '',
                domain: '',
                path: '',
                payment_redirect_url: '',
                payment_redirect_after: appConfig.DEFAULT_PAYMENT_REDIRECT_AFTER,
                url_published: '',
                description: '',
                price: '0',
                price_compare: '',
                cost_per_item: '',
                min_buy: 1,
                max_buy: '',
                sku: '',
                weight: '',
                weight_unit: '',
                inventory_checked: appConfig.INVENTORY_STATUS.INACTIVE.CODE,
                allow_sold_out: appConfig.STATUS.INACTIVE,
                quantity: 0,
                display_type: appConfig.DISPLAY_TYPE.DEFAULT.CODE,
                type: appConfig.PRODUCT_TYPE_CONFIG.PHYSICAL.CODE,
                checkout_config_id: '',
                status: appConfig.PRODUCT_STATUS.INACTIVE.CODE,
                external_link: '',
                interface_options: [],
                cta: 0,
                selectedIconCTA: 'cta-add',
                seo: {},
                is_one_time: false,
            },
            isShowAdvanced: false,
            isShowBlockCustomField: false,
            seo_social: {
                title_page: '',
                description_page: '',
                img_publish_page: '',
                favicon_page: '',
            },
            images: [],
            variants: [],
            customFields: [],
            options: [],
            tags: [],
            // relateProducts: [],
            productUpSells: [],
            tabIndexActive: 0,
            // isShowModalAddProduct: false,
        };

        this.selectedImage = {};

        this.ref = React.createRef();
        this.fileInputRef = React.createRef();
        this.panelCreateVariantRef = React.createRef();
        this.panelEditVariantRef = React.createRef();

        this.inputsRef = new Set();
        this.tagRef = React.createRef();
        this.relateProductRef = React.createRef();
        this.productUpSellRef = React.createRef();
        this.panelProductCheckoutRef = React.createRef();
        this.panelCustomFieldRef = React.createRef();
        this.productSeoSocialRef = React.createRef();

        // this.PanelProductSellSocialRef = React.createRef();
    }

    componentDidMount() {
        let paramUrl = queryString.parse(this.props.location.search);
        this.props.show(this.props.match.params['productID']);

        if (this.props.openVariantID) {
            setTimeout(() => {
                document.getElementById('trigger-tab-variants-edit').click();
            }, 100);
        }

        const { tab } = queryString.parse(this.props.location.search);
        if (tab == 'checkout') {
            setTimeout(() => {
                document.getElementById('trigger-tab-checkout').click();
            });
            this.props.history.push(`/products`);
        }

        if (paramUrl.is_copy) {
            this.setState({
                isCopy: true,
            });
        }

        this.props.listAllTags();

        let payload = {
            search: {
                type: "Product"
            }
        }

        this.props.listCategorySelect(payload);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.productImage.action != nextProps.productImage.action) {
            if (nextProps.productImage.action === productImageTypes.PRODUCT_IMAGE_CREATE) {
                if (nextProps.productImage.status) {
                    const { images } = this.state;

                    images.push(...nextProps.productImage.images);
                    this.setState(
                        {
                            // images: produce(images, (draft) => {
                            //   draft.push(...nextProps.productImage.images);
                            // }),
                            images,
                        },
                        () => this.renewGallery()
                    );
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.productImage.message, 'OK');
                }
            }

            if (nextProps.productImage.action === productImageTypes.PRODUCT_IMAGE_DELETE) {
                if (nextProps.productImage.status) {
                    const { images } = this.state;
                    remove(images, (image) => image.src_hash == this.selectedImage.src_hash);

                    this.setState(
                        {
                            images,
                        },
                        () => this.renewGallery()
                    );

                    this.selectedImage = {};

                    window.LadiUI.toastCustom('success', '', nextProps.productImage.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.productImage.message, 'OK');
                }

                window.LadiUI.closeModal('confirm-image-delete');
            }
        }

        if (this.props.fileReducer.action != nextProps.fileReducer.action) {
            if (nextProps.fileReducer.action == fileTypes.UPLOAD_PRODUCT_IMAGES) {
                if (nextProps.fileReducer.status) {
                    const { images, isCopy } = this.state;
                    const newUploadedImage = map(nextProps.fileReducer.product_images, (item, index) => {
                        return {
                            src: item,
                            position: images.length + index,
                        };
                    });

                    if (!isCopy) {
                        const productImages = {
                            product_id: this.state.product.product_id,
                            images: newUploadedImage,
                        };
                        this.props.createImages(productImages);
                    } else {
                        let listImage = [...images];
                        listImage.push(...newUploadedImage);

                        this.setState({
                            images: listImage,
                        });
                    }
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.fileReducer.message, 'OK');
                }
            }
        }

        if (nextProps.product.action === productTypes.SHOW_PRODUCT) {
            if (nextProps.product.status) {
                // baseHelper.normalizeCopyProduct({ ...nextProps.product.product });
                let normalizeCopyProduct = { ...nextProps.product.product };
                if (this.state.isCopy) {
                    this.initCreate(normalizeCopyProduct);
                } else {
                    this.initUpdate(normalizeCopyProduct);
                }
                // this.init(nextProps.product.product);
            } else {
                // Không show lôi, thay vào đó redirect
                this.props.history.push('/products');
            }
        }

        if (nextProps.product.action === productTypes.UPDATE_PRODUCT) {
            const { isReloadIframe } = this.state;

            this.setState({
                isReloadIframe: !isReloadIframe,
            });
            if (nextProps.product.status) {
                window.LadiUI.toastCustom('success', '', nextProps.product.message);
                this.props.reloadProduct(this.state.product.product_id);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
            }
        }

        if (nextProps.product.action === productTypes.CREATE_PRODUCT) {
            const { isReloadIframe } = this.state;

            this.setState({
                isReloadIframe: !isReloadIframe,
            });
            if (nextProps.product.status) {
                window.LadiUI.toastCustom('success', '', nextProps.product.message);
                this.props.history.push(`/products`);
                // this.props.reloadProduct(this.state.product.product_id);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
            }
        }

        if (nextProps.product.action === productTypes.RELOAD_PRODUCT) {
            if (nextProps.product.status) {
                this.initUpdate(nextProps.product.product);
            }
        }

        if (this.props.productCategory.action != nextProps.productCategory.action) {
            if (nextProps.productCategory.action === productCategoryTypes.LIST_SELECT_CATEGORY) {
                if (nextProps.productCategory.status) {
                    let _categories = nextProps.productCategory.listSelects;
                    let _data = [];
                    _categories.map((item, index) => {
                        _data.push({
                            level: item.level,
                            name: item.name,
                            value: item.product_category_id,
                        });
                    });
                    this.setState({
                        categories: _data,
                    });
                }
            }
        }
    }

    handleSetImage = (images) => {
        this.setState({
            images,
        });
    };

    initCreate = async (props) => {
        let currentProductInfo = cloneDeep(props);
        currentProductInfo = await baseHelper.normalizeCopyProduct(currentProductInfo);

        const customFields = currentProductInfo.custom_fields || [];
        delete currentProductInfo.path;
        delete currentProductInfo.url_published;
        let { seo } = currentProductInfo;
        let product = { ...currentProductInfo };

        if (currentProductInfo.interface_options) {
            product.interface_options = JSON.parse(currentProductInfo.interface_options);
        } else product.interface_options = [];

        let newProduct = {};

        map(this.state.product, (value, key) => {
            newProduct[key] = !baseHelper.isEmpty(product[key]) ? product[key] : '';
            if (key == 'min_buy' && baseHelper.isEmpty(product[key])) {
                newProduct[key] = 1;
            }
            if (key == 'seo' && baseHelper.isEmpty(product[key])) {
                newProduct['seo'] = {
                    title: '',
                    description: '',
                    keywords: '',
                    image: '',
                    canonical_url: '',
                };
            }
        });

        const newCustomFields = !baseHelper.isEmpty(customFields) ? customFields : [];

        this.setState({
            product: newProduct,
            images: currentProductInfo.images,
            variants: currentProductInfo.variants,
            tags: currentProductInfo.tags,
            options: currentProductInfo.options,
            customFields: newCustomFields,
            productUpSells: currentProductInfo.product_up_sells,
        });
    };

    getIconCTA = (code) => {
        const matched = find(appConfig.LIST_CTA, (item) => item.CODE == code) || {};
        if (matched) {
            return matched.CLASS_ICON;
        }
        return 'cta-add';
    };

    initUpdate = (props) => {
        const currentProductInfo = cloneDeep(props);

        // let { tags, images, seo_socials } = currentProductInfo;
        const customFields = currentProductInfo.custom_fields || [];
        let { tags, images, seo } = currentProductInfo;
        let options = currentProductInfo.options || [];
        let variants = currentProductInfo.variants || [];
        // let relateProducts = currentProductInfo.relate_products || [];
        let productUpSells = currentProductInfo.product_up_sells || [];
        delete currentProductInfo.tags;
        delete currentProductInfo.options;
        delete currentProductInfo.variants;
        delete currentProductInfo.images;
        delete currentProductInfo.relate_products;
        delete currentProductInfo.product_up_sells;
        // delete currentProductInfo.seo_socials;

        let product = { ...currentProductInfo };

        if (currentProductInfo.interface_options) {
            product.interface_options = JSON.parse(currentProductInfo.interface_options);
        } else product.interface_options = [];

        if (currentProductInfo.product_category_ids && currentProductInfo.product_category_ids.length > 0) {
            this.setState({
                product_category_ids: currentProductInfo.product_category_ids,
            });
        }

        let newProduct = {};
        map(this.state.product, (value, key) => {
            newProduct[key] = !baseHelper.isEmpty(product[key]) ? product[key] : '';
            if (key == 'path' && baseHelper.isEmpty(newProduct[key])) {
                newProduct[key] = '' + product.product_id;
            }
            if (key == 'cta' && baseHelper.isEmpty(newProduct[key])) {
                newProduct[key] = 0;
            }

            if (key == 'selectedIconCTA' && baseHelper.isEmpty(product['cta'])) {
                newProduct[key] = 'cta-add';
            }

            if (key == 'selectedIconCTA' && !baseHelper.isEmpty(product['cta'])) {
                newProduct['selectedIconCTA'] = this.getIconCTA(product['cta']);
            }

            if (key == 'seo' && baseHelper.isEmpty(product[key])) {
                newProduct['seo'] = {
                    title: '',
                    description: '',
                    keywords: '',
                    image: '',
                    canonical_url: '',
                };
            }
        });

        // Assign default variant to product
        if (options.length <= 0) {
            newProduct = {
                ...newProduct,
                ...pick(variants[0], [
                    'product_variant_id',
                    'price',
                    'sku',
                    'price_compare',
                    'cost_per_item',
                    'weight',
                    'weight_unit',
                    'quantity',
                    'min_buy',
                    'max_buy',
                    'display_type',
                    'inventory_checked',
                    'allow_sold_out',
                ]),
            };

            variants.length = 0;
        }

        const newCustomFields = !baseHelper.isEmpty(customFields) ? customFields : [];

        const newTags = tags || [];
        const newVariants = variants || [];
        const newImages = images || [];
        const newOptions = options || [];
        const newProductUpSells = productUpSells || [];

        this.setState(
            {
                product: newProduct,
                tags: newTags,
                variants: newVariants,
                images: newImages,
                options: newOptions,
                customFields: newCustomFields,
                productUpSells: newProductUpSells,
            },
            () => this.renewGallery()
        );
    };

    renewGallery = () => {
        if (document.getElementById('gallery-edit')) {
            window.renewGallery(['gallery-edit'], 0);
        }
    };

    /*******************************HANDLE INPUT***************************/
    onChangeProductInput = (event) => {
        this.setState({
            product: {
                ...this.state.product,
                [event.target.name]: event.target.value,
                alias_name: event.target.name == 'name' ? baseHelper.getAliasName(event.target.value) : this.state.product.alias_name,
            },
        });
    };

    handleEditorChange = (value) => {
        this.setState({
            product: {
                ...this.state.product,
                description: value,
            },
        });
    };

    onChangeInput = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    };
    /*****************************************************************/

    /******************************IMAGE*********************************/
    fileChangedHandler = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                this.props.uploadProductImages(form);
            }
        }
    };

    openConfirmDeleteModal = (selectedImage) => {
        this.selectedImage = selectedImage;

        window.LadiUI.showModal('confirm-image-delete');
    };

    deleteImage = () => {
        const { product } = this.state;
        const { selectedImage } = this;
        this.props.deleteImage(product.product_id, selectedImage.src_hash);
    };
    /*****************************************************************/

    submit = () => {
        // Validate
        let errors = [];
        let flagFocusTab = true;
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    if (flagFocusTab) {
                        const tabActiveID = ReactDOM.findDOMNode(ref.ref.current).closest('.ladiui.tab-pane').getAttribute('id');
                        document.getElementById(`trigger-${tabActiveID}`).click();
                    }
                    flagFocusTab = false;
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        if (this.state.tabIndexActive == 3) {
            if (!this.panelProductCheckoutRef.current.validate()) {
                return;
            }
        }

        // Tao productRequest
        const { product, isCopy, images } = this.state;

        let createData = {};
        let options = [];

        if (isCopy) {
            if (this.panelCreateVariantRef.current) createData = this.panelCreateVariantRef.current.getData();
            options = createData.options;
        } else {
            if (this.panelEditVariantRef.current) createData = this.panelEditVariantRef.current.getData();
            options = this.state.options;
        }

        const tags = this.tagRef.current.getData();

        const productCustomField = this.panelCustomFieldRef.current.getData();

        const productUpSells = this.productUpSellRef.current.getData();

        const productSeoSocial = this.productSeoSocialRef.current.getData();

        if (productSeoSocial) {
            if (productSeoSocial.title && productSeoSocial.title.length > 70) {
                window.LadiUI.toastCustom('warning', '', 'Tiêu đề vượt quá số ký tự quy định!');
                return;
            }
            if (productSeoSocial.description && productSeoSocial.description.length > 170) {
                window.LadiUI.toastCustom('warning', '', 'Mô tả vượt quá số ký tự quy định!');
                return;
            }
            if (productSeoSocial.keywords && productSeoSocial.keywords.length > 170) {
                window.LadiUI.toastCustom('warning', '', 'Từ khóa vượt quá số ký tự quy định!');
                return;
            }
        }

        let productReq = {};
        if (options.length > 0) {
            // let createData = {};
            let variants = [];

            variants = createData.variants;
            let newOptions = createData.options;

            productReq = {
                ...product,
                interface_options: JSON.stringify(product.interface_options),
                variants,
                tags,
                images,
                options: newOptions,
                custom_fields: productCustomField,
                product_up_sells: productUpSells,
                is_publish: true,
                seo: { ...productSeoSocial },
                product_category_ids: this.state.product_category_ids || [],
            };
        } else {
            const createData = this.panelCreateVariantRef.current.getData();
            let variants = createData.variants;
            let newOptions = createData.options;

            variants = this.getVariantReq(variants);

            productReq = {
                ...product,
                interface_options: JSON.stringify(product.interface_options),
                variants,
                options: newOptions,
                tags,
                images,
                custom_fields: productCustomField,
                product_up_sells: productUpSells,
                is_publish: true,
                seo: { ...productSeoSocial },
                product_category_ids: this.state.product_category_ids || [],
            };
        }
        // this.props.onSubmit(productReq);
        if (this.state.isCopy) {
            this.props.create(productReq);
        } else {
            this.props.update(productReq);
        }
    };

    /**
     * @description Neu variants empty => tao variant mac dinh
     */
    getVariantReq = (variants) => {
        const {
            product_id,
            product_variant_id,
            price,
            price_compare,
            cost_per_item,
            sku,
            weight,
            weight_unit,
            inventory_checked,
            allow_sold_out,
            quantity,
            min_buy,
            max_buy,
        } = this.state.product;

        if (variants.length <= 0) {
            const defaultVariant = {
                product_id,
                product_variant_id,
                price,
                price_compare,
                cost_per_item,
                sku,
                weight,
                weight_unit,
                inventory_checked,
                allow_sold_out,
                quantity,
                min_buy,
                max_buy,
            };

            if (this.state.isCopy) {
                delete defaultVariant.product_id;
                delete defaultVariant.product_variant_id;
            }

            variants.push(defaultVariant);
        }

        return variants;
    };

    getVariantPanel = () => {
        const { product, variants, options, images, isCopy } = this.state;
        const hasNoVariant = variants.length == 0;
        if (hasNoVariant || (!hasNoVariant && isCopy)) {
            return (
                <PanelCreateVariant
                    ref={this.panelCreateVariantRef}
                    variants={variants}
                    inputsRef={this.inputsRef}
                    product={product}
                    options={options}
                    images={images}
                />
            );
        }

        return (
            <PanelEditVariant
                ref={this.panelEditVariantRef}
                product={product}
                variants={variants}
                options={options}
                images={images}
                openVariantID={this.props.openVariantID}
            />
        );
    };

    getNameProductByType = (type) => {
        const matched = find(appConfig.PRODUCT_TYPE, (item) => item.CODE == type) || {};
        return matched.NAME;
    };

    actionDeleteImage = (item) => {
        this.openConfirmDeleteModal(item);
    };

    onChangeCTA = async (item) => {
        this.setState({
            product: {
                ...this.state.product,
                cta: item.CODE,
                selectedIconCTA: item.CLASS_ICON,
            },
        });
    };

    handleRefreshDataCategories = () => {
        let payload = {
            search: {
                type: "Product"
            }
        }
        this.props.listCategorySelect(payload);
    };

    render() {
        const { t } = this.props;
        const {
            product,
            seo_social,
            variants,
            tags,
            images,
            productUpSells,
            customFields,
            isReloadIframe,
            isCopy,
            categories,
            product_category_ids,
        } = this.state;

        const { currency_symbol: currencySymbol, currency_code: currencyCode } = this.props.store.userInfo.currentStore;

        const hasNoVariant = variants.length == 0;
        const isLoadingDelete =
            this.props.productImage.loading && includes([productImageTypes.PRODUCT_IMAGE_DELETE], this.props.productImage.waiting);
        const isLoadingImage =
            this.props.productImage.loading && includes([productImageTypes.PRODUCT_IMAGE_DELETE], this.props.productImage.waiting);
        // const isLoadingUpload =
        //   this.props.fileReducer.loading &&
        //   this.props.fileReducer.waiting == fileTypes.UPLOAD_PRODUCT_IMAGES;

        const isSubmitLoading =
            this.props.product.loading && includes([productTypes.CREATE_PRODUCT, productTypes.UPDATE_PRODUCT], this.props.product.waiting);

        const allTags = this.props.productTag.allTags || [];

        return (
            <div className='page-content'>
                {(isLoadingImage || isSubmitLoading) && <LoadingScene blur={true} />}
                <div className='ladiui content-main-title flex-row mb-20'>
                    <div className='ladiui flex-row'>
                        {isCopy || isCopy == 'true' ? (
                            <div className='ladiui main-title'>{t('PRODUCTS.ADD_TITLE')}</div>
                        ) : (
                            <div className='ladiui main-title'>{t('PRODUCTS.EDIT_PRODUCT_TITLE')}</div>
                        )}
                    </div>
                    <div className='flex'>
                        <button onClick={(e) => this.props.history.push('/products')} className='ladiui btn btn-secondary mr-24 '>
                            {t('ACTIONS.REMOVE')}
                        </button>

                        <button
                            type='button'
                            className={`ladiui btn btn-primary btn-lg btn-open-modal ${isSubmitLoading ? 'loader' : ''}`}
                            disabled={isSubmitLoading}
                            onClick={this.submit}
                        >
                            <div>{isCopy ? t('PRODUCTS.ADD_TITLE') : t('PRODUCTS.SAVE_PRODUCT_INFO')}</div>
                        </button>
                    </div>
                </div>

                <div>
                    <ul className='ladiui nav nav-tabs' id='tabProductEdit' role='tablist'>
                        <li className={`ladiui nav-item `} role='presentation'>
                            <span
                                className='ladiui tab-link active'
                                id='trigger-tab-general-edit'
                                data-toggle='tab'
                                data-parent='tabProductEdit'
                                onClick={() => this.setState({ tabIndexActive: 0 })}
                                data-target='tab-general-edit'
                            >
                                {t('PRODUCTS.PRODUCT_INFO')}
                            </span>
                        </li>
                        {product.type != appConfig.PRODUCT_TYPE_CONFIG.COMBO.CODE ? (
                            <li className={`ladiui nav-item `} role='presentation'>
                                <span
                                    className='ladiui tab-link'
                                    id='trigger-tab-variants-edit'
                                    data-toggle='tab'
                                    data-parent='tabProductEdit'
                                    onClick={() => this.setState({ tabIndexActive: 1 })}
                                    data-target='tab-variants-edit'
                                >
                                    {t('COMMON.TAB_VARIANT')}
                                </span>
                            </li>
                        ) : (
                            ''
                        )}
                        <li className={`ladiui nav-item `} role='presentation'>
                            <span
                                className='ladiui tab-link'
                                id='trigger-tab-product-up-sell'
                                data-toggle='tab'
                                data-parent='tabProductEdit'
                                onClick={() => this.setState({ tabIndexActive: 2 })}
                                data-target='tab-product-up-sell'
                            >
                                {product.type == appConfig.PRODUCT_TYPE_CONFIG.PHYSICAL.CODE
                                    ? t('PRODUCTS.PRODUCT_UP_SELL')
                                    : product.type == appConfig.PRODUCT_TYPE_CONFIG.COMBO.CODE
                                        ? t('PRODUCTS.PRODUCT_COMBO')
                                        : t('PRODUCTS.PRODUCT_TOPPING')}
                            </span>
                        </li>
                        <li className={`ladiui nav-item`} role='presentation'>
                            <span
                                className='ladiui tab-link'
                                id='trigger-tab-checkout'
                                data-toggle='tab'
                                data-parent='tabProductEdit'
                                onClick={() => this.setState({ tabIndexActive: 3 })}
                                data-target='tab-checkout'
                            >
                                {t('PRODUCT_EVENTS.TAB_EVENT_CHECKOUT')}
                            </span>
                        </li>
                        <li className={`ladiui nav-item`} role='presentation'>
                            <span
                                className='ladiui tab-link'
                                id='trigger-tab-seo-social'
                                data-toggle='tab'
                                data-parent='tabProductEdit'
                                onClick={() => this.setState({ tabIndexActive: 4 })}
                                data-target='tab-seo-social'
                            >
                                Seo & Social
                            </span>
                        </li>
                    </ul>

                    <div className='ladiui tab-content ' id='tabProductContentEdit'>
                        <div
                            className='ladiui tab-pane fade show open mt-0'
                            id='tab-general-edit'
                            role='tabpanel'
                            aria-labelledby='trigger-tab-general-edit'
                        >
                            <div className='ladiui-product-left block-product-content-left mt-24'>
                                <ListImageProduct
                                    listImage={images}
                                    handleDeleteImage={this.actionDeleteImage}
                                    handleSetImage={this.handleSetImage}
                                    productID={product.product_id}
                                    isCopy={isCopy}
                                    handleSetImageCopy={this.handleSetImageCopy}
                                />

                                <div className='ladiui form-group'>
                                    <label className='ladiui title-text-form-lever2'>{t('PRODUCTS.LB_EXTERNAL_LINK')}</label>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name='external_link'
                                        validationName={t('PRODUCTS.LB_EXTERNAL_LINK')}
                                        placeholder={t('PRODUCTS.LB_EXTERNAL_LINK_PLACEHOLDER')}
                                        value={product.external_link}
                                        onChange={this.onChangeProductInput}
                                        validations={{ isURL: true }}
                                    />
                                </div>

                                <Tag ref={this.tagRef} id='product-tag' allTags={allTags} selectedTags={tags} />

                                <div className='ladiui toggle-dropdown-product-edit block-custom-field'>
                                    <h3
                                        className={`ladiui title-block pointer relative mb-24 ${this.state.isShowBlockCustomField ? 'arrow-up' : 'arrow-down'
                                            }`}
                                        onClick={() =>
                                            this.setState({
                                                isShowBlockCustomField: !this.state.isShowBlockCustomField,
                                            })
                                        }
                                    >
                                        {t('PRODUCTS.CUSTOM_FIELD')}
                                    </h3>
                                    <div style={this.state.isShowBlockCustomField ? { display: 'block' } : { display: 'none' }}>
                                        <PanelCustomField
                                            ref={this.panelCustomFieldRef}
                                            customFields={customFields}
                                            groupType={appConfig.CUSTOM_FIELD.GROUP_TYPES.PRODUCT.CODE}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='ladiui-product-right'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui title-text-form-lever2'>{t('PRODUCTS.NAME')}</label>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name='name'
                                        validationName={t('PRODUCTS.NAME')}
                                        placeholder='T-Shirt'
                                        value={product.name}
                                        onChange={this.onChangeProductInput}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui title-text-form-lever2'>Danh mục</label>
                                    <SelectManyDropdown
                                        data={categories}
                                        _key='name'
                                        _value='value'
                                        pickTitle={'Tiêu đề'}
                                        placeHolder={'Chọn danh mục'}
                                        onSubmit={(items) => {
                                            this.setState({
                                                product_category_ids: items,
                                            });
                                        }}
                                        innerClassName='w100'
                                        styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                                        buttonStyle={{
                                            width: '100%',
                                            paddingRight: '30px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: ' -webkit-box',
                                            webkitLineClamp: 1,
                                            webkitBoxOrient: 'vertical',
                                            textAlign: 'left',
                                        }}
                                        selectedItems={product_category_ids}
                                        isShowBlockInputSearch={true}
                                        isCreateCategory={true}
                                        handleRefreshData={this.handleRefreshDataCategories}
                                    />
                                </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui title-text-form-lever2'>
                                        {t('PRODUCTS.PRODUCT_TYPE')} : {baseHelper.getLabelNameByType(product.type)}
                                    </label>
                                </div>

                                {product.type == 'COMBO' && (
                                    <div className='ladiui form-group'>
                                        <label className='ladiui title-text-form-lever2'>{t('PRODUCTS.DISPLAY_TYPE')}</label>
                                        <SelectOption
                                            wrapperClassName='select-assignee'
                                            innerClassName='ellipsis'
                                            currentKey={this.state.product.display_type}
                                            _key='CODE'
                                            _value='NAME'
                                            items={appConfig.DISPLAY_TYPE || []}
                                            onSelectItem={(item) => {
                                                this.setState({
                                                    product: {
                                                        ...this.state.product,
                                                        display_type: item.CODE,
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                )}

                                <div className='ladiui form-group'>
                                    <label className='ladiui title-text-form-lever2'>{t('PRODUCTS.DESCRIPTION')}</label>
                                    <Editor
                                        value={product.description}
                                        init={{
                                            height: 250,
                                            plugins: 'preview powerpaste autosave fullscreen lists checklist',
                                            toolbar:
                                                'bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat',
                                            toolbar_drawer: 'sliding',
                                            language_url: '../../../../language/tinymce-vi',
                                            valid_elements: '*[*]',
                                        }}
                                        onEditorChange={this.handleEditorChange}
                                    />
                                </div>

                                {hasNoVariant && (
                                    <div className='ladiui form-group group-prices'>
                                        <div className='price-item'>
                                            <label className='ladiui title-text-form-lever2'>{t('PRODUCTS.PRICE')}</label>
                                            <NumberInput
                                                name='price'
                                                suffix={currencySymbol}
                                                value={product.price}
                                                onChange={this.onChangeProductInput}
                                            />
                                        </div>
                                        <div className='price-item'>
                                            <label className='ladiui title-text-form-lever2'>{t('PRODUCTS.PRICE_COMPARE')}</label>
                                            <NumberInput
                                                name='price_compare'
                                                suffix={currencySymbol}
                                                value={product.price_compare}
                                                onChange={this.onChangeProductInput}
                                            />
                                        </div>
                                        <div className='price-item'>
                                            <label className='ladiui title-text-form-lever2'>{t('PRODUCTS.COST')}</label>
                                            <NumberInput
                                                name='cost_per_item'
                                                suffix={currencySymbol}
                                                value={product.cost_per_item}
                                                onChange={this.onChangeProductInput}
                                            />
                                        </div>
                                    </div>
                                )}

                                {hasNoVariant && product.type !== appConfig.PRODUCT_TYPE.COMBO.CODE && (
                                    <div className='ladiui form-group clearfix product-variants mb-24'>
                                        <span>
                                            {t('PRODUCTS.ADD_VARIANTS_TITLE_1')} {t('PRODUCTS.ADD_VARIANTS_TITLE_2')}{' '}
                                            <a
                                                onClick={() => {
                                                    document.getElementById('trigger-tab-variants-edit').click();
                                                }}
                                            >
                                                {t('PRODUCTS.ADD_VARIANTS_TITLE_3')}
                                            </a>
                                        </span>
                                    </div>
                                )}

                                <div className='flex'>
                                    <div className='ladiui item-form switch'>
                                        <label className='ladiui switch m-0'>
                                            <input
                                                type='checkbox'
                                                onChange={(event) => {
                                                    this.setState({
                                                        product: {
                                                            ...this.state.product,
                                                            is_one_time: !this.state.product.is_one_time,
                                                        },
                                                    });
                                                }}
                                                checked={product.is_one_time}
                                            />
                                            <span className='ladiui slider round'></span>
                                        </label>
                                    </div>
                                    <div className='ml-8'>
                                        <span className='ladiui title-text-form-lever2 mb-0'> {t('PRODUCTS.BUY_ONCE')}</span>
                                    </div>
                                </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui title-text-form-lever2'>Nút CTA</label>
                                    <span className='text-default block'>Thêm nút kêu gọi hành động cho sản phẩm của bạn</span>
                                    <SelectOption
                                        wrapperClassName='select-assignee w100'
                                        innerClassName='ellipsis mt-12 w100 flex'
                                        currentKey={this.state.product.cta}
                                        classIconCTA={this.state.product.selectedIconCTA}
                                        _key='CODE'
                                        _value='NAME'
                                        items={appConfig.LIST_CTA || []}
                                        onSelectItem={(item) => {
                                            this.onChangeCTA(item);
                                        }}
                                    />
                                </div>

                                <div className='flex'>
                                    <div className='mr-8'>
                                        <span className='ladiui title-text-form-lever2 mb-0'> {t('PRODUCTS.LB_STATUS')}</span>
                                    </div>

                                    <div className='ladiui item-form switch'>
                                        <label className='ladiui switch m-0'>
                                            <input
                                                type='checkbox'
                                                onChange={(event) => {
                                                    this.setState({
                                                        product: {
                                                            ...this.state.product,
                                                            status:
                                                                this.state.product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE
                                                                    ? appConfig.PRODUCT_STATUS.INACTIVE.CODE
                                                                    : appConfig.PRODUCT_STATUS.ACTIVE.CODE,
                                                        },
                                                    });
                                                }}
                                                checked={product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE}
                                            />
                                            <span className='ladiui slider round'></span>
                                        </label>
                                    </div>
                                </div>
                                <div className='pt-8 mb-24'>
                                    <span className='text-default'>{t('PRODUCTS.SHOW_PRODUCT_IN_CHECKOUT_PAGE')}</span>
                                </div>

                                {hasNoVariant && (
                                    <div className='ladiui mb-24 toggle-dropdown-product-edit'>
                                        <h2
                                            className={`ladiui pointer title-block ${this.state.isShowAdvanced ? ' arrow-up' : 'arrow-down'
                                                }`}
                                            onClick={() =>
                                                this.setState({
                                                    isShowAdvanced: !this.state.isShowAdvanced,
                                                })
                                            }
                                        >
                                            {t('PRODUCTS.OPTION_THOUGH')}
                                        </h2>
                                        {this.state.isShowAdvanced && (
                                            <div className='blockAdvancedProduct'>
                                                <div className='ladiui card card-body border-none pd-0'>
                                                    <div>
                                                        <div className='ladiui flex-row group-prices al-item-start'>
                                                            <div className='ladiui item-form price-item mr-20'>
                                                                <div className='ladiui form-group mb-0'>
                                                                    <label className='ladiui title-text-form-lever2'>
                                                                        {t('PRODUCTS.SKU')}
                                                                    </label>
                                                                    <Input
                                                                        name='sku'
                                                                        value={product.sku}
                                                                        placeholder='Nhập mã SKU'
                                                                        onChange={this.onChangeProductInput}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='ladiui item-form price-item mr-20'>
                                                                <div className='ladiui form-group pos-rel mb-0'>
                                                                    <label className='ladiui title-text-form-lever2'>
                                                                        {t('COMMON.WEIGHT')}
                                                                    </label>
                                                                    <NumberInput
                                                                        name='weight'
                                                                        value={product.weight}
                                                                        placeholder='Khối lượng'
                                                                        onChange={this.onChangeProductInput}
                                                                        onlyNumber={true}
                                                                    />
                                                                    <SelectOption
                                                                        customStyle={{
                                                                            width: '45px',
                                                                            height: '22px',
                                                                            position: 'absolute',
                                                                            top: '34px',
                                                                            right: '10px',
                                                                        }}
                                                                        directionUp={true}
                                                                        wrapperClassName='mini-dropdown'
                                                                        innerClassName='select-weight mini-dropdown mini-dropdown-pd'
                                                                        currentValue={product.weight_unit}
                                                                        items={appConfig.WEIGHT_UNITS}
                                                                        _key='CODE'
                                                                        _value='NAME'
                                                                        onSelectItem={(item) => {
                                                                            this.setState({
                                                                                product: {
                                                                                    ...this.state.product,
                                                                                    weight_unit: item.CODE,
                                                                                },
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='ladiui item-form price-item mr-20'>
                                                                <div className='ladiui form-group mb-0'>
                                                                    <label className='ladiui title-text-form-lever2'>
                                                                        <span className='mr-12'>{t('ORDERS.INVENTORY')}</span>
                                                                        <label className='ladiui switch ml-12 m-0 '>
                                                                            <input
                                                                                type='checkbox'
                                                                                onChange={(event) => {
                                                                                    this.setState(
                                                                                        {
                                                                                            product: {
                                                                                                ...this.state.product,
                                                                                                inventory_checked:
                                                                                                    this.state.product.inventory_checked ==
                                                                                                        appConfig.INVENTORY_STATUS.ACTIVE.CODE
                                                                                                        ? appConfig.INVENTORY_STATUS
                                                                                                            .INACTIVE.CODE
                                                                                                        : appConfig.INVENTORY_STATUS.ACTIVE
                                                                                                            .CODE,
                                                                                            },
                                                                                        },
                                                                                        () => {
                                                                                            this.setState({
                                                                                                product: {
                                                                                                    ...this.state.product,
                                                                                                    allow_sold_out:
                                                                                                        this.state.product
                                                                                                            .inventory_checked ==
                                                                                                            appConfig.INVENTORY_STATUS.ACTIVE
                                                                                                                .CODE
                                                                                                            ? appConfig.STATUS.ACTIVE
                                                                                                            : appConfig.STATUS.INACTIVE,
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    );
                                                                                }}
                                                                                checked={
                                                                                    product.inventory_checked ==
                                                                                    appConfig.INVENTORY_STATUS.ACTIVE.CODE
                                                                                }
                                                                            />
                                                                            <span className='ladiui slider round'></span>
                                                                        </label>
                                                                    </label>
                                                                    <NumberInput
                                                                        name='quantity'
                                                                        value={product.quantity}
                                                                        onChange={this.onChangeProductInput}
                                                                        onlyNumber={true}
                                                                        thousandSeparator={false}
                                                                        decimalScale={0}
                                                                        allowNegative={true}
                                                                        disabled={!product.inventory_checked}
                                                                    />
                                                                    <div className='ladiui form-group mt-10 flex mb-0'>
                                                                        <input
                                                                            disabled={!product.inventory_checked}
                                                                            id='allowPurchaseWhenSoldOut'
                                                                            onChange={(event) => {
                                                                                this.setState({
                                                                                    product: {
                                                                                        ...this.state.product,
                                                                                        allow_sold_out:
                                                                                            this.state.product.allow_sold_out ==
                                                                                                appConfig.STATUS.ACTIVE
                                                                                                ? appConfig.STATUS.INACTIVE
                                                                                                : appConfig.STATUS.ACTIVE,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            checked={product.allow_sold_out == appConfig.STATUS.ACTIVE}
                                                                            type='checkbox'
                                                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                                                        />
                                                                        <label> {t('INVENTORY.ALLOW_SOLD_OUT')}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='min-max-buy '>
                                                        <div className='ladiui form-group wp-50 '>
                                                            <div className='date-item'>
                                                                <label className='ladiui title-text-form-lever2'>
                                                                    {t('PRODUCTS.PR_MIN_BUY')}
                                                                </label>
                                                                <NumberInput
                                                                    name='min_buy'
                                                                    value={product.min_buy}
                                                                    onChange={this.onChangeProductInput}
                                                                    onlyNumber={true}
                                                                    thousandSeparator={false}
                                                                    decimalScale={0}
                                                                    allowNegative={true}
                                                                    validations={{
                                                                        min: 1,
                                                                    }}
                                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                                    validationName={t('PRODUCTS.PR_MIN_BUY')}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='ladiui form-group wp-50 '>
                                                            <div className='date-item'>
                                                                <label className='ladiui title-text-form-lever2'>
                                                                    {t('PRODUCTS.PR_MAX_BUY')}
                                                                </label>
                                                                <NumberInput
                                                                    name='max_buy'
                                                                    value={product.max_buy}
                                                                    onChange={this.onChangeProductInput}
                                                                    onlyNumber={true}
                                                                    thousandSeparator={false}
                                                                    decimalScale={0}
                                                                    allowNegative={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div
                            className='ladiui tab-pane fade'
                            id='tab-variants-edit'
                            role='tabpanel'
                            aria-labelledby='trigger-tab-variants-edit'
                        >
                            {this.getVariantPanel()}
                        </div>
                        <div
                            className='ladiui tab-pane fade'
                            id='tab-product-up-sell'
                            role='tabpanel'
                            aria-labelledby='trigger-tab-product-up-sell'
                        >
                            <RelateProduct
                                ref={this.productUpSellRef}
                                parentID={product.product_id}
                                selectedVariants={productUpSells}
                                displayProductType={product && product.type}
                            />
                        </div>
                    </div>

                    <div className='ladiui tab-pane fade mt-0' id='tab-checkout' role='tabpanel' aria-labelledby='trigger-tab-checkout'>
                        <PanelProductCheckout
                            isReloadIframe={isReloadIframe}
                            ref={this.panelProductCheckoutRef}
                            checkoutConfigID={product.checkout_config_id}
                            productID={product.product_id}
                            urlPublished={product.url_published}
                            domain={product.domain}
                            path={product.path}
                            payment_redirect_url={product.payment_redirect_url}
                            payment_redirect_after={product.payment_redirect_after}
                            checkoutLocation={product.interface_options}
                            setCheckoutLocation={(data) => {
                                this.setState({
                                    product: {
                                        ...this.state.product,
                                        interface_options: data,
                                    },
                                });
                            }}
                            onChangeCheckoutConfig={(checkoutConfigID) => {
                                this.setState({
                                    product: {
                                        ...this.state.product,
                                        checkout_config_id: checkoutConfigID,
                                    },
                                });
                            }}
                            onChangeDomain={(domain) => {
                                this.setState({
                                    product: {
                                        ...this.state.product,
                                        domain,
                                    },
                                });
                            }}
                            onChangePath={(path) => {
                                this.setState({
                                    product: {
                                        ...this.state.product,
                                        path,
                                    },
                                });
                            }}
                            onChangePaymentRedirectPath={(payment_redirect_url) => {
                                this.setState({
                                    product: {
                                        ...this.state.product,
                                        payment_redirect_url,
                                    },
                                });
                            }}
                            onChangePaymentRedirectAfter={(paymentRedirectAfter) => {
                                this.setState({
                                    product: {
                                        ...this.state.product,
                                        payment_redirect_after: baseHelper.parsePositiveInt(paymentRedirectAfter),
                                    },
                                });
                            }}
                        />
                    </div>
                    <div className='ladiui tab-pane fade mt-0' id='tab-seo-social' role='tabpanel' aria-labelledby='trigger-tab-seo-social'>
                        <PanelProductSellSocial ref={this.productSeoSocialRef} productInfoSeo={product.seo || {}} />
                    </div>
                </div>

                <ConfirmModal
                    style={{ zIndex: 1050 }}
                    id='confirm-image-delete'
                    title={t('PRODUCTS.MSG_DELETE_IMAGE_TITLE')}
                    content={t('PRODUCTS.MSG_DELETE_IMAGE_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={this.deleteImage}
                    isLoading={isLoadingDelete}
                />
            </div>
            // </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadProductImages: (form) => dispatch(fileActions.uploadProductImages(form)),
        listAllTags: () => dispatch(productTagActions.listAll()),
        reloadProduct: (productID) => dispatch(productActions.reload(productID)),
        deleteTag: (productID, productTagID) => dispatch(productTagActions.delete(productID, productTagID)),
        deleteImage: (productID, srcHash) => dispatch(productImageActions.delete(productID, srcHash)),
        createImages: (productImages) => dispatch(productImageActions.create(productImages)),
        show: (productID) => dispatch(productActions.show(productID)),
        update: (product) => dispatch(productActions.update(product)),
        create: (product) => dispatch(productActions.create(product)),
        listCategorySelect: (data) => dispatch(productCategoryActions.listSelectCategory(data)),
    };
};

const mapStateToProps = (state) => ({
    productImage: { ...state.productImage },
    productTag: { ...state.productTag },
    store: { ...state.store },
    fileReducer: { ...state.file },
    product: { ...state.product },
    productCategory: { ...state.productCategory },
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation', { withRef: true })(ModalEditProduct))
);
