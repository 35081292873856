import React from "react";
import "../../assets/css/style.css";
import "../../assets/css/custom.css";
import "../../assets/css/responsive.css";
import "../../assets/css/setting.css";
import "../../assets/css/discount.css";
import "../../assets/css/integrate.css";
import "../../assets/css/sdk.css";

import baseHelper from "../../helpers/BaseHelper";

import SideBar from "./Sidebar";
import Topbar from "./Topbar";
import Footer from "./Footer";
import { map } from "lodash";
import { Redirect, Route, Switch } from "react-router-dom";

import storeActions from "../../redux/futures/store/actions";

import { connect } from "react-redux";
import routes from "../../routes";

import queryString from "query-string";

class AppLayout extends React.Component {
  componentDidUpdate() {
    window.LadiUI.init();
  }

  onOkNotification = () => {
    baseHelper.setCookie("NOTIFICATION_CREDIT", true, 86400);
    window.LadiUI.closeModal("confirm-notification-credit");
    window.LadiChat.push([["do", "chat:open"]]);
  };

  openNotification = () => {
    setTimeout(() => {
      window.LadiUI.showModal("confirm-notification-credit");
    }, 2000);
  };

  render() {
    const { scopes, userInfo } = this.props.store;
    let url = window.location.href;

    let isPageCheckIn = url.includes("/checkin");
    let isPageCheckQrCode = url.includes("/check-qrcode");

    let isDomainIPay = url.includes("app.ipay.com.vn");

    if (isDomainIPay) {
      document.title = "iPay.com.vn - Nền tảng quản lý bán hàng thương mại điện tử";
    }

    const availableRoutes = routes.getRoutesByScopes(scopes);
    // const { store } = userInfo;
    // const notificationCredit = baseHelper.getCookie('NOTIFICATION_CREDIT');
    // if (!notificationCredit && store && (store.app_credit <= 30)) {
    //     if (window.LadiUI) {
    //         this.openNotification();
    //     }
    // }

    const pageCheckIn = queryString.parse(this.props.location.search);
    return (
      <section className="ladiui wrapper" style={isPageCheckQrCode || isPageCheckIn ? { minWidth: "auto" } : {}}>
        {!isPageCheckQrCode && !isPageCheckIn && <SideBar scopes={scopes} userInfo={userInfo} isDomainIPay={isDomainIPay} />}
        <div
          className="ladiui main-custom"
          id="elm-main"
          style={isPageCheckQrCode || isPageCheckIn ? { marginLeft: 0, width: "100%" } : {}}
        >
          {!isPageCheckQrCode && !isPageCheckIn && <Topbar availableRoutes={availableRoutes} />}
          <div className="ladiui content-main-full" style={isPageCheckQrCode || isPageCheckIn ? { padding: 0 } : {}}>
            <Switch>
              {map(availableRoutes, (route, index) => (
                <Route key={index} path={route.path} exact={route.exact} component={route.component} />
              ))}
              <Route render={() => <Redirect to="/orders" />} />
            </Switch>
          </div>
          <Footer />
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfo: (storeID) => dispatch(storeActions.getUserInfo(storeID)),
  };
};

const mapStateToProps = (state) => ({
  store: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
