import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const deleteOption = (data) => {
    return ({
        type: types.DELETE_OPTION,
        meta: {
            endpoint: endpoint.PRODUCT_OPTION_DELETE,
            method: REQUEST_METHOD.POST,
            body: {
                product_option: data
            },
            hasAuth: true,
        }
    })
};

const createAndUpdate = (data) => {
    return ({
        type: types.CREATE_UPDATE_OPTION,
        meta: {
            endpoint: endpoint.PRODUCT_OPTION_CREATE_UPDATE,
            method: REQUEST_METHOD.POST,
            body: {
                options: data
            },
            hasAuth: true,
        }
    })
};

export default {
    delete: deleteOption,
    createAndUpdate,
}