import React, { useEffect, useState } from "react";
import { find, compact, map, includes, remove, keys, pick } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Input";
import appConfig from "../../../config/app";
import baseHelper from "../../../helpers/BaseHelper";
import { useTranslation, withTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import ConfirmModal from "../../../components/ConfirmModal";
import produce from "immer";
import settingActions from "../../../redux/futures/setting/actions";
import * as settingTypes from "../../../redux/futures/setting/types";

import OrderDateRangePicker from "../../../components/OrderDateRangePicker";

import LadiPagination from "../../../components/LadiPagination";

const webHookInitData = {
  name: "",
  url: "",
  header: "",
  send: appConfig.SEND.YES,
  send_mail_to_user: appConfig.SEND.NO,
  scopes: {},
};

export default function ModalSettingWebHook(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const moment = baseHelper.getMoment();

  const storeReducer = useSelector((state) => state.store);
  const settingReducer = useSelector((state) => state.setting);

  const [hasCreate, setHasCreate] = useState(false);
  const [hasUpdate, setHasUpdate] = useState(false);
  const [hasDelete, setHasDelete] = useState(false);
  const [isShowModalWebHook, setIsShowModalWebHook] = useState(false);

  const [mode, setMode] = useState(null);
  const [webHooks, setWebHooks] = useState([]);
  const [webHook, setWebHook] = useState(webHookInitData);

  const [tabIndexActive, setTabIndexActive] = useState(0);

  const [webHookTransactions, setWebHookTransactions] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [selectedLimit, setSelectedLimit] = useState(appConfig.PAGINATION.LIMIT_OPTIONS[0].value);
  const [activePage, setActivePage] = useState(1);
  const [focusedInput, setFocusedInput] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [searchData, setSearchData] = useState("");

  const TAB_WEBHOOK = {
    LIST_WEBHOOK: 0,
    WEBHOOK_TRANSACTION: 1,
  };

  const inputs = new Set();

  const submit = () => {
    // Validate
    let errors = [];
    const inputsRef = compact(Array.from(inputs));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    if (mode === appConfig.FORM_MODE.CREATE) {
      dispatch(settingActions.createWebHook(webHook));
    } else if (mode === appConfig.FORM_MODE.EDIT) {
      dispatch(settingActions.updateWebHook(webHook));
    }
  };

  useEffect(() => {
    window.LadiUI.init();
    window.LadiUI.customInit();
  });

  useEffect(() => {
    dispatch(
      settingActions.listWebHookTransaction({
        limit: selectedLimit,
      })
    );

    dispatch(settingActions.listWebHook());
  }, []);

  useEffect(() => {
    const { role, scopes, email } = storeReducer.userInfo;
    const checkCreate = baseHelper.checkScope(role, scopes, "web_hook", "create");
    const checkUpdate = baseHelper.checkScope(role, scopes, "web_hook", "update");
    const checkDelete = baseHelper.checkScope(role, scopes, "web_hook", "delete");
    setHasCreate(checkCreate);
    setHasUpdate(checkUpdate);
    setHasDelete(checkDelete);
  }, [storeReducer]);

  useEffect(() => {
    if (settingTypes.SETTING_WEB_HOOK_CREATE === settingReducer.action) {
      if (settingReducer.status) {
        dispatch(settingActions.listWebHook());
        setIsShowModalWebHook(false);
      } else {
        window.LadiUI.toastCustom("danger", "", settingReducer.message);
      }
    }

    if (settingTypes.SETTING_WEB_HOOK_UPDATE === settingReducer.action) {
      if (settingReducer.status) {
        dispatch(settingActions.listWebHook());
        window.LadiUI.toastCustom("success", "", settingReducer.message);
        setIsShowModalWebHook(false);
      } else {
        window.LadiUI.toastCustom("danger", "", settingReducer.message);
      }
    }

    if (settingTypes.SETTING_WEB_HOOK_DELETE === settingReducer.action) {
      if (settingReducer.status) {
        dispatch(settingActions.listWebHook());
        window.LadiUI.toastCustom("success", "", settingReducer.message);
        window.LadiUI.closeModal("confirm-remove-web-hook");
      } else {
        window.LadiUI.toastCustom("danger", "", settingReducer.message);
      }
    }

    if (settingTypes.SETTING_WEB_HOOK_TRANSACTION_LIST === settingReducer.action) {
      if (settingReducer.status) {
        setWebHookTransactions(settingReducer.web_hook_transactions);
        setTotalRecord(settingReducer.total_record);
        setTotalPage(settingReducer.total_page);
      } else {
        window.LadiUI.toastCustom("danger", "", settingReducer.message);
      }
    }

    if (settingTypes.SETTING_LIST_WEB_HOOK === settingReducer.action) {
      if (settingReducer.status) {
        setWebHooks(settingReducer.web_hooks);
      } else {
        window.LadiUI.toastCustom("danger", "", settingReducer.message);
      }
    }
  }, [settingReducer]);

  const onChangeLimit = (option) => {
    setSelectedLimit(option.value);
    setActivePage(1);
    const data = {
      limit: option.value,
      paged: 1,
      search: {
        from_date: fromDate,
        to_date: toDate,
        search_data: searchData,
      },
    };
    dispatch(settingActions.listWebHookTransaction(data));
  };

  const onPageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const data = {
      limit: selectedLimit,
      paged: pageNumber,
      search: {
        from_date: fromDate,
        to_date: toDate,
        search_data: searchData,
      },
    };
    dispatch(settingActions.listWebHookTransaction(data));
  };

  const onChangeInputSearch = (event) => {
    setSearchData(event.target.value);
  };

  const onKeyDownInput = (event) => {
    if (event.key === "Enter") {
      setActivePage(1);
      const data = {
        limit: selectedLimit,
        paged: 1,
        search: {
          from_date: fromDate,
          to_date: toDate,
          search_data: searchData,
        },
      };
      dispatch(settingActions.listWebHookTransaction(data));
    }
  };

  const onChangeInput = (event) => {
    setWebHook({
      ...webHook,
      [event.target.name]: event.target.value,
    });
  };

  const openModalCreate = () => {
    setIsShowModalWebHook(true);
    setMode(appConfig.FORM_MODE.CREATE);
    setWebHook(webHookInitData);
  };

  const openModalEdit = (selectedWebHook) => {
    setIsShowModalWebHook(true);
    setMode(appConfig.FORM_MODE.EDIT);
    setWebHook(selectedWebHook);
  };

  const openModalDelete = (selectedWebHook) => {
    setWebHook(selectedWebHook);
    window.LadiUI.showModal("confirm-remove-web-hook");
  };

  const hideModal = () => {
    setIsShowModalWebHook(false);
    setMode(null);
    setWebHook(webHookInitData);
  };

  const onChangeSendWebHook = () => {
    setWebHook({
      ...webHook,
      send: webHook.send === appConfig.SEND.YES ? appConfig.SEND.NO : appConfig.SEND.YES,
    });
  };

  const onChangeSendMailToUserWebHook = () => {
    setWebHook({
      ...webHook,
      send_mail_to_user: webHook.send_mail_to_user === appConfig.SEND.YES ? appConfig.SEND.NO : appConfig.SEND.YES,
    });
  };

  const onChangeScope = (scope) => {
    const groups = webHook.scopes ? webHook.scopes[scope] : true;
    setWebHook({
      ...webHook,
      scopes: {
        ...webHook.scopes,
        [scope]: groups == true ? false : true,
      },
    });
  };

  const onChangeGroup = (scope, group) => {
    let selectedWebHook = webHook;
    let webHookScopes = props.webHookScopes;
    let totalGroup = keys(webHookScopes[scope].groups).length;
    let groups = selectedWebHook.scopes[scope];

    if (groups == true) {
      selectedWebHook.scopes[scope] = keys(webHookScopes[scope].groups);
      groups = selectedWebHook.scopes[scope];
    }

    if (includes(groups, group)) {
      remove(groups, (item) => item == group);
    } else {
      if (!Array.isArray(groups)) {
        selectedWebHook.scopes[scope] = [];
        groups = selectedWebHook.scopes[scope];
      }
      groups.push(group);
    }

    let scopeChange;
    if (groups.length == totalGroup) {
      scopeChange = true;
    } else if (groups.length == 0) {
      scopeChange = false;
    } else {
      scopeChange = groups;
    }

    setWebHook({
      ...webHook,
      scopes: {
        ...webHook.scopes,
        [scope]: scopeChange,
      },
    });
  };

  const isLoadingList = settingReducer.loading && includes([settingTypes.SETTING_LIST_WEB_HOOK], settingReducer.waiting);
  const isLoadingRemove = settingReducer.loading && includes([settingTypes.SETTING_WEB_HOOK_DELETE], settingReducer.waiting);

  let fromItem = 0;
  let toItem = 0;
  if ((webHookTransactions || []).length > 0) {
    fromItem = (activePage - 1) * selectedLimit + 1;
    toItem = fromItem + webHookTransactions.length - 1;
  }
  const msgShowItem = `${t("PAGINATION.SHOW")} ${fromItem} ${t("PAGINATION.TO")} ${toItem} ${t("PAGINATION.OF")} ${baseHelper.formatNumber(
    totalRecord
  )} ${t("PAGINATION.ITEMS")}`;

  return (
    <div className="page-setting" id="page-setting-web-hook" title={t("SETTINGS.WEB_HOOK")}>
      <div className="ladi-card account-table">
        <div className="block-title-action">
          <h3 className="title">Webhook</h3>
          <div className="btn-action">
            {hasCreate && (
              <div className="action-item">
                <button className="ladiui btn btn-primary btn-sm" onClick={openModalCreate}>
                  <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                  {t("WEB_HOOK.INVITE")}
                </button>
              </div>
            )}
          </div>
        </div>

        <ul className="ladiui nav nav-tabs">
          <li className={`ladiui nav-item`} onClick={() => setTabIndexActive(TAB_WEBHOOK.LIST_WEBHOOK)}>
            <span className={`ladiui tab-link ${tabIndexActive == TAB_WEBHOOK.LIST_WEBHOOK ? "active" : ""}`}>
              {t("SETTINGS.WEB_HOOK_LIST")}
            </span>
          </li>
          <li className={` ladiui nav-item`} onClick={() => setTabIndexActive(TAB_WEBHOOK.WEBHOOK_TRANSACTION)}>
            <span className={`ladiui tab-link ${tabIndexActive == TAB_WEBHOOK.WEBHOOK_TRANSACTION ? "active" : ""}`}>
              {t("SETTINGS.WEB_HOOK_TRANSACTION.TITLE")}
            </span>
          </li>
        </ul>

        <div className="ladiui tab-content" id="myTabContent">
          <div className={`ladiui tab-pane fade ${tabIndexActive == TAB_WEBHOOK.LIST_WEBHOOK ? "show open" : ""}`} id="tab-list-webhook">
            <table className="ladiui table text-left">
              <thead>
                <tr className="ladiui table-vertical header">
                  <th scope="col" name="name" className="text-left">
                    {t("WEB_HOOK.LB_NAME")}
                  </th>
                  <th scope="col">{t("WEB_HOOK.LB_API_URL")}</th>
                  <th scope="col">{t("WEB_HOOK.LB_ACTIVE")}</th>
                  <th scope="col">{t("WEB_HOOK.SEND_MAIL_TO_USER")}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {map(webHooks, (item, index) => (
                  <tr key={index} className="ladiui table-vertical main">
                    <td className="show-name">{item.name}</td>
                    <td className="ellipsis">{item.url}</td>
                    <td>
                      <input
                        name="send"
                        checked={item.send == appConfig.SEND.YES}
                        type="checkbox"
                        className="ladiui checkbox size-checkbox form-check-input-checkbox"
                      />
                    </td>
                    <td>
                      <input
                        name="send"
                        checked={item.send_mail_to_user === appConfig.SEND.YES}
                        type="checkbox"
                        className="ladiui checkbox size-checkbox form-check-input-checkbox"
                      />
                    </td>
                    <td className="text-right pd-0">
                      {(hasDelete || hasUpdate) && (
                        <div className="ladiui btn-group">
                          <div className="ladiui dropdown hide-mt ba-c">
                            <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                              <i className="ladiui icon icon-ldp-dot"></i>
                            </button>
                            <ul className="ladiui dropdown-menu r-0">
                              {hasUpdate && (
                                <li>
                                  <a className="ladiui dropdown-item" onClick={() => openModalEdit(item)}>
                                    {t("ACTIONS.EDIT")}
                                  </a>
                                </li>
                              )}
                              {hasDelete && (
                                <li>
                                  <a className="ladiui dropdown-item" onClick={() => openModalDelete(item)}>
                                    {t("ACTIONS.DELETE")}
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}

                {webHooks.length <= 0 && (
                  <tr className="text-center">
                    <td colSpan="100%">{t("NO_DATA")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div
            className={`ladiui tab-pane fade ${tabIndexActive == TAB_WEBHOOK.WEBHOOK_TRANSACTION ? "show open" : ""}`}
            id="tab-webhook-transaction"
          >
            <div className="webhook-transaction" id="modal-web-hook-transactions">
              <div className="ladi-card account-table">
                <div>
                  <div className="ladiui-table-actions ladiui" style={{ justifyContent: "flex-start" }}>
                    <div className="action-item">
                      <div className="ladiui search-keyword">
                        <input
                          type="text"
                          id="search"
                          placeholder={t("SETTINGS.WEB_HOOK_TRANSACTION.SEARCH")}
                          name="searchProductName"
                          value={searchData}
                          onChange={onChangeInputSearch}
                          onKeyDown={onKeyDownInput}
                          className="ladiui form-control"
                        />
                        <i
                          className="ladi-icon icon-zoom"
                          onClick={() => {
                            setActivePage(1);
                            const data = {
                              limit: selectedLimit,
                              paged: 1,
                              search: {
                                from_date: fromDate,
                                to_date: toDate,
                                search_data: searchData,
                              },
                            };
                            dispatch(settingActions.listWebHookTransaction(data));
                          }}
                        />
                      </div>
                    </div>

                    <div className="action-item order-date action-item">
                      <OrderDateRangePicker
                        startDateId="fromDate"
                        endDateId="toDate"
                        startDate={fromDate}
                        endDate={toDate}
                        onDatesChange={({ startDate, endDate }) => {
                          setFromDate(baseHelper.getFromDate(startDate));
                          setToDate(baseHelper.getToDate(endDate));

                          if ((startDate && endDate) || (!startDate && !endDate)) {
                            const data = {
                              search: {
                                from_date: baseHelper.getFromDate(startDate),
                                to_date: baseHelper.getToDate(endDate),
                                search_data: searchData,
                              },
                              paged: activePage,
                              limit: selectedLimit,
                            };
                            dispatch(settingActions.listWebHookTransaction(data));
                          }
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) => {
                          setFocusedInput(focusedInput);
                        }}
                        showDefaultInputIcon={true}
                        inputIconPosition="after"
                        small={true}
                        hideKeyboardShortcutsPanel={true}
                        customInputIcon={<i className="ladi-icon icon-calendar-date-2" />}
                        startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                        endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                        isOutsideRange={() => false}
                        isDayHighlighted={(date) => {
                          return moment().isSame(date, "day");
                        }}
                        minimumNights={0}
                        showClearDates={true}
                      />
                    </div>
                  </div>
                </div>

                <table className="ladiui table text-left">
                  <thead>
                    <tr className="ladiui table-vertical header">
                      <th scope="col" className="text-left">
                        {t("SETTINGS.WEB_HOOK_TRANSACTION.EVENT")}
                      </th>
                      <th scope="col" className="text-left">
                        {t("SETTINGS.WEB_HOOK_TRANSACTION.TRANSACTION_CODE")}
                      </th>
                      <th scope="col" className="text-left">
                        {t("SETTINGS.WEB_HOOK_TRANSACTION.API")}
                      </th>
                      <th scope="col" className="text-left">
                        {t("SETTINGS.WEB_HOOK_TRANSACTION.STATUS")}
                      </th>
                      <th scope="col" className="text-left">
                        {t("SETTINGS.WEB_HOOK_TRANSACTION.RETRY")}
                      </th>
                      <th scope="col" className="text-left">
                        {t("SETTINGS.WEB_HOOK_TRANSACTION.EVENT_TIME")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(webHookTransactions, (item, index) => {
                      return (
                        <tr key={index} className="ladiui table-vertical main">
                          <td>{item.type}</td>
                          <td>{item.transaction_code}</td>
                          <td>{item.api_url}</td>
                          <td>
                            {item.status == "Success" ? (
                              <div className="ladiui flex-row">
                                <i className="ladiui icon icon-ldp-status-success"></i>
                                <div>{t("SETTINGS.WEB_HOOK_TRANSACTION.SUCCESS")}</div>
                              </div>
                            ) : (
                              <div className="ladiui flex-row">
                                <i className="ladiui icon icon-ldp-status-warning status-custom-error"></i>
                                <div>{t("SETTINGS.WEB_HOOK_TRANSACTION.ERROR")}</div>
                              </div>
                            )}
                          </td>
                          <td>{item.retry}</td>
                          <td>{baseHelper.formatDateToStr(item.created_at)}</td>
                        </tr>
                      );
                    })}
                    {webHookTransactions.length <= 0 && (
                      <tr className="text-center">
                        <td colSpan="100%">{t("NO_DATA")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div>
                {totalRecord > 0 && (
                  <LadiPagination
                    conditionsProp={{ page: activePage, limit: selectedLimit }}
                    listName={t("PAGINATION.ITEMS")}
                    message={""}
                    items={webHookTransactions}
                    total={totalRecord}
                    limitAction={onChangeLimit}
                    pageAction={onPageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        id="confirm-remove-web-hook"
        title={t("WEB_HOOK.MSG_WEB_HOOK_TITLE_DELETE")}
        content={t("WEB_HOOK.MSG_WEB_HOOK_CONTENT_DELETE", webHook.name)}
        cancelText={t("ACTIONS.CANCEL")}
        okText={t("ACTIONS.DELETE")}
        onOk={() => dispatch(settingActions.deleteWebHook(webHook.web_hook_id))}
        isLoading={isLoadingRemove}
      />

      {isShowModalWebHook && (
        <Modal
          id="modal-edit-web-hook"
          title={mode === appConfig.FORM_MODE.CREATE ? t("WEB_HOOK.INVITE") : t("WEB_HOOK.EDIT")}
          onOk={submit}
          onCancel={hideModal}
          isLoading={props.isLoading}
          visible={isShowModalWebHook}
          bodyStyles={{ minHeight: "40vh" }}
          width={900}
        >
          <div>
            <div className="ladiui form-group">
              <label className="ladiui-label">{t("WEB_HOOK.LB_NAME")}</label>
              <Input
                ref={(ref) => inputs.add(ref)}
                name="name"
                validationName={t("WEB_HOOK.LB_NAME")}
                placeholder={t("WEB_HOOK.LB_NAME")}
                value={webHook.name}
                onChange={onChangeInput}
                validations={{ isRequired: true }}
              />
            </div>

            <div className="ladiui form-group">
              <label className="ladiui-label">{t("WEB_HOOK.LB_API_URL")}</label>
              <Input
                ref={(ref) => inputs.add(ref)}
                name="url"
                validationName={t("WEB_HOOK.LB_API_URL")}
                placeholder={t("WEB_HOOK.LB_API_URL")}
                value={webHook.url}
                onChange={onChangeInput}
                validations={{ isRequired: true }}
              />
            </div>

            <div className="ladiui form-group">
              <label className="ladiui-label">{t("WEB_HOOK.LB_API_REQUEST_HEADER")}</label>
              <textarea
                ref={(ref) => inputs.add(ref)}
                style={{ height: 150 }}
                name="header"
                className="ladiui textarea"
                placeholder={t("WEB_HOOK.LB_PLACEHOLDER_HEADER")}
                value={webHook.header}
                onChange={onChangeInput}
              />
            </div>

            <div className="ladiui form-group flex" style={{ clear: "both" }}>
              <input
                name="send"
                onChange={onChangeSendWebHook}
                checked={webHook.send === appConfig.SEND.YES}
                type="checkbox"
                className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
              />
              <label htmlFor="send"> {t("WEB_HOOK.LB_ACTIVE")}</label>
            </div>

            <div className="ladiui form-group flex" style={{ clear: "both" }}>
              <input
                name="send-mail-to-user"
                onChange={onChangeSendMailToUserWebHook}
                checked={webHook.send_mail_to_user === appConfig.SEND.YES}
                type="checkbox"
                className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
              />
              <label htmlFor="send-mail-to-user"> {t("WEB_HOOK.LB_SEND_MAIL_TO_USER")}</label>
            </div>

            <div>
              <div className="ladi-card">
                <table className={`ladiui table text-left`}>
                  <tbody>
                    {map(props.webHookScopes, (scope, key) => {
                      const groups = webHook.scopes ? webHook.scopes[key] : false;

                      const result = (
                        <tr key={key} className="ladiui table-vertical">
                          <td className="text-left" style={{ fontWeight: 500 }}>
                            {scope.title}
                            {scope.description && <span className="scope-description">{scope.description}</span>}
                          </td>
                          <td style={{ width: 100 }}>
                            <label className="flex">
                              <input
                                type="checkbox"
                                className="ladiui checkbox size-checkbox form-check-input-checkbox mr-4"
                                onChange={() => onChangeScope(key)}
                                checked={groups == true}
                              />{" "}
                              <span>{t("COMMON.ALL")}</span>
                            </label>
                          </td>
                          {scope.groups &&
                            map(scope.groups, (group, _key) => {
                              return (
                                <td key={_key}>
                                  <label className="flex" key={_key}>
                                    <input
                                      type="checkbox"
                                      className="ladiui checkbox size-checkbox form-check-input-checkbox mr-4"
                                      onChange={() => {
                                        onChangeGroup(key, group.code);
                                      }}
                                      checked={groups == true || includes(groups, group.code)}
                                    />{" "}
                                    <span>{group.title}</span>
                                  </label>
                                </td>
                              );
                            })}
                          <td colSpan={8} style={{ padding: 0 }} />
                        </tr>
                      );

                      return result;
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
