/**
 * Import default package
 *
 * @format
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
/** Import from third party */
import { map, includes, remove, find, cloneDeep } from "lodash";

/** Import component from my app */
import { withTranslation } from "react-i18next";
import ModalTicket from "./ModalTicket";

import appConfig from "../../../config/app";
import * as variantTypes from "../../../redux/futures/product_variant/types";
import variantActions from "../../../redux/futures/product_variant/actions";

import productActions from "../../../redux/futures/product/actions";
import * as productTypes from "../../../redux/futures/product/types";

import ConfirmModal from "../../../components/ConfirmModal";
import baseHelper from "../../../helpers/BaseHelper";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import produce from "immer";
import LoadingScene from "../../../components/LoadingScene";
import ticketSeatActions from "../../../redux/futures/ticket_seat/actions";

class PanelTicket extends React.Component {
  static propTypes = {
    inputsRef: PropTypes.any,
    mode: PropTypes.string,
    productID: PropTypes.any,
    variants: PropTypes.array,
    options: PropTypes.array,
    openVariantID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    inputsRef: new Set(),
  };

  constructor(props) {
    super(props);

    this.state = {
      variants: [],
      options: [
        {
          name: props.t("PRODUCTS.VARIANT_TICKET_DEFAULT"),
          position: 1,
          type: appConfig.PRODUCT_OPTION_TYPE.TEXT.CODE,
          values: [],
        },
      ],
      currentProductInfo: {},
      selectedVariant: {},
      isShowModalTicket: false,
    };

    this.formTicketRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.variants || this.props.options) {
      this.init(this.props);
    }
  }

  init = (props) => {
    const { options } = props;

    if (!options || options.length <= 0 || options[0].values.length <= 0) {
      this.setState({
        variants: [],
        options: [
          {
            name: props.t("PRODUCTS.VARIANT_TICKET_DEFAULT"),
            position: 1,
            type: appConfig.PRODUCT_OPTION_TYPE.TEXT.CODE,
            values: [],
          },
        ],
      });
      return;
    }

    this.setState(
      {
        variants: produce(props.variants, (draft) => {
          // delete draft.sku;

          draft.forEach((item) => {
            delete item.package_quantity_unit;
            if (props.mode == appConfig.FORM_MODE.EDIT) {
              item.option_value_value = item.options[0].option_value_value.name;
              item.variant_id = item.product_variant_id;
              item.options[0][options[0].name] = item.options[0].option_value_value.name;
            } else {
              item.option_value_value = item.options[options[0].name];
            }
          });
        }),
        options,
      },
      () => {
        if (this.props.openVariantID) {
          const foundOpenVariant = find(this.state.variants, (item) => item.variant_id == this.props.openVariantID);
          this.openModalTicketEdit(foundOpenVariant);
        }
      }
    );
  };

  componentDidUpdate() {
    window.LadiUI.init();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.variants != nextProps.variants || this.props.options != nextProps.options) {
      this.init(nextProps);
    }

    if (this.props.isShowModalTicket != nextProps.isShowModalTicket) {
      this.setState({
        isShowModalTicket: this.props.isShowModalTicket,
      });
    }

    if (this.props.currentProductInfo != nextProps.currentProductInfo) {
      this.setState({
        currentProductInfo: this.props.currentProductInfo,
      });
    }

    if (this.props.variantReducer.action != nextProps.variantReducer.action) {
      if (nextProps.variantReducer.action === variantTypes.CREATE_VARIANT) {
        if (nextProps.variantReducer.status) {
          this.setState({
            isShowModalTicket: false,
          });
          this.props.reloadProduct(this.props.productID);
          window.LadiUI.toastCustom("success", "", nextProps.variantReducer.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.variantReducer.message, "OK");
        }
      }

      if (nextProps.variantReducer.action === variantTypes.UPDATE_VARIANT) {
        if (nextProps.variantReducer.status) {
          this.setState({
            isShowModalTicket: false,
          });
          this.props.reloadProduct(this.props.productID);
          window.LadiUI.toastCustom("success", "", nextProps.variantReducer.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.variantReducer.message, "OK");
        }
      }

      if (nextProps.variantReducer.action === variantTypes.CHANGE_IMAGE_VARIANT) {
        if (nextProps.variantReducer.status) {
          this.setState({
            isShowModalTicket: false,
          });
          this.props.reloadProduct(this.props.productID);
          window.LadiUI.toastCustom("success", "", nextProps.variantReducer.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.variantReducer.message, "OK");
        }
      }

      if (nextProps.variantReducer.action === variantTypes.DELETE_VARIANT) {
        if (nextProps.variantReducer.status) {
          this.props.reloadProduct(this.props.productID);

          this.reloadTicketSeat();

          window.LadiUI.closeModal("confirm-variant-delete");
          window.LadiUI.toastCustom("success", "", nextProps.variantReducer.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.variantReducer.message, "OK");
        }
      }

      if (nextProps.variantReducer.action === variantTypes.RE_ORDER_VARIANT) {
        if (nextProps.variantReducer.status) {
          this.props.reloadProduct(this.props.productID);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.variantReducer.message, "OK");
        }
      }
    }
    if (nextProps.productReducer.action === productTypes.UPDATE_PRODUCT) {
      if (nextProps.productReducer.status) {
        this.setState({
          isShowModalTicket: false,
        });
        //   this.props.reloadProduct(this.props.productID);
        //   window.LadiUI.toastCustom("success", "", nextProps.variantReducer.message);
        // } else {
        //   window.LadiUI.showErrorMessage("Thông báo", nextProps.variantReducer.message, "OK");
      }
    }
  }

  /*******************************VARIANTS***************************/

  onChangeVariantInput = (event, index) => {
    const { name, value } = event.target;
    const { variants } = this.state;

    this.setState({
      variants: produce(variants, (draft) => {
        draft[index][name] = value;
      }),
    });
  };
  /*****************************************************************/

  reloadTicketSeat = () => {
    const data = {
      search: {
        event_id: this.props.productID,
      },
    };

    this.props.reloadTicketSeat(data);
  };

  getData = () => {
    let { variants, options } = cloneDeep(this.state);

    if (variants.length <= 0) {
      options = [];
    }

    remove(options, (option) => !option.values || option.values.length <= 0);
    map(variants, (item, index) => (item.position = index));

    return {
      variants,
      options,
    };
  };

  openModalTicketCreate = () => {
    this.setState({
      selectedVariant: {},
      mode: appConfig.FORM_MODE.CREATE,
      isShowModalTicket: true,
    });
  };

  openModalTicketEdit = (selectedVariant) => {
    this.setState({
      selectedVariant,
      mode: appConfig.FORM_MODE.EDIT,
      isShowModalTicket: true,
    });
  };

  createTicket = (variant) => {
    let { variants, options, mode } = this.state;

    // Check exists
    let isExists = false;

    if (mode == appConfig.FORM_MODE.CREATE) {
      const foundOptionValue = find(options[0].values, (item) => item.name == variant.option_value_value);
      if (foundOptionValue) {
        isExists = true;
      }
    } else {
      let foundOptionValue;
      if (this.props.mode == appConfig.FORM_MODE.EDIT) {
        foundOptionValue = find(
          options[0].values,
          (item) => item.name != variant.options[0][options[0].name] && item.name == variant.option_value_value
        );
      } else {
        foundOptionValue = find(
          options[0].values,
          (item) => item.name != variant.options[options[0].name] && item.name == variant.option_value_value
        );
      }
      if (foundOptionValue) {
        isExists = true;
      }
    }
    if (isExists) {
      window.LadiUI.toastCustom("danger", "", this.props.t("PRODUCTS.EXISTS_TICKET_NAME"));
      return;
    }

    if (this.props.mode == appConfig.FORM_MODE.CREATE) {
      if (mode == appConfig.FORM_MODE.CREATE) {
        options = produce(options, (draft) => {
          draft[0].values.push({
            name: variant.option_value_value,
          });
        });

        variant.options = {
          [options[0].name]: variant.option_value_value,
        };
        variants = produce(variants, (draft) => {
          draft.push(variant);
        });
      } else {
        options = produce(options, (draft) => {
          const foundOptionValue = draft[0].values.find((item) => item.name == variant.options[options[0].name]);
          foundOptionValue.name = variant.option_value_value;
        });

        variants = produce(variants, (draft) => {
          const fountVariantIndex = draft.findIndex((item) => item.options[options[0].name] == variant.options[options[0].name]);
          variant.options = {
            [options[0].name]: variant.option_value_value,
          };
          draft[fountVariantIndex] = variant;
        });
      }

      this.setState({
        variants,
        options,
        isShowModalTicket: false,
      });
    } else {
      if (mode == appConfig.FORM_MODE.CREATE) {
        options = produce(options, (draft) => {
          draft[0].values.push({
            name: variant.option_value_value,
          });
        });

        variant.options = [
          {
            option_id: options[0].option_id,
            [options[0].name]: variant.option_value_value,
            option_value_value: {
              name: variant.option_value_value,
            },
          },
        ];
        variant.product_id = this.props.productID;

        if (options && options.length > 0 && options[0].values.length > 1) {
          this.props.create(variant);
        } else {
          variant.product_variant_id = this.props.variants[0].product_variant_id;
          variant.options = {
            [options[0].name]: variant.option_value_value,
          };
          const productReq = {
            product_id: this.props.productID,
            variants: [variant],
            options,
          };
          this.props.updateProduct(productReq);
        }
      } else {
        const foundOptionValue = find(options[0].values, (item) => item.name == variant.options[0][options[0].name]);
        foundOptionValue.name = variant.option_value_value;

        variant.options[0][options[0].name] = variant.option_value_value;
        variant.options[0].option_value_value = {
          name: variant.option_value_value,
        };

        this.props.update(variant);
      }
    }
  };

  removeTicket = (index) => {
    const { variants, options } = this.state;
    this.setState({
      variants: produce(variants, (draft) => {
        draft.splice(index, 1);
      }),
      options: produce(options, (draft) => {
        const foundIndex = draft[0].values.findIndex((item) => item.name == variants[index].option_value_value);
        draft[0].values.splice(foundIndex, 1);
      }),
    });
  };

  openModelDelete = (variant) => {
    this.setState({
      selectedVariant: variant,
    });

    window.LadiUI.showModal("confirm-variant-delete");
  };

  onDragEnd = (result) => {
    const { variants } = this.state;
    // dropped outside the list
    if (!result.destination || result.source.index == result.destination.index) {
      return;
    }

    this.setState(
      {
        variants: produce(variants, (draft) => {
          const [removed] = draft.splice(result.source.index, 1);
          draft.splice(result.destination.index, 0, removed);
        }),
      },
      () => {
        if (this.props.mode == appConfig.FORM_MODE.EDIT) {
          this.props.reOrderTicket({
            product_id: this.props.productID,
            product_variant_ids: map(this.state.variants, (item) => item.variant_id),
          });
        }
      }
    );
  };

  filteredVariant = () => {
    const { t } = this.props;
    const { variants } = this.state;

    return variants.map((variant, index) => {
      const isAvailableVariant = baseHelper.isAvailableVariant(variant);
      return (
        <Draggable key={variant.variant_id || index} draggableId={"" + (variant.variant_id || index)} index={index}>
          {(provided, snapshot) => (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...provided.draggableProps.style,
                userSelect: "none",
                background: snapshot.isDragging ? "#e8f0fe" : "none",
                display: "table-row",
              }}
              className={!isAvailableVariant ? "ladiui table-vertical" : "ladiui table-vertical"}
            >
              <td>
                <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg"} alt="" />
              </td>
              <td>
                {variant.option_value_value}
                {!isAvailableVariant && (
                  <span className="badge badge-child-light sold-out flex">
                    <i className="ladi-icon icon-c-warning mr-4" />
                    <React.Fragment>
                      {baseHelper.isNotOpenForSale(variant) ? t("COMMON.NOT_OPEN_FOR_SALE") : t("COMMON.SOLD_OUT")}
                    </React.Fragment>
                  </span>
                )}
              </td>
              <td>{baseHelper.formatMoneyPostFix(variant.price, this.props.store.userInfo.currentStore.currency_symbol)}</td>
              <td>{variant.total_quantity}</td>
              <td>{variant.total_sold}</td>
              <td>{variant.variant_id}</td>
              <td>{variant.sku}</td>
              <td className="text-right pd-0">
                <div className="ladiui btn-group">
                  <div className="ladiui dropdown hide-mt ba-c">
                    <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                      <i className="ladiui icon icon-ldp-dot"></i>
                    </button>
                    <ul className="ladiui dropdown-menu r-0">
                      <li>
                        <a className="ladiui dropdown-item" onClick={() => this.openModalTicketEdit(variant)}>
                          {t("ACTIONS.EDIT")}
                        </a>
                      </li>
                      <li>
                        <a
                          className="ladiui dropdown-item"
                          onClick={() => {
                            if (this.props.mode == appConfig.FORM_MODE.CREATE) {
                              this.removeTicket(index);
                            } else {
                              this.openModelDelete(variant);
                            }
                          }}
                        >
                          {t("ACTIONS.DELETE")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </Draggable>
      );
    });
  };

  render() {
    const { t } = this.props;
    const { variants, options } = this.state;

    const { currency_code: currencyCode } = this.props.store.userInfo.currentStore;

    const isSubmitLoading =
      this.props.variantReducer.loading &&
      includes([variantTypes.CREATE_VARIANT, variantTypes.UPDATE_VARIANT, productTypes.UPDATE_PRODUCT], this.props.variantReducer.waiting);
    const isLoadingDeleteVariant = this.props.variantReducer.loading && variantTypes.DELETE_VARIANT == this.props.variantReducer.waiting;
    const isLoadingReOrder =
      this.props.variantReducer.loading && includes([variantTypes.RE_ORDER_VARIANT], this.props.variantReducer.waiting);

    return (
      <React.Fragment>
        {isLoadingReOrder && <LoadingScene blur={true} />}
        <a onClick={this.openModalTicketCreate} className="ladiui-link flex pull-right">
          <img className="mr-8" src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg" alt="" />
          <span>{t("PRODUCTS.LB_ADD_VARIANT_TICKET")}</span>
        </a>
        <br style={{ clear: "both" }} />
        {(!options || options.length <= 0 || options[0].values.length <= 0) && (
          <div className="text-center">{t("PRODUCTS.TICKET_EMPTY_HEADER")}</div>
        )}

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className="ladi-card"
                ref={provided.innerRef}
                style={{
                  background: snapshot.isDragging ? "#e8f0fe" : "none",
                }}
              >
                {variants.length > 0 && (
                  <table className="ladiui table text-left">
                    <thead>
                      <tr className="ladiui table-vertical header">
                        <th scope="col" />
                        <th scope="col" style={{ width: "40%" }}>
                          {t("PRODUCTS.VARIANT_TICKET_DEFAULT")}
                        </th>
                        <th scope="col">{t("PRODUCTS.PRICE")}</th>
                        <th scope="col">{t("PRODUCT_EVENTS.QUANTITY")}</th>
                        <th scope="col">{t("PRODUCT_EVENTS.LB_TOTAL_SOLD")}</th>
                        <th scope="col">{t("PRODUCTS.ID")}</th>
                        <th scope="col">{t("PRODUCTS.SKU")}</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {this.filteredVariant()}
                      {provided.placeholder}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {this.state.isShowModalTicket && (
          <ModalTicket
            ref={this.formTicketRef}
            mode={this.state.mode}
            currentVariantInfo={this.state.selectedVariant}
            images={this.props.images}
            onCancel={() =>
              this.setState({
                isShowModalTicket: false,
              })
            }
            onSubmit={this.createTicket}
            currentProductInfo={this.state.currentProductInfo}
            visible={this.state.isShowModalTicket}
            isLoading={isSubmitLoading}
          />
        )}

        <ConfirmModal
          id="confirm-variant-delete"
          title={t("PRODUCT_EVENTS.MSG_DELETE_PRODUCT_VARIANT_EVENT_TITLE")}
          content={t("PRODUCT_EVENTS.MSG_DELETE_PRODUCT_VARIANT_EVENT_CONTENT", this.state.selectedVariant.option_value_value)}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={() => this.props.delete(this.props.productID, this.state.selectedVariant.product_variant_id)}
          isLoading={isLoadingDeleteVariant}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    create: (variant) => dispatch(variantActions.create(variant)),
    update: (variant) => dispatch(variantActions.update(variant)),
    updateProduct: (form) => dispatch(productActions.update(form)),
    delete: (productID, variantID) => dispatch(variantActions.delete(productID, variantID)),
    reloadProductVariant: (productID, variantID) => dispatch(variantActions.show(productID, variantID)),
    reloadProduct: (productID) => dispatch(productActions.reload(productID)),
    reOrderTicket: (productID) => dispatch(variantActions.reOrder(productID)),
    reloadTicketSeat: (data) => dispatch(ticketSeatActions.list(data)),
  };
};

const mapStateToProps = (state) => ({
  variantReducer: { ...state.variant },
  productReducer: { ...state.product },
  store: { ...state.store },
});
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation("translation", { withRef: true })(PanelTicket));
