import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import baseHelper from "../helpers/BaseHelper";
// eslint-disable-next-line max-lines-per-function
let CDN = "https://w.ladicdn.com/";
function ModalAddVideo(props) {
  const {
    t,
    onClose,
    isShow,
    searchVariant,
    onFocusVariantInput,
    onChangeInputSearch,
    searchVideoValue,
    innerStyle,
    listVideos,
    setVideoLinkSelected,
    handleUploadVideo,
  } = props;
  const wrapperRef = useRef(null);

  const [selectedVideoLink, setSelectedVideoLink] = useState("");

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const dispatch = useDispatch();

  const eventCancel = () => {
    onClose(false);
  };

  const handleSelectedVideo = (video) => {
    if (video.src) {
      setSelectedVideoLink(video.src);
    }
  };

  const handleAddVideoLink = () => {
    if (selectedVideoLink) {
      setVideoLinkSelected(selectedVideoLink);
    }
  };

  // const handleOpenUploadVideo = () => {
  //   handleUploadVideo();
  // };

  return (
    <div className={`ladiui modal ${isShow ? "show" : ""}`} id="modal-add-video">
      <div className="ladiui modal-dialog modal-dialog-centered" style={{ width: "800px" }} ref={wrapperRef}>
        <div className="ladiui modal-content ">
          <div className="ladiui modal-header ">
            <h2 className="ladiui modal-title ">Chọn video từ thư viện</h2>
            <button data-dismiss="modal" data-target="add-product" onClick={eventCancel} className="ladiui modal-close">
              <i className="ladiui icon icon-ldp-close"></i>
            </button>
          </div>
          <div className="ladiui modal-body">
            <div className="ladiui search-group">
              <input
                className="ladiui search-field dropdown-toggle form-control"
                value={searchVideoValue}
                placeholder={"Tìm kiếm video"}
                data-toggle="dropdown"
                aria-expanded="false"
                onFocus={onFocusVariantInput}
                onChange={onChangeInputSearch}
              />
              <i className="ladiui icon icon-search" onClick={searchVariant}></i>
            </div>
            <div className="cursor-pointer" style={innerStyle}>
              <a className="flex-center-left cursor-pointer my-24 pd-0">
                <img className="mr-8" src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg"} alt="" />
                <div> {t("PRODUCTS.ADD_NEW")}</div>
              </a>
            </div>

            <div className={`list-videos ${innerStyle ? "mt-24" : ""}`}>
              {listVideos &&
                listVideos.length > 0 &&
                listVideos.map((item, index) => {
                  return (
                    <div key={index} className="list-video-item">
                      <label className="flex">
                        <div className="video-info">
                          <div>
                            <input
                              type="radio"
                              name="radio-video-item"
                              className="ladiui form-check-input mr-16"
                              onChange={() => handleSelectedVideo(item)}
                            />
                          </div>
                          <div>
                            <img
                              src={item.url_thumbnail ? CDN + item.url_thumbnail : "https://w.ladicdn.com/ladiui/icons/ldicon-image.svg"}
                              alt=""
                            />
                          </div>
                          <span>{item.name}</span>
                        </div>
                        <div className="date-time">
                          <span className="time-video">{item.duration ? baseHelper.msToTimeSeconds(item.duration) : "00:00:00"}</span>
                          <span className="date">{baseHelper.formatDateToStr(item.created_at)}</span>
                        </div>
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="ladiui modal-footer">
            {/* <div className="btn-upload-video" onClick={() => handleOpenUploadVideo()}>
              <i className="ldicon-upload mr-8"></i>
              <span>Tải lên video</span>
            </div> */}
            <div className="ladiui footer-modal-btn">
              <button
                type="button"
                data-dismiss="modal"
                data-target="add-product"
                className="ladiui btn btn-secondary bold"
                onClick={eventCancel}
              >
                {t("ACTIONS.CANCEL")}
              </button>

              <button
                type="button"
                data-dismiss="modal"
                data-target="add-product"
                className="ladiui btn btn-primary bold ml-16"
                onClick={() => handleAddVideoLink()}
              >
                {t("ACTIONS.DONE")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(ModalAddVideo);
