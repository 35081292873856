import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../../components/Modal";

import baseHelper from "../../../../helpers/BaseHelper";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/Input";
import appConfig from "../../../../config/app";
import { map, includes } from "lodash";

ModalShopeePay.propTypes = {
  onCancel: PropTypes.func,
  paymentGatewayConfigured: PropTypes.any,
};

export default function ModalShopeePay(props) {
  const {
    paymentGatewayConfigured,
    onCancel,
    upDateDataCofigPayment,
    connect,
    isSubmitLoading,
    onchangeDataUpdateShoppePay,
    shopeePayRef,
    shopeePayAccount,
    onChangeShopeePayInput,
    setShopeePayMode,
    shopeePayMode,
    logo,
  } = props;

  const { t } = useTranslation();

  return (
    <Modal
      id="modal-shopee-pay"
      title={!paymentGatewayConfigured ? t("PAYMENTS.SET_UP_PAYMENT_METHOD") : t("PAYMENTS.DETAIL_PAYMENT_METHOD")}
      onCancel={onCancel}
      hasFooter={true}
      onOk={
        !paymentGatewayConfigured
          ? () => connect(appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE)
          : () => upDateDataCofigPayment(appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE, paymentGatewayConfigured)
      }
      visible={true}
      logo={logo}
      width={1000}
      bodyStyles={{ minHeight: "40vh" }}
    >
      <div className="content">
        {paymentGatewayConfigured ? (
          <React.Fragment>
            <React.Fragment>
              <div className="partner-account">
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.LB_METHOD_NAME")}</label>
                  <Input value={paymentGatewayConfigured.config.method_name} disabled={true} />
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.SHOPEE_PAY.MERCHANT_ID")}</label>
                  <Input value={paymentGatewayConfigured.config.merchant_ext_id} disabled={true} />
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.SHOPEE_PAY.STORE_ID")}</label>
                  <Input value={paymentGatewayConfigured.config.store_ext_id} disabled={true} />
                </div>

                <div className="ladiui form-group pc_description">
                  <label className="ladiui-label">
                    <i className="ladi-icon icon-pc" />
                    {t("PAYMENTS.SHOPEE_PAY.PC_PARAMETER")}
                  </label>
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.SHOPEE_PAY.CLIENT_ID")}</label>
                  <Input value={paymentGatewayConfigured.config.pc_client_id} disabled={true} />
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.SHOPEE_PAY.SECRET_KEY")}</label>
                  <Input value={paymentGatewayConfigured.config.pc_secret_key} disabled={true} />
                </div>

                <div className="ladiui form-group mb_description">
                  <label className="ladiui-label">
                    <i className="ladi-icon icon-mobile" />
                    {t("PAYMENTS.SHOPEE_PAY.MB_PARAMETER")}
                  </label>
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.SHOPEE_PAY.CLIENT_ID")}</label>
                  <Input value={paymentGatewayConfigured.config.mb_client_id} disabled={true} />
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.SHOPEE_PAY.SECRET_KEY")}</label>
                  <Input value={paymentGatewayConfigured.config.mb_secret_key} disabled={true} />
                </div>

                <div id="data-tooltip-guide" className="ladiui form-group">
                  <label className="ladiui-label">
                    <span className="tooltip-paymet-guide">{t("PAYMENTS.LB_PAYMENT_GUIDE")}</span>
                    <span className="tooltip-icon" data-tooltip={t("PAYMENTS.LB_TT_PAYMENT_GUIDE")} data-tooltip-position="right">
                      {" "}
                      <i className="ladi-icon icon-c-question" />
                    </span>
                  </label>
                  <textarea
                    name="payment_guide"
                    className="ladiui textarea"
                    defaultValue={paymentGatewayConfigured.config.payment_guide}
                    onChange={(e) => onchangeDataUpdateShoppePay(e)}
                  />
                </div>
                {baseHelper.isDevelopEnv() && (
                  <div className="ladiui form-group flex">
                    <input
                      type="checkbox"
                      className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                      checked={paymentGatewayConfigured.mode === appConfig.MODE.TEST}
                      disabled={true}
                    />
                    <label htmlFor="shopee_pay_mode"> {t("MODE.TEST")}</label>
                  </div>
                )}
              </div>
            </React.Fragment>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <div className="partner-account">
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.LB_METHOD_NAME")}</label>
                  <Input
                    ref={(ref) => shopeePayRef.add(ref)}
                    value={shopeePayAccount.method_name}
                    validationName={t("PAYMENTS.LB_METHOD_NAME")}
                    name="method_name"
                    onChange={(event) => onChangeShopeePayInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.SHOPEE_PAY.MERCHANT_ID")}</label>
                  <Input
                    ref={(ref) => shopeePayRef.add(ref)}
                    value={shopeePayAccount.merchant_ext_id}
                    validationName={t("PAYMENTS.SHOPEE_PAY.MERCHANT_ID")}
                    name="merchant_ext_id"
                    onChange={(event) => onChangeShopeePayInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.SHOPEE_PAY.STORE_ID")}</label>
                  <Input
                    ref={(ref) => shopeePayRef.add(ref)}
                    value={shopeePayAccount.store_ext_id}
                    validationName={t("PAYMENTS.SHOPEE_PAY.STORE_ID")}
                    name="store_ext_id"
                    onChange={(event) => onChangeShopeePayInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>

                <div className="ladiui form-group pc_description">
                  <label className="ladiui-label">
                    <i className="ladi-icon icon-pc" />
                    {t("PAYMENTS.SHOPEE_PAY.PC_PARAMETER")}
                  </label>
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.SHOPEE_PAY.CLIENT_ID")}</label>
                  <Input
                    ref={(ref) => shopeePayRef.add(ref)}
                    value={shopeePayAccount.pc_client_id}
                    validationName={t("PAYMENTS.SHOPEE_PAY.CLIENT_ID")}
                    name="pc_client_id"
                    onChange={(event) => onChangeShopeePayInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.SHOPEE_PAY.SECRET_KEY")}</label>
                  <Input
                    ref={(ref) => shopeePayRef.add(ref)}
                    value={shopeePayAccount.pc_secret_key}
                    validationName={t("PAYMENTS.SHOPEE_PAY.SECRET_KEY")}
                    name="pc_secret_key"
                    onChange={(event) => onChangeShopeePayInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>

                <div className="ladiui form-group mb_description">
                  <label className="ladiui-label">
                    <i className="ladi-icon icon-mobile" />
                    {t("PAYMENTS.SHOPEE_PAY.MB_PARAMETER")}
                  </label>
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.SHOPEE_PAY.CLIENT_ID")}</label>
                  <Input
                    ref={(ref) => shopeePayRef.add(ref)}
                    value={shopeePayAccount.mb_client_id}
                    validationName={t("PAYMENTS.SHOPEE_PAY.CLIENT_ID")}
                    name="mb_client_id"
                    onChange={(event) => onChangeShopeePayInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.SHOPEE_PAY.SECRET_KEY")}</label>
                  <Input
                    ref={(ref) => shopeePayRef.add(ref)}
                    value={shopeePayAccount.mb_secret_key}
                    validationName={t("PAYMENTS.SHOPEE_PAY.SECRET_KEY")}
                    name="mb_secret_key"
                    onChange={(event) => onChangeShopeePayInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>

                <div id="data-tooltip-guide" className="ladiui form-group">
                  <label className="ladiui-label">
                    <span className="tooltip-paymet-guide">{t("PAYMENTS.LB_PAYMENT_GUIDE")}</span>
                    <span className="tooltip-icon" data-tooltip={t("PAYMENTS.LB_TT_PAYMENT_GUIDE")} data-tooltip-position="right">
                      {" "}
                      <i className="ladi-icon icon-c-question" />
                    </span>
                  </label>
                  <textarea
                    name="payment_guide"
                    className="ladiui textarea"
                    value={shopeePayAccount.payment_guide}
                    onChange={(event) => onChangeShopeePayInput(event)}
                  />
                </div>
                {baseHelper.isDevelopEnv() && (
                  <div className="ladiui form-group flex">
                    <input
                      id="shopee_pay_mode"
                      type="checkbox"
                      className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                      onChange={() => setShopeePayMode(!shopeePayMode)}
                      checked={shopeePayMode == appConfig.MODE.TEST}
                    />
                    <label htmlFor="shopee_pay_mode"> {t("MODE.TEST")}</label>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
}
