import React from 'react';

import baseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import { debounce, find, remove, reduce, cloneDeep, map, isEqual, compact } from 'lodash';

import orderActions from '../../../redux/futures/order/actions';
import * as orderTypes from '../../../redux/futures/order/types';

import variantActions from '../../../redux/futures/product_variant/actions';
import * as variantTypes from '../../../redux/futures/product_variant/types';

import customerActions from '../../../redux/futures/customer/actions';
import * as customerTypes from '../../../redux/futures/customer/types';

import discountActions from '../../../redux/futures/discount/actions';
import * as discountTypes from '../../../redux/futures/discount/types';

import shippingRateActions from '../../../redux/futures/shipping_rate/actions';
import * as shippingRateTypes from '../../../redux/futures/shipping_rate/types';

import orderTagActions from '../../../redux/futures/order_tag/actions';
import * as orderTagTypes from '../../../redux/futures/order_tag/types';

import paymentActions from '../../../redux/futures/payment/actions';
import * as paymentTypes from '../../../redux/futures/payment/types';

import { connect } from 'react-redux';

import Input from '../../../components/Input';
import PanelCustomer from '../components/OrderCustomer';
import PanelBillingAddress from '../components/BillingAddress';
import PanelShippingAddress from '../components/ShippingAddress';
import { withTranslation } from 'react-i18next';
import LoadingSence from '../../../components/LoadingScene';
import Image from '../../../components/Image';
import Modal from '../../../components/Modal';
import AsyncAutoComplete from '../../../components/AsyncAutoComplete';
import Popover from '../../../components/Popover';
import PopoverDiscount from './components/PopoverDiscount';
import NumberInput from '../../../components/NumberInput';
import SelectOption from '../../../components/SelectOption';
import DropdownTag from '../../../components/DropdownTag';
import PanelCustomField from '../../../components/PanelCustomField';
import ModalAddProduct from './components/ModalAddProduct';

import { appLocalStorage } from '../../../localforage';
import Dropdown from '../../../components/Dropdown';

class OrderCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customer: {},
            variants: [],
            selectDiscount: {
                type: appConfig.DISCOUNT.TYPE.FIXED.value,
                value: '',
                note: '',
                code: '',
            },
            applyDiscount: {},
            discountFee: '',
            selectShipping: {
                type: appConfig.SHIPPING_TYPE.FREE,
                note: '',
                value: '',
            },
            applyShipping: {},
            shipping_fee: '',
            is_auto_shipping: false,
            note: '',
            tax_fee: '',
            searchVariant: '',
            displaySearchVariant: props.t('PRODUCTS.SEARCH_PRODUCT'),
            total: 0,
            subTotal: 0,
            selectedCustomer: {},
            customVariant: {
                name: '',
                price: '',
                quantity: '',
            },
            isShowModalNewProduct: false,
            disabledApplyDiscount: true,
            currentShippingAddress: {},
            currentBillingAddress: {},
            isSameShippingAddress: true,
            assigneeID: null,
            userInfo: {
                currentStore: {},
            },
            isShowModalConfirmCreate: false,
            emailNotification: false,
            paymentStatus: false,
            selectedPaymentCode: '',
            selectedPaymentType: '',
            listPaymentTypes: [],
            isShowBlockCustomField: false,
            isShowModalAddProduct: false,
            isShowModalAddTag: false,
            typeSelected: appConfig.TAG_SELECTED.TAG_ORDER.CODE,
            tagSelected: {},
        };

        this.panelBillingAddressRef = React.createRef();
        this.panelShippingAddressRef = React.createRef();
        this.popoverDiscountRef = React.createRef();
        this.popoverShippingRef = React.createRef();
        this.tagRef = React.createRef();
        this.formProductCreateRef = new Set();
        this.paymentRef = React.createRef();
        this.panelCustomFieldRef = React.createRef();
        this.inputsRef = new Set();

        this.searchVariant = debounce(this.searchVariant, 500);
        this.validateDiscount = debounce(this.validateDiscount, 500);

        this.firstFocusVariant = true;
        this.firstFocusTag = true;
        this.loadedListPayment = false;
    }

    componentDidMount() {
        const { state } = this.props.location;
        if (state) {
            this.setState({
                note: state.note ? state.note : '',
                variants: state.variants ? state.variants : [],
                selectedCustomer: state.selectedCustomer ? state.selectedCustomer : {},
                currentShippingAddress: state.currentShippingAddress ? state.currentShippingAddress : {},
                currentBillingAddress: state.currentBillingAddress ? state.currentBillingAddress : {},
            });
        }
        this.props.listAllTags();
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            const owner = find(result.staffs, (item) => item.ladi_uid == result.ladi_uid);
            owner.name = this.props.t('COMMON.ME');

            this.setState({
                userInfo: result,
                assigneeID: result.ladi_uid,
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        window.LadiUI.init();
        setTimeout(() => {
            window.LadiUI.customInit();
        }, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.order.action != nextProps.order.action) {
            if ([orderTypes.CREATE_ORDER].includes(nextProps.order.action)) {
                if (nextProps.order.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                    setTimeout(() => {
                        this.props.history.push(`/orders/${nextProps.order.order_id}`);
                    }, 100);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }
        }

        if (this.props.discount.action != nextProps.discount.action) {
            if (nextProps.discount.action == discountTypes.VALIDATE_DISCOUNT) {
                if (nextProps.discount.status) {
                    this.setState(
                        {
                            discountFee: nextProps.discount.discount_fee || this.state.discountFee,
                            variants: nextProps.discount.variants,
                        },
                        () => this.calculateTotal()
                    );
                } else {
                    this.setState(
                        {
                            discountFee: 0,
                            selectDiscount: {
                                type: appConfig.DISCOUNT.TYPE.FIXED.value,
                                code: '',
                            },
                            applyDiscount: {
                                type: appConfig.DISCOUNT.TYPE.FIXED.value,
                                code: '',
                            },
                        },
                        () => this.calculateTotal()
                    );

                    window.LadiUI.showErrorMessage('Thông báo', nextProps.discount.message, 'OK');
                }
            }
        }

        if (this.props.variant.action != nextProps.variant.action) {
            if (nextProps.variant.action == variantTypes.SEARCH_VARIANT) {
                if (nextProps.variant.status) {
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.variant.message, 'OK');
                }
            }
        }

        if (this.props.shippingRate.action != nextProps.shippingRate.action) {
            if ([shippingRateTypes.SHIPPING_RATE_GET_AVAILABLE].includes(nextProps.shippingRate.action)) {
                if (nextProps.shippingRate.status) {
                    this.clearShippingRate();
                }
            }
        }

        if (this.props.paymentReducer.action != nextProps.paymentReducer.action) {
            if ([paymentTypes.PAYMENT_LIST_GATEWAYS].includes(nextProps.paymentReducer.action)) {
                if (nextProps.paymentReducer.status) {
                    this.loadedListPayment = true;

                    if (this.props.paymentReducer.gateways != nextProps.paymentReducer.gateways) {
                        this.setState({
                            selectedPaymentCode: nextProps.paymentReducer.gateways[0].code,
                            listPaymentTypes: nextProps.paymentReducer.gateways[0].config.active_payment_methods,
                        });
                    }
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.paymentReducer.message, 'OK');
                }
            }
        }

        if (this.props.orderTag != nextProps.orderTag) {
            if (nextProps.orderTag.action === orderTagTypes.CREATE_ORDER_TAG) {
                if (nextProps.orderTag.action) {
                    this.props.listAllTags();
                    window.LadiUI.toastCustom('success', '', nextProps.orderTag.message);
                    this.setState({
                        isShowModalAddTag: false,
                    });
                }
            }
        }
    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    onChangeAddress = (address, type) => {
        if (type == appConfig.ADDRESS_TYPE.SHIPPING) {
            let currentBillingAddress = this.state.currentBillingAddress;
            if (this.state.isSameShippingAddress) {
                currentBillingAddress = address;
            }
            this.setState(
                {
                    currentShippingAddress: address,
                    currentBillingAddress,
                },
                () => {
                    this.getAvailableShippingRate();
                    const isSameShippingAddress = baseHelper.compareShippingAndBilling(
                        this.state.currentShippingAddress,
                        this.state.currentBillingAddress
                    );
                    this.setState({
                        isSameShippingAddress,
                    });
                }
            );
        } else {
            this.setState(
                {
                    currentBillingAddress: address,
                },
                () => {
                    const isSameShippingAddress = baseHelper.compareShippingAndBilling(
                        this.state.currentShippingAddress,
                        this.state.currentBillingAddress
                    );
                    this.setState({
                        isSameShippingAddress,
                    });
                }
            );
        }
    };

    /*******************************SEARCH VARIANT***************************/
    onChangeSearchVariantInput = (event) => {
        this.setState(
            {
                searchVariant: event.target.value,
            },
            () => this.searchVariant()
        );
    };

    onFocusVariantInput = (event) => {
        if (this.firstFocusVariant) {
            this.searchVariant();
        }

        this.firstFocusVariant = false;
    };

    /**
     * Tim kiem theo name
     */

    searchVariant = () => {
        this.props.searchVariant(this.state.searchVariant);
    };

    /**
     * @description:
     * Vi code JS doi value cua displaySearchVariant nhung state trong React khong doi
     * =>Khong render lai ma phai thay doi state cua displaySearchVariant de React hieu duoc
     */
    selectVariant = (variant) => {
        if (!baseHelper.isAvailableVariant(variant)) {
            return;
        }
        this.setState(
            {
                displaySearchVariant: '',
                disabledApplyDiscount: false,
                isShowModalAddProduct: false,
            },
            () => {
                this.setState({
                    search: '',
                    displaySearchVariant: this.props.t('PRODUCTS.SEARCH_PRODUCT'),
                });
            }
        );

        const { variants } = this.state;
        const { product_id: productID, product_variant_id: variantID, name, price, src, quantity, weight, options } = variant;

        if (find(variants, (item) => item.product_id && item.variant_id && item.product_id == productID && item.variant_id == variantID)) {
            document.getElementById(`quantity-${variantID}`).select();
            return;
        }

        const newVariant = {
            product_id: productID,
            variant_id: variantID,
            name,
            price,
            weight,
            src,
            quantity: 1,
            total: price,
            options,
        };

        variants.push(newVariant);

        this.setState(
            {
                variants,
            },
            () => this.validateDiscount()
        );
    };

    removeVariant = (index) => {
        const { variants } = this.state;
        remove(variants, (item, _index) => {
            return index == _index;
        });

        this.setState(
            {
                variants,
            },
            () => {
                this.checkDisabledApply();
                this.validateDiscount();
            }
        );
    };
    /****************************************************************/

    onChangeQuantity = (event, max_buy, index) => {
        const { variants } = this.state;

        const message = this.props.t('STORES.MSG_MAX_BUY');

        if (max_buy != null && event.target.value > max_buy) {
            window.LadiUI.toastCustom('danger', message);
            event.target.value = max_buy;
        }

        const selectVariant = variants[index];

        selectVariant.quantity = baseHelper.parseInt(event.target.value);
        if (!selectVariant.quantity) {
            selectVariant.quantity = 1;
        }
        this.setState({
            variants,
        });
    };

    changeQuantityVariant = (value, max_buy, index) => {
        const { variants } = this.state;

        const message = this.props.t('STORES.MSG_MAX_BUY');
        const selectVariant = variants[index];
        selectVariant.quantity += value;

        if (max_buy != null && selectVariant.quantity > max_buy) {
            window.LadiUI.toastCustom('danger', message);
            selectVariant.quantity -= value;
        }

        if (selectVariant.quantity === 0) {
            this.removeVariant(index);
            return;
        }

        if (!selectVariant.quantity) {
            selectVariant.quantity = 1;
        }

        this.setState(
            {
                variants,
            },
            () => {
                this.validateDiscount();
            }
        );
    };

    calculateTotal = (listAvailableShippingRate = true) => {
        const { variants, shipping_fee, discountFee } = this.state;
        const subTotal = reduce(
            variants,
            (subTotal, item) => {
                item.total = this.getLastPrice(item) * (item.quantity || 0);
                return baseHelper.parseFloat(subTotal) + baseHelper.parseFloat(item.total);
            },
            0
        );

        const total = baseHelper.parseFloat(subTotal) + baseHelper.parseFloat(shipping_fee) - baseHelper.parseFloat(discountFee);

        this.setState(
            {
                subTotal,
                total,
            },
            () => {
                if (listAvailableShippingRate) {
                    this.getAvailableShippingRate();
                }
            }
        );
    };

    onChangeDiscountValue = (event) => {
        const { selectDiscount } = this.state;

        selectDiscount.value = event.target.value;
        this.setState(
            {
                selectDiscount,
            },
            () => {
                this.checkDisabledApply();
            }
        );
    };

    onChangeDiscountInput = (event) => {
        const { selectDiscount } = this.state;
        selectDiscount[event.target.name] = event.target.value;
        this.setState(
            {
                selectDiscount,
            },
            () => {
                this.checkDisabledApply();
            }
        );
    };

    removeDiscount = () => {
        this.setState(
            {
                selectDiscount: {
                    type: appConfig.DISCOUNT.TYPE.FIXED.value,
                    value: '',
                    note: '',
                },
                applyDiscount: {},
            },
            () => this.calculateDiscount()
        );

        this.popoverDiscountRef.current.instanceRef.hide();
    };

    applyDiscount = () => {
        const applyDiscount = cloneDeep(this.state.selectDiscount);
        this.setState(
            {
                applyDiscount,
            },
            () => this.calculateDiscount()
        );

        this.popoverDiscountRef.current.instanceRef.hide();
    };

    calculateDiscount = () => {
        let {
            applyDiscount: { value, type },
            subTotal,
        } = this.state;
        let discountFee = 0;

        if (type) {
            if (type == appConfig.DISCOUNT.TYPE.FIXED.value) {
                discountFee = value;
            } else {
                if (value < 0) {
                    value = 0;
                }

                if (value > 100) {
                    value = 100;
                }
                discountFee = (baseHelper.parseFloat(subTotal) * baseHelper.parseFloat(value)) / 100;
            }

            if (discountFee > subTotal) {
                discountFee = subTotal;
            }
        }

        this.setState(
            {
                discountFee,
            },
            () => this.validateDiscount(true)
        );
    };

    validateDiscount = (force) => {
        let variants = cloneDeep(this.state.variants);

        if (baseHelper.isEmpty(variants)) {
            this.calculateTotal();
            return;
        }

        // Loại bỏ các promotion
        map(variants, (item) => {
            if (!item.is_custom_discount) {
                delete item.discount;
            }
        });

        const data = {
            discount_code: this.state.selectDiscount.code,
            variants,
            customer_id: this.state.selectedCustomer.customer_id,
        };

        if (force) {
            this.oldData = data;
            this.props.validateDiscount(data);
        } else {
            if (!isEqual(this.oldData, data)) {
                this.oldData = data;
                this.props.validateDiscount(data);
            }
        }
    };

    getAvailableShippingRate = () => {
        const { total, variants } = this.state;
        const shippingAddress = this.panelShippingAddressRef.current.getData();
        // Tính tổng weight
        const weight = reduce(
            variants,
            (_weight, item) => {
                return _weight + baseHelper.parseFloat(item.weight) * baseHelper.parseInt(item.quantity);
            },
            0
        );

        this.props.getAvailableShippingRate({
            total,
            weight,
            shipping_address: shippingAddress,
        });
    };

    checkDisabledApply = () => {
        const { selectDiscount } = this.state;
        let flag = false;

        if (baseHelper.isEmpty(this.state.variants)) {
            flag = true;
        }
        if ((selectDiscount.value || selectDiscount.note) && selectDiscount.code) {
            flag = true;
        }
        if (selectDiscount.type == appConfig.DISCOUNT.TYPE.PERCENT.value && selectDiscount.value > 100) {
            flag = true;
        }

        this.setState({
            disabledApplyDiscount: flag,
        });
    };

    applyVariantDiscount = (index, discount) => {
        const { variants } = this.state;
        variants[index].discount = discount;
        variants[index].is_custom_discount = true;

        this.setState(
            {
                variants,
            },
            () => {
                this.validateDiscount();
            }
        );
    };

    removeVariantDiscount = (index) => {
        const { variants } = this.state;
        variants[index].is_custom_discount = true;
        delete variants[index].discount;

        this.setState(
            {
                variants,
            },
            () => {
                this.validateDiscount();
            }
        );
    };
    /****************************************************************/

    /*******************************SHIPPING***************************/
    showShippingOption = () => {
        const classList = document.getElementsByClassName('shipping-box')[0].classList;
        if (classList.contains('visible')) {
            classList.remove('visible');
            return;
        }

        classList.add('visible');
    };

    onChangeShippingType = (type, shippingRateDetail) => {
        const { selectShipping } = this.state;

        selectShipping.type = type;

        if (type == appConfig.SHIPPING_TYPE.AVAILABLE) {
            selectShipping.id = shippingRateDetail.shipping_rate_detail_id;
            selectShipping.note = shippingRateDetail.name;
            selectShipping.value = shippingRateDetail.fee;
        } else {
            selectShipping.note = '';
            selectShipping.value = '';
            delete selectShipping.id;
        }

        this.setState({
            selectShipping,
        });
    };

    onChangeShippingValue = (event) => {
        const { selectShipping } = this.state;

        selectShipping.value = event.target.value;
        this.setState({
            selectShipping,
        });
    };

    onChangeShippingNote = (event) => {
        const { selectShipping } = this.state;
        selectShipping[event.target.name] = event.target.value;
        this.setState({
            selectShipping,
        });
    };

    clearShippingRate = () => {
        this.setState(
            {
                selectShipping: {
                    type: appConfig.SHIPPING_TYPE.FREE,
                    value: '',
                    note: '',
                },
                applyShipping: {},
            },
            () => this.calculateShipping()
        );

        this.popoverShippingRef.current.instanceRef.hide();
    };

    applyShipping = () => {
        const applyShipping = cloneDeep(this.state.selectShipping);
        this.setState(
            {
                applyShipping,
            },
            () => this.calculateShipping()
        );
        this.popoverShippingRef.current.instanceRef.hide();
    };

    calculateShipping = () => {
        let { value } = this.state.applyShipping;
        let shipping_fee = baseHelper.parseFloat(value);
        this.setState(
            {
                shipping_fee,
            },
            () => this.calculateTotal(false)
        );
    };
    /****************************************************************/

    /*******************************CUSTOMER***************************/
    openCreateCustomerForm = () => {
        baseHelper.resetForm('modal-customer');

        this.setState({
            currentCustomerInfo: {},
            mode: appConfig.FORM_MODE.CREATE,
        });
    };
    /****************************************************************/

    /*******************************ADD PRODUCT***************************/
    onChangeCustomVariant = (event) => {
        this.setState({
            customVariant: {
                ...this.state.customVariant,
                [event.target.name]: event.target.value,
            },
        });
    };

    openModalNewProduct = () => {
        this.setState({
            isShowModalAddProduct: false,
            isShowModalNewProduct: true,
        });
    };

    hideModalNewProduct = () => {
        this.setState({
            customVariant: {},
            isShowModalNewProduct: false,
        });
    };

    createNewProduct = () => {
        // Validate
        let errors = [];
        const inputsRef = compact(Array.from(this.formProductCreateRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        this.selectVariant(cloneDeep(this.state.customVariant));

        this.hideModalNewProduct();
    };
    /****************************************************************/

    getLastPrice = (variant) => {
        if (!variant.discount) {
            return baseHelper.parseFloat(variant.price);
        }

        const discountFee = baseHelper.calculateDiscountFee(variant.price, variant.discount.type, variant.discount.value);
        return baseHelper.parseFloat(variant.price) - baseHelper.parseFloat(discountFee);
    };

    submit = (isDraft = appConfig.STATUS.INACTIVE) => {
        if (isDraft == appConfig.STATUS.INACTIVE) {
            let errors = this.paymentRef.validate();
            if (errors.length > 0) {
                const errorMessage = errors.join('<br/>');
                window.LadiUI.toastCustom('danger', '', errorMessage);

                return;
            }
        }
        let {
            variants,
            selectedCustomer,
            applyDiscount,
            note,
            shipping_fee,
            tax_fee,
            applyShipping,
            assigneeID,
            emailNotification,
            paymentStatus,
            selectedPaymentCode,
            selectedPaymentType,
            is_auto_shipping,
        } = this.state;

        const billingAddress = this.panelBillingAddressRef.current.getData();
        const shippingAddress = this.panelShippingAddressRef.current.getData();
        const productCustomField = this.panelCustomFieldRef.current.getData();
        const tags = this.tagRef.current.getData();

        const order = {
            customer_id: selectedCustomer.customer_id,
            billing_address: {
                ...billingAddress,
            },
            shipping_address: {
                ...shippingAddress,
            },
            variants,
            discount: applyDiscount,
            note,
            custom_fields: productCustomField,
            shipping_rate_type: applyShipping.type,
            shipping_rate_detail_id: applyShipping.id,
            shipping_rate_name: applyShipping.note,
            shipping_fee,
            tax_fee,
            is_draft: isDraft,
            tags,
            assignee_id: assigneeID,
            email_notification: emailNotification,
            is_auto_shipping,
        };

        if (isDraft == appConfig.STATUS.INACTIVE) {
            if (selectedPaymentCode == appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE) {
                selectedPaymentType = 'SPP';
            }
            if (selectedPaymentCode == appConfig.PAYMENT_PARTNERS.BANK.CODE) {
                selectedPaymentType = 'BK';
            }
            if (selectedPaymentCode == appConfig.PAYMENT_PARTNERS.MOMO.CODE) {
                selectedPaymentType = 'MO';
            }
            if (selectedPaymentCode == appConfig.PAYMENT_PARTNERS.COD.CODE) {
                selectedPaymentType = selectedPaymentCode;
            }
            if (selectedPaymentCode == appConfig.PAYMENT_PARTNERS.PAYPAL.CODE) {
                selectedPaymentType = 'PAYPAL';
            }
            order.payment = {
                method: selectedPaymentCode,
                type: selectedPaymentType,
                status: paymentStatus,
            };
        }

        this.props.create(order);
    };

    createDraft = () => {
        if (!this.validateBeforeSubmit()) {
            return;
        }

        this.submit(appConfig.STATUS.ACTIVE);
    };

    openModalConfirmCreateOrder = (paymentStatus) => {
        if (!this.validateBeforeSubmit()) {
            return;
        }

        if (!this.loadedListPayment) {
            this.props.listGateways();
        }

        this.setState({
            paymentStatus,
            isShowModalConfirmCreate: true,
        });
    };

    validateBeforeSubmit = () => {
        const { variants, selectedCustomer } = this.state;
        if (variants.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('ORDERS.SELECT_AT_LEAST_ONE_PRODUCT'));
            return false;
        }

        if (!selectedCustomer.customer_id) {
            window.LadiUI.toastCustom('danger', '', this.props.t('ORDERS.SELECT_AT_LEAST_ONE_CUSTOMER'));
            return false;
        }

        return true;
    };

    hideModalConfirmCreateOrder = () => {
        this.setState({
            isShowModalConfirmCreate: false,
        });
    };

    onChangeEmailNotification = () => {
        this.setState({
            emailNotification: !this.state.emailNotification,
        });
    };

    getDataPaymentTypes = (types) => {
        let { selectedPaymentCode } = this.state;

        let dataListTypes = [];
        if (selectedPaymentCode && selectedPaymentCode == appConfig.PAYMENT_PARTNERS.APPOTA.CODE) {
            map(appConfig.SN_PAYMENT_APPOTA_METHODS, (value, index) => {
                let findIndex = types.findIndex((item) => item == value.CODE);
                if (findIndex !== -1) {
                    dataListTypes.push({
                        code: value.CODE,
                        name: value.NAME,
                    });
                }
            });
        } else {
            map(appConfig.SN_PAYMENT_METHODS, (value, index) => {
                let findIndex = types.findIndex((item) => item == value.CODE);
                if (findIndex !== -1) {
                    dataListTypes.push({
                        code: value.CODE,
                        name: value.NAME,
                    });
                }
            });
        }
        return dataListTypes;
    };

    setShowHideAddProduct = (status) => {
        if (status) {
            this.searchVariant();
        }
        this.setState({
            isShowModalAddProduct: status,
        });
    };

    handleDeleteItemVariants = async (id) => {
        const { variants } = this.state;
        let newVariant = variants.filter((item) => item.product_id !== id);

        await this.setState({
            variants: newVariant,
        });
    };

    openModalAddTag = () => {
        let blockTag = document.querySelector('#block-dropdown-tag-custom');
        blockTag.classList.remove('open');
        this.setState({
            tagSelected: {
                color: appConfig.TAG_COLORS[0],
            },
            isShowModalAddTag: true,
        });
    };

    handleCreateTag = (event) => {
        event.preventDefault();
        // Validate
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const { tagSelected } = this.state;
        let data = {
            name: tagSelected.name,
            color: tagSelected.color,
        };

        this.props.createOrderTag(data);
    };

    render() {
        const { t } = this.props;
        const {
            total,
            subTotal,
            selectDiscount,
            shipping_fee,
            variants,
            searchVariant,
            discountFee,
            selectShipping,
            selectedCustomer,
            note,
            customVariant,
            disabledApplyDiscount,
            applyShipping,
            isShowModalAddProduct,
            applyDiscount,
            assigneeID,
            userInfo,
            emailNotification,
            selectedPaymentCode,
            selectedPaymentType,
        } = this.state;
        const allTags = this.props.orderTag.allTags || [];

        const foundVariants = this.props.variant.variants || [];

        const isLoadingCreateOrder = this.props.order.loading && orderTypes.CREATE_ORDER == this.props.order.waiting;
        const isLoadingShowCustomer = this.props.customer.loading && customerTypes.SHOW_CUSTOMER == this.props.customer.waiting;
        const isLoadingValidateDiscountCode = this.props.discount.loading && discountTypes.VALIDATE_DISCOUNT == this.props.discount.waiting;
        const isLoadingSearchVariant = this.props.variant.loading && this.props.variant.waiting == variantTypes.SEARCH_VARIANT;

        const isActivePercent = selectDiscount.type == appConfig.DISCOUNT.TYPE.PERCENT.value;

        const currencyCode = userInfo.currentStore.currency_code;
        const currencySymbol = userInfo.currentStore.currency_symbol;
        const staffs = userInfo.staffs;

        const isPendingPaymentStatus = this.state.paymentStatus == appConfig.PAYMENT_STATUS.PENDING.CODE;

        const listOptionDiscount = {
            PERCENT: {
                CODE: appConfig.DISCOUNT.TYPE.PERCENT.value,
                NAME: '%',
            },
            FIXED: {
                CODE: appConfig.DISCOUNT.TYPE.FIXED.value,
                NAME: currencyCode,
            },
        };

        return (
            <div className='page-content'>
                {(isLoadingShowCustomer || isLoadingValidateDiscountCode) && <LoadingSence blur={true} />}
                <div className='ladiui content-main-title flex-row'>
                    <div className='ladiui flex-row'>
                        <div className='ladiui main-title'>{t('ORDERS.ADD_ORDER')}</div>
                    </div>
                    <button
                        type='button'
                        className={`ladiui btn btn-primary btn-lg btn-open-modal ${isLoadingCreateOrder ? 'loader' : ''}`}
                        disabled={isLoadingCreateOrder}
                        onClick={this.createDraft}
                        data-target='#white-page'
                    >
                        <img
                            src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-save-data.svg'}
                            alt=''
                            style={{ marginRight: '8px' }}
                        />
                        <div className='ladiui btn-custom-text '>{t('ORDERS.SAVE_DRAFT_ORDER')}</div>
                    </button>
                </div>

                <div className='flex order-custom-edit' style={{ marginBottom: '24px', marginTop: '16px' }}>
                    <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-tag.svg'} alt='' />
                    <DropdownTag
                        ref={this.tagRef}
                        allTags={allTags}
                        innerClassName='dropdown-tag-custom'
                        openModalAddTag={this.openModalAddTag}
                    />
                </div>

                <div>
                    <div className='ladiui-order-left'>
                        <div className='ladi-card order-products' style={{ marginBottom: 20 }}>
                            <div>
                                {variants.map((item, index) => {
                                    return (
                                        <div className={`item-variant ${index == 0 ? 'border-none' : ''}`} key={index}>
                                            <div className='image-product image-product-variant mr-14'>
                                                <Image src={item.src} width={82} height={82} />
                                            </div>
                                            <div className='w-name-product'>
                                                <div className='item-variant-name-info'>
                                                    <span className='item-title'>{item.name}</span>
                                                    <div className='item-variant-list-option'>
                                                        {item.options &&
                                                            item.options.map((option, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <span className='option-name'>{option.option_name}: </span>
                                                                        <span className='option-value'>{option.option_value_value}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-200 flex'>
                                                {item.discount && (
                                                    <span className='price-before-discount'>
                                                        {baseHelper.formatMoneyPostFix(
                                                            item.price,
                                                            this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                        )}
                                                    </span>
                                                )}
                                                <PopoverDiscount
                                                    discount={item.discount}
                                                    apply={(discount) => this.applyVariantDiscount(index, discount)}
                                                    remove={() => this.removeVariantDiscount(index)}
                                                >
                                                    <a className='ladiui-custom-popup-init'>
                                                        {baseHelper.formatMoneyPostFix(
                                                            this.getLastPrice(item),
                                                            this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                        )}
                                                    </a>
                                                </PopoverDiscount>
                                            </div>
                                            <div className='w-120 item-variant-price'>
                                                <button
                                                    className='ladiui btn btn-sm'
                                                    onClick={() => this.changeQuantityVariant(-1, item.max_buy, index)}
                                                >
                                                    -
                                                </button>
                                                <Input
                                                    id={`quantity-${item.variant_id}`}
                                                    type='number'
                                                    className='item-variant-price-input'
                                                    min={1}
                                                    value={item.quantity}
                                                    onChange={(event) => this.onChangeQuantity(event, item.max_buy, index)}
                                                    onBlur={() => this.validateDiscount()}
                                                    style={{ textAlign: 'center' }}
                                                />
                                                <button
                                                    className='ladiui btn btn-sm'
                                                    onClick={() => this.changeQuantityVariant(1, item.max_buy, index)}
                                                >
                                                    +
                                                </button>
                                            </div>
                                            <div className='w-190 text-end item-title'>
                                                {baseHelper.formatMoneyPostFix(
                                                    item.total,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </div>
                                            <div className='w-50 text-end'>
                                                <span
                                                    className='cursor-pointer'
                                                    onClick={() => this.handleDeleteItemVariants(item.product_id)}
                                                >
                                                    <i className='ladi-icon icon-bin' style={{ color: '#6d6d6d' }}></i>
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className='center-btn mb-20'>
                                <div className='ladiui border-none flex cursor-pointer' onClick={() => this.setShowHideAddProduct(true)}>
                                    <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'} alt='' />
                                    <div className='btn-add-product'>{t('PRODUCTS.ADD_NEW')}</div>
                                </div>
                            </div>

                            <div className='footer-order'>
                                <div className='w-49-p'>
                                    <div className='item-info block-create-order-staff'>
                                        <div className='title-label bold-500'>{t('ORDERS.ASSIGN_TITLE')}</div>

                                        <SelectOption
                                            wrapperClassName='select-assignee'
                                            innerClassName='ellipsis custom-dropdown-staff'
                                            currentKey={assigneeID}
                                            _key='ladi_uid'
                                            _value='name'
                                            items={staffs || []}
                                            onSelectItem={(item) => {
                                                this.setState({
                                                    assigneeID: item.ladi_uid,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className='block-note mt-19 mb-24'>
                                        <div className='title-lable mb-8'>{t('ORDERS.NOTE')}</div>
                                        <textarea className='ladiui textarea' value={note} name='note' onChange={this.onChangeInput} />
                                    </div>
                                    <div className='ladiui toggle-dropdown-product-edit block-custom-field order'>
                                        <h3
                                            className={`ladiui title-block pointer relative mb-24 ${
                                                this.state.isShowBlockCustomField ? 'arrow-up' : 'arrow-down'
                                            }`}
                                            onClick={() =>
                                                this.setState({
                                                    isShowBlockCustomField: !this.state.isShowBlockCustomField,
                                                })
                                            }
                                        >
                                            {t('PRODUCTS.CUSTOM_FIELD')}
                                        </h3>
                                        <div style={this.state.isShowBlockCustomField ? { display: 'block' } : { display: 'none' }}>
                                            <PanelCustomField
                                                ref={this.panelCustomFieldRef}
                                                customFields={[]}
                                                groupType={appConfig.CUSTOM_FIELD.GROUP_TYPES.ORDER.CODE}
                                                actionType={appConfig.FORM_MODE.CREATE}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='w-49-p'>
                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-dola.svg'} alt='' />
                                            <span className='footer-lable'>{t('ORDERS.TOTAL_AMOUNT')}</span>
                                        </div>
                                        <div>
                                            <span>
                                                {baseHelper.formatMoneyPostFix(
                                                    subTotal,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-discount.svg'} alt='' />
                                            <span className='footer-lable'>
                                                {t('ORDERS.DISCOUNT')} {` `}
                                            </span>
                                            <Popover
                                                wrapperProps={{ className: 'discount-option' }}
                                                wrapperPopoverProps={{
                                                    className: 'text-left discount-box discount-popup',
                                                }}
                                                ref={this.popoverDiscountRef}
                                                content={
                                                    <div>
                                                        <div className='ladiui-input-group pos-rel'>
                                                            {selectDiscount.type == appConfig.DISCOUNT.TYPE.FIXED.value && (
                                                                <NumberInput
                                                                    className='pl-10'
                                                                    prefix={currencySymbol}
                                                                    value={selectDiscount.value}
                                                                    onChange={this.onChangeDiscountValue}
                                                                    isDiscountType={appConfig.DISCOUNT.TYPE.FIXED.value}
                                                                />
                                                            )}
                                                            {selectDiscount.type == appConfig.DISCOUNT.TYPE.PERCENT.value && (
                                                                <NumberInput
                                                                    prefix='%'
                                                                    min={0}
                                                                    max={100}
                                                                    className='pl-10'
                                                                    value={selectDiscount.value}
                                                                    onChange={(event) => this.onChangeDiscountValue(event)}
                                                                    isDiscountType={appConfig.DISCOUNT.TYPE.PERCENT.value}
                                                                />
                                                            )}
                                                            <SelectOption
                                                                customStyle={{
                                                                    width: '65px',
                                                                    height: '22px',
                                                                    position: 'absolute',
                                                                    top: '6px',
                                                                    right: '10px',
                                                                }}
                                                                directionUp={true}
                                                                wrapperClassName='mini-dropdown fix'
                                                                innerClassName='select-weight mini-dropdown mini-dropdown-pd dropdown-discount-type'
                                                                currentKey={selectDiscount.type}
                                                                items={listOptionDiscount}
                                                                _key='CODE'
                                                                _value='NAME'
                                                                onSelectItem={(item) => {
                                                                    this.setState({
                                                                        selectDiscount: {
                                                                            ...this.state.selectDiscount,
                                                                            type: item.CODE,
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        </div>

                                                        <div className='discount-reason'>
                                                            <Input
                                                                type='text'
                                                                name='note'
                                                                value={selectDiscount.note}
                                                                onChange={this.onChangeDiscountInput}
                                                                placeholder={t('COMMON.REASON')}
                                                            />
                                                        </div>

                                                        <div className='discount-code'>
                                                            <Input
                                                                type='text'
                                                                name='code'
                                                                value={selectDiscount.code}
                                                                onChange={this.onChangeDiscountInput}
                                                                placeholder={t('DISCOUNTS.OR_DISCOUNT_CODE')}
                                                            />
                                                        </div>

                                                        <div className='flex-end'>
                                                            <button
                                                                className='ladiui btn btn-secondary btn-sm  mr-24'
                                                                onClick={this.removeDiscount}
                                                            >
                                                                {' '}
                                                                {t('ACTIONS.REMOVE')}
                                                            </button>
                                                            <button
                                                                className={`ladiui btn btn-primary btn-sm  ${
                                                                    disabledApplyDiscount ? 'disabled' : ''
                                                                }`}
                                                                disabled={disabledApplyDiscount}
                                                                onClick={this.applyDiscount}
                                                            >
                                                                {' '}
                                                                {t('ACTIONS.CONFIRM')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <span>
                                                    &nbsp;{`(`}
                                                    <a className='ladiui-custom-popup-init'>{`${t('ORDERS.ADD_DISCOUNT')}`}</a>
                                                    {`)`}
                                                </span>
                                            </Popover>

                                            {applyDiscount.note || applyDiscount.code}
                                        </div>
                                        <div>
                                            <span>
                                                {baseHelper.formatMoneyPostFix(
                                                    discountFee,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-delivery.svg'} alt='' />
                                            <span className='footer-lable'>{t('ORDERS.SHIPPING')}</span>
                                            <Popover
                                                ref={this.popoverShippingRef}
                                                wrapperProps={{ className: 'shipping-option' }}
                                                wrapperPopoverProps={{
                                                    className: 'shipping-box text-left ladiui-custom-popup-content shipping-popup',
                                                }}
                                                content={
                                                    <div>
                                                        <div className='shipping-group'>
                                                            {map(this.props.shippingRate.shipping_rates, (item, index) => (
                                                                <div key={index} className='flex mb-10'>
                                                                    <input
                                                                        id={`shipping-rate-service-${index}`}
                                                                        className='ladiui form-check-input'
                                                                        type='radio'
                                                                        name='radio-group'
                                                                        value={appConfig.SHIPPING_TYPE.AVAILABLE}
                                                                        onChange={() =>
                                                                            this.onChangeShippingType(
                                                                                appConfig.SHIPPING_TYPE.AVAILABLE,
                                                                                item
                                                                            )
                                                                        }
                                                                        checked={
                                                                            selectShipping.type == appConfig.SHIPPING_TYPE.AVAILABLE &&
                                                                            item.shipping_rate_detail_id == selectShipping.id
                                                                        }
                                                                    />
                                                                    <label htmlFor={`shipping-rate-service-${index}`}>{item.name}</label>
                                                                </div>
                                                            ))}

                                                            <div className='flex mb-10'>
                                                                <input
                                                                    id='radioShippingFree'
                                                                    type='radio'
                                                                    className='ladiui form-check-input'
                                                                    name='radio-group'
                                                                    value={appConfig.SHIPPING_TYPE.FREE}
                                                                    onChange={() => this.onChangeShippingType(appConfig.SHIPPING_TYPE.FREE)}
                                                                    checked={selectShipping.type == appConfig.SHIPPING_TYPE.FREE}
                                                                />
                                                                <label htmlFor='radioShippingFree'>{t('ORDERS.FREE_SHIPPING')}</label>
                                                            </div>
                                                            <div className='flex mb-10'>
                                                                <input
                                                                    id='radioShippingCustom'
                                                                    type='radio'
                                                                    className='ladiui form-check-input'
                                                                    name='radio-group'
                                                                    value={appConfig.SHIPPING_TYPE.CUSTOM}
                                                                    onChange={() =>
                                                                        this.onChangeShippingType(appConfig.SHIPPING_TYPE.CUSTOM)
                                                                    }
                                                                    checked={selectShipping.type == appConfig.SHIPPING_TYPE.CUSTOM}
                                                                />
                                                                <label htmlFor='radioShippingCustom'>{t('ORDERS.CUSTOM')}</label>
                                                            </div>
                                                        </div>
                                                        {selectShipping.type == appConfig.SHIPPING_TYPE.CUSTOM && (
                                                            <div className='shipping-group'>
                                                                <div className='shipping-item'>
                                                                    <Input
                                                                        type='text'
                                                                        name='note'
                                                                        value={selectShipping.note}
                                                                        onChange={this.onChangeShippingNote}
                                                                        placeholder={t('ORDERS.SHIPPING_RATE_NAME')}
                                                                    />
                                                                </div>
                                                                <div className='shipping-item'>
                                                                    <NumberInput
                                                                        prefix={currencySymbol}
                                                                        value={selectShipping.value}
                                                                        onChange={this.onChangeShippingValue}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div className='flex-end'>
                                                            <button
                                                                className='ladiui btn btn-secondary btn-sm  mr-24'
                                                                data-dissmiss='shipping-popup'
                                                                onClick={this.clearShippingRate}
                                                            >
                                                                {' '}
                                                                {t('ACTIONS.REMOVE')}
                                                            </button>
                                                            <button
                                                                className='ladiui btn btn-primary btn-sm '
                                                                data-dissmiss='shipping-popup'
                                                                onClick={this.applyShipping}
                                                            >
                                                                {' '}
                                                                {t('ACTIONS.CONFIRM')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <span>
                                                    &nbsp;{`(`}
                                                    <a className='ladiui-custom-popup-init'>{t('ORDERS.ADD_SHIPPING')}</a>
                                                    {`)`}
                                                </span>
                                            </Popover>
                                        </div>
                                        <div>
                                            <span>
                                                {baseHelper.formatMoneyPostFix(
                                                    shipping_fee,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>

                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-dola.svg'} alt='' />
                                            <span className='footer-lable'>{t('ORDERS.TOTAL_ORDER')}</span>
                                        </div>
                                        <div>
                                            <span>
                                                {baseHelper.formatMoneyPostFix(
                                                    total,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='item-info'>
                                        <div className='flex'>
                                            <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-payment.svg'} alt='' />
                                            <span className='footer-lable'>{t('ORDERS.PAYMENT_ACTION')}</span>
                                        </div>
                                        <div className='flex'>
                                            <button
                                                className='ladiui btn btn-primary btn-sm mr-12'
                                                onClick={() => this.openModalConfirmCreateOrder(appConfig.PAYMENT_STATUS.PENDING.CODE)}
                                            >
                                                {' '}
                                                {t('ORDERS.MARK_AS_PENDING')}
                                            </button>
                                            <button
                                                className='ladiui btn btn-success btn-sm'
                                                onClick={() => this.openModalConfirmCreateOrder(appConfig.PAYMENT_STATUS.SUCCESS.CODE)}
                                            >
                                                {' '}
                                                {t('ORDERS.MARK_AS_PAID')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='ladiui-order-right'>
                        <div className='info-customer mb-24'>
                            <div className='create-order-customer-right'>
                                <div className='panel-customer'>
                                    <PanelCustomer
                                        mode={appConfig.FORM_MODE.CREATE}
                                        selectedCustomer={selectedCustomer}
                                        isResetCustomer={true}
                                        onSelectCustomer={(selectedCustomer) => {
                                            this.setState(
                                                {
                                                    selectedCustomer,
                                                    currentShippingAddress: selectedCustomer.address_default,
                                                    currentBillingAddress: selectedCustomer.address_default,
                                                },
                                                () => this.validateDiscount()
                                            );
                                        }}
                                    />
                                </div>
                                <div className='panel-shipping-address'>
                                    <PanelShippingAddress
                                        ref={this.panelShippingAddressRef}
                                        mode={appConfig.FORM_MODE.CREATE}
                                        shippingAddress={this.state.currentShippingAddress}
                                        addresses={selectedCustomer.addresses}
                                        onChangeAddress={this.onChangeAddress}
                                    />

                                    <PanelBillingAddress
                                        ref={this.panelBillingAddressRef}
                                        mode={appConfig.FORM_MODE.CREATE}
                                        billingAddress={this.state.currentBillingAddress}
                                        addresses={selectedCustomer.addresses}
                                        onChangeAddress={this.onChangeAddress}
                                        isSameShippingAddress={this.state.isSameShippingAddress}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isShowModalNewProduct && (
                    <Modal
                        id='modal-new-product'
                        title={t('PRODUCTS.ADD_PRODUCT')}
                        visible={this.state.isShowModalNewProduct}
                        onCancel={this.hideModalNewProduct}
                        onOk={this.createNewProduct}
                        width={700}
                    >
                        <div className='info'>
                            <div className='ladiui form-group mt-0'>
                                <label className='ladiui-label'>{t('PRODUCTS.NAME')}</label>
                                <Input
                                    name='name'
                                    onChange={this.onChangeCustomVariant}
                                    value={customVariant.name}
                                    validationName={t('PRODUCTS.NAME')}
                                    validations={{ isRequired: true }}
                                    ref={(ref) => this.formProductCreateRef.add(ref)}
                                />
                            </div>
                            <div className='ladiui form-group mt-0'>
                                <label className='ladiui-label'>{t('PRODUCTS.PRICE')}</label>

                                <Input
                                    name='price'
                                    type='number'
                                    currency={true}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/[, ]/g, '')}
                                    onChange={this.onChangeCustomVariant}
                                    value={customVariant.price}
                                    validationName={t('PRODUCTS.PRICE')}
                                    validations={{ isRequired: true }}
                                    ref={(ref) => this.formProductCreateRef.add(ref)}
                                />
                            </div>
                            <div className='ladiui form-group mt-0'>
                                <label className='ladiui-label'>{t('COMMON.QUANTITY')}</label>

                                <Input
                                    name='quantity'
                                    type='number'
                                    onChange={this.onChangeCustomVariant}
                                    value={customVariant.quantity}
                                    validationName={t('COMMON.QUANTITY')}
                                    validations={{ isRequired: true }}
                                    ref={(ref) => this.formProductCreateRef.add(ref)}
                                />
                            </div>
                        </div>
                    </Modal>
                )}

                {this.state.isShowModalConfirmCreate && (
                    <Modal
                        id='modal-confirm-payment-order'
                        title={isPendingPaymentStatus ? t('ORDERS.MSG_PAYMENT_PENDING_TITLE') : t('ORDERS.MSG_PAYMENT_SUCCESS_TITLE')}
                        visible={this.state.isShowModalConfirmCreate}
                        onCancel={this.hideModalConfirmCreateOrder}
                        width={600}
                        isCustomFooter={true}
                        bodyClassName='unset-modal'
                        footer={
                            <div className='ladiui-modal-footer'>
                                <div className='send-email flex'>
                                    <input
                                        id='emailNotification'
                                        checked={emailNotification}
                                        type='checkbox'
                                        onChange={this.onChangeEmailNotification}
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                    />
                                    <label className='ladiui label'> {t('COMMON.EMAIL_NOTIFICATION')}</label>
                                </div>
                                <div className='flex'>
                                    <button onClick={this.hideModalConfirmCreateOrder} className='ladiui btn btn-secondary mr-12 flex'>
                                        <i className='ladi-icon icon-e-remove mr-8' /> {t('ACTIONS.REMOVE')}
                                    </button>
                                    <button
                                        onClick={() => this.submit(appConfig.STATUS.INACTIVE)}
                                        className={`ladiui btn btn-primary ${isLoadingCreateOrder ? 'loader' : ''}`}
                                        disabled={isLoadingCreateOrder}
                                    >
                                        {' '}
                                        {t('COMMON.VERIFY')}
                                    </button>
                                </div>
                            </div>
                        }
                    >
                        <p>
                            <span>
                                {isPendingPaymentStatus ? t('ORDERS.MSG_PAYMENT_PENDING_CONFIRM') : t('ORDERS.MSG_PAYMENT_SUCCESS_CONFIRM')}
                            </span>
                        </p>
                        <p>
                            <span>
                                {isPendingPaymentStatus
                                    ? t('ORDERS.MSG_PAYMENT_PENDING_CONTENT1')
                                    : t('ORDERS.MSG_PAYMENT_SUCCESS_CONTENT1')}
                            </span>
                        </p>
                        <p className='pt-24'>
                            <strong>
                                {isPendingPaymentStatus
                                    ? t(
                                          'ORDERS.MSG_PAYMENT_PENDING_CONTENT2',
                                          baseHelper.formatMoneyPostFix(
                                              total,
                                              this.props.storeReducer.userInfo.currentStore.currency_symbol
                                          )
                                      )
                                    : t(
                                          'ORDERS.MSG_PAYMENT_SUCCESS_CONTENT2',
                                          baseHelper.formatMoneyPostFix(
                                              total,
                                              this.props.storeReducer.userInfo.currentStore.currency_symbol
                                          )
                                      )}
                            </strong>
                        </p>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>{t('PAYMENTS.LB_SELECT_PAYMENT')}</label>
                            <Dropdown
                                data={this.props.paymentReducer.gateways}
                                onSelectItem={(item) => {
                                    this.setState({
                                        selectedPaymentCode: item.code,
                                        listPaymentTypes: item.config.active_payment_methods,
                                    });
                                }}
                                currentKey={selectedPaymentCode}
                                _key={'code'}
                                _value={'name'}
                                validationName={t('PAYMENTS.LB_PAYMENT_VALIDATION')}
                                placeHolder={t('PAYMENTS.LB_SELECT_PAYMENT')}
                                ref={(ref) => (this.paymentRef = ref)}
                                validations={{ isRequired: true }}
                            />
                        </div>
                        {!baseHelper.isEmpty(this.state.listPaymentTypes) && (
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('PAYMENTS.LB_SELECT_PAYMENT_TYPE')}</label>
                                <Dropdown
                                    data={this.getDataPaymentTypes(this.state.listPaymentTypes)}
                                    onSelectItem={(item) => {
                                        this.setState({
                                            selectedPaymentType: item.code,
                                        });
                                    }}
                                    currentKey={selectedPaymentType}
                                    _key={'code'}
                                    _value={'name'}
                                    validationName={t('PAYMENTS.LB_PAYMENT_VALIDATION')}
                                    placeHolder={t('PAYMENTS.CHOOSE_PAYMENT_TYPE')}
                                    ref={(ref) => (this.paymentRef = ref)}
                                    validations={{ isRequired: true }}
                                />
                            </div>
                        )}

                        {userInfo.currentStore.store_id && userInfo.currentStore.store_id == '3319' && (
                            <>
                                {this.state.paymentStatus == appConfig.PAYMENT_STATUS.SUCCESS.CODE ||
                                (this.state.paymentStatus == appConfig.PAYMENT_STATUS.PENDING.CODE &&
                                    this.state.selectedPaymentCode == appConfig.PAYMENT_PARTNERS.COD.CODE) ? (
                                    <div className='ladiui form-group'>
                                        <div className='flex flex-start'>
                                            <input
                                                type='checkbox'
                                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                                name='is_auto_shipping'
                                                onChange={(event) =>
                                                    this.setState({
                                                        is_auto_shipping: !this.state.is_auto_shipping,
                                                    })
                                                }
                                                checked={this.state.is_auto_shipping}
                                            />{' '}
                                            Tự động tạo đơn vận chuyển
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </Modal>
                )}

                {isShowModalAddProduct && (
                    <ModalAddProduct
                        t={t}
                        onClose={this.setShowHideAddProduct}
                        isShow={isShowModalAddProduct}
                        listVariant={foundVariants}
                        currency_symbol={this.props.storeReducer.userInfo.currentStore.currency_symbol}
                        openModalNewProduct={this.openModalNewProduct}
                        selectVariant={this.selectVariant}
                        onChangeSearchVariantInput={this.onChangeSearchVariantInput}
                        onFocusVariantInput={this.onFocusVariantInput}
                        selectedProducts={variants || []}
                        searchVariant={this.searchVariant}
                        searchVariantValue={searchVariant}
                        isSelectedTypeProduct={false}
                    />
                )}

                {this.state.isShowModalAddTag && (
                    <Modal
                        id='modal-tag-edit'
                        title={t('TAGS.ADD_TAG')}
                        visible={this.state.isShowModalAddTag}
                        onCancel={() =>
                            this.setState({
                                isShowModalAddTag: false,
                            })
                        }
                        width={400}
                        bodyStyles={{ minHeight: '25vh' }}
                        isCustomFooter={true}
                        footer={
                            <div className='ladiui-modal-footer'>
                                <div className='ladiui page-tags'>
                                    <a className='flex' target='_blank' href='/settings#TAG'>
                                        <i className='icon-tag-footer-modal mr-8'></i>
                                        <span>Quản lý Tag</span>
                                    </a>
                                </div>
                                <div className='flex'>
                                    <button
                                        onClick={() =>
                                            this.setState({
                                                isShowModalAddTag: false,
                                            })
                                        }
                                        className='ladiui btn btn-secondary mr-12'
                                    >
                                        {t('ACTIONS.CLOSE')}
                                    </button>
                                    <button onClick={this.handleCreateTag} className='ladiui btn btn-primary'>
                                        {t('ACTIONS.SAVE')}
                                    </button>
                                </div>
                            </div>
                        }
                    >
                        <div className='ladiui form-group'>
                            <label className='ladiui-label' style={{ marginBottom: '8px' }}>
                                {t('TAGS.COL_TAG')}
                            </label>
                            <Input
                                value={this.state.tagSelected.name}
                                onChange={(event) => {
                                    this.setState({
                                        tagSelected: {
                                            ...this.state.tagSelected,
                                            name: event.target.value,
                                        },
                                    });
                                }}
                                validationName={t('TAGS.COL_TAG')}
                                validations={{ isRequired: true }}
                                ref={(ref) => this.inputsRef.add(ref)}
                            />
                        </div>
                        {this.state.typeSelected == appConfig.TAG_SELECTED.TAG_ORDER.CODE &&
                            this.state.tagSelected.color &&
                            this.state.tagSelected.name && (
                                <span
                                    className='order-tag-item'
                                    style={{ color: 'var(--main-default)', backgroundColor: this.state.tagSelected.color }}
                                >
                                    {this.state.tagSelected.name}
                                </span>
                            )}

                        {this.state.typeSelected == appConfig.TAG_SELECTED.TAG_ORDER.CODE && (
                            <div className='form-default-color'>
                                <label className='ladiui-label'>{t('TAGS.PICK_COLOR')}</label>
                                <div className='list-pick-color-tag-customer'>
                                    {map(appConfig.TAG_COLORS, (item) => (
                                        <span
                                            key={item}
                                            className='item'
                                            style={{ backgroundColor: item }}
                                            onClick={() => {
                                                this.setState({
                                                    tagSelected: {
                                                        ...this.state.tagSelected,
                                                        color: item,
                                                    },
                                                });
                                            }}
                                        >
                                            {item == this.state.tagSelected.color && <i className='ladi-icon icon-d-check' />}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Modal>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        create: (order) => dispatch(orderActions.create(order)),
        searchVariant: (name) => dispatch(variantActions.search(name)),
        searchCustomer: (name) => dispatch(customerActions.search(name)),
        validateDiscount: (data) => dispatch(discountActions.validateDiscount(data)),
        getAvailableShippingRate: (data) => dispatch(shippingRateActions.getAvailable(data)),
        listAllTags: () => dispatch(orderTagActions.listAll()),
        listGateways: () => dispatch(paymentActions.listGateways()),
        createOrderTag: (data) => dispatch(orderTagActions.create(data)),
    };
};

const mapStateToProps = (state) => ({
    variant: { ...state.variant },
    customer: { ...state.customer },
    order: { ...state.order },
    discount: { ...state.discount },
    shippingRate: { ...state.shippingRate },
    orderTag: { ...state.orderTag },
    storeReducer: { ...state.store },
    paymentReducer: { ...state.payment },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderCreate));
