/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';

import Dropdown from '../../../components/Dropdown';
import { Editor } from '@tinymce/tinymce-react';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';

import productActions from '../../../redux/futures/product/actions';
import productCategoryActions from '../../../redux/futures/product_category/actions';
import fileActions from '../../../redux/futures/file/actions';
import * as fileTypeAction from '../../../redux/futures/file/types';

import * as productTypes from '../../../redux/futures/product/types';
import * as productCategoryTypes from '../../../redux/futures/product_category/types';

import ModalAddProduct from '../../orders/OrderCreate/components/ModalAddProduct';
import produce from 'immer';
import { find, map } from 'lodash';
import Image from '../../../components/Image';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CDN = 'https://w.ladicdn.com/';

function ModalCreateCategory(props) {
    const { t, i18n, showModal, onCancel, currentCategoryID, isModalEdit, selectedType } = props;
    const dispatch = useDispatch();

    const productReducer = useSelector((state) => state.product);
    const productCategoryReducer = useSelector((state) => state.productCategory);
    const fileReducer = useSelector((state) => state.file);
    const storeReducer = useSelector((state) => state.store);

    let [categoryInfo, setCategoryInfo] = useState({
        name: '',
        parent_id: null,
        src: null,
        description: '',
        isShow: true,
        product_ids: [],
    });

    // let [isShowModalAddProduct, setIsShowModalAddProduct] = useState(false);
    let [products, setProducts] = useState([]);
    let [selectedProducts, setSelectedProducts] = useState([]);
    let [searchProducts, setSearchProducts] = useState('');

    let [listSelects, setListSelects] = useState([]);

    const inputsRef = new Set();

    useEffect(() => {
        if (fileReducer.action == fileTypeAction.UPLOAD_CATEGORY_IMAGE) {
            if (fileReducer.status) {
                if (fileReducer.image_url) {
                    setCategoryInfo({
                        ...categoryInfo,
                        src: fileReducer.image_url,
                    });
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
    }, [fileReducer]);

    useEffect(() => {
        if (productReducer && productReducer.action == productTypes.SEARCH_PRODUCT) {
            if (productReducer.status) {
                setProducts(productReducer.products);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer]);

    useEffect(() => {
        if (productCategoryReducer && productCategoryReducer.action == productCategoryTypes.CREATE_CATEGORY) {
            if (productCategoryReducer.status) {
                window.LadiUI.toastCustom('success', '', productCategoryReducer.message);
                onCancel();
                props.reloadListCate();
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
        if (productCategoryReducer && productCategoryReducer.action == productCategoryTypes.SHOW_CATEGORY) {
            if (productCategoryReducer.status) {
                setCategoryInfo({ ...productCategoryReducer.productCategory });
                setSelectedProducts(productCategoryReducer.productCategory.products || []);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
        if (productCategoryReducer && productCategoryReducer.action == productCategoryTypes.UPDATE_CATEGORY) {
            if (productCategoryReducer.status) {
                window.LadiUI.toastCustom('success', '', productCategoryReducer.message);
                onCancel();
                props.reloadListCate();
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
    }, [productCategoryReducer]);

    useEffect(() => {
        if (currentCategoryID) {
            dispatch(productCategoryActions.show({ product_category_id: currentCategoryID }));
        }
    }, [currentCategoryID]);

    useEffect(() => {
        if (props.listSelectCategories && props.listSelectCategories.length > 0) {
            setListSelects(props.listSelectCategories);
        }
    }, [props.listSelectCategories]);

    const handleClickBtnUploadImage = () => {
        document.getElementById('input-upload-image-category').click();
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }

        const newProducts = produce(selectedProducts, (draft) => {
            const [removed] = draft.splice(result.source.index, 1);
            draft.splice(result.destination.index, 0, removed);

            Object.values(draft).forEach((item, index) => {
                item.position = index;
            });
        });

        setSelectedProducts(newProducts);
    };

    const removeProduct = (index) => {
        const newSelectedProducts = produce(selectedProducts, (draft) => {
            draft.splice(index, 1);

            Object.values(draft).forEach((item, index) => {
                item.position = index;
            });
        });
        setSelectedProducts(newSelectedProducts);
    };

    const onChangeInputCategory = (event) => {
        setCategoryInfo(
            produce(categoryInfo, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const fileChangedHandler = (event) => {
        const files = event.target.files;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadCategoryImage(form));
            }
        }
    };

    const selectedParentCategory = (selected) => {
        setCategoryInfo({
            ...categoryInfo,
            parent_id: selected.product_category_id,
        });
    };

    const handleSubmitCategory = () => {
        let payloadCategory = {
            name: categoryInfo.name,
            parent_id: categoryInfo.parent_id,
            description: categoryInfo.description,
            src: categoryInfo.src,
            isShow: categoryInfo.isShow,
            type: selectedType
            // product_ids: selectedProducts.map((item) => item.product_id),
        };
        if (categoryInfo.product_category_id) {
            payloadCategory.product_category_id = categoryInfo.product_category_id;
            dispatch(productCategoryActions.update(payloadCategory));
        } else {
            dispatch(productCategoryActions.create(payloadCategory));
        }
    };

    return (
        <>
            <Modal
                id='modal-create-category'
                title={!isModalEdit ? t('CATEGORY.ADD_CATEGORY') : 'Sửa danh mục'}
                visible={showModal}
                onCancel={onCancel}
                width={600}
                okText={!isModalEdit ? 'Tạo danh mục' : 'Lưu'}
                onOk={handleSubmitCategory}
                hasFooter={true}
            >
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('CATEGORY.CATEGORY_NAME')}</label>
                    <Input
                        name='name'
                        validationName='tên danh mục'
                        placeholder='Nhập tên danh mục'
                        value={categoryInfo.name}
                        onChange={onChangeInputCategory}
                        ref={(ref) => inputsRef.add(ref)}
                        validations={{ isRequired: true }}
                    />
                </div>
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('CATEGORY.PARENT_CATEGORY')}</label>
                    <Dropdown
                        id='state'
                        data={listSelects}
                        onSelectItem={selectedParentCategory}
                        currentKey={categoryInfo.parent_id}
                        // currentValue={FIX_PARENT_CATEGORY[0].NAME}
                        _key={'product_category_id'}
                        _value={'name'}
                        validationName={'Danh mục cha'}
                        ref={(ref) => inputsRef.add(ref)}
                        validations={false}
                        innerClassName='dropdown-custom-lds'
                        placeHolder={'Chọn danh mục'}
                        isShowBlockInputSearch={true}
                        isParentCategoryDropdown={true}
                    />
                </div>
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('CATEGORY.DESCRIPTION')}</label>
                    <textarea
                        placeholder={'Nhập mô tả'}
                        style={{ minHeight: '120px' }}
                        value={categoryInfo.description}
                        className='ladiui form-control'
                        onChange={(event) => {
                            setCategoryInfo({
                                ...categoryInfo,
                                description: event.target.value,
                            });
                        }}
                    ></textarea>
                </div>
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('CATEGORY.BACKGROUND')}</label>
                    <div className='block-upload-image-category' onClick={handleClickBtnUploadImage}>
                        <img
                            className='image'
                            src={categoryInfo.src ? CDN + categoryInfo.src : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'}
                            alt=''
                        />
                        <div className='block-upload-image'>
                            <div className='block-overlay'>
                                <div className='text'>
                                    <i className='ldicon-upload mb-8' />
                                    <p>Tải ảnh nền danh mục</p>
                                    <p>Định dạng file JPG, PNG, ZIP.</p>
                                    <p>Kích thước: 200x200 (px)</p>
                                </div>

                                <input
                                    type='file'
                                    id='input-upload-image-category'
                                    name='file'
                                    accept='image/*'
                                    onChange={fileChangedHandler}
                                    style={{ display: 'none' }}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex mt-24'>
                    <div className='mr-8'>
                        <span className='ladiui title-text-form-lever2 mb-0'>{t('CATEGORY.DISPLAY_CATEGORY')}</span>
                    </div>
                    <div className='ladiui item-form switch'>
                        <label className='ladiui switch m-0'>
                            <input
                                type='checkbox'
                                checked={categoryInfo.isShow}
                                onChange={() => {
                                    setCategoryInfo({
                                        ...categoryInfo,
                                        isShow: !categoryInfo.isShow,
                                    });
                                }}
                            />
                            <span className='ladiui slider round'></span>
                        </label>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default withTranslation()(ModalCreateCategory);
