import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import { endpoint } from "../../../config/app";

const list = (data) => ({
  type: types.LIST_PRODUCT,
  meta: {
    endpoint: endpoint.PRODUCT_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const deletes = (productIDs) => ({
  type: types.DELETE_PRODUCTS,
  meta: {
    endpoint: endpoint.PRODUCT_DELETE_IDS,
    method: REQUEST_METHOD.POST,
    body: {
      product_ids: productIDs,
    },
    hasAuth: true,
  },
});

const deleteStore = (productID) => ({
  type: types.DELETE_PRODUCT,
  meta: {
    endpoint: endpoint.PRODUCT_DELETE_ID,
    method: REQUEST_METHOD.POST,
    body: {
      product_id: productID,
    },
    hasAuth: true,
  },
});

const create = (product) => ({
  type: types.CREATE_PRODUCT,
  meta: {
    endpoint: endpoint.PRODUCT_CREATE,
    method: REQUEST_METHOD.POST,
    body: {
      product,
    },
    hasAuth: true,
  },
});

const update = (product) => ({
  type: types.UPDATE_PRODUCT,
  meta: {
    endpoint: endpoint.PRODUCT_UPDATE,
    method: REQUEST_METHOD.POST,
    body: {
      product,
    },
    hasAuth: true,
  },
});

const show = (productID) => ({
  type: types.SHOW_PRODUCT,
  meta: {
    endpoint: endpoint.PRODUCT_SHOW,
    method: REQUEST_METHOD.POST,
    body: {
      product_id: productID,
    },
    hasAuth: true,
  },
});

const reload = (productID) => ({
  type: types.RELOAD_PRODUCT,
  meta: {
    endpoint: endpoint.PRODUCT_SHOW,
    method: REQUEST_METHOD.POST,
    body: {
      product_id: productID,
    },
    hasAuth: true,
  },
});

const copy = (productID) => ({
  type: types.COPY_PRODUCT,
  meta: {
    endpoint: endpoint.PRODUCT_SHOW,
    method: REQUEST_METHOD.POST,
    body: {
      product_id: productID,
    },
    hasAuth: true,
  },
});

const search = (name, type, category_ids = null, selected_product_ids = []) => ({
  type: types.SEARCH_PRODUCT,
  meta: {
    endpoint: endpoint.SEARCH_PRODUCT,
    method: REQUEST_METHOD.POST,
    body: {
      search: name,
      type: type,
      category_ids: category_ids,
      selected_product_ids: selected_product_ids
    },
    hasAuth: true,
  },
});

const listCustomField = (data) => ({
  type: types.LIST_ALL_CUSTOM_FIELD,
  meta: {
    endpoint: endpoint.LIST_ALL_CUSTOM_FIELD,
    method: REQUEST_METHOD.POST,
    body: {
      ...data,
    },
    hasAuth: true,
  },
});

const updateCustomFieldByOrder = (data) => ({
  type: types.UPDATE_CUSTOM_FIELD_BY_ORDER,
  meta: {
    endpoint: endpoint.UPDATE_CUSTOM_FIELD_BY_ORDER,
    method: REQUEST_METHOD.POST,
    body: {
      ...data,
    },
    hasAuth: true,
  },
});

const importProduct = (form) => ({
  type: types.IMPORT_PRODUCT,
  meta: {
    endpoint: endpoint.IMPORT_PRODUCT,
    method: REQUEST_METHOD.POST,
    form,
    hasAuth: true,
  },
});

const exportProduct = (data) => ({
  type: types.EXPORT_PRODUCT,
  meta: {
    endpoint: endpoint.EXPORT_PRODUCT,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const importEvent = (form) => ({
  type: types.IMPORT_EVENT,
  meta: {
    endpoint: endpoint.IMPORT_EVENT,
    method: REQUEST_METHOD.POST,
    form,
    hasAuth: true,
  },
});

const exportEvent = (data) => ({
  type: types.EXPORT_EVENT,
  meta: {
    endpoint: endpoint.EXPORT_EVENT,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const importService = (form) => ({
  type: types.IMPORT_SERVICE,
  meta: {
    endpoint: endpoint.IMPORT_SERVICE,
    method: REQUEST_METHOD.POST,
    form,
    hasAuth: true,
  },
});

const exportService = (data) => ({
  type: types.EXPORT_SERVICE,
  meta: {
    endpoint: endpoint.EXPORT_SERVICE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

export default {
  list,
  deletes,
  delete: deleteStore,
  create,
  update,
  show,
  reload,
  copy,
  search,
  listCustomField,
  updateCustomFieldByOrder,
  importProduct,
  exportProduct,
  importEvent,
  exportEvent,
  importService,
  exportService,
};
