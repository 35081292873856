import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { I18nContext, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ModalAddProduct from "../../orders/OrderCreate/components/ModalAddProduct";

import productActions from "../../../redux/futures/product/actions";
import * as productTypes from "../../../redux/futures/product/types";
import productCategoryActions from "../../../redux/futures/product_category/actions";
import * as productCategoryTypes from "../../../redux/futures/product_category/types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import fileActions from "../../../redux/futures/file/actions";
import * as fileTypes from "../../../redux/futures/file/types";

import produce from "immer";
import { find, map } from "lodash";
import BaseHelper from "../../../helpers/BaseHelper";

import Input from "../../../components/Input";
import Tag from "../../../components/Tag";

const CDN = "https://w.ladicdn.com/";

const PanelCourseCatalog = forwardRef((props, ref) => {
  const { isShow, currentCourseCatalog, selectedBanners, currentSuggestedCategories, handlePreviewPageCourse } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fileReducer = useSelector((state) => state.file);

  const courseReducer = useSelector((state) => state.course);

  const storeReducer = useSelector((state) => state.store);

  const productReducer = useSelector((state) => state.product);
  const productCategoryReducer = useSelector((state) => state.productCategory);

  const [isShowBlockAddCourse, setIsShowBlockAddCourse] = useState(false);
  const [isShowBlockAddFeaturedCourse, setIsShowBlockAddFeaturedCourse] = useState(false);
  const [isShowBlockAddCategory, setIsShowBlockAddCategory] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedFeaturedCourses, setSelectedFeaturedCourses] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [Courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);

  const [valueSeachCourse, setValueSearchCourse] = useState("");
  const [valueSeachFeaturedCourse, setValueSearchFeaturedCourse] = useState("");
  const [valueSeachCategory, setValueSearchCategory] = useState("");

  const [selectedProductCategoryId, setSelectedProductCategoryId] = useState(null);

  const [selectedIds, setSelectedIds] = useState([]);

  const [banners, setBanners] = useState([]);
  const [suggestedCategories, setSuggestedCategories] = useState([]);
  const [isShowInputAddLink, setIsShowInputAddLink] = useState(false);
  const [indexBanner, setIndexBanner] = useState("");

  const inputsRef = new Set();

  const categoryRef = React.createRef();

  useImperativeHandle(ref, () => ({
    validate() {
      return true;
    },
    getData() {
      let _categories = [];
      let _product_ids = [];
      let dataItem = {};
      if (selectedCategories && selectedCategories.length > 0) {
        selectedCategories.map((item) => {
          if (!item.is_show_new && item.products && item.products.length > 0) {
            dataItem = {
              product_category_id: item.product_category_id,
              is_show_new: item.is_show_new,
              product_ids: item.products.map((item) => item.product_id),
            };
          } else {
            dataItem = {
              product_category_id: item.product_category_id,
              is_show_new: item.is_show_new,
              product_ids: [],
            };
          }
          _categories.push(dataItem);
        });
      }
      return {
        featured_product_ids: selectedCourses && selectedCourses.length > 0 ? selectedCourses.map((item) => item.product_id) : [],
        config_category: _categories,
        banners: banners || [],
        suggested_category_ids:
          suggestedCategories && suggestedCategories.length > 0 ? suggestedCategories.map((item) => item.product_category_id) : [],
      };
    },
  }));

  useEffect(() => {
    console.log("storeReducer...........", storeReducer);
  }, [storeReducer]);

  useEffect(() => {
    dispatch(productCategoryActions.search("", "Course"));
  }, []);

  useEffect(() => {
    if (currentCourseCatalog) {
      setSelectedCourses(currentCourseCatalog.selectedCourses);
      setSelectedCategories(currentCourseCatalog.config_category);
    }
  }, [currentCourseCatalog]);

  useEffect(() => {
    if (currentSuggestedCategories && currentSuggestedCategories.length > 0) {
      setSuggestedCategories(currentSuggestedCategories);
    }
  }, [currentSuggestedCategories]);

  useEffect(() => {
    if (selectedBanners && selectedBanners.length > 0) {
      setBanners(selectedBanners);
    }
  }, [selectedBanners]);

  useEffect(() => {
    // if (fileReducer.action == fileTypes.UPLOAD_CART_LOGO) {
    //   if (fileReducer.status) {
    //     setDataConfig({
    //       ...dataConfig,
    //       logo: fileReducer.cart_logo_url,
    //     });
    //   }
    // }
    if (fileReducer.action == fileTypes.UPLOAD_PRODUCT_IMAGES) {
      let selectedBanners = banners || [];
      if (fileReducer.status) {
        const newUploadedImage = map(fileReducer.product_images, (item, index) => {
          return {
            url: item,
            link: "",
          };
        });

        if (newUploadedImage && newUploadedImage.length > 0) {
          map(newUploadedImage, (item) => {
            selectedBanners.push(item);
          });
        }

        // selectedBanners.push(newUploadedImage);

        setBanners(selectedBanners);
      }
    }
  }, [fileReducer]);

  useEffect(() => {
    if (productReducer && productReducer.action == productTypes.SEARCH_PRODUCT) {
      if (productReducer.status) {
        setCourses(productReducer.products);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", productReducer.message, "OK");
      }
    }
  }, [productReducer]);

  useEffect(() => {
    if (productCategoryReducer && productCategoryReducer.action == productCategoryTypes.SEARCH_CATEGORY) {
      if (productCategoryReducer.status) {
        setCategories(productCategoryReducer.productCategories);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", productCategoryReducer.message, "OK");
      }
    }
  }, [productCategoryReducer]);

  const handleShowBlockAddCourse = (status) => {
    if (status) {
      dispatch(productActions.search("", "Course"));
    }
    setValueSearchCourse("");
    setIsShowBlockAddCourse(status);
  };

  const handleShowBlockAddCategory = (status) => {
    // if (status) {
    //   dispatch(productCategoryActions.search(""));
    // }
    setValueSearchCategory("");
    setIsShowBlockAddCategory(status);
  };

  const selectCourse = (data) => {
    if (selectedCourses && selectedCourses.length >= 6) {
      window.LadiUI.toastCustom("danger", "", "Khóa học nổi bật tối đa là 6");
      return;
    }
    let course = { ...data };
    course.quantity = 1;
    course.position = 0;

    if (course.max_price == course.min_price) {
      course.price = course.max_price;
    }

    if (find(selectedCourses, (item) => item.product_id == course.product_id)) {
      return;
    }

    const newSelectedCourses = produce(selectedCourses, (draft) => {
      Object.values(draft).forEach((item, index) => {
        item.position = index + 1;
      });

      draft.splice(0, 0, course);
    });

    setSelectedCourses(newSelectedCourses);
  };

  const selectFeaturedCourse = (data) => {
    let course = { ...data };
    course.position = selectedFeaturedCourses.length;

    if (course.min_price == course.max_price) {
      course.price = course.max_price;
    }
    if (find(selectedFeaturedCourses, (item) => item.product_id == course.product_id)) {
      return;
    }
    const newSelectedFeatureCourses = [...selectedFeaturedCourses];

    newSelectedFeatureCourses.push(course);

    const _newSelectCategories = [...selectedCategories];
    _newSelectCategories.map((item, index) => {
      if (item.product_category_id == selectedProductCategoryId) {
        item.products = newSelectedFeatureCourses;
      }
    });

    setSelectedFeaturedCourses(newSelectedFeatureCourses);
    setSelectedCategories(_newSelectCategories);
  };

  const handleDeleteCourse = (courseID) => {
    let selectedCourseDelete = selectedCourses.filter((item) => item.product_id != courseID);

    setSelectedCourses(selectedCourseDelete);
  };

  const selectCategory = (data) => {
    let category = { ...data };
    category.is_show_new = true;
    category.products = [];
    category.product_ids = [];

    if (find(selectedCategories, (item) => item.product_category_id == category.product_category_id)) {
      return;
    }

    const newSelectedCategories = [...selectedCategories];

    newSelectedCategories.push(category);

    setSelectedCategories(newSelectedCategories);
  };

  const listCourseItem = () => {
    return selectedCourses.map((item, index) => {
      return (
        <Draggable key={index} draggableId={"" + index} index={index}>
          {(provided, snapshot) => (
            <tr
              key={index}
              className="ladiui table-vertical"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...provided.draggableProps.style,
                userSelect: "none",
                background: snapshot.isDragging ? "#e8f0fe" : "none",
              }}
            >
              <td>
                <div className="flex">
                  <div className="block-icon-drag mr-24">
                    <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg" />
                  </div>
                  <div className="image-name flex">
                    <img
                      className="mr-8"
                      src={item.src ? CDN + item.src : "https://w.ladicdn.com/ladiui/ladisales/no-image.svg"}
                      style={{ width: "40px", height: "40px", objectFit: "cover" }}
                    />
                    <span>{item.name}</span>
                  </div>
                </div>
              </td>
              <td className="space-nowrap">
                {BaseHelper.formatMoneyPostFix(item.price, storeReducer.userInfo.currentStore.currency_symbol)}
              </td>
              <td className="text-right">
                <a onClick={() => handleDeleteCourse(item.product_id)}>
                  <i className="ladi-icon icon-bin"></i>
                </a>
              </td>
            </tr>
          )}
        </Draggable>
      );
    });
  };

  const handleChangeIsShowCourse = (productCategoryId, isShow) => {
    let _selectedCategories = [...selectedCategories];

    _selectedCategories.map((item) => {
      if (item.product_category_id == productCategoryId) {
        item.is_show_new = isShow;
      }
    });

    setSelectedCategories(_selectedCategories);
  };

  const handleShowBlockAddFeaturedCourse = (status, item) => {
    let ids = [];
    if (item && item.product_category_id) {
      ids.push(item.product_category_id);
      setSelectedProductCategoryId(item.product_category_id);
    }
    if (item && item.products) {
      setSelectedFeaturedCourses(item.products);
    }
    if (status) {
      dispatch(productActions.search("", "Course", ids));
    }

    setSelectedIds(ids);

    setValueSearchFeaturedCourse("");
    setIsShowBlockAddFeaturedCourse(status);
  };

  const handleDeleteFreatureCourse = (productCategoryId, productId) => {
    let _selectedDeleteCates = [...selectedCategories];

    _selectedDeleteCates.map((item, index) => {
      if (item.product_category_id == productCategoryId) {
        item.products = item.products.filter((item) => item.product_id != productId);
      }
    });

    setSelectedCategories(_selectedDeleteCates);
  };

  const listCourseFeaturedItem = (productCategoryId, products) => {
    return products.map((item, index) => {
      return (
        <Draggable key={index} draggableId={`${item.course_lesson_id}_${index}`} index={index}>
          {(provided, snapshot) => (
            <tr
              key={index}
              className="ladiui table-vertical"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <td>
                <div className="flex">
                  <div className="block-icon-drag mr-24">
                    <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg" />
                  </div>
                  <div className="image-name flex">
                    <img
                      className="mr-8"
                      src={item.src ? CDN + item.src : "https://w.ladicdn.com/ladiui/ladisales/no-image.svg"}
                      style={{ width: "40px", height: "40px", objectFit: "cover" }}
                    />
                    <span>{item.name}</span>
                  </div>
                </div>
              </td>
              <td className="text-right">
                {BaseHelper.formatMoneyPostFix(item.price, storeReducer.userInfo.currentStore.currency_symbol)}
              </td>
              <td className="text-right">
                <a onClick={() => handleDeleteFreatureCourse(productCategoryId, item.product_id)}>
                  <i className="ladi-icon icon-bin"></i>
                </a>
              </td>
            </tr>
          )}
        </Draggable>
      );
    });
  };

  const handleDeleteCategoryItem = (item) => {
    let _selectedDeleteCategories = selectedCategories.filter((_item) => _item.product_category_id != item.product_category_id);

    if (_selectedDeleteCategories) {
      setSelectedCategories(_selectedDeleteCategories);
    }
  };

  const renderCategoryItem = () => {
    return selectedCategories.map((item, index) => {
      return (
        <Draggable key={index} draggableId={"" + index} index={index}>
          {(provided, snapshot) => (
            <div
              key={index}
              className="category-list-item"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...provided.draggableProps.style,
                userSelect: "none",
                background: snapshot.isDragging ? "#e8f0fe" : "none",
              }}
            >
              <div className="content-top-item">
                <div className="icon-name flex">
                  <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg" />
                  <span className="ml-16 ladiui-label pb-0">{item.name}</span>
                </div>
                <div className="action-right">
                  <div className="flex">
                    <input
                      type="radio"
                      checked={item.is_show_new}
                      className="ladiui form-check-input"
                      onClick={() => handleChangeIsShowCourse(item.product_category_id, true)}
                    />
                    <span>4 khóa học mới nhất</span>
                  </div>
                  <div className="flex">
                    <input
                      type="radio"
                      checked={!item.is_show_new}
                      className="ladiui form-check-input"
                      onClick={() => handleChangeIsShowCourse(item.product_category_id, false)}
                    />
                    <span>Tùy chỉnh khóa học</span>
                  </div>
                  <div className="btn-delete cursor-pointer" onClick={() => handleDeleteCategoryItem(item)}>
                    <i className="ladi-icon icon-bin"></i>
                  </div>
                </div>
              </div>
              {!item.is_show_new && (
                <div className="block-add-featured-course">
                  {item.products && item.products.length > 0 && (
                    <div className="list-featured-courses">
                      {item.products && item.products.length > 0 && (
                        <Droppable
                          droppableId={`featured-course-${item.product_category_id}`}
                          type={`droppableSubItem-${item.product_category_id}`}
                        >
                          {(provided, snapshot) => (
                            <div className="list-course" {...provided.droppableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                              <table className="ladiui table text-left table-custom mt-0">
                                <thead>
                                  <tr className="ladiui table-vertical header">
                                    <th className="text-left pl-0" style={{ paddingLeft: "0!important" }}>
                                      Tên khóa học
                                    </th>
                                    <th className="text-right">Giá tiền</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {listCourseFeaturedItem(item.product_category_id, item.products)}
                                  {provided.placeholder}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </Droppable>
                      )}
                    </div>
                  )}
                  <div className={`ladiui border-none btn-add-option ${item.products && item.products.length == 0 ? "pt-16" : ""}`}>
                    <img className="mr-8" src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg" />
                    <div className="btn-add-product" onClick={() => handleShowBlockAddFeaturedCourse(true, item)}>
                      Thêm khóa học
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Draggable>
      );
    });
  };

  const onChangeSearchValueCourse = async (event) => {
    let value = event.target.value;
    await setValueSearchCourse(value);
    dispatch(productActions.search(value, "Course"));
  };

  const onChangeSearchValueFeaturedCourse = async (event) => {
    let value = event.target.value;
    await setValueSearchFeaturedCourse(value);
    dispatch(productActions.search(value, "Course", selectedIds));
  };

  const onChangeSearchValueCategory = async (event) => {
    let value = event.target.value;
    await setValueSearchCategory(value);
    dispatch(productCategoryActions.search(value, "Course"));
  };

  // Kéo thả giao diện

  //hàm giúp thực hiện việc sắp xếp khi kéo thả
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndCourseItem = (result) => {
    if (!result.destination) {
      return;
    }

    const _selectedCourses = reorder(selectedCourses, result.source.index, result.destination.index);

    setSelectedCourses(_selectedCourses);
  };

  const onDragEndFeaturedCourseItem = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.type == "droppableItem") {
      const _selectedCategories = reorder(selectedCategories, result.source.index, result.destination.index);
      setSelectedCategories(_selectedCategories);
    } else if (result.type.includes("droppableSubItem")) {
      const parentID = result.type.split("-")[1];
      const itemSubItemMap = find(selectedCategories, (item) => item.product_category_id == parentID);

      const reorderedSubItems = reorder(itemSubItemMap.products, result.source.index, result.destination.index);

      // let _newSelectedCategories = produce(selectedCategories, (draft) => {
      //   draft.map((item) => {
      //     if (item.product_category_id == parentID) {
      //       item.products = reorderedSubItems;
      //     }
      //     return item;
      //   });
      // });

      let _newSelectedCategories = selectedCategories.map((item) => {
        if (item.product_category_id == parentID) {
          item.products = reorderedSubItems;
        }
        return item;
      });

      setSelectedCategories(_newSelectedCategories);
    }
  };

  const handleSubmitData = () => {
    props.handleSubmitData();
  };

  // ------------------------------------------------------Banner------------------------------------------------------
  const handleClickInputFile = () => {
    document.getElementById("upload-background-store").click();
  };

  const fileChangedHandlerCartBackground = (event) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const form = BaseHelper.getFormDataUpload(files);
      if (form) {
        dispatch(fileActions.uploadProductImages(form));
      }
    }
  };
  const handleAddLinkImage = (index) => {
    setIndexBanner(index);
    setIsShowInputAddLink(!isShowInputAddLink);
  };

  const handleDeleteBannerImage = (index) => {
    let _selectedBanners = [...banners];
    _selectedBanners.splice(index, 1);

    setBanners(_selectedBanners);
  };

  const handleChangeInputLink = (event, _index) => {
    let _selectedBanners = [...banners];

    _selectedBanners.map((item, index) => {
      if (index == _index) {
        item.link = event.target.value;
      }
    });

    setBanners(_selectedBanners);
  };

  const onKeyDownInput = (event, _index) => {
    if (event.key === "Enter") {
      let _selectedBanners = [...banners];
      _selectedBanners.map((item, index) => {
        if (index == _index) {
          item.link = event.target.value;
        }
      });

      setBanners(_selectedBanners);

      setIsShowInputAddLink(false);
    }
  };

  const handleSaveInputLink = (_index) => {
    setIsShowInputAddLink(false);
  };

  const selectCategorySuggestion = (item) => {
    let _category_ids = [...suggestedCategories];

    _category_ids.push(item);

    setSuggestedCategories(_category_ids);
  };

  const removeCategorySuggestion = (_item) => {
    let _selectedCategories = [...suggestedCategories];
    let _suggestedDelete = _selectedCategories.filter((item) => item.product_category_id != _item.product_category_id);
    if (_suggestedDelete) {
      setSuggestedCategories(_suggestedDelete);
    }
  };

  const handleActionPreviewPageCourse = () => {
    handlePreviewPageCourse();
  };

  return (
    <div className="page-setting" style={isShow ? { display: "block" } : { display: "none" }}>
      <div className="block-title-action">
        <div className="title">
          <h3>Thiết lập trang chủ</h3>
        </div>
        <div className="actions flex">
          <button className="ladiui btn btn-outline-primary mr-24" onClick={() => handleActionPreviewPageCourse()}>
            Xem trước
          </button>
          <button className="ladiui btn btn-primary" onClick={() => handleSubmitData()}>
            Lưu trang khóa học
          </button>
        </div>
      </div>
      <div className="setting-content-menu-banner config-panel">
        <div className="panel">
          <div className="panel-line">
            <div className="main-description">
              Banner
              <br />
              <div className="sub-description">
                Kích thước 1366x350(px)
                <br />
                (Định dạng .png, .jpg, .svg)
              </div>
            </div>
            <div className="content">
              {banners && banners.length == 0 ? (
                <>
                  <div className="block-add-image-background" onClick={handleClickInputFile}>
                    <img src="https://w.ladicdn.com/ladiui/icons/ldicon-image.svg" className="mb-8" />
                    <p>Tải ảnh lên</p>
                  </div>
                  <div className="ladiui button upload text-center">
                    <input
                      id="upload-background-store"
                      className="cursor-pointer"
                      type="file"
                      name="file"
                      accept="image/*"
                      multiple="multiple"
                      onChange={fileChangedHandlerCartBackground}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      style={{ display: "none" }}
                    />
                  </div>
                </>
              ) : (
                <div className="block-list-banner-images">
                  {banners.map((item, index) => {
                    return (
                      <div key={index} className="banner-image-item">
                        <div className="block-action-item">
                          <div className="block-icon-link" onClick={() => handleAddLinkImage(index)}>
                            <i className="ldicon-link" />
                          </div>
                          <div className="block-icon-delete" onClick={() => handleDeleteBannerImage(index)}>
                            <i className="ldicon-delete" />
                          </div>
                        </div>
                        {isShowInputAddLink && indexBanner == index && (
                          <div className="block-input-add-link">
                            <Input
                              name="name"
                              placeholder="Nhập đường dẫn"
                              value={item.link}
                              onChange={(event) => handleChangeInputLink(event, index)}
                              onKeyDown={(event) => onKeyDownInput(event, index)}
                              style={{ width: "auto" }}
                            />
                            <i className="ldicon-save" onClick={() => handleSaveInputLink(index)}></i>
                          </div>
                        )}
                        <img src={CDN + item.url}></img>
                      </div>
                    );
                  })}
                  <div className="block-add-image-background" onClick={handleClickInputFile}>
                    <img src="https://w.ladicdn.com/ladiui/icons/ldicon-add.svg" className="mb-8" />
                    <p>Thêm mới</p>
                  </div>
                  <div className="ladiui button upload text-center">
                    <input
                      id="upload-background-store"
                      className="cursor-pointer"
                      type="file"
                      name="file"
                      accept="image/*"
                      multiple="multiple"
                      onChange={fileChangedHandlerCartBackground}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="block-suggestion-category">
          <h3>Danh mục gợi ý({suggestedCategories && suggestedCategories.length}/5)</h3>
          <span className="sub-title">Danh mục gợi ý sẽ hiển thị phía dưới thanh menu</span>

          <Tag
            wrapperStyle={{
              position: "relative",
              minWidth: "400px",
            }}
            tabIndex={6}
            // ref={categoryRef}
            id="category-tag"
            allTags={categories || []}
            selectedTags={suggestedCategories || []}
            title={""}
            className="mt-0"
            placeholderInput={"Chọn danh mục"}
            isCategoryTag={true}
            hideTitle={true}
            onSelectTag={(item) => selectCategorySuggestion(item)}
            onRemoveTag={(item) => removeCategorySuggestion(item)}
          />
        </div>
        <div className="block-featured-course">
          <label className="ladiui-label">
            Khóa học nổi bật ({selectedCourses && selectedCourses.length > 0 ? selectedCourses.length : 0}/6)
          </label>
          {selectedCourses && selectedCourses.length > 0 && (
            <DragDropContext onDragEnd={onDragEndCourseItem}>
              <Droppable droppableId="droppable" type="droppableItem">
                {(provided, snapshot) => (
                  <div
                    className="list-course"
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDragging ? "#e8f0fe" : "none",
                    }}
                  >
                    <table className="ladiui table text-left table-custom">
                      <thead>
                        <tr className="ladiui table-vertical header">
                          <th className="text-left pl-0" style={{ paddingLeft: "0!important" }}>
                            Tên khóa học
                          </th>
                          <th>Giá tiền</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {listCourseItem()}
                        {provided.placeholder}
                      </tbody>
                    </table>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {selectedCourses && selectedCourses.length < 6 && (
            <div className="ladiui border-none btn-add-option mb-12 mt-24">
              <img className="mr-8" src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg" />
              <div className="btn-add-product" onClick={() => handleShowBlockAddCourse(true)}>
                Thêm khóa học nổi bật
              </div>
            </div>
          )}
        </div>
        <div className="block-category-show-home">
          <label className="ladiui-label pb-24">Danh mục khóa học</label>
          {selectedCategories && selectedCategories.length > 0 && (
            <DragDropContext onDragEnd={onDragEndFeaturedCourseItem}>
              <Droppable droppableId="droppable" type="droppableItem">
                {(provided, snapshot) => (
                  <div
                    className="block-list-category"
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDragging ? "#e8f0fe" : "none",
                    }}
                  >
                    {renderCategoryItem()}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          <div className="btn-add-category">
            <button className="ladiui btn btn-secondary flex" onClick={() => handleShowBlockAddCategory(true)}>
              <i className="cta-add mr-8"></i>
              Thêm danh mục
            </button>
          </div>
        </div>
      </div>
      {isShowBlockAddCourse && (
        <ModalAddProduct
          onClose={() => handleShowBlockAddCourse(false)}
          titleModal={"Thêm khóa học"}
          isShow={isShowBlockAddCourse}
          listVariant={Courses || []}
          innerStyle={{ display: "none" }}
          selectVariant={selectCourse}
          isSelectedTypeProduct={true}
          isFeaturedCourse={true}
          selectedProducts={selectedCourses || []}
          searchVariantValue={valueSeachCourse}
          onChangeSearchVariantInput={onChangeSearchValueCourse}
        />
      )}

      {isShowBlockAddFeaturedCourse && (
        <ModalAddProduct
          onClose={() => handleShowBlockAddFeaturedCourse(false)}
          titleModal={"Thêm khóa học"}
          isShow={isShowBlockAddFeaturedCourse}
          listVariant={Courses || []}
          innerStyle={{ display: "none" }}
          selectVariant={selectFeaturedCourse}
          isSelectedTypeProduct={true}
          selectedProducts={selectedFeaturedCourses || []}
          searchVariantValue={valueSeachFeaturedCourse}
          onChangeSearchVariantInput={onChangeSearchValueFeaturedCourse}
        />
      )}

      {isShowBlockAddCategory && (
        <ModalAddProduct
          onClose={() => handleShowBlockAddCategory(false)}
          titleModal={"Thêm danh mục"}
          isShow={isShowBlockAddCategory}
          listVariant={categories || []}
          innerStyle={{ display: "none" }}
          selectVariant={selectCategory}
          isSelectedTypeProduct={true}
          selectedProducts={selectedCategories || []}
          searchVariantValue={valueSeachCategory}
          placeholder="Tìm kiếm danh mục"
          isCategory={true}
          onChangeSearchVariantInput={onChangeSearchValueCategory}
        />
      )}
    </div>
  );
});
PanelCourseCatalog.propTypes = {};

export default PanelCourseCatalog;
