import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import baseHelper from "../helpers/BaseHelper";
import Image from "./Image";
import fileActions from "../redux/futures/file/actions";
import productActions from "../redux/futures/product_image/actions";
import * as productImageTypes from "../redux/futures/product_image/types";
import produce from "immer";

export default function ListImageProduct(props) {
  let { listImage, productID, handleSetImage, isCopy } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [imageSelect, setImageSelect] = useState({});
  const [imgSelected, setImgSelected] = useState({});

  const [listImages, setListImages] = useState([]);

  const productImageReducer = useSelector((state) => state.productImage);

  const uploadFile = useSelector((state) => {
    return state.file;
  });

  useEffect(() => {
    if (listImage && listImage.length > 0) {
      setImageSelect(listImage[0]);
      setListImages(listImage);
    } else {
      setImageSelect({});
      setListImages([]);
    }
  }, [JSON.stringify(listImage)]);

  useEffect(() => {
    if (productImageReducer && productImageReducer.action == productImageTypes.PRODUCT_UPDATE_POSITION) {
      if (productImageReducer.status) {
        let images = listImages;

        let imageList = images.filter((img) => img.src_hash != imgSelected.src_hash);
        imageList.unshift(imgSelected);

        imageList.map((item, index) => {
          item.position = index;
        });

        setImageSelect(imgSelected);

        props.handleSetImage(imageList);

        window.LadiUI.toastCustom("success", "", productImageReducer.message);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", productImageReducer.message, "OK");
      }
    }
  }, [productImageReducer]);

  const fileChangedHandler = (event) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const form = baseHelper.getFormDataUpload(files);
      if (form) {
        dispatch(fileActions.uploadProductImages(form));
      }
    }
  };

  const delteImage = (item) => {
    if (!isCopy) {
      props.handleDeleteImage(item);
    } else {
      let imageList = listImages.filter((img) => img.src != item.src);
      props.handleSetImage(imageList);
    }
  };

  const sortImage = (item) => {
    if (!isCopy) {
      if (item.position != 0) {
        let data = {
          product_id: productID,
          src_hash: item.src_hash,
          position: item.position,
        };
        setImgSelected(item);
        dispatch(productActions.updatePosition(data));
      } else {
        return;
      }
    } else {
      if (item.position != 0) {
        let imageList = listImages.filter((img) => img.src != item.src);
        imageList.unshift(item);

        imageList.map((item, index) => {
          item.position = index;
        });
        setImageSelect(item);
        setListImages(imageList);
        props.handleSetImage(imageList);
        // dispatch(productActions.updatePosition(data));
      } else {
        return;
      }
    }
  };

  return (
    <div className={`ladiui list-image-product`}>
      {listImages.length == 0 ? (
        <div className="text-center image-none">
          <div className="ladiui-button-image">
            <div className="btn-add-image">
              <div>
                <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-import-image.svg"} alt="" />
              </div>
              <div className="mt-24">
                <span className="color-default">Bấm vào đây </span> để chọn ảnh sản phẩm từ máy tính của bạn
                <p className="text-center">(Kích thước: 400x400px)</p>
              </div>
            </div>
            <input
              className="cursor-pointer"
              type="file"
              name="file"
              accept="image/*"
              multiple="multiple"
              onChange={fileChangedHandler}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="image-select mb-20">
            <Image src={imageSelect.src} width={400} height={400} />
            <div
              className="block-delete-img cursor-pointer"
              onClick={() => {
                delteImage(imageSelect);
              }}
            >
              <i style={{ color: "#fff" }} className="ladi-icon icon-bin" />
            </div>
          </div>
          <div className={`list-image ${listImages.length <= 2 ? "flex-box" : "grid-box"}`}>
            {listImages.map((item, index) => {
              return (
                <div
                  className="item-image relative"
                  key={index}
                  // onClick={() => {
                  //   setImageSelect(item);
                  // }}
                >
                  <Image src={item.src} key={index} width={82} height={82} />
                  <div
                    className="block-icon-star cursor-pointer"
                    onClick={() => {
                      sortImage(item);
                    }}
                  >
                    <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-outline-star.svg"} alt="" />
                  </div>
                  <div
                    className="block-delete-img cursor-pointer"
                    onClick={() => {
                      delteImage(item);
                    }}
                  >
                    <i style={{ color: "#fff" }} className="ladi-icon icon-bin" />
                  </div>
                </div>
              );
            })}
            <div className="text-center">
              <div className="ladiui-button-upload">
                <div className="btn-add-image">
                  <div>
                    <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-add-image.svg"} alt="" />
                  </div>
                  <div>Thêm mới</div>
                </div>
                <input
                  className="cursor-pointer"
                  type="file"
                  name="file"
                  accept="image/*"
                  multiple="multiple"
                  onChange={fileChangedHandler}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
