import { Component } from "react";
import appConfig from "../../../config/app";

// Màn hình preview điền thông tin thanh toán
class FormPaymentPageTemplate extends Component {
  renderData(content) {
    let blockLogoImage = "";
    let CDN = "https://w.ladicdn.com/";
    if (content && content.logo_image != "") {
      blockLogoImage = `<div class="logo-image"><img src=${CDN + content.logo_image} alt=""/></div>`;
    } else {
      blockLogoImage = `
        <div class="logo-image">
          <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.7594 3.9191C27.8269 3.9711 26.9035 4.13107 26.0078 4.39581V22.3947C25.9856 26.6357 25.4595 27.9127 21.5445 28.7179L7.74463 31.5164C7.85645 32.2105 8.08973 32.8794 8.43376 33.4924L23.0883 30.4074C28.5668 29.0761 28.7693 27.6682 28.7915 22.8171L28.7594 3.9191Z" fill="white"/>
            <path d="M33.5963 9.24697C33.5815 6.99433 32.5169 5.22334 30.9114 4.41318L28.1747 3.00281C29.7678 3.82285 30.8324 5.60125 30.8448 7.85883L30.862 23.8694C30.8398 28.3154 30.2841 30.4717 25.181 31.5116L10.8204 34.4756C9.76299 34.7117 8.6564 34.5747 7.68848 34.0878L10.4277 35.4982C11.3911 35.9852 12.4936 36.1231 13.5473 35.8884L27.9351 32.9121C31.9093 31.9784 33.6161 29.5702 33.6161 24.6771L33.5963 9.24697Z" fill="white"/>
            <path d="M2.85177 28.1251V20.0186L0.134766 22.291V26.737C0.134766 28.997 1.25862 30.7853 2.85177 31.6053C2.85177 31.6053 4.85247 32.6329 4.96115 32.6921C5.01549 32.7243 5.04019 32.6921 4.99326 32.6452C3.72615 31.7091 2.86165 30.1011 2.85177 28.1251Z" fill="white"/>
            <path d="M23.3357 0.500626C22.3678 0.0136915 21.2612 -0.123323 20.2037 0.112836L5.84065 3.08425C2.6667 3.74127 0.110244 7.17457 0.125064 10.7141V18.218L2.84207 15.9159V12.1047C2.83713 8.56519 5.40593 5.15411 8.58235 4.49462L22.943 1.53062C23.7173 1.36143 24.5215 1.38518 25.2845 1.59978C25.3487 1.61707 25.3685 1.55285 25.3166 1.52568L23.3357 0.500626Z" fill="white"/>
            <path d="M17.1284 12.364C14.1792 12.9741 11.7685 16.1604 11.7932 19.448C11.8179 22.7356 14.2335 24.9265 17.1827 24.3164C20.1319 23.7063 22.5303 20.5175 22.513 17.2324C22.4957 13.9473 20.0751 11.754 17.1284 12.364Z" fill="white"/>
            <path d="M7.70523 30.637V25.4945L4.95117 21.1325V29.2267C4.96352 31.4843 6.0849 33.2651 7.68052 34.0852L10.4198 35.4955C8.82414 34.6755 7.71758 32.8971 7.70523 30.637Z" fill="white"/>
            <path d="M25.0398 2.60492L10.6792 5.56893C7.50275 6.23336 4.93642 9.66666 4.95124 13.2062V16.5308L7.70529 20.8953V14.6165C7.69047 11.082 10.242 7.64373 13.4184 6.98671L27.779 4.0227C28.8367 3.7873 29.9433 3.92519 30.911 4.41296L28.1742 3.00259C27.2068 2.51174 26.0991 2.37121 25.0398 2.60492Z" fill="white"/>
          </svg>
        </div>
      `;
    }
    return `<div class="ladichat-widget-container" id="ladichat-widget-container">
        <div>
          <div class="ladichat-startpage" id="ladichat-startpage">
            <div class="header-start-page">
             <div class="payment-page-header">
              <div class="logo-payment-page">
                ${blockLogoImage}
                <h3 class="title">Trang thanh toán</h3>
              </div>
             </div>
            </div>
            <div class="ladichat-startpage-body" id="ladichat-startpage-body">
              <iframe src="https://checkout.ladi.sale/page/2038?isPreview=true" width="380" id="iframe-checkout-page"></iframe>
            </div>
          </div>
        </div>{" "}
      </div>`;
  }
}

export default new FormPaymentPageTemplate();
