import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import baseHelper from '../../../../helpers/BaseHelper';
import appConfig from '../../../../config/app';

import Tag from '../../../../components/Tag';
import NumberInput from '../../../../components/NumberInput';
import Input from '../../../../components/Input';

import actionsProductTag from '../../../../redux/futures/product_tag/actions';
import actionsProduct from '../../../../redux/futures/product/actions';

import * as productCategoryTypes from '../../../../redux/futures/product_category/types';
import productCategoryActions from '../../../../redux/futures/product_category/actions';

import * as productTypeRedux from '../../../../redux/futures/product/types';
import actionsStore from '../../../../redux/futures/store/actions';
import { Editor } from '@tinymce/tinymce-react';
import { map } from 'lodash';

import SelectManyDropdown from '../../../../components/SelectManyDropdown';

// function useQuery() {
//     const { search } = useLocation();

//     return React.useMemo(() => new URLSearchParams(search), [search]);
// }

function ProductCreate(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryURL = baseHelper.useQuery();
    const { t, i18n, onClick, info } = props;
    const listProduct = [appConfig.PRODUCT_TYPE.PHYSICAL.CODE, appConfig.PRODUCT_TYPE.F_B.CODE, appConfig.PRODUCT_TYPE.COMBO.CODE];

    const tagRef = useRef();
    const productNameRef = useRef();

    const productCategoryReducer = useSelector((state) => state.productCategory);

    const [description, setDescription] = useState('');
    const [isLoading, setLoading] = useState(false);

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [product, setProduct] = useState({
        name: '',
        price: 0,
        display_type: 0,
        price_compare: 0,
        cost_per_item: 0,
    });

    const [valid, setValid] = useState({
        name: true,
    });

    const listAllTags = useSelector((state) => {
        if (state.productTag.action === 'ASYNC_END' && state.productTag.allTags) return state.productTag.allTags;
        else return [];
    });

    const storeInfo = useSelector((state) => {
        if (state.store.action === 'ASYNC_END' && state.store.userInfo) return state.store.userInfo;
        else return [];
    });
    // const productInfo = useSelector(state => {
    //     return state.product.product;
    // })

    const productState = useSelector((state) => state.product);

    useEffect(() => {
        let payload = {
            search: {
                type: "Product"
            }
        }
        dispatch(actionsProductTag.listAll());
        dispatch(productCategoryActions.listSelectCategory(payload));
    }, []);

    useEffect(() => {
        if (!productState.loading) setLoading(false);
        const productInfo = productState.product;

        if (productState.action === 'CREATE_PRODUCT' && productInfo && productInfo.product_id) {
            history.push(`/products/update/${productInfo.product_id}`);
        }

        if (productState.action === 'CREATE_PRODUCT' && !productState.status) {
            window.LadiUI.toastCustom('danger', '', productState.message);
        }
    }, [productState]);

    useEffect(() => {
        if (productCategoryReducer.action == productCategoryTypes.LIST_SELECT_CATEGORY) {
            if (productCategoryReducer.status) {
                if (productCategoryReducer.listSelects && productCategoryReducer.listSelects.length > 0) {
                    let _data = [];
                    productCategoryReducer.listSelects.map((item, index) => {
                        _data.push({
                            level: item.level,
                            name: item.name,
                            value: item.product_category_id,
                        });
                    });

                    setCategories(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
    }, [productCategoryReducer]);

    // useEffect(() => {
    //     if (productInfo && productState.product_id) {
    //     }
    // }, [productInfo])

    const handleEditorChange = (value) => {
        setDescription(value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name' && value) {
            setValid({
                name: true,
            });
        }

        setProduct((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const submit = () => {
        let errors = [];
        if (productNameRef) {
            const error = productNameRef.current.validate();
            if (error.length > 0) {
                errors = [...errors, ...error];
            }
        }

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('warning', '', errorMessage);
            return;
        }

        let type = queryURL.get('type');
        if (!type || !listProduct.includes(type)) {
            type = appConfig.PRODUCT_TYPE.PHYSICAL.CODE;
        }

        const products = {
            name: product.name,
            alias_name: baseHelper.getAliasName(product.name),
            description: description,
            inventory_checked: 0,
            allow_sold_out: 0,
            type: type,
            display_type: product.display_type,
            status: 'Active',
            external_link: '',
            min_buy: 1,
            max_buy: null,
            tags: tagRef.current.getData(),
            options: [],
            variants: [
                {
                    price: product.price,
                    price_compare: product.price_compare,
                    cost_per_item: product.cost_per_item,
                    sku: '',
                    weight: '',
                    weight_unit: 'g',
                    inventory_checked: 0,
                    allow_sold_out: 0,
                    quantity: 0,
                    min_buy: 1,
                    max_buy: null,
                },
            ],
            custom_fields: [],
            images: [],
            product_up_sells: [],
            product_category_ids: selectedCategories || [],
        };

        setLoading(true);
        dispatch(actionsProduct.create(products));
    };

    const getProductNameByType = () => {
        let productName = '';
        let type = queryURL.get('type');

        switch (type) {
            case 'Physical':
                productName = t('PRODUCTS.ADD_TITLE');
                break;
            case 'F_B':
                productName = t('PRODUCTS.ADD_PRODUCT_F_B');
                break;
            case 'COMBO':
                productName = t('PRODUCTS.ADD_PRODUCT_COMBO');
            default:
        }

        return productName;
    };

    const isCombo = () => {
        let isCombo = false;
        let type = queryURL.get('type');

        if (type == 'COMBO') {
            isCombo = true;
        }

        return isCombo;
    };

    const handleChangeDisplayType = (type) => {
        setProduct((prevState) => ({
            ...prevState,
            display_type: type,
        }));
    };

    const getLabelByType = () => {
        let labelName = '';
        let type = queryURL.get('type');

        switch (type) {
            case 'Physical':
                labelName = t('PRODUCTS.NAME');
                break;
            case 'F_B':
                labelName = t('PRODUCTS.NAME');
                break;
            case 'COMBO':
                labelName = t('PRODUCTS.COMBO_NAME');
            default:
        }

        return labelName;
    };

    const handleRefreshDataCategories = () => {
        let payload = {
            search: {
                type: "Product"
            }
        }
        dispatch(productCategoryActions.listSelectCategory(payload));
    };

    return (
        <div className='product-create'>
            <div className='ladiui title-form text-center'>
                <label className='ladiui title-text-form-lever1 text-left'> {getProductNameByType()}</label>
            </div>
            <div className='ladiui form-group mb-0'>
                <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                    {getLabelByType()}{' '}
                </label>
                <Input
                    className={`ladiui form-control input-price ${valid.name ? '' : 'valid-input-error'}`}
                    ref={productNameRef}
                    name='name'
                    validationName={t('PRODUCTS.NAME')}
                    placeholder='Nhập tên sản phẩm'
                    value={product.name}
                    onChange={handleChange}
                    validations={{ isRequired: true }}
                />
            </div>

            {isCombo() && (
                <div className='ladiui form-group mb-0'>
                    <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                        {t('PRODUCTS.DISPLAY_TYPE')}
                    </label>
                    <div class='ladiui btn-group w100'>
                        <div class='ladiui dropdown w100'>
                            <button
                                style={{ minWidth: '100%' }}
                                class='ladiui btn btn-outline-light dropdown-toggle select-dropdown'
                                id='dropdownMenuButton3'
                                data-toggle='dropdown'
                            >
                                <i></i>
                                <span class='ladiui dropdown-text'>{t('PRODUCTS.PICK_OPTION_TYPE')}</span>
                            </button>
                            <ul class='ladiui dropdown-menu w100'>
                                {map(appConfig.DISPLAY_TYPE, (item, index) => {
                                    return (
                                        <li key={index} onClick={() => handleChangeDisplayType(item.CODE)}>
                                            <a className='ladiui dropdown-item' href='#'>
                                                {item.NAME}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            )}

            <div className='ladiui flex-row'>
                <div className='ladiui item-form mr-28'>
                    <div className='ladiui form-group mb-0'>
                        <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                            {t('PRODUCTS.PRICE')}
                        </label>
                        {/* <input className="ladiui form-control pr-15" value={price} onChange={changePrice} /> */}
                        <NumberInput
                            name='price'
                            suffix={
                                storeInfo.currentStore && storeInfo.currentStore.currency_symbol
                                    ? storeInfo.currentStore.currency_symbol
                                    : 'đ'
                            }
                            value={product.price}
                            onChange={handleChange}
                        />

                        {/* <span className='sub-icon-group'>đ</span> */}
                    </div>
                </div>
                <div className='ladiui item-form mr-28'>
                    <div className='ladiui form-group pos-rel mb-0'>
                        <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                            {t('PRODUCTS.PRICE_COMPARE')}
                        </label>
                        <NumberInput
                            name='price_compare'
                            suffix={
                                storeInfo.currentStore && storeInfo.currentStore.currency_symbol
                                    ? storeInfo.currentStore.currency_symbol
                                    : 'đ'
                            }
                            value={product.price_compare}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='ladiui item-form'>
                    <div className='ladiui form-group pos-rel mb-0'>
                        <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                            {t('PRODUCTS.COST')}
                        </label>
                        <NumberInput
                            name='cost_per_item'
                            suffix={
                                storeInfo.currentStore && storeInfo.currentStore.currency_symbol
                                    ? storeInfo.currentStore.currency_symbol
                                    : 'đ'
                            }
                            value={product.cost_per_item}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>

            <div className='ladiui form-group'>
                <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                    Chọn danh mục
                </label>
                <SelectManyDropdown
                    data={categories}
                    _key='name'
                    _value='value'
                    pickTitle={'Tiêu đề'}
                    placeHolder={'Chọn danh mục'}
                    onSubmit={(items) => {
                        setSelectedCategories(items);
                    }}
                    innerClassName='w100'
                    styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                    buttonStyle={{
                        width: '100%',
                        paddingRight: '30px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: ' -webkit-box',
                        webkitLineClamp: 1,
                        webkitBoxOrient: 'vertical',
                        textAlign: 'left',
                    }}
                    selectedItems={selectedCategories || []}
                    isShowBlockInputSearch={true}
                    isCreateCategory={true}
                    handleRefreshData={handleRefreshDataCategories}
                />
            </div>

            <div className='ladiui form-group'>
                <Tag
                    tabIndex={6}
                    ref={tagRef}
                    id='product-tag'
                    allTags={listAllTags}
                // selectedTags={tags}
                />
            </div>

            <div className='ladiui form-group'>
                <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                    {t('PRODUCTS.DESCRIPTION')}{' '}
                </label>
                <Editor
                    value={description}
                    init={{
                        height: 250,
                        plugins: 'preview powerpaste autosave fullscreen lists checklist',
                        toolbar:
                            'bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat',
                        toolbar_drawer: 'sliding',
                        language_url: '../../../../language/tinymce-vi',
                        valid_elements: '*[*]',
                    }}
                    onEditorChange={handleEditorChange}
                />
            </div>

            <div className='ladiui modal-footer pr-0'>
                <button onClick={() => history.push('/products')} className='ladiui btn btn-secondary btn-sm'>
                    {' '}
                    {t('ACTIONS.REMOVE')}
                </button>
                <button onClick={submit} className={`ladiui btn btn-primary btn-sm ${isLoading ? 'loader' : ''}`} disabled={isLoading}>
                    {' '}
                    {t('ACTIONS.NEXT')}
                </button>
            </div>
        </div>
    );
}

export default withTranslation()(ProductCreate);
