import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import queryString from "query-string";

import checkoutConfigActions from "../redux/futures/checkout_config/actions";
import * as checkoutConfigTypes from "../redux/futures/checkout_config/types";

import domainActions from "../redux/futures/domain/actions";
import * as domainTypes from "../redux/futures/domain/types";

import Input from "./Input";
import baseHelper from "../helpers/BaseHelper";
import ValidatorHelper from "../helpers/ValidatorHelper";
import SelectOption from "./SelectOption";
import { filter, find, isEmpty } from "lodash";
import appConfig from "../config/app";
import config from "../config/config";

import ModalSettingCheckoutConfig from "../pages/settings/components/ModalSettingCheckoutConfig";
import { compact, map } from "lodash";
import LoadingScene from "./LoadingScene";
import PanelSettingCheckout from "./PanelSettingCheckout";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ModalAddImage from "./ModalAddImage";
import ModalAddText from "./ModalAddText";
import produce from "immer";

const PanelProductCheckout = forwardRef((props, ref) => {
  const { checkoutLocation, setCheckoutLocation, checkoutConfigID, isReloadIframe } = props;

  const storeReducer = useSelector((state) => state.store);
  const checkoutConfigReducer = useSelector((state) => state.checkoutConfigReducer);
  const domainReducer = useSelector((state) => state.domainReducer);

  const [urlPublished, setUrlPublished] = useState(props.urlPublished);

  const [isShowQrCode, setIsShowQrCode] = useState(false);
  const [isShowModalSettingCheckoutConfig, setIsShowModalSettingCheckoutConfig] = useState(false);

  const [showApiKey, setShowApiKey] = useState(false);

  const [isShowBlockAddContent, setIsShowBlockAddContent] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [pathName, setPathName] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [isShowModalAddImage, setIsShowModalAddImage] = useState(false);
  const [isShowModalAddText, setIsShowModalAddText] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [dataSelect, setDataSelect] = useState({});
  const [indexData, setIndexData] = useState(-1);

  const timeRedirectPath = [
    {
      value: 5,
      name: "5 giây",
    },
    {
      value: 10,
      name: "10 giây",
    },
    {
      value: 15,
      name: "15 giây",
    },
    {
      value: 20,
      name: "20 giây",
    },
    {
      value: 0,
      name: "Chuyển trực tiếp",
    },
  ];

  const locations = [
    {
      value: 0,
      name: "Vị trí top",
    },
    {
      value: 1,
      name: "Vị trí navbar tab cửa hàng",
    },
    {
      value: 4,
      name: "Vị trí navbar tab khách hàng",
    },
    {
      value: 5,
      name: "Vị trí navbar tab vận chuyển",
    },
    {
      value: 6,
      name: "Vị trí navbar tab thanh toán",
    },
    {
      value: 2,
      name: "Vị trí bottom",
    },
    {
      value: 3,
      name: "Vị trí trên cùng giỏ hàng",
    },
  ];

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const inputsRef = new Set();

  // useEffect(() => {
  //     dispatch(checkoutConfigActions.list());
  // }, []);

  useEffect(() => {
    let iFrame = document.getElementById("iframe-checkout-page");

    if (iFrame) {
      iFrame.src = iFrame.src;
      // iFrame.contentWindow.location.reload();
    }
  }, [isReloadIframe]);

  useEffect(() => {
    setUrlPublished(props.urlPublished);
    if (props.urlPublished) {
      const result = new URL(props.urlPublished);
      setPathName(result.pathname);
    }
  }, [props.urlPublished]);

  useEffect(() => {
    if (props.productID || props.isPageCheckout) {
      dispatch(domainActions.listVerified());
      dispatch(checkoutConfigActions.list());
    }
  }, [props.productID, props.isPageCheckout]);

  useEffect(() => {
    if (checkoutConfigReducer.action == checkoutConfigTypes.LIST_CHECKOUT_CONFIG) {
      if (checkoutConfigReducer.status) {
        if (!props.checkoutConfigID) {
          const defaultCheckoutConfig = find(checkoutConfigReducer.checkout_configs, (item) => item.is_default == appConfig.STATUS.ACTIVE);
          props.onChangeCheckoutConfig(defaultCheckoutConfig.checkout_config_id);
        }
      } else {
        // window.LadiUI.toastCustom("danger", "", checkoutConfigReducer.message);
        window.LadiUI.showErrorMessage("Thông báo", checkoutConfigReducer.message, "OK");
      }
    }
  }, [checkoutConfigReducer]);

  useEffect(() => {
    if (domainReducer.action == domainTypes.LIST_VERIFIED_DOMAIN) {
      if (domainReducer.status) {
        if (!props.domain) {
          props.onChangeDomain(domainReducer.verified_domains[0].domain);
        }
      } else {
        // window.LadiUI.toastCustom("danger", "", domainReducer.message);
        window.LadiUI.showErrorMessage("Thông báo", domainReducer.message, "OK");
      }
    }
  }, [domainReducer]);

  useEffect(() => {
    sendMes(checkoutLocation, "LADISALE");
  }, [checkoutLocation]);

  useEffect(() => {
    const dataSend = {
      checkout_config_id: checkoutConfigID,
      store_id: baseHelper.getCookie(appConfig.COOKIE.STORE_ID),
    };
    sendMes(dataSend, "LADISALE_CHECKOUT_CONFIG_ID");
  }, [checkoutConfigID]);

  const sendMes = (data, status) => {
    let iFrame = document.getElementById("iframe-checkout-page");
    const dataSend = {
      status: status, //'LADISALE',
      data: data,
    };
    if (iFrame) iFrame.contentWindow.postMessage(dataSend, "*");
  };

  const getFullUrlPublished = () => {
    const result = queryString.parseUrl(urlPublished);
    return queryString.stringifyUrl(result);
  };

  useImperativeHandle(ref, () => ({
    validate() {
      let errors = [];
      const _inputsRef = compact(Array.from(inputsRef));
      map(_inputsRef, (ref) => {
        if (ref.validate) {
          const error = ref.validate();
          if (error.length > 0) {
            errors = errors.concat(error);
          }
        }
      });

      errors = compact(errors);
      if (errors.length > 0) {
        const errorMessage = errors.join("<br/>");
        window.LadiUI.toastCustom("danger", "", errorMessage);
        return false;
      }

      return true;
    },
  }));

  const copy = (text) => {
    baseHelper.copyToClipboard(text);
    window.LadiUI.toastCustom("success", "", t("COMMON.COPIED"));
  };

  //hàm giúp thực hiện việc sắp xếp khi kéo thả
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    // let tg = list[startIndex];

    return result;
  };

  const onDragEnd = (result) => {
    const options = [...checkoutLocation];
    // dropped outside the list
    if (!result.destination || result.source.index == result.destination.index) {
      return;
    }

    const items = reorder(options[result.destination.droppableId].datas, result.source.index, result.destination.index);
    // setConfigPayment(items);

    options[result.destination.droppableId].datas = items;

    setCheckoutLocation(options);
  };

  const handleChangeLocation = (item) => {
    if (item) {
      setSelectedLocation(item);
    }
  };

  const handleAddLocationCheckout = () => {
    if (isEmpty(selectedLocation)) {
      window.LadiUI.toastCustom("warning", "", "Vui lòng chọn vị trí muốn thêm");
      return;
    }

    let locations = [...checkoutLocation];

    let findIndex = locations.findIndex((item) => item.value == selectedLocation.value);

    if (findIndex != -1) {
      window.LadiUI.toastCustom("warning", "", "Vị trí đã tồn tại!");
      return;
    }

    locations.push({
      name: selectedLocation.name,
      value: selectedLocation.value,
      datas: [],
    });

    setCheckoutLocation(locations);
  };

  const handleDeleteLocation = (value) => {
    let location = checkoutLocation.filter((item) => item.value != value);

    setCheckoutLocation(location);
  };

  const handleShowBlockAddContent = (value, index) => {
    setIsShowBlockAddContent(!isShowBlockAddContent);
    setSelectedValue(value);
    setSelectedIndex(index);
  };

  const handleShowModalImage = () => {
    setIndexData(-1);
    setDataSelect({});
    setIsShowModalAddImage(true);
    setIsShowBlockAddContent(false);
  };

  const handleShowModalAddText = () => {
    setIndexData(-1);
    setDataSelect({});
    setIsShowModalAddText(true);
    setIsShowBlockAddContent(false);
  };

  const onCancelModalAddImage = () => {
    setIsShowModalAddImage(false);
  };

  const onSubmitModalAddImage = (value, index) => {
    if (isEmpty(value)) {
      window.LadiUI.toastCustom("warning", "", "Vui lòng nhập nội dung");
      return;
    }
    if (!ValidatorHelper.isValidHttpUrl(value.url)) {
      window.LadiUI.toastCustom("warning", "", "Đường dẫn không đúng định dạng");
      return;
    }

    let checkoutLocationTg = [...checkoutLocation];
    let data = [...checkoutLocationTg[selectedIndex].datas];

    if (index < 0) data.push(value);
    else data[index] = value;

    checkoutLocationTg[selectedIndex].datas = data;

    setCheckoutLocation(checkoutLocationTg);
    setIndexData(-1);
    setIsShowModalAddImage(false);
    setIsShowBlockAddContent(false);
  };

  const onCancelModalAddText = () => {
    setIsShowModalAddText(false);
  };

  const onSubmitModalAddText = (value, index) => {
    if (isEmpty(value)) {
      window.LadiUI.toastCustom("warning", "", "Vui lòng nhập nội dung");
      return;
    }
    let checkoutLocationTg = [...checkoutLocation];
    let data = checkoutLocationTg[selectedIndex].datas;

    if (index < 0) data.push(value);
    else data[index] = value;

    setCheckoutLocation(checkoutLocationTg);
    setIndexData(-1);

    setIsShowModalAddText(false);
    setIsShowBlockAddContent(false);
  };

  const handleDeleteItem = (index, index2) => {
    let location = [...checkoutLocation];
    location[index].datas.splice(index2, 1);
    setCheckoutLocation(location);
  };

  const handleEditItem = (item, index, indexD) => {
    setDataSelect(item);
    setSelectedIndex(index);
    setIndexData(indexD);

    if (item.type == "IMAGE") {
      setIsShowModalAddImage(true);
    } else {
      setIsShowModalAddText(true);
    }
  };

  const handelChangeConfig = (checkout_config_id) => {
    props.onChangeCheckoutConfig(checkout_config_id);
  };

  const getValuePath = () => {
    let valuePath = "";
    let path = props.path;
    if (path) {
      if (path == undefined || path == "undefined") {
        valuePath = "";
      } else {
        valuePath = path;
      }
    }
    return valuePath;
  };

  const handleCopyApiKey = (api_key) => {
    navigator.clipboard.writeText(api_key);
    window.LadiUI.toastCustom("success", "", "Đã sao chép vào ClipBoard !");
  };

  const isLoadingCheckoutConfig =
    checkoutConfigReducer.loading && checkoutConfigReducer.waiting == checkoutConfigTypes.LIST_CHECKOUT_CONFIG;
  const isLoadingVerifiedDomain = domainReducer.loading && domainReducer.waiting == domainTypes.LIST_VERIFIED_DOMAIN;
  const selectedCheckoutConfig =
    find(checkoutConfigReducer.checkout_configs, (item) => item.checkout_config_id == props.checkoutConfigID) || {};

  return (
    <React.Fragment>
      {(isLoadingCheckoutConfig || isLoadingVerifiedDomain) && <LoadingScene blur={true} />}
      <div className="ladiui-row">
        <div className="col-50">
          <div className="ladiui form-group">
            <div>
              <label className="ladiui-label" style={{ paddingBottom: 8 }}>
                {t("PRODUCTS.LB_CHECKOUT_CONFIG")}
              </label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <SelectOption
                  wrapperStyle={{
                    minWidth: "250px",
                    width: "100%",
                    display: "inline-block",
                    zIndex: 999,
                  }}
                  innerStyle={{ width: "100%", minWidth: "100%" }}
                  customStyle={{ padding: "0px", width: "100%" }}
                  wrapperClassName="select-option-checkout-config mr-24"
                  innerClassName="ellipsis"
                  currentKey={props.checkoutConfigID}
                  headerItems={[
                    {
                      key: "create",
                      name: t("CHECKOUTS.ADD_CHECKOUT_CONFIG"),
                      icon: "icon-c-add",
                      className: "prevent-hide",
                      is_header: true,
                    },
                    {
                      key: "refresh",
                      name: t("COMMON.REFRESH_LIST"),
                      icon: "icon-sync",
                      className: "prevent-hide",
                      is_header: true,
                    },
                  ]}
                  headerKey={"key"}
                  headerValue={"name"}
                  items={checkoutConfigReducer.checkout_configs || []}
                  _key={"checkout_config_id"}
                  _value={"name"}
                  onSelectItem={(item) => {
                    if (item.checkout_config_id) {
                      handelChangeConfig(item.checkout_config_id);
                    } else {
                      switch (item.key) {
                        case "create":
                          window.open("/settings?type=setting-checkout&is_new=true#CONFIG_CHECKOUT");
                          break;
                        case "refresh":
                          dispatch(checkoutConfigActions.list());
                          break;
                      }
                    }
                  }}
                />
                <a
                  className="ladiui btn btn-outline-primary"
                  target="_blank"
                  href={`/settings?type=setting-checkout&checkout_config_id=${props.checkoutConfigID}#CONFIG_CHECKOUT`}
                >
                  {t("PRODUCTS.LB_EDIT_CHECKOUT_CONFIG")}
                </a>
              </div>
            </div>
          </div>

          <PanelSettingCheckout checkoutConfig={selectedCheckoutConfig.config} preview={true} />

          {(props.productID || props.isPageCheckout) && (
            <div className="ladiui form-group panel-publish">
              <label className="ladiui-label" style={{ paddingBottom: 8 }}>
                {t("PRODUCTS.LB_PUBLISH")}
              </label>
              <div className="group">
                <SelectOption
                  wrapperStyle={{
                    minWidth: "240px",
                    maxWidth: "29%",
                    display: "inline-block",
                  }}
                  wrapperClassName="select-option-domain"
                  innerClassName="ellipsis"
                  innerStyle={{ width: "100%", minWidth: "100%" }}
                  customStyle={{ padding: "0px", marginRight: "8px" }}
                  currentKey={props.domain}
                  headerItems={[
                    {
                      key: "create",
                      name: t("DOMAIN.ADD"),
                      icon: "icon-c-add",
                      className: "prevent-hide",
                      is_header: true,
                    },
                    {
                      key: "refresh",
                      name: t("COMMON.REFRESH_LIST"),
                      icon: "icon-sync",
                      className: "prevent-hide",
                      is_header: true,
                    },
                  ]}
                  headerKey={"key"}
                  headerValue={"name"}
                  items={domainReducer.verified_domains || []}
                  _key={"domain"}
                  _value={"domain"}
                  onSelectItem={(item) => {
                    if (item.domain) {
                      props.onChangeDomain(item.domain);
                    } else {
                      switch (item.key) {
                        case "create":
                          window.open("/settings?is_new=true#DOMAIN");
                          break;
                        case "refresh":
                          dispatch(domainActions.listVerified());
                          break;
                      }
                    }
                  }}
                />
                /{props.isPageCheckout && "page/"}
                <Input
                  ref={(ref) => inputsRef.add(ref)}
                  validationName={t("PRODUCTS.LB_PATH")}
                  placeholder={`${t("PRODUCTS.LB_PATH")}...`}
                  value={getValuePath()}
                  onChange={(event) => {
                    props.onChangePath(event.target.value);
                  }}
                  validations={{ isRequired: true }}
                  className="ml-8"
                />
              </div>
            </div>
          )}

          {
            //show api key
            props.api_key && (
              <div className="ladiui form-group">
                <label className="ladiui-label">Api key</label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #F1F3F4",
                    padding: "6px 12px",
                  }}
                >
                  {showApiKey ? props.api_key : baseHelper.maskText(props.api_key, 1)}
                  <ul className="flex">
                    <li className="mr-8">
                      <a
                        style={{ color: "#000" }}
                        onClick={() => {
                          setShowApiKey(!showApiKey);
                        }}
                      >
                        <i className={showApiKey ? "ladi-icon icon-preview" : "ladi-icon icon-b-preview"}></i>
                      </a>
                    </li>
                    <li>
                      <a style={{ color: "#000" }} onClick={() => handleCopyApiKey(props.api_key)}>
                        <i className="ladi-icon icon-copy"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )
          }

          {props.isPageCheckoutSDK && (
            <div className="ladiui form-group flex">
              <div className="ladiui item-form switch">
                <label className="ladiui switch m-0">
                  <input
                    type="checkbox"
                    checked={props.is_sync_product == 1}
                    onChange={(event) => {
                      props.onChangeSyncProduct(event);
                    }}
                    className="ladiui checkbox size-checkbox form-check-input-checkbox"
                  />
                  <span className="ladiui slider round"></span>
                </label>
              </div>
              <div className="ml-8">
                <label className="ladiui-label pb-0">Đồng bộ sản phẩm với Ladisales</label>
              </div>
            </div>
          )}

          {(props.productID || props.isPageCheckout) && (
            <div className="ladiui form-group">
              {urlPublished && (
                <React.Fragment>
                  <div className="publish-times" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="publish-path">
                      <label className="ladiui-label">{t("PRODUCTS.LB_REDIRECT_PATH")}</label>
                      <div>
                        <Input
                          placeholder={t("PRODUCTS.LB_REDIRECT_PATH")}
                          value={props.payment_redirect_url}
                          onChange={(event) => {
                            props.onChangePaymentRedirectPath(event.target.value);
                          }}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="publish-redirect-after">
                      <label className="ladiui-label">{t("PRODUCTS.LB_REDIRECT_AFTER")}</label>
                      <div className="ladiui btn-group">
                        <SelectOption
                          wrapperClassName="select-assignee"
                          innerClassName="ellipsis"
                          currentKey={props.payment_redirect_after}
                          _key="value"
                          _value="name"
                          items={timeRedirectPath || []}
                          onSelectItem={(item) => {
                            props.onChangePaymentRedirectAfter(item.value);
                          }}
                          innerStyle={{ maxWidth: "170px" }}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}

          {props.isPageCheckout && props.domain != "checkout.ladi.sale" && (
            <div className="ladiui form-group flex">
              <div className="ladiui item-form switch">
                <label className="ladiui switch m-0">
                  <input
                    type="checkbox"
                    checked={props.is_home == 1}
                    onChange={(event) => {
                      props.onChangeIsHome(event);
                    }}
                    className="ladiui checkbox size-checkbox form-check-input-checkbox"
                  />
                  <span className="ladiui slider round"></span>
                </label>
              </div>
              <div className="ml-8">
                <label className="ladiui-label pb-0"> {t("MENU.SET_HOME_PAGE")}</label>
              </div>
            </div>
          )}

          <div
            className="line"
            style={{
              display: "block",
              width: "100%",
              borderBottom: "1px solid #f1f3f4",
              margin: "24px 0px",
            }}
          ></div>
          {urlPublished && (
            <div id="block-custom-interface" className="block-custom-interface">
              <div className="label-header mb-24">
                <h3>{t("PAGE_CHECKOUT.CUSTOM_INTERFACE")}</h3>
                <p>{t("PAGE_CHECKOUT.SELECT_LOCATION_AND_ADD_CONTENT")}</p>
              </div>
              <div className="block-content">
                <div className="ladiui block-list-location">
                  {checkoutLocation &&
                    checkoutLocation.length > 0 &&
                    checkoutLocation.map((item, index) => {
                      return (
                        <DragDropContext onDragEnd={onDragEnd} key={index}>
                          <Droppable droppableId={`${index}`} id={index}>
                            {(provided, snapshot) => (
                              <div
                                key={index}
                                className="block-item mb-24"
                                ref={provided.innerRef}
                                style={{
                                  background: snapshot.isDragging ? "#e8f0fe" : "none",
                                }}
                              >
                                <div className="legend-header flex">
                                  <span>{item.name}</span>
                                  <span className="tooltip-icon" data-tooltip-position="right">
                                    {" "}
                                    <i className="ladi-icon icon-c-question"></i>
                                  </span>
                                </div>
                                <div className="list-item">
                                  {item.datas &&
                                    item.datas.length > 0 &&
                                    item.datas.map((_item, _index) => {
                                      return (
                                        <Draggable key={_index} draggableId={"" + _index} index={_index}>
                                          {(provided, snapshot) => (
                                            <>
                                              {_item.type == "IMAGE" ? (
                                                <div
                                                  className="item flex"
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                >
                                                  <div className="icon-move mr-12">
                                                    <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg" alt=" " />
                                                  </div>
                                                  <div className="w100 flex space-between content">
                                                    <div className="flex">
                                                      <div className="item-img mr-11">
                                                        <svg
                                                          width="18"
                                                          height="18"
                                                          viewBox="0 0 18 18"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M15 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V15C0 15.7956 0.316071 16.5587 0.87868 17.1213C1.44129 17.6839 2.20435 18 3 18H15C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15V3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0ZM5 4C5.29667 4 5.58668 4.08797 5.83335 4.2528C6.08003 4.41762 6.27229 4.65189 6.38582 4.92597C6.49935 5.20006 6.52906 5.50166 6.47118 5.79264C6.4133 6.08361 6.27044 6.35088 6.06066 6.56066C5.85088 6.77044 5.58361 6.9133 5.29264 6.97118C5.00166 7.02906 4.70006 6.99935 4.42597 6.88582C4.15189 6.77229 3.91762 6.58003 3.7528 6.33335C3.58797 6.08668 3.5 5.79667 3.5 5.5C3.5 5.10218 3.65804 4.72064 3.93934 4.43934C4.22064 4.15804 4.60218 4 5 4ZM16 14.83C16.0221 15.1177 15.9292 15.4023 15.7418 15.6217C15.5543 15.841 15.2876 15.977 15 16H3L10.57 9.18C10.6971 9.06406 10.863 8.99978 11.035 8.99978C11.207 8.99978 11.3729 9.06406 11.5 9.18L16 13.66V14.83Z"
                                                            fill="#6D6D6D"
                                                          />
                                                        </svg>
                                                      </div>
                                                      <div className="item-text">{_item.name}</div>
                                                    </div>
                                                    <div className="action-item">
                                                      <span
                                                        className="cursor-pointer"
                                                        style={{
                                                          marginRight: "22px",
                                                        }}
                                                        onClick={() => handleDeleteItem(index, _index)}
                                                      >
                                                        <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg" alt=" " />
                                                      </span>
                                                      <span className="cursor-pointer" onClick={() => handleEditItem(_item, index, _index)}>
                                                        <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-edit.svg" alt=" " />
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div
                                                  className="item flex"
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                >
                                                  <div className="icon-move mr-12">
                                                    <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg" alt=" " />
                                                  </div>
                                                  <div className="w100 flex space-between content">
                                                    <div className="flex">
                                                      <div className="item-img mr-11">
                                                        <svg
                                                          width="18"
                                                          height="18"
                                                          viewBox="0 0 18 18"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M18 2.75C18 2.02065 17.7103 1.32118 17.1945 0.805456C16.6788 0.289731 15.9793 0 15.25 0H2.75C2.02065 0 1.32118 0.289731 0.805456 0.805456C0.289731 1.32118 0 2.02065 0 2.75V15.25C0 15.9793 0.289731 16.6788 0.805456 17.1945C1.32118 17.7103 2.02065 18 2.75 18H15.25C15.9793 18 16.6788 17.7103 17.1945 17.1945C17.7103 16.6788 18 15.9793 18 15.25V2.75ZM11.25 8.5C11.44 8.50006 11.6229 8.57224 11.7618 8.70197C11.9006 8.8317 11.9851 9.0093 11.998 9.19888C12.011 9.38846 11.9515 9.57589 11.8316 9.7233C11.7117 9.87071 11.5402 9.9671 11.352 9.993L11.25 10H3.75C3.55998 9.99994 3.37706 9.92776 3.23821 9.79803C3.09936 9.6683 3.01493 9.4907 3.00197 9.30112C2.98902 9.11154 3.04852 8.92411 3.16843 8.7767C3.28835 8.62929 3.45975 8.5329 3.648 8.507L3.75 8.5H11.25ZM3.75 12.5H14.25C14.44 12.5001 14.6229 12.5722 14.7618 12.702C14.9006 12.8317 14.9851 13.0093 14.998 13.1989C15.011 13.3885 14.9515 13.5759 14.8316 13.7233C14.7117 13.8707 14.5402 13.9671 14.352 13.993L14.25 14H3.75C3.55998 13.9999 3.37706 13.9278 3.23821 13.798C3.09936 13.6683 3.01493 13.4907 3.00197 13.3011C2.98902 13.1115 3.04852 12.9241 3.16843 12.7767C3.28835 12.6293 3.45975 12.5329 3.648 12.507L3.75 12.5ZM14.25 4.5C14.44 4.50006 14.6229 4.57224 14.7618 4.70197C14.9006 4.8317 14.9851 5.0093 14.998 5.19888C15.011 5.38846 14.9515 5.57589 14.8316 5.7233C14.7117 5.87071 14.5402 5.9671 14.352 5.993L14.25 6H3.75C3.55998 5.99994 3.37706 5.92776 3.23821 5.79803C3.09936 5.6683 3.01493 5.4907 3.00197 5.30112C2.98902 5.11154 3.04852 4.92411 3.16843 4.7767C3.28835 4.62929 3.45975 4.5329 3.648 4.507L3.75 4.5H14.25Z"
                                                            fill="#6D6D6D"
                                                          />
                                                        </svg>
                                                      </div>
                                                      <div
                                                        className="item-text"
                                                        dangerouslySetInnerHTML={{
                                                          __html: _item.name,
                                                        }}
                                                      ></div>
                                                    </div>
                                                    <div className="action-item">
                                                      <span
                                                        className="cursor-pointer"
                                                        style={{
                                                          marginRight: "22px",
                                                        }}
                                                        onClick={() => handleDeleteItem(index, _index)}
                                                      >
                                                        <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg" alt=" " />
                                                      </span>
                                                      <span className="cursor-pointer" onClick={() => handleEditItem(_item, index, _index)}>
                                                        <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-edit.svg" alt=" " />
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                  {provided.placeholder}
                                </div>
                                <div className="action-footer flex space-between">
                                  <a
                                    className="flex"
                                    style={{ paddingLeft: "26px" }}
                                    onClick={() => handleShowBlockAddContent(item.value, index)}
                                  >
                                    <img className="mr-8" src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg" alt="" />
                                    <h3>{t("PAGE_CHECKOUT.ADD_LOCATION")}</h3>
                                  </a>
                                  <a style={{ color: "#D94F45" }} onClick={() => handleDeleteLocation(item.value)}>
                                    <h3>{t("PAGE_CHECKOUT.DELETE_LOCATION")}</h3>
                                  </a>
                                </div>

                                {isShowBlockAddContent && selectedValue == item.value && (
                                  <div className="choose-content">
                                    <ul className="list-choose-item">
                                      <li onClick={() => handleShowModalImage()}>
                                        <div className="image">
                                          <img
                                            src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-choose-image.svg"
                                            alt=" "
                                            style={{ marginRight: "4px" }}
                                          />
                                        </div>
                                        <div className="content">
                                          <h3>{t("PAGE_CHECKOUT.IMAGE")}</h3>
                                          <span>
                                            <h3>{t("PAGE_CHECKOUT.ADD_IMAGE")}</h3>
                                          </span>
                                        </div>
                                      </li>
                                      <li onClick={() => handleShowModalAddText()}>
                                        <div className="image">
                                          <img
                                            src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-content.svg"
                                            alt=" "
                                            style={{ marginRight: "4px" }}
                                          />
                                        </div>
                                        <div className="content">
                                          <h3>{t("PAGE_CHECKOUT.CONTENT")}</h3>
                                          <span>
                                            <h3>{t("PAGE_CHECKOUT.ADD_CONTENT")}</h3>
                                          </span>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      );
                    })}
                </div>
              </div>
              <div className="block-custom-checkout mt-16">
                <div className="add-block">
                  <div className="ladiui btn-group mr-12">
                    <div className="ladiui dropdown w100">
                      <button
                        className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                        style={{ minWidth: "100%" }}
                        id="dropdownMenuButton3"
                        data-toggle="dropdown"
                      >
                        <i></i>
                        <span className="ladiui dropdown-text">
                          <h3>{t("PAGE_CHECKOUT.SELECT_LOCATION")}</h3>
                        </span>
                      </button>
                      <ul className="ladiui dropdown-menu w100">
                        {locations.map((item, index) => {
                          let index2 = -1;
                          if (checkoutLocation) index2 = checkoutLocation.findIndex((item2) => item2.value === item.value);
                          if (index2 > -1) return null;
                          return (
                            <li key={index} onClick={() => handleChangeLocation(item)}>
                              <a className="ladiui dropdown-item" href="#">
                                {item.name}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <button type="input" className="ladiui btn btn-primary" onClick={() => handleAddLocationCheckout()}>
                    <h3>{t("PAGE_CHECKOUT.ADD_DISPLAY_LOCATION")}</h3>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-50">
          {urlPublished && (
            <div className="block-content-right">
              <div className="ladiui form-group publish mb-24">
                <div>
                  <label style={{ paddingBottom: 8 }} className="ladiui-label">
                    {t("COMMON.LINK_PUBLISH")}
                  </label>
                  {/* {urlPublished && ( */}
                  <div className="panel-publish">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "6px 12px",
                        backgroundColor: "#f1f3f4",
                      }}
                    >
                      <a className="item ellipsis" style={{ width: "80%" }} target="_blank" href={getFullUrlPublished()}>
                        {getFullUrlPublished()}
                      </a>
                      <div className="copy-qr flex">
                        <span className="flex mr-18" data-tooltip={t("COMMON.QR_CODE")} data-tooltip-position="top">
                          <i onClick={() => setIsShowQrCode(true)} className="ladi-icon icon-qrcode item" />
                        </span>

                        <span className="flex" data-tooltip={t("COMMON.COPY")} data-tooltip-position="top">
                          {/* <i onClick={() => copy(getFullUrlPublished())} className="ladi-icon icon-copy item" /> */}
                          <img
                            src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-coppy.svg"
                            onClick={() => copy(getFullUrlPublished())}
                            alt=""
                          />
                        </span>

                        {isShowQrCode && (
                          <div className="qr-code-publish">
                            <div className="qr-code-content">
                              <img src={`https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=${getFullUrlPublished()}`} />
                              <span className="qr-code-publish-close" onClick={() => setIsShowQrCode(false)} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* )} */}
                </div>
              </div>
              <div className="ladiui iframe-checkout">
                {/* <iframe src={`${config.DOMAIN_DNS}${pathName}?isPreview=true`} width={375} id="iframe-checkout-page" /> */}
                <iframe src={`${urlPublished}?isPreview=true`} width={375} id="iframe-checkout-page" />
              </div>
            </div>
          )}
        </div>
      </div>

      {isShowModalAddImage && (
        <ModalAddImage
          onCancel={onCancelModalAddImage}
          onSubmit={onSubmitModalAddImage}
          visible={isShowModalAddImage}
          dataSelect={dataSelect}
          indexData={indexData}
        />
      )}

      {isShowModalAddText && (
        <ModalAddText
          onCancel={onCancelModalAddText}
          onSubmit={onSubmitModalAddText}
          visible={isShowModalAddText}
          dataSelect={dataSelect}
          indexData={indexData}
        />
      )}

      {isShowModalSettingCheckoutConfig && (
        <ModalSettingCheckoutConfig
          visible={isShowModalSettingCheckoutConfig}
          onCancel={() => setIsShowModalSettingCheckoutConfig(false)}
        />
      )}
    </React.Fragment>
  );
});

PanelProductCheckout.propTypes = {
  domain: PropTypes.string,
  path: PropTypes.string,
  payment_redirect_url: PropTypes.string,
  payment_redirect_after: PropTypes.number,
  urlPublished: PropTypes.string,
  onChangePath: PropTypes.func,
  onChangeIsHome: PropTypes.func,
  onChangePaymentRedirectPath: PropTypes.func,
  onChangePaymentRedirectAfter: PropTypes.func,
  onChangeCheckoutConfig: PropTypes.func.isRequired,
  checkoutConfigID: PropTypes.any,
  productID: PropTypes.any,
};
export default PanelProductCheckout;
