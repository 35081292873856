import React from "react";
import PropTypes from "prop-types";
import AsyncAutoComplete from "../components/AsyncAutoComplete";
import { cloneDeep, remove, find, uniq, map, forEach } from "lodash";
import { withTranslation } from "react-i18next";
import ModalAllTag from "./ModalAllTag";
import { utimes } from "fs";

class Tag extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    wrapperStyle: PropTypes.object,
    wrapperProps: PropTypes.object,
    allTags: PropTypes.array,
    onFocus: PropTypes.func,
    onFocusInput: PropTypes.func,
    loading: PropTypes.bool,
    tabIndex: PropTypes.number,
    selectedTags: PropTypes.array,
    onListAllTags: PropTypes.func,
    toggleResetTag: PropTypes.func,
    hideTitle: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    hideTitle: false,
    onFocusInput: () => {},
    wrapperStyle: {
      position: "relative",
      width: "100%",
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      search: "",
      allTags: cloneDeep(map(props.allTags, (item) => item.name || item)) || [],
      selectedTags: cloneDeep(map(props.selectedTags, (item) => item.name || item)) || [],
      isShowModalAllTag: false,
    };

    this.loadedAllTag = false;
    this.loadedRecentTag = false;
    this.ref = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.state.allTags != nextProps.allTags) {
      this.setState(
        {
          allTags: cloneDeep(map(nextProps.allTags, (item) => item.name || item)) || [],
        },
        () => this.normalizeRecentTag()
      );
    }

    if (this.props.selectedTags != nextProps.selectedTags) {
      this.setState(
        {
          selectedTags: cloneDeep(map(nextProps.selectedTags, (item) => item.name || item)) || [],
        },
        () => this.normalizeRecentTag()
      );
    }
  }

  normalizeRecentTag = () => {
    const allTags = cloneDeep(map(this.props.allTags, (item) => item.name)) || [];

    remove(allTags, (tag) => find(this.state.selectedTags, (_tag) => (_tag ? _tag.trim() : "") == tag.trim()));

    this.setState({
      allTags,
    });
  };

  onChangeInput = (event) => {
    if (this.props.onSearchApi) {
      this.props.onSearchApi(event.target.value);
    }
    this.setState({
      search: event.target.value,
    });
  };

  onFocus = () => {
    this.props.onFocusInput();
  };

  onSelect = (item) => {
    item = item.trim();
    this.setState({
      search: "",
    });

    if (!item) {
      return;
    }
    const { selectedTags } = this.state;
    if (find(selectedTags, (tag) => tag == item)) {
      return;
    }

    let matched = find(this.props.allTags, (_item) => (_item.name ? _item.name.trim() == item : false));
    if (this.props.onSelectTag && matched) {
      this.props.onSelectTag(matched);
    }

    if (this.props.onNewTag && !matched) this.props.onNewTag(item);

    selectedTags.push(item);
    this.setState(
      {
        selectedTags,
      },
      () => this.normalizeRecentTag()
    );
  };

  removeTag = (item) => {
    const { selectedTags } = this.state;
    if (find(selectedTags, (tag) => tag == item)) {
      remove(selectedTags, (tag) => tag == item);

      if (this.props.onRemoveTag) {
        let matched = find(this.props.selectedTags, (_item) => _item.name == item || _item == item);
        if (matched) {
          this.props.onRemoveTag(matched);
        }
      }

      this.setState(
        {
          selectedTags,
        },
        () => this.normalizeRecentTag()
      );
    }
  };

  addNewTag = (newTag) => {
    if (this.props.onSearchApi) {
      // this.props.onSearchApi(newTag);
    } else this.onSelect(newTag);
  };

  getData = () => {
    return this.state.selectedTags;
  };

  getIDs = () => {
    let result = [];

    forEach(this.state.selectedTags, (item) => {
      let matched = find(this.props.allTags, (_item) => _item.name == item);

      if (matched) {
        result.push(matched.product_id || matched.product_tag_id || matched.customer_tag_id || matched.order_tag_id);
      }
    });

    result = uniq(result);

    return result;
  };

  openModalAllTag = () => {
    if (!this.loadedAllTag) {
      this.props.onListAllTags();
      this.loadedAllTag = true;

      if (this.props.toggleResetTag) {
        this.props.toggleResetTag();
      }
    }

    this.setState({
      isShowModalAllTag: true,
    });
  };

  hideModalAllTag = () => {
    this.setState({
      isShowModalAllTag: false,
    });
  };

  applyTags = (tags) => {
    let { selectedTags } = this.state;

    selectedTags.push(...tags);
    selectedTags = uniq(selectedTags);

    this.setState(
      {
        selectedTags,
      },
      () => this.normalizeRecentTag()
    );

    this.hideModalAllTag();
  };

  render() {
    const { t, hideTitle, className, title, placeholderInput } = this.props;
    return (
      <div className={`ladiui form-group clearfix form-tags ${className ? className : ""}`}>
        {!hideTitle && (
          <div className="ladiui-tags-label">
            <label className="ladiui-label">{t("COMMON.TAGS")}</label>
            {/* <label onClick={this.openModalAllTag} className="ladiui-label all-tag">{t('TAGS.VIEW_ALL_TAG')}</label> */}
          </div>
        )}
        {title && (
          <div className="ladiui-tags-label">
            <label className="ladiui-label">{title}</label>
            {/* <label onClick={this.openModalAllTag} className="ladiui-label all-tag">{t('TAGS.VIEW_ALL_TAG')}</label> */}
          </div>
        )}
        <div id={this.props.id} className="ladiui tags">
          {/* <div className="ladiui-tag-list"> */}
          {map(this.state.selectedTags, (item, index) => (
            <span style={{ zIndex: 1 }} key={index} className="ladiui-btn-tag-X tag">
              {item}
              {/* <img
                className="ladiui span-img pointer"
                src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-close-no-bg.svg"}
                alt=""
                onClick={() => {
                  setTimeout(() => {
                    this.removeTag(item);
                  }, 0);
                }}
              /> */}
              <i
                onClick={() => {
                  setTimeout(() => {
                    this.removeTag(item);
                  }, 0);
                }}
                className="ladiui span-img pointer icon-close-no-bg-16"
              />

              {/* <span className="close" onClick={() => {
                                        setTimeout(() => {
                                            this.removeTag(item)
                                        }, 0);
                                    }} /> */}
            </span>
          ))}
          {/* </div> */}
          {this.props.isCategoryTag && this.state.selectedTags && this.state.selectedTags.length < 5 && (
            <AsyncAutoComplete
              wrapperStyle={
                this.props.wrapperStyle
                  ? this.props.wrapperStyle
                  : {
                      position: "relative",
                      with: "100%",
                    }
              }
              items={this.state.allTags}
              getItemValue={(item) => item}
              renderItem={(item) => (
                <div key={item}>
                  {item.isDefault ? (
                    <></>
                  ) : (
                    // <div key={-1} className="item">
                    //     <label className="ladiui-label recent-header">{t('TAGS.LIST')}</label>
                    // </div>
                    <div className="item">
                      <span className="tag-item">{item}</span>
                    </div>
                  )}
                </div>
              )}
              value={this.state.search}
              onChange={this.onChangeInput}
              onSelect={(value, item) => {
                setTimeout(() => {
                  this.onSelect(item);
                }, 0);
              }}
              shouldItemRender={(item, value) => item.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1}
              inputProps={{
                onFocus: this.onFocus,
                onKeyPress: (event) => {
                  if (event.key === "Enter") {
                    this.addNewTag(event.target.value);
                  }
                },
                className: "ladiui form-control border-none pl-0",
                placeholder: `${placeholderInput ? placeholderInput : t("TAGS.SEARCH_TAG")}`,
                style: { minHeight: "100%" },
                tabIndex: this.props.tabIndex,
              }}
              hasDefaultItem={true}
              isItemSelectable={(item, value) => {
                if (item.isDefault) {
                  return false;
                }
                return true;
              }}
              loading={this.props.loading}
              showWhenEmpty={() => <span data-tooltip={t("PRODUCTS.DONT_CHECK_INVENTORY")} data-tooltip-position="right"></span>}
            />
          )}
          {!this.props.isCategoryTag && (
            <AsyncAutoComplete
              wrapperStyle={
                this.props.wrapperStyle
                  ? this.props.wrapperStyle
                  : {
                      position: "relative",
                      with: "100%",
                    }
              }
              items={this.state.allTags}
              getItemValue={(item) => item}
              renderItem={(item) => (
                <div key={item}>
                  {item.isDefault ? (
                    <></>
                  ) : (
                    // <div key={-1} className="item">
                    //     <label className="ladiui-label recent-header">{t('TAGS.LIST')}</label>
                    // </div>
                    <div className="item">
                      <span className="tag-item">{item}</span>
                    </div>
                  )}
                </div>
              )}
              value={this.state.search}
              onChange={this.onChangeInput}
              onSelect={(value, item) => {
                setTimeout(() => {
                  this.onSelect(item);
                }, 0);
              }}
              shouldItemRender={(item, value) => item.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1}
              inputProps={{
                onFocus: this.onFocus,
                onKeyPress: (event) => {
                  if (event.key === "Enter") {
                    this.addNewTag(event.target.value);
                  }
                },
                className: "ladiui form-control border-none pl-0",
                placeholder: `${placeholderInput ? placeholderInput : t("TAGS.SEARCH_TAG")}`,
                style: { minHeight: "100%" },
                tabIndex: this.props.tabIndex,
              }}
              hasDefaultItem={true}
              isItemSelectable={(item, value) => {
                if (item.isDefault) {
                  return false;
                }
                return true;
              }}
              loading={this.props.loading}
              showWhenEmpty={() => <span data-tooltip={t("PRODUCTS.DONT_CHECK_INVENTORY")} data-tooltip-position="right"></span>}
            />
          )}
        </div>

        {this.state.isShowModalAllTag && (
          <ModalAllTag
            visible={this.state.isShowModalAllTag}
            allTags={this.props.allTags}
            onCancel={this.hideModalAllTag}
            onSubmit={this.applyTags}
          />
        )}
      </div>
    );
  }
}

export default withTranslation("translation", { withRef: true })(Tag);
