/**
 * Import default package
 *
 * @format
 */

/** Import from third party */
import { map, compact, pick, cloneDeep, includes, remove, find } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
/** Import component from my app */
import Input from '../../../components/Input';
/** Import redux */

import variantActions from '../../../redux/futures/product_variant/actions';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import productImageActions from '../../../redux/futures/product_image/actions';
import * as productImageTypes from '../../../redux/futures/product_image/types';

import productActions from '../../../redux/futures/product/actions';
import * as productTypes from '../../../redux/futures/product/types';

import * as productCategoryTypes from '../../../redux/futures/product_category/types';
import productCategoryActions from '../../../redux/futures/product_category/actions';

import productTagActions from '../../../redux/futures/product_tag/actions';

import { withTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import PropTypes from 'prop-types';
import appConfig from '../../../config/app';
import LoadingScene from '../../../components/LoadingScene';
import baseHelper from '../../../helpers/BaseHelper';
import { Editor } from '@tinymce/tinymce-react';
import Image from '../../../components/Image';
import Tag from '../../../components/Tag';
import PanelProductCheckout from '../../../components/PanelProductCheckout';
import PanelPackage from './PanelPackage';
import ProductUpSell from '../../products/components/AttachedProduct';
import produce from 'immer';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import PanelPackageSeat from './PanelPackageSeat';
import Dropdown from '../../../components/Dropdown';
import PanelCustomField from '../../../components/PanelCustomField';
import ListImageProduct from '../../../components/ListImageProduct';

import ConfirmModal from '../../../components/ConfirmModal';

import SelectOption from '../../../components/SelectOption';
import PanelProductSellSocial from '../../../components/PanelProductSeoSocial';
import ValidatorHelper from '../../../helpers/ValidatorHelper';

import SelectManyDropdown from '../../../components/SelectManyDropdown';

class ModalService extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        visible: PropTypes.bool,
        loading: PropTypes.bool,
        currentProductInfo: PropTypes.any,
        mode: PropTypes.string,
        openVariantID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    constructor(props) {
        super(props);
        this.moment = baseHelper.getMoment();
        this.state = {
            isCopy: false,
            isReloadIframe: false,
            categories: [],
            product_category_ids: [],
            product: {
                product_id: '',
                product_variant_id: '',
                name: '',
                alias_name: '',
                path: '',
                payment_redirect_url: '',
                payment_redirect_after: appConfig.DEFAULT_PAYMENT_REDIRECT_AFTER,
                domain: '',
                url_published: '',
                description: '',
                quantity: 0,
                timezone: 'Asia/Bangkok',
                location: '',
                content: '',
                host_name: '',
                contact_phone: '',
                type: appConfig.PRODUCT_TYPE.SERVICE.CODE,
                checkout_config_id: '',
                status: appConfig.PRODUCT_STATUS.ACTIVE.CODE,
                select_many_service: false,
                external_link: '',
                ticket_creation_form: appConfig.TICKET_CREATION_FORM.AUTO.CODE,
                interface_options: [],
                cta: 0,
                selectedIconCTA: 'cta-add',
                seo: {},
                is_one_time: false,
                // is_buy_in_bulk: false,
            },
            isShowBlockCustomField: false,
            customFields: [],
            images: [],
            options: [],
            variants: [],
            tags: [],
            tabIndexActive: 0,
            productUpSells: [],
            mode: appConfig.FORM_MODE.EDIT,
        };

        this.ref = React.createRef();
        this.panelCreateVariantRef = React.createRef();
        this.inputsRef = new Set();
        this.panelProductCheckoutRef = React.createRef();
        this.tagRef = React.createRef();
        this.productUpSellRef = React.createRef();
        this.panelCustomFieldRef = React.createRef();
        this.productSeoSocialRef = React.createRef();
        this.TAB_SERVICES = {
            GENERAL_SERVICE: 0,
            PACKAGE_SERVICE: 1,
            UP_SELL: 2,
            SERVICE_SEAT: 3,
            SERVICE_CHECKOUT: 4,
            CUSTOM_FIELD: 5,
            SEO_SOCIAL: 6,
        };
    }

    componentDidMount() {
        let paramUrl = queryString.parse(this.props.location.search);

        this.props.show(this.props.match.params['productID']);

        if (this.props.openVariantID) {
            setTimeout(() => {
                document.getElementById('trigger-tab-variants-create').click();
            }, 100);
        }

        const { tab } = queryString.parse(this.props.location.search);
        if (tab == 'checkout') {
            setTimeout(() => {
                document.getElementById('trigger-tab-checkout').click();
            });
            this.props.history.push(`/services`);
        }

        if (tab == 'checkin') {
            this.setState({
                tabIndexActive: this.TAB_SERVICES.SERVICE_SEAT,
            });
        }

        if (paramUrl.is_copy) {
            this.setState({
                isCopy: true,
                mode: appConfig.FORM_MODE.CREATE,
            });
        }

        if (this.props.currentProductInfo) {
            this.init(this.props);
        }

        this.props.listAllTags();

        let payload = {
            search: {
                type: "Product"
            }
        }

        this.props.listCategorySelect(payload);
    }

    renewGallery = () => {
        if (document.getElementById('gallery-edit')) {
            window.renewGallery(['gallery-edit'], 0);
        }
    };

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }
        if (this.props.currentProductInfo != nextProps.currentProductInfo) {
            this.init(nextProps);
        }

        if (this.props.productImage.action != nextProps.productImage.action) {
            if (nextProps.productImage.action === productImageTypes.PRODUCT_IMAGE_CREATE) {
                if (nextProps.productImage.status) {
                    const { images } = this.state;
                    images.push(...nextProps.productImage.images);
                    this.setState(
                        {
                            // images: produce(images, (draft) => {
                            //   draft.push(...nextProps.productImage.images);
                            // }),
                            images,
                        },
                        () => this.renewGallery()
                    );
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.productImage.message, 'OK');
                }
            }
            if (nextProps.productImage.action === productImageTypes.PRODUCT_IMAGE_DELETE) {
                if (nextProps.productImage.status) {
                    const { images } = this.state;
                    remove(images, (image) => image.src_hash == this.selectedImage.src_hash);

                    this.setState(
                        {
                            images,
                        },
                        () => this.renewGallery()
                    );

                    this.selectedImage = {};

                    window.LadiUI.toastCustom('success', '', nextProps.productImage.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.productImage.message, 'OK');
                }

                window.LadiUI.closeModal('confirm-image-delete');
            }
        }

        if (this.props.fileReducer.action != nextProps.fileReducer.action) {
            if (nextProps.fileReducer.action == fileTypes.UPLOAD_PRODUCT_IMAGES) {
                if (nextProps.fileReducer.status) {
                    const { images, isCopy } = this.state;
                    // const newImage = {
                    //   src: nextProps.fileReducer.product_images[0],
                    //   position: 0,
                    // };
                    // this.setState({
                    //   images: [...images, newImage],
                    // });

                    // const productImages = {
                    //   product_id: this.state.product.product_id,
                    //   images: [...images, newImage],
                    // };
                    // this.props.createImages(productImages);
                    const newUploadedImage = map(nextProps.fileReducer.product_images, (item, index) => {
                        return {
                            src: item,
                            position: images.length + index,
                        };
                    });

                    if (!isCopy) {
                        const productImages = {
                            product_id: this.state.product.product_id,
                            images: newUploadedImage,
                        };
                        this.props.createImages(productImages);
                    } else {
                        let listImage = [...images];
                        listImage.push(...newUploadedImage);

                        this.setState({
                            images: listImage,
                        });
                    }
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.fileReducer.message, 'OK');
                }
            }
        }
        if (nextProps.product.action === productTypes.SHOW_PRODUCT) {
            if (nextProps.product.status) {
                // this.setState({
                //     currentProductInfo: nextProps.product.product,
                //     isShowModalEdit: true,
                // });
                // if (this.state.isCopy) {
                //     this.init(baseHelper.normalizeCopyProduct({ ...nextProps.product.product }));
                // } else {
                this.init(nextProps.product.product);
                // }
            } else {
                // Không show lôi, thay vào đó redirect
                this.props.history.push('/products');
            }
        }

        if (nextProps.product.action === productTypes.RELOAD_PRODUCT) {
            if (nextProps.product.status) {
                this.init(nextProps.product.product);

                // this.setState({
                //     currentProductInfo: nextProps.product.product,
                // });
            }
        }
        if (nextProps.product.action === productTypes.UPDATE_PRODUCT) {
            const { isReloadIframe } = this.state;

            this.setState({
                isReloadIframe: !isReloadIframe,
            });
            if (nextProps.product.status) {
                window.LadiUI.toastCustom('success', '', nextProps.product.message);
                this.props.reloadProduct(this.state.product.product_id);
                // this.list();
            } else {
                window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
            }
        }

        if (nextProps.product.action === productTypes.CREATE_PRODUCT) {
            const { isReloadIframe } = this.state;

            this.setState({
                isReloadIframe: !isReloadIframe,
            });
            if (nextProps.product.status) {
                window.LadiUI.toastCustom('success', '', nextProps.product.message);
                this.props.history.push(`/products`);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
            }
        }
        if (this.props.productCategory.action != nextProps.productCategory.action) {
            if (nextProps.productCategory.action === productCategoryTypes.LIST_SELECT_CATEGORY) {
                if (nextProps.productCategory.status) {
                    let _categories = nextProps.productCategory.listSelects;
                    let _data = [];
                    _categories.map((item, index) => {
                        _data.push({
                            level: item.level,
                            name: item.name,
                            value: item.product_category_id,
                        });
                    });
                    this.setState({
                        categories: _data,
                    });
                }
            }
        }
    }

    selectTimezone = (selectedItem) => {
        this.setState({
            product: {
                ...this.state.product,
                timezone: selectedItem.CODE,
            },
        });
    };

    selectTicketCreationForm = (selectedItem) => {
        this.setState({
            product: {
                ...this.state.product,
                ticket_creation_form: selectedItem.CODE,
            },
        });
        this.props.ticketCreationFrom(selectedItem.CODE);
    };

    /*******************************HANDLE INPUT***************************/
    onChangeProductInput = (event) => {
        this.setState({
            product: {
                ...this.state.product,
                [event.target.name]: event.target.value,
                alias_name: event.target.name == 'name' ? baseHelper.getAliasName(event.target.value) : this.state.product.alias_name,
                path: this.state.product.path ? this.state.product.path : baseHelper.getAliasName(event.target.value),
            },
        });
    };

    onChangeInput = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    };
    /*****************************************************************/

    /******************************IMAGE*****************************/
    fileChangedHandler = (event) => {
        const files = event.target.files;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                this.props.uploadProductImages(form);
            }
        }
    };

    getIconCTA = (code) => {
        const matched = find(appConfig.LIST_CTA, (item) => item.CODE == code) || {};
        if (matched) {
            return matched.CLASS_ICON;
        }
        return 'cta-add';
    };

    init = async (props) => {
        let currentProductInfo = { ...props };

        if (this.state.isCopy) {
            currentProductInfo = await baseHelper.normalizeCopyProduct(currentProductInfo);
        }

        if (currentProductInfo.product_category_ids && currentProductInfo.product_category_ids.length > 0) {
            this.setState({
                product_category_ids: currentProductInfo.product_category_ids,
            });
        }

        const { options, variants, tags } = currentProductInfo;
        const customFields = currentProductInfo.custom_fields || [];
        let newProduct = {};
        map(this.state.product, (value, key) => {
            newProduct[key] = currentProductInfo[key];

            if (key == 'path' && baseHelper.isEmpty(newProduct[key]) && currentProductInfo.product_id) {
                newProduct[key] = '' + currentProductInfo.product_id;
            }
            if (key == 'cta' && baseHelper.isEmpty(newProduct[key])) {
                newProduct[key] = 0;
            }
            if (key == 'seo' && baseHelper.isEmpty(newProduct[key])) {
                newProduct['seo'] = {
                    title: '',
                    description: '',
                    keywords: '',
                    image: '',
                    canonical_url: '',
                };
            }
            if (key == 'selectedIconCTA' && baseHelper.isEmpty(currentProductInfo['cta'])) {
                newProduct[key] = 'cta-add';
            }

            if (key == 'selectedIconCTA' && !baseHelper.isEmpty(currentProductInfo['cta'])) {
                newProduct['selectedIconCTA'] = this.getIconCTA(currentProductInfo['cta']);
            }
        });

        if (baseHelper.isEmpty(options)) {
            newProduct.product_variant_id = variants[0].product_variant_id;
        }

        if (variants) {
            newProduct.variants = variants;
        }

        const newCustomFields = !baseHelper.isEmpty(customFields) ? customFields : [];

        if (newProduct.interface_options) {
            newProduct.interface_options = JSON.parse(newProduct.interface_options);
        } else newProduct.interface_options = [];

        this.setState({
            product: newProduct,
            images: currentProductInfo.images,
            customFields: newCustomFields,
            variants: variants,
            options: options,
            tags: tags,
            productUpSells: produce(currentProductInfo.product_up_sells, (draft) => { }),
        });
    };

    openConfirmDeleteModal = (selectedImage) => {
        this.selectedImage = selectedImage;

        window.LadiUI.showModal('confirm-image-delete');
    };

    deleteImage = () => {
        const { product } = this.state;
        const { selectedImage } = this;
        this.props.deleteImage(product.product_id, selectedImage.src_hash);
    };

    submit = () => {
        // Validate
        let errors = [];
        let flagFocusTab = true;
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    if (flagFocusTab) {
                        const tabActiveID = ReactDOM.findDOMNode(ref.ref.current).closest('.ladiui.tab-pane').getAttribute('id');
                        document.getElementById(`trigger-${tabActiveID}`).click();
                    }
                    flagFocusTab = false;
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        // Tao productRequest
        const { product, images } = this.state;
        const tags = this.tagRef.current.getData();

        let { variants, options } = this.panelCreateVariantRef.current.getData();
        variants = this.getVariantReq(variants);

        const productUpSells = this.productUpSellRef.current.getData();

        const productCustomField = this.panelCustomFieldRef.current.getData();

        const productSeoSocial = this.productSeoSocialRef.current.getData();

        if (productSeoSocial) {
            if (productSeoSocial.title && productSeoSocial.title.length > 70) {
                window.LadiUI.toastCustom('warning', '', 'Tiêu đề vượt quá số ký tự quy định!');
                return;
            }
            if (productSeoSocial.description && productSeoSocial.description.length > 170) {
                window.LadiUI.toastCustom('warning', '', 'Mô tả vượt quá số ký tự quy định!');
                return;
            }
            if (productSeoSocial.keywords && productSeoSocial.keywords.length > 170) {
                window.LadiUI.toastCustom('warning', '', 'Từ khóa vượt quá số ký tự quy định!');
                return;
            }
        }

        let productReq;
        if (this.state.mode == appConfig.FORM_MODE.CREATE) {
            productReq = {
                ...pick(product, [
                    'name',
                    'alias_name',
                    'description',
                    'quantity',
                    'timezone',
                    'location',
                    'content',
                    'host_name',
                    'contact_phone',
                    'type',
                    'checkout_config_id',
                    'status',
                    'external_link',
                    'ticket_creation_form',
                    'cta',
                    'is_one_time',
                ]),
                interface_options: JSON.stringify(product.interface_options),
                tags,
                options,
                variants,
                images,
                custom_fields: productCustomField,
                product_up_sells: productUpSells,
                select_many_service: this.state.product.select_many_service,
                seo: { ...productSeoSocial },
                product_category_ids: this.state.product_category_ids || [],
            };
        } else {
            if (this.state.tabIndexActive == this.TAB_SERVICES.UP_SELL) {
                if (baseHelper.isEmpty(options)) {
                    window.LadiUI.toastCustom('danger', '', this.props.t('PRODUCT_SERVICES.REQUIRED_AT_LEAST_PACKAGE'));
                    return;
                }

                if (!this.panelProductCheckoutRef.current.validate()) {
                    return;
                }
            }
            productReq = {
                ...pick(product, [
                    'product_id',
                    'name',
                    'alias_name',
                    'domain',
                    'path',
                    'description',
                    'quantity',
                    'timezone',
                    'location',
                    'content',
                    'host_name',
                    'contact_phone',
                    'type',
                    'checkout_config_id',
                    'status',
                    'payment_redirect_url',
                    'payment_redirect_after',
                    'external_link',
                    'cta',
                    'is_one_time',
                ]),
                interface_options: JSON.stringify(product.interface_options),
                tags,
                variants,
                images,
                custom_fields: productCustomField,
                product_up_sells: productUpSells,
                is_publish: true,
                select_many_service: this.state.product.select_many_service,
                seo: { ...productSeoSocial },
                product_category_ids: this.state.product_category_ids || [],
            };
        }

        // this.props.onSubmit(productReq);
        if (this.state.isCopy) {
            productReq.options = options;
            this.props.create(productReq);
        } else {
            this.props.update(productReq);
        }
    };

    /**
     * @description Neu variants empty => tao variant mac dinh
     */
    getVariantReq = (variants) => {
        const {
            product: {
                product_id,
                product_variant_id,
                name,
                description,
                quantity,
                timezone,
                location,
                content,
                host_name,
                contact_phone,
                type,
            },
        } = this.state;
        if (variants.length <= 0) {
            const defaultVariant = {
                product_id,
                product_variant_id,
                name,
                description,
                quantity: 0,
                timezone,
                location,
                content,
                host_name,
                contact_phone,
                type,
                inventory_checked: appConfig.STATUS.ACTIVE,
                position: 0,
            };

            variants.push(defaultVariant);
        }

        return variants;
    };

    handleEditorChange = (value, editor) => {
        this.setState({
            product: {
                ...this.state.product,
                content: value,
            },
        });
    };

    handleSetImage = (images) => {
        this.setState({
            images,
        });
    };

    onChangeCTA = async (item) => {
        this.setState({
            product: {
                ...this.state.product,
                cta: item.CODE,
                selectedIconCTA: item.CLASS_ICON,
            },
        });
    };

    handleRefreshDataCategories = () => {
        let payload = {
            search: {
                type: "Product"
            }
        }
        this.props.listCategorySelect(payload);
    };

    render() {
        const { t } = this.props;
        const allTags = this.props.productTag.allTags || [];
        const { product, images, productUpSells, customFields, tags, isReloadIframe, isCopy, categories, product_category_ids } =
            this.state;

        const { GENERAL_SERVICE, PACKAGE_SERVICE, UP_SELL, SERVICE_SEAT, SERVICE_CHECKOUT, SEO_SOCIAL } = this.TAB_SERVICES;

        const isLoadingUpload = this.props.fileReducer.loading && this.props.fileReducer.waiting == fileTypes.UPLOAD_PRODUCT_IMAGES;

        const isSubmitLoading =
            this.props.product.loading && includes([productTypes.CREATE_PRODUCT, productTypes.UPDATE_PRODUCT], this.props.product.waiting);
        const isLoadingDelete =
            this.props.productImage.loading && includes([productImageTypes.PRODUCT_IMAGE_DELETE], this.props.productImage.waiting);

        return (
            <div className='page-content'>
                {(isLoadingUpload || isSubmitLoading) && <LoadingScene blur={true} />}

                <div className='ladiui content-main-title flex-row mb-20'>
                    <div className='ladiui flex-row'>
                        {isCopy ? (
                            <div className='ladiui main-title'>{t('PRODUCTS.ADD_TITLE')}</div>
                        ) : (
                            <div className='ladiui main-title'>{t('PRODUCTS.EDIT_PRODUCT_TITLE')}</div>
                        )}
                    </div>
                    <div className='flex'>
                        <button onClick={(e) => this.props.history.push('/products')} className='ladiui btn btn-secondary mr-24 '>
                            {' '}
                            {`Huỷ bỏ`}
                        </button>

                        <button
                            type='button'
                            className={`ladiui btn btn-primary btn-lg btn-open-modal ${isSubmitLoading ? 'loader' : ''}`}
                            disabled={isSubmitLoading}
                            onClick={this.submit}
                        >
                            <div>{isCopy ? t('PRODUCTS.ADD_TITLE') : t('PRODUCTS.SAVE_PRODUCT_INFO')}</div>
                        </button>
                    </div>
                </div>

                <div>
                    <ul className='ladiui nav nav-tabs' id='tabServiceEdit' role='tablist'>
                        <li className={`ladiui nav-item `} role='presentation'>
                            <span
                                className={`ladiui tab-link ${this.state.tabIndexActive == GENERAL_SERVICE ? 'active' : ''}`}
                                id='trigger-tab-general-create'
                                data-toggle='tab'
                                data-parent='tabServiceEdit'
                                onClick={() => this.setState({ tabIndexActive: GENERAL_SERVICE })}
                                data-target='tab-general-create'
                            >
                                {t('PRODUCTS.PRODUCT_INFO')}
                            </span>
                        </li>
                        <li className={`ladiui nav-item `} role='presentation'>
                            <span
                                className={`ladiui tab-link ${this.state.tabIndexActive == PACKAGE_SERVICE ? 'active' : ''}`}
                                id='trigger-tab-variants-create'
                                data-toggle='tab'
                                data-parent='tabServiceEdit'
                                onClick={() => this.setState({ tabIndexActive: PACKAGE_SERVICE })}
                                data-target='tab-variants-create'
                            >
                                {t('PRODUCT_SERVICES.PACKAGE')}
                            </span>
                        </li>
                        <li className={`ladiui nav-item `} role='presentation'>
                            <span
                                className={`ladiui tab-link ${this.state.tabIndexActive == UP_SELL ? 'active' : ''}`}
                                id='trigger-tab-product-up-sell'
                                data-toggle='tab'
                                data-parent='tabServiceEdit'
                                onClick={() => this.setState({ tabIndexActive: UP_SELL })}
                                data-target='tab-product-up-sell'
                            >
                                {t('PRODUCTS.PRODUCT_UP_SELL')}
                            </span>
                        </li>
                        {product.ticket_creation_form && product.ticket_creation_form != 0 ? (
                            <li className={`ladiui nav-item `} role='presentation'>
                                <span
                                    className={`ladiui tab-link ${this.state.tabIndexActive == SERVICE_SEAT ? 'active' : ''}`}
                                    id='trigger-tab-event-seat'
                                    data-toggle='tab'
                                    data-parent='tabServiceEdit'
                                    onClick={() => this.setState({ tabIndexActive: SERVICE_SEAT })}
                                    data-target='tab-event-seat'
                                >
                                    {t('PRODUCT_EVENTS.TAB_SERVICE_SEATS')}
                                </span>
                            </li>
                        ) : (
                            ''
                        )}
                        <li className={`ladiui nav-item `} role='presentation'>
                            <span
                                className={`ladiui tab-link ${this.state.tabIndexActive == SERVICE_CHECKOUT ? 'active' : ''}`}
                                id='trigger-tab-checkout'
                                data-toggle='tab'
                                data-parent='tabServiceEdit'
                                onClick={() => this.setState({ tabIndexActive: SERVICE_CHECKOUT })}
                                data-target='tab-checkout'
                            >
                                {t('PRODUCT_EVENTS.TAB_EVENT_CHECKOUT')}
                            </span>
                        </li>
                        <li className={`ladiui nav-item `} role='presentation'>
                            <span
                                className={`ladiui tab-link ${this.state.tabIndexActive == SEO_SOCIAL ? 'active' : ''}`}
                                id='trigger-tab-seo-social'
                                data-toggle='tab'
                                data-parent='tabServiceEdit'
                                onClick={() => this.setState({ tabIndexActive: SEO_SOCIAL })}
                                data-target='tab-seo-social'
                            >
                                Seo & Social
                            </span>
                        </li>
                    </ul>

                    <div className='ladiui tab-content'>
                        <div
                            className={`ladiui tab-pane fade mt-0 ${this.state.tabIndexActive == GENERAL_SERVICE ? 'show open ' : ''} `}
                            id='tab-general-create'
                            role='tabpanel'
                            aria-labelledby='trigger-tab-general-create'
                        >
                            <div className='ladiui-product-left block-product-content-left mt-24'>
                                <ListImageProduct
                                    listImage={images}
                                    handleDeleteImage={this.openConfirmDeleteModal}
                                    handleSetImage={this.handleSetImage}
                                    productID={product.product_id}
                                    isCopy={isCopy}
                                />
                                <div className='ladiui toggle-dropdown-product-edit service block-custom-field mt-24'>
                                    <h3
                                        className={`ladiui title-block pointer relative mb-24 ${this.state.isShowBlockCustomField ? 'arrow-up' : 'arrow-down'
                                            }`}
                                        onClick={() =>
                                            this.setState({
                                                isShowBlockCustomField: !this.state.isShowBlockCustomField,
                                            })
                                        }
                                    >
                                        Trường tùy chỉnh
                                    </h3>
                                    <div style={this.state.isShowBlockCustomField ? { display: 'block' } : { display: 'none' }}>
                                        <PanelCustomField
                                            ref={this.panelCustomFieldRef}
                                            customFields={customFields}
                                            groupType={appConfig.CUSTOM_FIELD.GROUP_TYPES.PRODUCT.CODE}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='ladiui-product-right service'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label custom'>{baseHelper.getLabelNameByType(product.type)}</label>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name='name'
                                        validationName={t('PRODUCT_SERVICES.SERVICE')}
                                        placeholder={t('PRODUCT_SERVICES.SERVICE_NAME')}
                                        value={product.name}
                                        onChange={this.onChangeProductInput}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui title-text-form-lever2'>Danh mục</label>
                                    <SelectManyDropdown
                                        data={categories}
                                        _key='name'
                                        _value='value'
                                        pickTitle={'Tiêu đề'}
                                        placeHolder={'Chọn danh mục'}
                                        onSubmit={(items) => {
                                            this.setState({
                                                product_category_ids: items,
                                            });
                                        }}
                                        innerClassName='w100'
                                        styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                                        buttonStyle={{
                                            width: '100%',
                                            paddingRight: '30px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: ' -webkit-box',
                                            webkitLineClamp: 1,
                                            webkitBoxOrient: 'vertical',
                                            textAlign: 'left',
                                        }}
                                        selectedItems={product_category_ids}
                                        isShowBlockInputSearch={true}
                                        isCreateCategory={true}
                                        handleRefreshData={this.handleRefreshDataCategories}
                                    />
                                </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui title-text-form-lever2'>
                                        {t('PRODUCTS.PRODUCT_TYPE')} : {baseHelper.getLabelNameByType(product.type)}
                                    </label>
                                </div>

                                <div className='ladiui form-group group-date flex'>
                                    <div className='date-item'>
                                        <label className='ladiui-label'>{t('PRODUCTS.LB_EXTERNAL_LINK')}</label>
                                        <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name='external_link'
                                            validationName={t('PRODUCTS.LB_EXTERNAL_LINK')}
                                            placeholder={t('PRODUCTS.LB_EXTERNAL_LINK_PLACEHOLDER')}
                                            value={product.external_link}
                                            onChange={this.onChangeProductInput}
                                            validations={{ isURL: true }}
                                        />
                                    </div>

                                    {/* <div className="date-item">
                    <label className="ladiui-label">
                      {t("PRODUCT_EVENTS.TICKET_CREATION_FORM")}
                    </label>
                    {this.props.mode == appConfig.FORM_MODE.EDIT ? (
                      <Input
                        value={
                          product.ticket_creation_form ==
                          appConfig.TICKET_CREATION_FORM.IMPORT.CODE
                            ? appConfig.TICKET_CREATION_FORM.IMPORT.NAME
                            : appConfig.TICKET_CREATION_FORM.AUTO.NAME
                        }
                        disabled={true}
                      />
                    ) : (
                      <Dropdown
                        data={appConfig.TICKET_CREATION_FORM}
                        onSelectItem={this.selectTicketCreationForm}
                        currentKey={product.ticket_creation_form}
                        _key={"CODE"}
                        _value={"NAME"}
                      />
                    )}
                  </div> */}
                                    {product.ticket_creation_form && product.ticket_creation_form > 0 ? (
                                        <div className='date-item'>
                                            <label className='ladiui title-text-form-lever2'>Hình thức tạo mã</label>
                                            <Input
                                                value={
                                                    product.ticket_creation_form == appConfig.TICKET_CREATION_FORM.IMPORT.CODE
                                                        ? appConfig.TICKET_CREATION_FORM.IMPORT.NAME
                                                        : appConfig.TICKET_CREATION_FORM.AUTO.NAME
                                                }
                                                disabled={true}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div className='ladiui form-group group-date flex'>
                                    <div className='date-item'>
                                        <Tag
                                            ref={this.tagRef}
                                            selectedTags={tags}
                                            id='product-tag'
                                            allTags={allTags}
                                            className='mt-0 mb-0'
                                        />
                                    </div>
                                </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PRODUCT_EVENTS.EVENT_CONTENT')}</label>
                                    <Editor
                                        value={product.content}
                                        init={{
                                            height: 250,
                                            plugins: '',
                                            toolbar:
                                                'bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat',
                                            toolbar_drawer: 'sliding',
                                            language_url: '../../../../language/tinymce-vi',
                                            valid_elements: '*[*]',
                                        }}
                                        onEditorChange={this.handleEditorChange}
                                    />
                                </div>

                                <div className='flex'>
                                    <div className='ladiui item-form switch'>
                                        <label className='ladiui switch m-0'>
                                            <input
                                                type='checkbox'
                                                onChange={(event) => {
                                                    this.setState(
                                                        {
                                                            product: {
                                                                ...this.state.product,
                                                                is_one_time: !this.state.product.is_one_time,
                                                            },
                                                        },
                                                        () => {
                                                            if (this.state.product.is_one_time) {
                                                                this.setState({
                                                                    product: {
                                                                        ...this.state.product,
                                                                        select_many_service: false,
                                                                    },
                                                                });
                                                            }
                                                        }
                                                    );
                                                }}
                                                checked={product.is_one_time}
                                            />
                                            <span className='ladiui slider round'></span>
                                        </label>
                                    </div>
                                    <div className='ml-8'>
                                        <span className='ladiui title-text-form-lever2 mb-0'> {t('PRODUCTS.BUY_ONCE')}</span>
                                    </div>
                                </div>

                                <div className='ladiui form-group'>
                                    <div className=' flex flex-start'>
                                        <input
                                            type='checkbox'
                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                            name='select_many_service'
                                            onChange={(event) =>
                                                this.setState({
                                                    product: {
                                                        ...this.state.product,
                                                        select_many_service: !this.state.product.select_many_service,
                                                    },
                                                })
                                            }
                                            checked={this.state.product.select_many_service}
                                            disabled={this.state.product.is_one_time}
                                        />{' '}
                                        {/* {`Cho phép chọn mua nhiều ${baseHelper.getLabelNameByType(product.type)}`} */}
                                        {t('PRODUCTS.ALLOW_SELECT_MANY_SERVICES')}
                                    </div>
                                </div>

                                {/* <div className="ladiui form-group">
                  <div className=" flex flex-start">
                    <input
                      type="checkbox"
                      className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                      name="is_buy_in_bulk"
                      onChange={(event) =>
                        this.setState({
                          product: {
                            ...this.state.product,
                            is_buy_in_bulk: !this.state.product.is_buy_in_bulk,
                          },
                        })
                      }
                      checked={this.state.product.is_buy_in_bulk}
                    />{" "}
                    {t("PRODUCTS.BUY_IN_BULK")}
                  </div>
                </div> */}

                                <div className='ladiui form-group'>
                                    <label className='ladiui title-text-form-lever2'>Nút CTA</label>
                                    <span className='text-default block'>Thêm nút kêu gọi hành động cho sản phẩm của bạn</span>
                                    <SelectOption
                                        wrapperClassName='select-assignee w100'
                                        innerClassName='ellipsis mt-12 w100 flex'
                                        currentKey={this.state.product.cta}
                                        classIconCTA={this.state.product.selectedIconCTA}
                                        _key='CODE'
                                        _value='NAME'
                                        items={appConfig.LIST_CTA || []}
                                        onSelectItem={(item) => {
                                            this.onChangeCTA(item);
                                        }}
                                    />
                                </div>

                                <div className='flex'>
                                    <div className='mr-8'>
                                        <span className='ladiui-label pb-0'> {t('PRODUCTS.LB_STATUS')}</span>
                                    </div>

                                    <div className='ladiui item-form switch'>
                                        <label className='ladiui switch m-0'>
                                            <input
                                                type='checkbox'
                                                onChange={(event) => {
                                                    this.setState({
                                                        product: {
                                                            ...this.state.product,
                                                            status:
                                                                this.state.product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE
                                                                    ? appConfig.PRODUCT_STATUS.INACTIVE.CODE
                                                                    : appConfig.PRODUCT_STATUS.ACTIVE.CODE,
                                                        },
                                                    });
                                                }}
                                                checked={product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE}
                                            />
                                            <span className='ladiui slider round'></span>
                                        </label>
                                    </div>
                                </div>

                                <div className='pt-8 mb-24'>
                                    <span className='text-default'>{t('PRODUCTS.SHOW_SERVICE_IN_CHECKOUT_PAGE')}</span>
                                </div>
                            </div>
                        </div>

                        {/* <div className="ladiui-tab-item" id="tab-variants-create"> */}
                        <div
                            className={`ladiui tab-pane fade mt-24 ${this.state.tabIndexActive == PACKAGE_SERVICE ? 'show open ' : ''} `}
                            id='tab-variants-create'
                            role='tabpanel'
                            aria-labelledby='trigger-tab-variants-create'
                        >
                            <PanelPackage
                                ref={this.panelCreateVariantRef}
                                inputsRef={this.inputsRef}
                                product={this.state.product}
                                productID={this.state.product.product_id}
                                variants={this.state.variants && this.state.variants}
                                options={this.state.options && this.state.options}
                                mode={this.state.mode}
                                openVariantID={this.props.openVariantID}
                                selectManyService={this.state.product.select_many_service}
                                isCopy={this.state.isCopy}
                            />
                        </div>
                        {/* <div className="ladiui-tab-item" id="tab-checkout"> */}
                        <div
                            className={`ladiui tab-pane fade ${this.state.tabIndexActive == SERVICE_CHECKOUT ? 'show open ' : ''} `}
                            id='tab-checkout'
                            role='tabpanel'
                            aria-labelledby='trigger-tab-checkout'
                        >
                            <PanelProductCheckout
                                isReloadIframe={isReloadIframe}
                                ref={this.panelProductCheckoutRef}
                                productID={product.product_id}
                                checkoutConfigID={product.checkout_config_id}
                                oncChangeCheckoutConfig={(checkoutConfigID) => {
                                    this.setState({
                                        product: {
                                            ...this.state.product,
                                            checkout_config_id: checkoutConfigID,
                                        },
                                    });
                                }}
                                urlPublished={product.url_published}
                                domain={product.domain}
                                path={product.path}
                                payment_redirect_url={product.payment_redirect_url}
                                payment_redirect_after={product.payment_redirect_after}
                                checkoutLocation={product.interface_options}
                                setCheckoutLocation={(data) => {
                                    this.setState({
                                        product: {
                                            ...this.state.product,
                                            interface_options: data,
                                        },
                                    });
                                }}
                                onChangeCheckoutConfig={(checkoutConfigID) => {
                                    this.setState({
                                        product: {
                                            ...this.state.product,
                                            checkout_config_id: checkoutConfigID,
                                        },
                                    });
                                }}
                                onChangePath={(path) => {
                                    this.setState({
                                        product: {
                                            ...this.state.product,
                                            path,
                                        },
                                    });
                                }}
                                onChangeDomain={(domain) => {
                                    this.setState({
                                        product: {
                                            ...this.state.product,
                                            domain,
                                        },
                                    });
                                }}
                                onChangePaymentRedirectPath={(payment_redirect_url) => {
                                    this.setState({
                                        product: {
                                            ...this.state.product,
                                            payment_redirect_url,
                                        },
                                    });
                                }}
                                onChangePaymentRedirectAfter={(paymentRedirectAfter) => {
                                    this.setState({
                                        product: {
                                            ...this.state.product,
                                            payment_redirect_after: baseHelper.parsePositiveInt(paymentRedirectAfter),
                                        },
                                    });
                                }}
                            />
                        </div>

                        {/* <div className={this.state.tabIndexActive == SERVICE_SEAT ? 'ladiui-tab-item active' : 'ladiui-tab-item'} id="tab-event-seat"> */}
                        <div
                            className={`ladiui tab-pane mt-24 fade ${this.state.tabIndexActive == SERVICE_SEAT ? 'show open ' : ''} `}
                            id='tab-event-seat'
                            role='tabpanel'
                            aria-labelledby='trigger-tab-event-seat'
                        >
                            <PanelPackageSeat
                                serviceID={this.state.product.product_id}
                                ticketCreationForm={this.state.product.ticket_creation_form}
                                currentProductInfo={product}
                                options={this.state.options && this.state.options}
                            />
                        </div>

                        {/* <div className="ladiui-tab-item" id="tab-product-up-sell"> */}
                        <div
                            className={`ladiui tab-pane fade ${this.state.tabIndexActive == UP_SELL ? 'show open ' : ''} `}
                            id='tab-product-up-sell'
                            role='tabpanel'
                            aria-labelledby='trigger-tab-product-up-sell'
                        >
                            <ProductUpSell
                                ref={this.productUpSellRef}
                                parentID={product.product_id}
                                selectedVariants={productUpSells ? productUpSells : []}
                            />
                        </div>
                        <div
                            className={`ladiui tab-pane fade ${this.state.tabIndexActive == SEO_SOCIAL ? 'show open ' : ''} `}
                            id='tab-seo-social'
                            role='tabpanel'
                            aria-labelledby='trigger-tab-seo-social'
                        >
                            <PanelProductSellSocial ref={this.productSeoSocialRef} productInfoSeo={product.seo || {}} />
                        </div>
                    </div>
                </div>
                <ConfirmModal
                    style={{ zIndex: 1050 }}
                    id='confirm-image-delete'
                    title={t('PRODUCTS.MSG_DELETE_IMAGE_TITLE')}
                    content={t('PRODUCTS.MSG_DELETE_IMAGE_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={this.deleteImage}
                    isLoading={isLoadingDelete}
                />
            </div>
            // </Modal >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadProductImages: (form) => dispatch(fileActions.uploadProductImages(form)),
        searchVariant: (name) => dispatch(variantActions.search(name)),
        deleteImage: (productID, srcHash) => dispatch(productImageActions.delete(productID, srcHash)),
        createImages: (productImages) => dispatch(productImageActions.create(productImages)),
        ticketCreationFrom: (code) => dispatch(variantActions.ticketCreationFrom(code)),
        listAllTags: () => dispatch(productTagActions.listAll()),
        deleteTag: (productID, productTagID) => dispatch(productTagActions.delete(productID, productTagID)),
        show: (productID) => dispatch(productActions.show(productID)),
        update: (product) => dispatch(productActions.update(product)),
        create: (product) => dispatch(productActions.create(product)),
        reloadProduct: (productID) => dispatch(productActions.reload(productID)),
        listCategorySelect: (data) => dispatch(productCategoryActions.listSelectCategory(data)),
    };
};

const mapStateToProps = (state) => ({
    productTag: { ...state.productTag },
    productImage: { ...state.productImage },
    store: { ...state.store },
    variantReducer: { ...state.variant },
    fileReducer: { ...state.file },
    product: { ...state.product },
    productCategory: { ...state.productCategory },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(ModalService)));
