import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../../components/Modal";

import baseHelper from "../../../../helpers/BaseHelper";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/Input";
import appConfig from "../../../../config/app";
import { map, includes } from "lodash";

ModalCod.propTypes = {
  onCancel: PropTypes.func,
  paymentGatewayConfigured: PropTypes.any,
};

export default function ModalCod(props) {
  const {
    paymentGatewayConfigured,
    onCancel,
    upDateDataCofigPayment,
    connect,
    isSubmitLoading,
    onchangeDataUpdateCod,
    codMode,
    codRef,
    codAccount,
    onChangeCodInput,
    setCodMode,
    logo,
  } = props;

  const { t } = useTranslation();
  return (
    <Modal
      id="modal-cod-pay"
      title={!paymentGatewayConfigured ? t("PAYMENTS.SET_UP_PAYMENT_METHOD") : t("PAYMENTS.DETAIL_PAYMENT_METHOD")}
      onCancel={onCancel}
      hasFooter={true}
      onOk={
        !paymentGatewayConfigured
          ? () => connect(appConfig.PAYMENT_PARTNERS.COD.CODE)
          : () => upDateDataCofigPayment(appConfig.PAYMENT_PARTNERS.COD.CODE, paymentGatewayConfigured)
      }
      visible={true}
      width={1000}
      bodyStyles={{ minHeight: "40vh" }}
      logo={logo}
    >
      <div className="content">
        {paymentGatewayConfigured ? (
          <React.Fragment>
            <div className="partner-account">
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("PAYMENTS.LB_METHOD_NAME")}</label>
                <Input value={paymentGatewayConfigured.config.method_name} disabled={true} />
              </div>
              <div id="data-tooltip-guide" className="ladiui form-group">
                <label className="ladiui-label">
                  <span className="tooltip-paymet-guide">{t("PAYMENTS.LB_PAYMENT_GUIDE")}</span>
                  <span className="tooltip-icon" data-tooltip={t("PAYMENTS.LB_TT_PAYMENT_GUIDE")} data-tooltip-position="right">
                    {" "}
                    <i className="ladi-icon icon-c-question" />
                  </span>
                </label>
                <textarea
                  name="payment_guide"
                  className="ladiui textarea"
                  defaultValue={paymentGatewayConfigured.config.payment_guide}
                  onChange={(e) => onchangeDataUpdateCod(e)}
                />
              </div>
              {baseHelper.isDevelopEnv() && (
                <div className="ladiui form-group flex">
                  <input
                    id="cod_mode"
                    type="checkbox"
                    className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                    checked={codMode === appConfig.MODE.TEST}
                    disabled={true}
                  />
                  <label htmlFor="cod_mode"> {t("MODE.TEST")}</label>
                </div>
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <div className="partner-account">
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.LB_METHOD_NAME")}</label>
                  <Input
                    ref={(ref) => codRef.add(ref)}
                    value={codAccount.method_name}
                    validationName={t("PAYMENTS.LB_METHOD_NAME")}
                    name="method_name"
                    onChange={(event) => onChangeCodInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>

                <div id="data-tooltip-guide" className="ladiui form-group">
                  <label className="ladiui-label">
                    <span className="tooltip-paymet-guide">{t("PAYMENTS.LB_PAYMENT_GUIDE")}</span>
                    <span className="tooltip-icon" data-tooltip={t("PAYMENTS.LB_TT_PAYMENT_GUIDE")} data-tooltip-position="right">
                      {" "}
                      <i className="ladi-icon icon-c-question" />
                    </span>
                  </label>
                  <textarea
                    name="payment_guide"
                    className="ladiui textarea"
                    value={codAccount.payment_guide}
                    onChange={(event) => onChangeCodInput(event)}
                  />
                </div>
                {baseHelper.isDevelopEnv() && (
                  <div className="ladiui form-group flex">
                    <input
                      id="cod_mode"
                      type="checkbox"
                      className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                      onChange={() => setCodMode(!codMode)}
                      checked={codMode === appConfig.MODE.TEST}
                    />
                    <label htmlFor="cod_mode"> {t("MODE.TEST")}</label>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
}
