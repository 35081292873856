import { isArray } from "lodash";
import { Component } from "react";
import appConfig from "../../../config/app";

// Màn hình preview giỏ hàng có sản phẩm
class FormCartTemplate extends Component {
  renderData(content) {
    let CDN = "https://w.ladicdn.com/";
    let contentHeaderLocation = [];
    let contentFooterLocation = [];

    if (content && content.locations) {
      content.locations.map((item, index) => {
        if (item.value === 7) {
          contentHeaderLocation = item.datas;
        } else {
          contentFooterLocation = item.datas;
        }
      });
    }

    const handleShowContentHeader = (data) => {
      let html = "";
      for (let i = 0; i < data.length; i++) {
        if (data[i].type === "IMAGE") {
          html += `<img src=${CDN + data[i].image} />`;
        } else {
          html += `<div class="content-header-cart">${data[i].content}</div>`;
        }
      }
      return html;
    };

    const handleShowContentFooter = (data) => {
      let html = "";
      for (let i = 0; i < data.length; i++) {
        if (data[i].type === "IMAGE") {
          html += `<img src=${CDN + data[i].image} />`;
        } else {
          html += `<div class="content-footer-cart">${data[i].content}</div>`;
        }
      }
      return html;
    };

    return `<div class="ladichat-widget-container" id="ladichat-widget-container">
        <div>
          <div class="ladichat-startpage" id="ladichat-startpage">
            <div class="header-start-page">
              <div class="header-background">
              </div>
              <div class="intro-store">
                <span>Giỏ hàng (3)</span>
                <span id="icon-close-cart-widget"><i class="ladi-icon icon-close"></i></span>
              </div>
            </div>
            <div class="ladichat-startpage-body" id="ladichat-startpage-body">
              <div class="extra-content content-sdk-ladisale">
                <div class="module-border-wrap" id="agent-module">
                  <div class="list-products-cart-sdk">
                    <div class="block-location-header-cart">
                    ${contentHeaderLocation && contentHeaderLocation.length > 0 ? handleShowContentHeader(contentHeaderLocation) : ""}
                    </div>
                    <div class="item-product">
                      <div class="image">
                        <img src="${require("../../../assets/img/image-default-sdk.png")}">
                      </div>
                      <div class="info-product">
                        <div class="product-info-action">
                          <div class="product-name">
                            <h3>Trà ô long hương hoa nhài</h3>
                            <span class="variant-name">S / Xanh navy</span>
                          </div>
                          <div class="action">
                            <a>
                              <i class="ladi-icon icon-bin"></i>
                            </a>
                          </div>
                        </div>
                        <div class="qty-price">
                          <div class="block-qty">
                            <div class="btn-quantity-img" >
                                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-subtract-quantity.svg" alt=""></img>
                            </div>
                              <span class="num-qty">5</span>
                            <div class="btn-quantity-img">
                                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-add-quantity.svg" alt="" > </img>
                            </div>
                          </div>
                          <span>200.000 đ</span>
                        </div>
                      </div>
                    </div>
                    <div class="item-product">
                      <div class="image">
                      <img src="${require("../../../assets/img/image-default-sdk.png")}">
                      </div>
                      <div class="info-product">
                        <div class="product-info-action">
                          <div class="product-name">
                            <h3>Trà ô long hương hoa nhài</h3>
                            <span class="variant-name">S / Xanh navy</span>
                          </div>
                          <div class="action">
                            <a>
                              <i class="ladi-icon icon-bin"></i>
                            </a>
                          </div>
                        </div>
                        <div class="qty-price">
                          <div class="block-qty">
                            <div class="btn-quantity-img" >
                                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-subtract-quantity.svg" alt=""></img>
                            </div>
                              <span class="num-qty">5</span>
                            <div class="btn-quantity-img">
                                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-add-quantity.svg" alt="" > </img>
                            </div>
                          </div>
                          <span>200.000 đ</span>
                        </div>
                      </div>
                    </div>
                    <div class="item-product">
                      <div class="image">
                      <img src="${require("../../../assets/img/image-default-sdk.png")}">
                      </div>
                      <div class="info-product">
                        <div class="product-info-action">
                          <div class="product-name">
                            <h3>Trà ô long hương hoa nhài</h3>
                            <span class="variant-name">S / Xanh navy</span>
                          </div>
                          <div class="action">
                            <a>
                              <i class="ladi-icon icon-bin"></i>
                            </a>
                          </div>
                        </div>
                        <div class="qty-price">
                          <div class="block-qty">
                            <div class="btn-quantity-img" >
                                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-subtract-quantity.svg" alt=""></img>
                            </div>
                              <span class="num-qty">5</span>
                            <div class="btn-quantity-img">
                                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-add-quantity.svg" alt="" > </img>
                            </div>
                          </div>
                          <span>200.000 đ</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="block-discount-price">
                    <label class="ladiui-label">
                      Mã giảm giá
                    </label>
                    <div class="enter-code">
                      <input class="ladiui form-control" type="text" placeholder="Nhập mã giảm giá" />
                      <button class="ladiui btn-apdung" id="btn-apply-coupon">
                          Áp dụng
                      </button>
                    </div>
                  </div>
                  <div class="block-note">
                    <div class="icon-title">
                      <i class="ladi-icon icon-note"></i>
                      Ghi chú
                    </div>
                    <div class="form-group">
                      <textarea type="text"> </textarea>
                    </div>
                  </div>
                  <div class="block-location-footer-cart">
                    ${contentFooterLocation && contentFooterLocation.length > 0 ? handleShowContentFooter(contentFooterLocation) : ""}
                    <div class="total-amount-payment">
                      <div class="total-amount">
                        <span class="">Tổng đơn hàng</span>
                        <span class="total">1.256.211đ</span>
                      </div>
                      <button class="payment-btn">Thanh toán</button>
                    </div>
                  </div>
                
              </div>
            </div>
          </div>
        </div>{" "}
      </div>`;
  }
}

export default new FormCartTemplate();
