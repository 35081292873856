/** @format */

import React from "react";

import { connect } from "react-redux";

import * as productCategoryTypes from "../../redux/futures/product_category/types";
import productCategoryActions from "../../redux/futures/product_category/actions";

import * as variantTypes from "../../redux/futures/product_variant/types";
import variantActions from "../../redux/futures/product_variant/actions";

import { find, includes, map, remove } from "lodash";
import appConfig from "../../config/app";
import baseHelper from "../../helpers/BaseHelper";

import ModalEdit from "./components/ModalEdit/ModalEditProduct";
import LoadingTable from "../../components/LoadingTable";
import { withTranslation } from "react-i18next";
import ConfirmModal from "../../components/ConfirmModal";
import LoadingScene from "../../components/LoadingScene";
import { appLocalStorage } from "../../localforage";
import queryString from "query-string";

import * as productTagTypes from "../../redux/futures/product_tag/types";
import productTagActions from "../../redux/futures/product_tag/actions";
import Tag from "../../components/Tag";

import ModalExportCustomer from "./components/ModalExportProduct";

import produce from "immer";
import ModalExportProduct from "./components/ModalExportProduct";
import ModalExportEvent from "../events/components/ModalExportEvent";
import ModalExportService from "../services/components/ModalExportService";
import FilterConditionProduct from "./components/FilterConditionProduct";
import LadiPagination from "../../components/LadiPagination";
import LadiDropdownMenu from "../../components/LadiDropdownMenu";
import ModalCreateCategory from "./components/ModalCreateCategory";
import ListTags from "../../components/ListTags";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

class Category extends React.Component {
  constructor(props) {
    super(props);
    // const defaultConditions = {
    //   page: 1,
    //   limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    // };

    this.state = {
      // activePage: 1,

      searchProductName: "",
      selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
      currentProductInfo: {},
      currentCategoryID: null,
      isShowModalCategory: false,
      isShowModalEdit: false,
      userInfo: {},
      isShowModalExportProduct: false,
      selectedIDs: [],
      // conditions: defaultConditions,
      productType: "",
      tag_ids: [],
      type: "",
      isShowModalExportEvent: false,
      isShowModalExportService: false,
      categories: [],
      listSelectCategories: [],
      isModalEdit: false,
    };

    this.selectedID = "";
    this.selectedProduct = {};

    this.sortBy = {
      product_id: "DESC",
    };
    this.searchTagRef = React.createRef();
  }

  componentWillMount() {
    appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
      this.setState({
        userInfo: result,
      });
    });
  }

  async componentDidMount() {

    const { isShowMessageErr, message, is_show_modal, type } = queryString.parse(this.props.location.search);

    if (type) {
      await this.setState({
        type
      })
    }

    if (is_show_modal) {
      this.setState({
        isShowModalCategory: true,
      });
    }

    if (isShowMessageErr && message) {
      window.LadiUI.showErrorMessage("Thông báo", message, "OK");
    }

    this.list();
  }

  componentDidUpdate() {
    window.LadiUI.init();
    setTimeout(() => {
      window.LadiUI.customInit();
    }, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }

    if (this.props.productCategory.action != nextProps.productCategory.action) {
      if (nextProps.productCategory.action == productCategoryTypes.LIST_CATEGORY) {
        if (nextProps.productCategory.status) {
          this.setState({
            categories: nextProps.productCategory.categories || [],
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.productCategory.message, "OK");
        }
      }
      if (nextProps.productCategory.action == productCategoryTypes.UPDATE_CATEGORY) {
        if (nextProps.productCategory.status) {
          this.list();
          this.setState({
            isShowModalCategory: false,
          });
          window.LadiUI.toastCustom("success", "", nextProps.productCategory.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.productCategory.status, "OK");
        }
      }
      if (nextProps.productCategory.action == productCategoryTypes.UPDATE_POSITION) {
        if (nextProps.productCategory.status) {
          this.list();
          this.setState({
            isShowModalCategory: false,
          });
          window.LadiUI.toastCustom("success", "", nextProps.productCategory.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.productCategory.status, "OK");
        }
      }
      if (nextProps.productCategory.action == productCategoryTypes.DELETE_CATEGORY) {
        if (nextProps.productCategory.status) {
          window.LadiUI.closeModal("confirm-category-delete");
          this.list();
          window.LadiUI.toastCustom("success", "", nextProps.productCategory.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.productCategory.status, "OK");
        }
      }
      if (nextProps.productCategory.action == productCategoryTypes.DELETE_CATEGORIES) {
        if (nextProps.productCategory.status) {
          window.LadiUI.closeModal("confirm-category-deletes");
          this.list();
          window.LadiUI.toastCustom("success", "", nextProps.productCategory.message);
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.productCategory.message, "OK");
        }
      }
      if (nextProps.productCategory.action == productCategoryTypes.LIST_SELECT_CATEGORY) {
        if (nextProps.productCategory.status) {
          this.setState({
            listSelectCategories: nextProps.productCategory.listSelects || [],
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.productCategory.message, "OK");
        }
      }
    }

    if (this.props.variant.action != nextProps.variant.action) {
      if (nextProps.variant.action == variantTypes.GET_VARIANT_INFOS) {
        if (nextProps.variant.status) {
          const variantInfos = map(nextProps.variant.variantInfos, (item) => {
            const variantName = `${item.product_variant_id}:1|${this.selectedProduct.name} ${item.name ? `(${item.name})` : ""}`;
            return variantName;
          });
          const variantInfo = variantInfos.join("\r\n");
          baseHelper.copyToClipboard(variantInfo);

          window.LadiUI.toastCustom("success", "", this.props.t("PRODUCTS.COPIED"));
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.variant.message, "OK");
        }
      }
    }
  }

  /**
   * list product
   */
  list = (resetActivePageFlag = true) => {
    if (document.getElementById("checkAllItem")) {
      document.getElementById("checkAllItem").checked = false;
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    // const { searchProductName, selectedLimit, conditions } = this.state;
    // let activePage = conditions.page;

    // if (resetActivePageFlag) {
    //   activePage = 1;
    //   conditions.page = 1;

    //   this.setState({
    //     conditions: conditions,
    //   });
    // }

    // Reset selectedIDs
    this.setState({
      selectedIDs: [],
    });
    const tagIDs = this.state.tag_ids ? this.state.tag_ids : [];
    // const tagIDs = this.searchTagRef.current.getIDs();

    const data = {
      search: {
        // name: searchProductName,
        // tag_ids: tagIDs,
        type: this.state.type,
      },
      // paged: activePage,
      // limit: conditions.limit,
      // sort: this.sortBy,
    };

    this.props.list(data);
    this.props.listSelectCategory(data);
  };

  /*******************************SEARCH***************************/
  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  /**
   * Tim kiem theo name
   */
  onKeyDownInput = (event) => {
    if (event.key === "Enter") {
      this.list();
    }
  };

  /****************************************************************/

  /*******************************PAGINATION***************************/
  /**
   * Change limit
   */
  // onChangeLimit = (option) => {
  //   let { conditions } = this.state;
  //   conditions.limit = option.value;

  //   // this.setState({ selectedLimit: option.value }, () => {
  //   this.setState({ conditions: conditions }, () => {
  //     this.list();
  //   });
  // };

  /**
   * Change page
   */
  // onPageChange = (pageNumber) => {
  //   let { conditions } = this.state;
  //   conditions.page = pageNumber;

  //   this.setState({ conditions }, () => {
  //     this.list(false);
  //   });
  // };
  /****************************************************************/

  /*******************************CHECK AND ACTIONS***************************/
  checkAllItem = (event) => {
    const { checked } = event.target;

    let selectedIDs = [];
    if (checked) {
      selectedIDs = map(this.props.productCategory.categories, (product) => product.product_id);

      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = true;
      });
    } else {
      window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui.checkbox"), function (e) {
        e.checked = false;
      });
    }

    this.setState({
      selectedIDs,
    });
  };

  checkItem = (event, productID) => {
    const { checked } = event.target;
    const { selectedIDs } = this.state;

    if (checked) {
      if (!includes(selectedIDs, productID)) {
        selectedIDs.push(productID);
      }

      if (selectedIDs.length == this.props.productCategory.categories.length) {
        document.getElementById("checkAllItem").checked = true;
      }
    } else {
      document.getElementById("checkAllItem").checked = false;
      remove(selectedIDs, (selectedProductID) => selectedProductID == productID);
    }

    this.setState({
      selectedIDs,
    });
  };

  deletes = () => {
    if (this.state.selectedIDs.length <= 0) {
      window.LadiUI.toastCustom("danger", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
      return;
    }

    this.props.deletes(this.state.selectedIDs);
  };

  deleteItem = (productID) => {
    this.props.delete(productID);
  };

  openModalCreate = () => {
    this.setState({
      isShowModalCategory: true,
      currentCategoryID: null,
      isModalEdit: false,
    });
  };

  hideModalCreate = () => {
    this.setState({
      isShowModalCategory: false,
    });
  };

  openOptionDeleteModal = (selectedID) => {
    this.selectedID = selectedID;
    window.LadiUI.showModal("confirm-category-delete");
  };

  openOptionDeletesModal = () => {
    if (this.state.selectedIDs.length <= 0) {
      window.LadiUI.toastCustom("danger", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
      return;
    }

    window.LadiUI.showModal("confirm-category-deletes");
  };

  copyVariantInfo = (product) => {
    this.selectedProduct = product;
    this.props.getVariantInfos(product.product_id);
  };

  exportProduct = (type) => {
    let ids;
    switch (baseHelper.parseInt(type)) {
      case appConfig.EXPORT_PRODUCT.ALL_PRODUCT:
        ids = appConfig.EXPORT_TYPE.ALL;
        break;
      case appConfig.EXPORT_PRODUCT.ON_PAGE:
        const onPageIDs = map(this.props.product.products, (item) => item.product_id);
        ids = onPageIDs;
        break;
      case appConfig.EXPORT_PRODUCT.SELECTED_PRODUCT:
        if (baseHelper.isEmpty(this.state.selectedIDs)) {
          window.LadiUI.toastCustom("warning", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
          return;
        }
        ids = this.state.selectedIDs;
        break;
    }

    this.props.exportProduct({ ids });
  };

  exportEvent = (type) => {
    let ids;
    switch (baseHelper.parseInt(type)) {
      case appConfig.EXPORT_EVENT.ALL_EVENT:
        ids = appConfig.EXPORT_TYPE.ALL;
        break;
      case appConfig.EXPORT_EVENT.ON_PAGE:
        const onPageIDs = map(this.props.product.products, (item) => item.product_id);
        ids = onPageIDs;
        break;
      case appConfig.EXPORT_EVENT.SELECTED_EVENT:
        if (baseHelper.isEmpty(this.state.selectedIDs)) {
          window.LadiUI.toastCustom("warning", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
          return;
        }
        ids = this.state.selectedIDs;
        break;
    }

    this.props.exportEvent({ ids });
  };

  exportService = (type) => {
    let ids;
    switch (baseHelper.parseInt(type)) {
      case appConfig.EXPORT_SERVICE.ALL_SERVICE:
        ids = appConfig.EXPORT_TYPE.ALL;
        break;
      case appConfig.EXPORT_SERVICE.ON_PAGE:
        const onPageIDs = map(this.props.product.products, (item) => item.product_id);
        ids = onPageIDs;
        break;
      case appConfig.EXPORT_SERVICE.SELECTED_SERVICE:
        if (baseHelper.isEmpty(this.state.selectedIDs)) {
          window.LadiUI.toastCustom("warning", "", this.props.t("COMMON.SELECT_AT_LEAST_ONE_RECORD"));
          return;
        }
        ids = this.state.selectedIDs;
        break;
    }

    this.props.exportService({ ids });
  };

  eventSelectAction = (action) => {
    switch (action) {
      case "DELETE":
        this.openOptionDeletesModal();
        break;
      default:
    }
  };
  clearFilter = (callback) => {
    this.setState(
      {
        tag_ids: [],
        type: "",
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  getNameProductByStatus = (status) => {
    const matched = find(appConfig.PRODUCT_STATUS, (item) => item.CODE == status) || {};
    if (matched) {
      return matched.NAME;
    }
  };

  getNameProductByType = (type) => {
    const matched = find(appConfig.PRODUCT_TYPE, (item) => item.CODE == type) || {};
    if (matched) {
      return matched.NAME;
    }
  };

  handleClickTag = (item, selectedTags) => {
    this.setState(
      {
        tag_ids: [...this.state.tag_ids, item],
      },
      () => {
        this.list();
      }
    );
  };

  handelShowModalCategory = (category) => {
    this.setState({
      isShowModalCategory: true,
      currentCategoryID: category.product_category_id,
      isModalEdit: true,
    });
  };

  handleChangeStatus = (category) => {
    let payloadChangeStatus = {
      product_category_id: category.product_category_id,
      isShow: !category.isShow,
      parent_id: category.parent_id ? category.parent_id : null,
    };

    this.props.update(payloadChangeStatus);
  };

  listChildItemCategory = (childs, padding) => {
    let { t } = this.props;
    return childs.map((category, index) => {
      return (
        <Draggable key={index} draggableId={`${category.product_category_id}_${index}`} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...provided.draggableProps.style,
                userSelect: "none",
                background: snapshot.isDragging ? "#e8f0fe" : "none",
              }}
            >
              <div className="ladiui table-vertical category-item level-one main">
                <div style={{ width: "24px" }}>
                  <input
                    type="checkbox"
                    onClick={(event) => this.checkItem(event, category.product_category_id)}
                    className="ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                  />
                </div>
                <div className="ladiui category-name flex" onClick={() => this.handelShowModalCategory(category)}>
                  <div className="td-draghandle flex" style={{ marginLeft: padding }}>
                    <i className="ldicon-subcategory" />
                    <img
                      src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg"
                      className="icon-draggable"
                      style={{ display: "none" }}
                    />
                  </div>
                  <a className="limit-width-content-product-name" style={{ color: "#202020" }}>
                    {category.name}
                  </a>
                </div>
                <div className="ladiui category-count">{category.product_number}</div>
                <div className="ladiui category-count">
                  <div className="ladiui item-form switch">
                    <label className="ladiui switch m-0">
                      <input type="checkbox" checked={category.isShow} onClick={() => this.handleChangeStatus(category)} />
                      <span className="ladiui slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="ladiui end-table-option">
                  <div className="ladiui btn-group">
                    <div className="ladiui dropdown hide-mt ba-c">
                      <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                        <i className="ladiui icon icon-ldp-dot"></i>
                      </button>
                      <ul className="ladiui dropdown-menu r-0">
                        <li>
                          <a className="ladiui dropdown-item" onClick={() => this.handelShowModalCategory(category)}>
                            {t("ACTIONS.EDIT")}
                          </a>
                        </li>
                        <li>
                          <a className="ladiui dropdown-item" onClick={() => this.openOptionDeleteModal(category.product_category_id)}>
                            {t("ACTIONS.DELETE")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {category.childs && category.childs.length > 0 && (
                <div className="block-list-childs">
                  <Droppable
                    droppableId={`category-child-${category.product_category_id}`}
                    type={`droppableSubItem-${category.product_category_id}`}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="list-category-childs"
                        {...provided.droppableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDragging ? "#e8f0fe" : "none",
                        }}
                      >
                        {this.listChildItemCategory(category.childs, padding + 16)}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              )}
            </div>
          )}
        </Draggable>
      );
    });
  };

  listItemCategory = () => {
    let { t } = this.props;
    let { categories } = this.state;
    {
      return map(categories, (category, index) => {
        return (
          <Draggable key={index} draggableId={"" + index} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style,
                  userSelect: "none",
                  background: snapshot.isDragging ? "#e8f0fe" : "none",
                }}
              >
                <div className="ladiui table-vertical category-item level-one main">
                  <div style={{ width: "24px" }}>
                    <input
                      type="checkbox"
                      onClick={(event) => this.checkItem(event, category.product_category_id)}
                      className="ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                    />
                  </div>
                  <div className="ladiui category-name flex" onClick={() => this.handelShowModalCategory(category)}>
                    <div className="td-draghandle" style={{ marginLeft: 0 }}>
                      <img
                        src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg"
                        className="icon-draggable"
                        style={{ display: "none" }}
                      />
                    </div>
                    <a className="limit-width-content-product-name" style={{ fontWeight: "500", color: "#202020" }}>
                      {category.name}
                    </a>
                  </div>
                  <div className="ladiui category-count">{category.product_number}</div>
                  <div className="ladiui category-count">
                    <div className="ladiui item-form switch">
                      <label className="ladiui switch m-0">
                        <input type="checkbox" checked={category.isShow} onClick={() => this.handleChangeStatus(category)} />
                        <span className="ladiui slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="ladiui end-table-option">
                    <div className="ladiui btn-group">
                      <div className="ladiui dropdown hide-mt ba-c">
                        <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                          <i className="ladiui icon icon-ldp-dot"></i>
                        </button>
                        <ul className="ladiui dropdown-menu r-0">
                          <li>
                            <a className="ladiui dropdown-item" onClick={() => this.handelShowModalCategory(category)}>
                              {t("ACTIONS.EDIT")}
                            </a>
                          </li>
                          <li>
                            <a className="ladiui dropdown-item" onClick={() => this.openOptionDeleteModal(category.product_category_id)}>
                              {t("ACTIONS.DELETE")}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {category.childs && category.childs.length > 0 && (
                  <div className="block-list-childs">
                    <Droppable
                      droppableId={`category-child-${category.product_category_id}`}
                      type={`droppableSubItem-${category.product_category_id}`}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="list-lesson"
                          {...provided.droppableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDragging ? "#e8f0fe" : "none",
                          }}
                        >
                          {this.listChildItemCategory(category.childs, 32)}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                )}
              </div>
            )}
          </Draggable>
        );
      });
    }
  };

  // --------------------------------------------------------Kéo thả danh mục----------------------------------------------------------------------------------

  //hàm giúp thực hiện việc sắp xếp khi kéo thả
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  checkChildItem = (childs, productCategoryId, courseIndex, destinationIndex) => {
    childs.map((item) => {
      if (item.product_category_id == productCategoryId) {
        let findCate = item.childs[courseIndex];

        if (findCate) {
          let payload = {
            product_category_id: findCate.product_category_id,
            position: destinationIndex,
          };
          this.props.updatePosition(payload);
        }
      }
    });
  };

  onDragEndCategory = (result) => {
    let { categories, type } = this.state;
    let payload = {};
    if (!result.destination) {
      return;
    }

    if (result.type == "droppableItem") {
      // const items = this.reorder(categories, result.source.index, result.destination.index);
      let findCategories = categories[result.source.index];

      if (findCategories) {
        payload = {
          product_category_id: findCategories.product_category_id,
          position: result.destination.index,
          type
        };
      }

      this.props.updatePosition(payload);
    } else if (result.type.includes("droppableSubItem")) {
      let productCategoryId = result.type.split("-")[1];
      categories.map((item) => {
        if (item.product_category_id == productCategoryId) {
          let findCate = item.childs[result.source.index];

          if (findCate) {
            let payload = {
              product_category_id: findCate.product_category_id,
              position: result.destination.index,
              type
            };
            this.props.updatePosition(payload);
          }

          return;
        }
        if (item.childs && item.childs.length > 0) {
          this.checkChildItem(item.childs, productCategoryId, result.source.index, result.destination.index);
        }
      });
      // this.props.updatePosition(payload);
    }
  };

  render() {
    const { t } = this.props;
    const { activePage, selectedLimit, searchProductName, userInfo, conditions, categories } = this.state;
    let fromItem = 0;
    let toItem = 0;

    let { product_id: productID, variant_id: variantID } = queryString.parse(this.props.location.search);

    const msgShowItem = ``;

    const isLoadingData = this.props.productCategory.loading && this.props.productCategory.waiting == productCategoryTypes.LIST_CATEGORY;
    const isLoadingDelete =
      this.props.productCategory.loading &&
      includes([productCategoryTypes.DELETE_CATEGORY, productCategoryTypes.DELETE_CATEGORIES], this.props.productCategory.waiting);
    const isSubmitLoading =
      this.props.productCategory.loading &&
      includes([productCategoryTypes.CREATE_CATEGORY, productCategoryTypes.UPDATE_CATEGORY], this.props.productCategory.waiting);
    const isLoadingReload =
      this.props.productCategory.loading && productCategoryTypes.RELOAD_CATEGORY == this.props.productCategory.waiting;

    const allTags = this.props.productTagReducer.allTags || [];

    const listAction = [{ value: "DELETE", name: t("ACTIONS.DELETE") }];

    return (
      <div className="page-content">
        {isLoadingReload && <LoadingScene blur={true} />}
        <div>
          <div className="ladiui-table-actions">
            <div className="ladiui content-main-tools block-filter-condition flex-row">
              {/* <div className="ladiui btn-group block-filter-header">
                <FilterConditionProduct conditionProp={conditions} allTags={allTags} filterActionProp={this.filterActionProp} />
              </div> */}
              <div className="ladiui search-group" style={{ width: "100%" }}>
                <input
                  id="keyword_search"
                  className="ladiui search-field dropdown-toggle form-control search-width"
                  name="searchProductName"
                  placeholder={t("CATEGORY.SEARCH_CATEGORY")}
                  aria-expanded="false"
                  value={searchProductName}
                  onChange={this.onChangeInput}
                  onKeyDown={this.onKeyDownInput}
                />
                <i className="ladiui icon icon-search" onClick={this.list}></i>
              </div>
            </div>
            <div className="action-btn">
              <button
                type="button"
                className="ladiui btn btn-primary btn-lg btn-open-modal"
                data-toggle="modal"
                onClick={this.openModalCreate}
              >
                <img className="ladiui btn-custom-img" src="https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg" alt="" />
                <div className="ladiui btn-custom-text ">{t("CATEGORY.ADD_CATEGORY")}</div>
              </button>
            </div>
          </div>
          {isLoadingData ? (
            <LoadingTable />
          ) : (
            <div>
              <div className="ladi-card ladiui-table-responsive">
                <DragDropContext onDragEnd={this.onDragEndCategory}>
                  <Droppable droppableId="droppable" type="droppableItem">
                    {(provided, snapshot) => (
                      <div
                        className={`ladiui table text-left`}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDragging ? "#e8f0fe" : "none",
                        }}
                      >
                        <div>
                          <div className="ladiui drag-vertical header category">
                            <div className="ladiui col-first" scope="col">
                              <input
                                id="checkAllItem"
                                onClick={this.checkAllItem}
                                type="checkbox"
                                className="ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle"
                              />
                            </div>
                            {this.state.selectedIDs.length <= 0 ? (
                              <div className="ladiui col-name">{t("CATEGORY.CATEGORY_NAME")}</div>
                            ) : (
                              <div className="ladiui col-name relative" style={{ padding: "12px 0" }}>
                                {t("CATEGORY.CATEGORY_NAME")}
                                <LadiDropdownMenu
                                  labelDropdown={"Chọn hành động"}
                                  listProp={listAction}
                                  defaultTextProp={t("ACTIONS.TITLE")}
                                  hideLabel={true}
                                  cbProp={this.eventSelectAction}
                                  classSize="btn-sm "
                                  classWidth="w-180"
                                  classDropdown="w-180 action-record-table"
                                />
                              </div>
                            )}
                            <div className="ladiui col-five">{t("CATEGORY.PRODUCT_QTY")}</div>
                            <div className="ladiui col-five">{t("CATEGORY.DISPLAY_CATEGORY")}</div>
                            <div className="ladiui col-six "></div>
                          </div>
                        </div>
                        <div className="list-categories">
                          {this.listItemCategory()}
                          {provided.placeholder}
                          {categories && categories.length <= 0 && (
                            <div className="text-center">
                              <p className="text-center">{t("NO_DATA")}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          )}
        </div>

        {this.state.isShowModalCategory && (
          <ModalCreateCategory
            showModal={this.state.isShowModalCategory}
            currentCategoryID={this.state.currentCategoryID}
            listSelectCategories={this.state.listSelectCategories || []}
            loading={isSubmitLoading}
            isModalEdit={this.state.isModalEdit}
            reloadListCate={this.list}
            onCancel={this.hideModalCreate}
            selectedType={this.state.type}
          />
        )}

        <ConfirmModal
          id="confirm-category-delete"
          title={t("CATEGORY.MSG_DELETE_CATEGORY_TITLE")}
          content={t("CATEGORY.MSG_DELETE_CATEGORY_CONTENT")}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={() => this.props.delete(this.selectedID)}
          isLoading={isLoadingDelete}
        />

        <ConfirmModal
          id="confirm-category-deletes"
          title={t("CATEGORY.MSG_DELETE_CATEGORY_TITLE")}
          content={t("CATEGORY.MSG_DELETE_CATEGORIES_CONTENT")}
          cancelText={t("ACTIONS.CANCEL")}
          okText={t("ACTIONS.DELETE")}
          onOk={() => this.props.deletes(this.state.selectedIDs)}
          isLoading={isLoadingDelete}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    list: (data) => dispatch(productCategoryActions.list(data)),
    listSelectCategory: (data) => dispatch(productCategoryActions.listSelectCategory(data)),
    update: (data) => dispatch(productCategoryActions.update(data)),
    updatePosition: (data) => dispatch(productCategoryActions.updatePosition(data)),
    delete: (data) => dispatch(productCategoryActions.deleteCategory(data)),
    deletes: (data) => dispatch(productCategoryActions.deleteCategories(data)),
  };
};

const mapStateToProps = (state) => ({
  productCategory: { ...state.productCategory },
  variant: { ...state.variant },
  productTagReducer: { ...state.productTag },
  storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Category));
