export const UPLOAD_PRODUCT_IMAGES = "UPLOAD_PRODUCT_IMAGES";
export const UPLOAD_IMAGES = "UPLOAD_IMAGES";
export const UPLOAD_VARIANT_IMAGE = "UPLOAD_VARIANT_IMAGE";
export const UPLOAD_SWATCH_IMAGE = "UPLOAD_SWATCH_IMAGE";
export const UPLOAD_LOGO_URL = "UPLOAD_LOGO_URL";
export const UPLOAD_FAVICON_URL = "UPLOAD_FAVICON_URL";
export const UPLOAD_COVER_URL = "UPLOAD_COVER_URL";
export const UPLOAD_CHECKOUT_IMAGES = "UPLOAD_CHECKOUT_IMAGES";
export const UPLOAD_FILES = "UPLOAD_FILES";
export const UPLOAD_FILE_DIGITAL = "UPLOAD_FILE_DIGITAL";
export const UPLOAD_FILE_LESSON = "UPLOAD_FILE_LESSON";
export const UPLOAD_CART_IMAGE = "UPLOAD_CART_IMAGE";
export const UPLOAD_CART_LOGO = "UPLOAD_CART_LOGO";
export const UPLOAD_FOOTER_LOGO = "UPLOAD_FOOTER_LOGO";
export const UPLOAD_CART_BACK_GROUND = "UPLOAD_CART_BACK_GROUND";
export const UPLOAD_THUMBNAIL = "UPLOAD_THUMBNAIL";
export const UPLOAD_CATEGORY_IMAGE = "UPLOAD_CATEGORY_IMAGE";
export const BUNNY_CREATE_VIDEO = "BUNNY_CREATE_VIDEO";
