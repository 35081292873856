import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import { I18nContext, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import produce from "immer";
import Input from "../../../components/Input";
import { map, find } from "lodash";
import ModalAddCategory from "./ModalAddCategory";

import fileActions from "../../../redux/futures/file/actions";
import * as fileTypes from "../../../redux/futures/file/types";

import productCategoryActions from "../../../redux/futures/product_category/actions";
import * as productCategoryTypes from "../../../redux/futures/product_category/types";

import baseHelper from "../../../helpers/BaseHelper";

import appConfig from "../../../config/app";

import { SketchPicker } from "react-color";

import Popover from "../../../components/Popover";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const CDN = "https://w.ladicdn.com/";

const PanelSettingMenuBanner = forwardRef((props, ref) => {
  let { isShow, currentDataMenu, handlePreviewPageCourse } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fileReducer = useSelector((state) => state.file);

  const productCategoryReducer = useSelector((state) => state.productCategory);

  const courseReducer = useSelector((state) => state.course);

  const [categories, setCategories] = useState([]);
  const [dataConfig, setDataConfig] = useState({
    background: "#002333",
    text_color: "#FFFFFF",
  });

  const [heroSection, setHeroSection] = useState({
    background: "#002333",
    text_color: "#FFFFFF",
  });

  const [isShowInputAddLink, setIsShowInputAddLink] = useState(false);
  const [indexBanner, setIndexBanner] = useState("");
  const [isShowBlockAddCategory, setIsShowBlockAddCategory] = useState(false);

  const [valueSeachCategory, setValueSearchCategory] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const inputsRef = new Set();
  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        console.log("handle click out side.....................................................");
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useImperativeHandle(ref, () => ({
    validate() {
      return true;
    },
    getData() {
      return {
        background: dataConfig.background,
        text_color: dataConfig.text_color,
        hero_section: {
          background: heroSection.background,
          text_color: heroSection.text_color,
        },
        menu_category_ids:
          selectedCategories && selectedCategories.length > 0 ? selectedCategories.map((item) => item.product_category_id) : [],
      };
    },
  }));

  useEffect(() => {
    if (productCategoryReducer && productCategoryReducer.action == productCategoryTypes.LIST_SELECT_CATEGORY) {
      if (productCategoryReducer.status) {
        if (productCategoryReducer.listSelects && productCategoryReducer.listSelects.length > 0) {
          setCategories(productCategoryReducer.listSelects);
        }
      } else {
        window.LadiUI.showErrorMessage("Thông báo", productCategoryReducer.message, "OK");
      }
    }
  }, [productCategoryReducer]);

  useEffect(() => {
    if (currentDataMenu) {
      setDataConfig({
        background: currentDataMenu.background || "#002333",
        text_color: currentDataMenu.text_color || "#FFFFFF",
      });
      if (currentDataMenu.menu_categories && currentDataMenu.menu_categories.length > 0) {
        setSelectedCategories(currentDataMenu.menu_categories);
      }

      if (currentDataMenu && currentDataMenu.hero_section) {
        setHeroSection({
          background: currentDataMenu.hero_section.background || "#002333",
          text_color: currentDataMenu.hero_section.text_color || "#FFFFFF",
        });
      }
    }
  }, [currentDataMenu]);

  const onChangeInput = (event) => {
    let { value } = event.target;
    setDataConfig(
      produce(dataConfig, (draft) => {
        draft[event.target.name] = value;
      })
    );
  };

  const setColorPicker = (color) => {
    setDataConfig({
      ...dataConfig,
      background: color,
    });
  };

  const renderColorPickerItem = () => {
    return appConfig.LIST_COLOR_PICKER.map((value, index) => {
      return (
        <div
          className={`color-item ${dataConfig.background == value ? "checked" : ""}`}
          key={index}
          style={{ backgroundColor: value }}
          onClick={() => setColorPicker(value)}
        >
          {dataConfig.background == value && <i className="ladiui icon icon-checked"></i>}
        </div>
      );
    });
  };

  const renderHeroSectionColorPickerItem = () => {
    return appConfig.LIST_COLOR_PICKER.map((value, index) => {
      return (
        <div
          className={`color-item ${heroSection.background == value ? "checked" : ""}`}
          key={index}
          style={{ backgroundColor: value }}
          onClick={() => setColorPickerHeroSection(value)}
        >
          {heroSection.background == value && <i className="ladiui icon icon-checked"></i>}
        </div>
      );
    });
  };

  const setColorPickerHeroSection = (color) => {
    setHeroSection({
      ...heroSection,
      background: color,
    });
  };

  const handleSubmitData = () => {
    props.handleSubmitData();
  };

  const onKeyDownInput = (event, _index) => {
    if (event.key === "Enter") {
      let selectedBanners = dataConfig.banners || [];
      selectedBanners.map((item, index) => {
        if (index == _index) {
          item.link = event.target.value;
        }
      });

      setDataConfig({
        ...dataConfig,
        banners: selectedBanners,
      });

      setIsShowInputAddLink(false);
    }
  };

  const handleShowBlockAddCategory = (status) => {
    if (status) {
      let payload = {
        search: {
          type: "Course"
        }
      }
      dispatch(productCategoryActions.listSelectCategory(payload));
    }
    setValueSearchCategory("");
    setIsShowBlockAddCategory(status);
  };

  const handleAddCategories = (data) => {
    setSelectedCategories(data);

    setIsShowBlockAddCategory(false)
  }

  const handleDeleteCategoryItem = (item) => {
    let _selectedDeleteCategories = selectedCategories.filter((_item) => _item.product_category_id != item.product_category_id);

    if (_selectedDeleteCategories) {
      setSelectedCategories(_selectedDeleteCategories);
    }
  };

  // Kéo thả giao diện

  //hàm giúp thực hiện việc sắp xếp khi kéo thả
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndCategory = (result) => {
    if (!result.destination) {
      return;
    }

    const _selectedCategories = reorder(selectedCategories, result.source.index, result.destination.index);

    setSelectedCategories(_selectedCategories);
  };

  const listCategoryItem = () => {
    return selectedCategories.map((item, index) => {
      return (
        <Draggable key={index} draggableId={"" + index} index={index}>
          {(provided, snapshot) => (
            <div
              key={index}
              className="category-list-item"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...provided.draggableProps.style,
                userSelect: "none",
                background: snapshot.isDragging ? "#e8f0fe" : "none",
              }}
            >
              <div className="content-top-item">
                <div className="icon-name flex">
                  <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg" />
                  <span className="ml-16 ladiui-label pb-0 w100">{item.name}</span>
                </div>
                <div className="action-right">
                  <div className="btn-delete cursor-pointer" onClick={() => handleDeleteCategoryItem(item)}>
                    <i className="ladi-icon icon-bin"></i>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Draggable>
      );
    });
  };

  const handleActionPreviewPageCourse = () => {
    handlePreviewPageCourse();
  };

  const handleShowBackgroundPicker = () => {
    document.getElementById("picker-id-background-color").click();
  };

  const handleChangeBackgroundPicker = (color) => {
    setDataConfig({
      ...dataConfig,
      background: color.hex,
    });
  };

  const handleShowHeroSectionPicker = () => {
    document.getElementById("picker-id-hero-section").click();
  };


  const handleChangeBackgroundHeroSectionPicker = (color) => {
    setHeroSection({
      ...heroSection,
      background: color.hex,
    });
  };

  return (
    <div className="page-setting" style={isShow ? { display: "block" } : { display: "none" }}>
      <div className="block-title-action">
        <div className="title">
          <h3>Thiết lập menu và màu sắc</h3>
        </div>
        <div className="actions flex">
          <button className="ladiui btn btn-outline-primary mr-24" onClick={() => handleActionPreviewPageCourse()}>
            Xem trước
          </button>
          <button className="ladiui btn btn-primary" onClick={() => handleSubmitData()}>
            Lưu trang khóa học
          </button>
        </div>
      </div>
      <div className="setting-content-menu-banner config-panel">
        <div>
          <h3 className="ladiui-label pb-0 flex">
            Màu sắc chủ đạo
            <div className='item-tracking' data-tooltip={"Áp dụng với thanh Menu, nút CTA và nút thêm vào giỏ hàng"} data-tooltip-position='right'>
              <span className="flex">
                <i className='ladi-icon icon-c-question ml-8' />
              </span>
            </div>
          </h3>
          <div className="flex">
            <div className="ladiui form-group pr-24" style={{ borderRight: "1px solid #e5e5e5" }}>
              <label className="ladiui-label bold-400">Màu nền</label>
              <div className="content">
                <div className="color-picker flex relative">
                  {renderColorPickerItem()}
                  {!appConfig.LIST_COLOR_PICKER.includes(dataConfig.background) && (
                    <div className={`color-item checked`} style={{ backgroundColor: dataConfig.background }}>
                      {console.log("dataConfig.background", dataConfig.background)}
                      <i className="ladiui icon icon-checked" style={dataConfig.background == "#FFFFFF" || dataConfig.background == "#ffffff" ? { backgroundColor: "#6d6d6d" } : {}}></i>
                    </div>
                  )}
                  <div className="color-item btn-add-background" onClick={() => handleShowBackgroundPicker()}>
                    +
                  </div>
                  <Popover
                    wrapperPopoverProps={{ className: "popover-picker" }}
                    styleCustom={{ bottom: "-150", right: "-220", position: "absolute" }}
                    content={
                      <SketchPicker
                        color={dataConfig.background}
                        onChangeComplete={(color) => handleChangeBackgroundPicker(color)}
                      />
                    }
                  >
                    <div id={`picker-id-background-color`}> </div>
                  </Popover>
                </div>
              </div>
            </div>
            <div className="ladiui form-group ml-24">
              <label className="ladiui-label bold-400">Màu chữ</label>
              <div className="content">
                <div className="flex">
                  <span
                    className="color-item select-text-color white"
                    onClick={() =>
                      setDataConfig({
                        ...dataConfig,
                        text_color: "#FFFFFF",
                      })
                    }
                  >
                    {dataConfig.text_color == "#FFFFFF" && <i className="ladiui icon icon-checked"></i>}
                  </span>
                  <span
                    className="color-item select-text-color black"
                    onClick={() =>
                      setDataConfig({
                        ...dataConfig,
                        text_color: "#000000",
                      })
                    }
                  >
                    {dataConfig.text_color == "#000000" && <i className="ladiui icon icon-checked"></i>}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className="ladiui-label pb-0">Màu nền trang giới thiệu khóa học</h3>
          <div className="flex">
            <div className="ladiui form-group pr-24" style={{ borderRight: "1px solid #e5e5e5" }}>
              <label className="ladiui-label bold-400">Màu nền</label>
              <div className="content">
                <div className="color-picker flex relative">
                  {renderHeroSectionColorPickerItem()}
                  {!appConfig.LIST_COLOR_PICKER.includes(heroSection.background) && (
                    <div className={`color-item checked`} style={{ backgroundColor: heroSection.background }}>
                      <i className="ladiui icon icon-checked" style={heroSection.background == "#FFFFFF" || heroSection.background == "#ffffff" ? { backgroundColor: "#6d6d6d" } : {}}></i>
                    </div>
                  )}
                  <div className="color-item btn-add-background" onClick={() => handleShowHeroSectionPicker()}>
                    +
                  </div>
                  <Popover
                    wrapperPopoverProps={{ className: "popover-picker" }}
                    styleCustom={{ bottom: "-150", right: "-220", position: "absolute" }}
                    content={
                      <SketchPicker
                        color={heroSection.background}
                        onChangeComplete={(color) => handleChangeBackgroundHeroSectionPicker(color)}
                      />
                    }
                  >
                    <div id={`picker-id-hero-section`}> </div>
                  </Popover>
                </div>
              </div>
            </div>
            <div className="ladiui form-group ml-24">
              <label className="ladiui-label bold-400">Màu chữ</label>
              <div className="content">
                <div className="flex">
                  <span
                    className="color-item select-text-color white"
                    onClick={() =>
                      setHeroSection({
                        ...heroSection,
                        text_color: "#FFFFFF",
                      })
                    }
                  >
                    {heroSection.text_color == "#FFFFFF" && <i className="ladiui icon icon-checked"></i>}
                  </span>
                  <span
                    className="color-item select-text-color black"
                    onClick={() =>
                      setHeroSection({
                        ...heroSection,
                        text_color: "#000000",
                      })
                    }
                  >
                    {heroSection.text_color == "#000000" && <i className="ladiui icon icon-checked"></i>}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block-category-top-menu">
          <h3>Danh mục trên menu</h3>
          <span className="sub-title">Chọn danh mục sẽ hiển thị trên thanh menu khóa học của bạn</span>
          <div className="block-list-category">
            {selectedCategories && selectedCategories.length > 0 && (
              <DragDropContext onDragEnd={onDragEndCategory}>
                <Droppable droppableId="droppable" type="droppableItem">
                  {(provided, snapshot) => (
                    <div
                      className="block-list-category"
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDragging ? "#e8f0fe" : "none",
                      }}
                    >
                      {listCategoryItem()}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>

          <div className="ladiui border-none btn-add-option mb-12 mt-24">
            <img className="mr-8" src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg" />
            <div className="btn-add-product" onClick={() => handleShowBlockAddCategory(true)}>
              Thêm danh mục
            </div>
          </div>
        </div>
      </div>
      {isShowBlockAddCategory && (
        <ModalAddCategory
          onClose={() => handleShowBlockAddCategory(false)}
          isShow={isShowBlockAddCategory}
          dataSelectCategories={categories || []}
          selectedCategories={selectedCategories || []}
          handleAddCategories={handleAddCategories}
          innerStyle={{ display: "none" }}
        />
      )}
    </div>
  );
});
PanelSettingMenuBanner.propTypes = {};

export default PanelSettingMenuBanner;
