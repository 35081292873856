import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import baseHelper from '../../../../helpers/BaseHelper';
import appConfig from '../../../../config/app';

import Tag from '../../../../components/Tag';
import NumberInput from '../../../../components/NumberInput';
import Input from '../../../../components/Input';

import * as productCategoryTypes from '../../../../redux/futures/product_category/types';
import productCategoryActions from '../../../../redux/futures/product_category/actions';

import actionsProductTag from '../../../../redux/futures/product_tag/actions';
import actionsProduct from '../../../../redux/futures/product/actions';
import * as productTypeRedux from '../../../../redux/futures/product/types';
import actionsStore from '../../../../redux/futures/store/actions';
import { Editor } from '@tinymce/tinymce-react';
import Dropdown from '../../../../components/Dropdown';
import SelectManyDropdown from '../../../../components/SelectManyDropdown';

// function useQuery() {
//     const { search } = useLocation();

//     return React.useMemo(() => new URLSearchParams(search), [search]);
// }

function ServiceCreate(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryURL = baseHelper.useQuery();
    const { t, i18n } = props;

    const tagRef = useRef();
    const productNameRef = useRef();

    const productCategoryReducer = useSelector((state) => state.productCategory);

    const [content, setContent] = useState('');
    const [isLoading, setLoading] = useState(false);

    const [product, setProduct] = useState({
        name: '',
        external_link: '',
        ticket_creation_form: 0,
    });

    const [valid, setValid] = useState({
        name: true,
    });

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const listAllTags = useSelector((state) => {
        if (state.productTag.action === 'ASYNC_END' && state.productTag.allTags) return state.productTag.allTags;
        else return [];
    });

    const storeInfo = useSelector((state) => {
        if (state.store.action === 'ASYNC_END' && state.store.userInfo) return state.store.userInfo;
        else return [];
    });
    // const productInfo = useSelector(state => {
    //     return state.product.product;
    // })

    const productState = useSelector((state) => state.product);

    useEffect(() => {
        let payload = {
            search: {
                type: "Product"
            }
        }
        dispatch(actionsProductTag.listAll());
        dispatch(productCategoryActions.listSelectCategory(payload));
    }, []);

    useEffect(() => {
        if (!productState.loading) setLoading(false);
        const productInfo = productState.product;

        if (productState.action === 'CREATE_PRODUCT' && productInfo && productInfo.product_id) {
            if (productState.product.type == appConfig.PRODUCT_TYPE.DIGITAL.CODE) {
                history.push(`/digital/update/${productInfo.product_id}`);
            }
            if (productState.product.type == appConfig.PRODUCT_TYPE.SERVICE.CODE) {
                history.push(`/services/update/${productInfo.product_id}`);
            }
        }

        if (productState.action === 'CREATE_PRODUCT' && !productState.status) {
            window.LadiUI.toastCustom('danger', '', productState.message);
        }
    }, [productState]);

    useEffect(() => {
        if (productCategoryReducer.action == productCategoryTypes.LIST_SELECT_CATEGORY) {
            if (productCategoryReducer.status) {
                if (productCategoryReducer.listSelects && productCategoryReducer.listSelects.length > 0) {
                    let _data = [];
                    productCategoryReducer.listSelects.map((item, index) => {
                        _data.push({
                            level: item.level,
                            name: item.name,
                            value: item.product_category_id,
                        });
                    });

                    setCategories(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
    }, [productCategoryReducer]);

    // useEffect(() => {
    //     if (productInfo && productState.product_id) {
    //     }
    // }, [productInfo])

    const handleEditorChange = (value) => {
        setContent(value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name' && value) {
            setValid({
                name: true,
            });
        }
        setProduct((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const selectTicketCreationForm = (selectedItem) => {
        setProduct({
            ...product,
            ticket_creation_form: selectedItem.CODE,
        });

        // this.props.ticketCreationFrom(selectedItem.CODE);
    };

    const submit = () => {
        let errors = [];
        if (productNameRef) {
            const error = productNameRef.current.validate();
            if (error.length > 0) {
                errors = [...errors, ...error];
            }
        }

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('warning', '', errorMessage);
            return;
        }

        let type = queryURL.get('type');
        if (!type) {
            type = appConfig.PRODUCT_TYPE.SERVICE.CODE;
        }

        const products = {
            name: product.name,
            alias_name: baseHelper.getAliasName(product.name),
            content: content,
            quantity: 0,
            timezone: 'Asia/Bangkok',
            location: '',
            host_name: '',
            contact_phone: '',
            type: type,
            checkout_config_id: 30,
            status: 'Active',
            external_link: product.external_link,
            ticket_creation_form: product.ticket_creation_form,
            tags: tagRef.current.getData(),
            options: [],
            variants: [
                {
                    product_id: '',
                    product_variant_id: '',
                    name: product.name,
                    content: content,
                    quantity: 0,
                    timezone: 'Asia/Bangkok',
                    location: '',
                    content: '',
                    host_name: '',
                    contact_phone: '',
                    type: type,
                    inventory_checked: 1,
                    position: 0,
                },
            ],
            images: [],
            custom_fields: [],
            product_up_sells: [],
            select_many_service: false,
            product_category_ids: selectedCategories || [],
        };

        setLoading(true);
        dispatch(actionsProduct.create(products));
    };

    const getTitleFormByType = () => {
        let labelName = '';
        let type = queryURL.get('type');

        switch (type) {
            case 'Service':
                labelName = t('PRODUCTS.LB_ADD_VARIANT_PACKAGE');
                break;
            case 'Digital':
                labelName = t('PRODUCTS.LB_ADD_PRODUCT_DIGITAL');
                break;
            default:
        }

        return labelName;
    };

    const isProductDigital = () => {
        let isProductDigital = false;
        let type = queryURL.get('type');

        // if (type == appConfig.PRODUCT_TYPE.SERVICE.CODE || type == appConfig.PRODUCT_TYPE.DIGITAL.CODE) {
        //   isProductDigital = true;
        // }

        if (type == appConfig.PRODUCT_TYPE.DIGITAL.CODE) {
            isProductDigital = true;
        }

        return isProductDigital;
    };

    const handleRefreshDataCategories = () => {
        let payload = {
            search: {
                type: "Product"
            }
        }
        dispatch(productCategoryActions.listSelectCategory(payload));
    };

    return (
        <div className='product-create'>
            <div className='ladiui title-form text-center'>
                <label className='ladiui title-text-form-lever1 text-left'>{getTitleFormByType()}</label>
            </div>
            <div className='ladiui form-group'>
                <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                    {`Tên ${baseHelper.getLabelNameByType(queryURL.get('type'))}`}
                </label>
                <Input
                    className={`ladiui form-control input-price ${valid.name ? '' : 'valid-input-error'}`}
                    ref={productNameRef}
                    name='name'
                    validationName={`Tên ${baseHelper.getLabelNameByType(queryURL.get('type'))}`}
                    placeholder={`Nhập tên ${baseHelper.getLabelNameByType(queryURL.get('type'))}`}
                    value={product.name}
                    onChange={handleChange}
                    validations={{ isRequired: true }}
                />
            </div>

            <div className='ladiui form-group'>
                <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                    {t('PRODUCT_SERVICES.PATH_DETAIL')}
                </label>
                <Input
                    className={`ladiui form-control input-price`}
                    // ref={productNameRef}
                    name='external_link'
                    validationName={'Tên dịch vụ'}
                    placeholder='https://abc.com'
                    value={product.external_link}
                    onChange={handleChange}
                    validations={{ isURL: true }}
                />
            </div>

            <div className='ladiui form-group'>
                <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                    Chọn danh mục
                </label>
                <SelectManyDropdown
                    data={categories}
                    _key='name'
                    _value='value'
                    pickTitle={'Tiêu đề'}
                    placeHolder={'Chọn danh mục'}
                    onSubmit={(items) => {
                        setSelectedCategories(items);
                    }}
                    innerClassName='w100'
                    styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                    buttonStyle={{
                        width: '100%',
                        paddingRight: '30px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: ' -webkit-box',
                        webkitLineClamp: 1,
                        webkitBoxOrient: 'vertical',
                        textAlign: 'left',
                    }}
                    selectedItems={selectedCategories || []}
                    isShowBlockInputSearch={true}
                    isCreateCategory={true}
                    handleRefreshData={handleRefreshDataCategories}
                />
            </div>

            <div className='ladiui form-group'>
                <Tag
                    tabIndex={6}
                    ref={tagRef}
                    id='product-tag'
                    allTags={listAllTags}
                // selectedTags={tags}
                />
            </div>

            {/* {!isProductDigital() && ( */}
            <div className='date-item mt-24'>
                <label className='ladiui-label'>{'Hình thức tạo mã'}</label>

                <Dropdown
                    data={appConfig.SERVICE_TICKET_CREATION_FORM}
                    onSelectItem={selectTicketCreationForm}
                    currentKey={product.ticket_creation_form}
                    _key={'CODE'}
                    _value={'NAME'}
                />
            </div>
            {/* )} */}

            <div className='ladiui form-group'>
                <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                    {t('PRODUCT_SERVICES.DESCRIPTION')}{' '}
                </label>
                <Editor
                    value={content}
                    init={{
                        height: 250,
                        plugins: 'preview powerpaste autosave fullscreen lists checklist',
                        toolbar:
                            'bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat',
                        toolbar_drawer: 'sliding',
                        language_url: '../../../../language/tinymce-vi',
                        valid_elements: '*[*]',
                    }}
                    onEditorChange={handleEditorChange}
                />
            </div>

            <div className='ladiui modal-footer pr-0'>
                <button onClick={() => history.push('/products')} className='ladiui btn btn-secondary btn-sm'>
                    {' '}
                    {t('ACTIONS.REMOVE')}
                </button>
                <button onClick={submit} className={`ladiui btn btn-primary btn-sm ${isLoading ? 'loader' : ''}`} disabled={isLoading}>
                    {' '}
                    {t('ACTIONS.NEXT')}
                </button>
            </div>
        </div>
    );
}

export default withTranslation()(ServiceCreate);
