export const LIST_CUSTOMER = "LIST_CUSTOMER";
export const SHOW_CUSTOMER = "SHOW_CUSTOMER";
export const DELETE_CUSTOMERS = "DELETE_CUSTOMERS";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const SEARCH_CUSTOMER = "SEARCH_CUSTOMER";
export const RELOAD_CUSTOMER = "RELOAD_CUSTOMER";
export const APPLY_DEFAULT_ADDRESS_CUSTOMER = "APPLY_DEFAULT_ADDRESS_CUSTOMER";
export const EXPORT_CUSTOMER = "EXPORT_CUSTOMER";
export const IMPORT_CUSTOMER = "IMPORT_CUSTOMER";
export const UPDATE_CUSTOM_FIELD_BY_CUSTOMER = "UPDATE_CUSTOM_FIELD_BY_CUSTOMER";
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
