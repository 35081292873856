import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { I18nContext, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import produce from 'immer';
import fileActions from '../redux/futures/file/actions';
import * as fileTypeAction from '../redux/futures/file/types';

import courseAction from '../redux/futures/course/actions';
import * as courseTypes from '../redux/futures/course/types';

import productVideoAction from '../redux/futures/product_video/actions';
import * as productVideoTypes from '../redux/futures/product_video/types';

import Input from './Input';
import baseHelper from '../helpers/BaseHelper';
import { Editor } from '@tinymce/tinymce-react';
import ConfirmModal from './ConfirmModal';
import ModalAddVideo from './ModalAddVideo';

import ModalPreviewVideoBunny from './ModalPreviewVideoBunny';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { map, find } from 'lodash';
import SelectOption from './SelectOption';
import appConfig from '../config/app';

import LoadingScene from './LoadingScene';

import moment from 'moment';

import DatePicker from '../components/DatePicker/CustomDatePicker';

import { compact } from 'lodash';

const CDN = 'https://w.ladicdn.com/';

const PanelProductContentCourse = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const fileReducer = useSelector((state) => state.file);

    const courseReducer = useSelector((state) => state.course);

    const productVideoReducer = useSelector((state) => state.productVideo);

    const [valueSearchVideo, setValueSearchVideo] = useState();

    // const [urlFavicon, setUrlFavicon] = useState(props.favicon);
    const [subjectContentVideo, setSubjectContentVideo] = useState({
        name: '',
        url_thumbnail: '',
        url_video: '',
        description: '',
        is_trial: false,
        lesson_time: 0,
        documents: [],
        video_platform: 'Youtube',
        release_date: null,
    });

    const [subjectContentPost, setSubjectContentPost] = useState({
        name: '',
        post_content: '',
        description: '',
        is_trial: false,
        documents: [],
        lesson_time: 0,
        release_date: null,
    });

    const [documentLink, setDocumentLink] = useState({
        name: '',
        src: '',
    });
    const [listChapters, setListChapters] = useState(produce(props.courseModules, (draft) => { }));
    const [fileType, setFileType] = useState(0);

    const [isShowBlockAddContentLesson, setIsShowBlockAddContentLesson] = useState(false);
    const [isShowBlockAddVideo, setIsShowBlockAddVideo] = useState(false);
    const [isShowBlockAddPost, setIsShowBlockAddPost] = useState(false);
    const [isEditTitleChapter, setIsEditTitleChapter] = useState(false);
    const [selectedCourseModuleID, setSelectedCourseModuleID] = useState('');
    const [selectedDeleteCourseLessonId, setSelectedDeleteCourseLessonId] = useState(null);
    const [selectedDeleteCourseModuleId, setSelectedDeleteCourseModuleId] = useState(null);

    const [lessonType, setLessonType] = useState('');

    const [productInfo, setProductInfo] = useState(produce(props.productInfo, (draft) => { }));

    const [chapterEditID, setChapterEditID] = useState('');

    const [defaultContentLesson, setDefaultContentLesson] = useState('');

    const [selectedLessonEdit, setSelectedLessonEdit] = useState('');
    const [selectedTypeUploadVideo, setSelectedTypeUploadVideo] = useState(2);

    const [isShowModalAddVideo, setIsShowModalAddVideo] = useState(false);

    const [listVideos, setListVideos] = useState([]);

    const [isLoadingUploadBunny, setIsLoadingUploadBunny] = useState(false);
    const [isLoadingPreviewBunny, setIsLoadingPreviewBunny] = useState(false);

    const [isShowModalPreviewVideo, setIsShowModalPreviewVideo] = useState(false);

    const [isShowModalActiveBunny, setIsShowModalActiveBunny] = useState(false);

    const [currentStoreInfo, setCurrentStoreInfo] = useState(null);

    const [selectedVideoUploadId, setSelectedVideoUploadId] = useState(null);

    const inputsRef = new Set();
    const DocumentInputsRef = new Set();

    useEffect(() => {
        if (props.productInfo) {
            setProductInfo(produce(props.productInfo, (draft) => { }));
        }
    }, [props.productInfo]);

    useEffect(() => {
        if (props.courseModules) {
            setListChapters(produce(props.courseModules, (draft) => { }));
        }
    }, [props.courseModules]);

    useEffect(() => {
        if (props.currentStore) {
            setCurrentStoreInfo(produce(props.currentStore, (draft) => { }));
        }
    }, [props.currentStore]);

    useEffect(() => {
        if (fileReducer.action == fileTypeAction.UPLOAD_THUMBNAIL) {
            if (fileReducer.status) {
                if (fileReducer.image_url) {
                    setSubjectContentVideo({
                        ...subjectContentVideo,
                        url_thumbnail: fileReducer.image_url,
                    });
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
        if (fileReducer.action == fileTypeAction.UPLOAD_FILE_LESSON) {
            if (fileReducer.status) {
                if (fileReducer.file_urls && fileReducer.file_urls.length > 0) {
                    let listPaths = [];
                    fileReducer.file_urls.map((item, index) => {
                        listPaths.push({
                            src: item.url,
                            name: item.name,
                            size: item.size,
                        });
                    });

                    if (lessonType == 'Video') {
                        let _documents = [...subjectContentVideo.documents, ...listPaths];

                        if (subjectContentVideo.course_lesson_id) {
                            let payload = {
                                course_lesson_id: subjectContentVideo.course_lesson_id,
                                documents: listPaths,
                            };

                            dispatch(courseAction.documentCreate(payload));
                        } else {
                            setSubjectContentVideo({
                                ...subjectContentVideo,
                                documents: _documents,
                            });
                        }
                    } else {
                        let _documents = [...subjectContentPost.documents, ...listPaths];
                        if (subjectContentPost.course_lesson_id) {
                            let payload = {
                                course_lesson_id: subjectContentPost.course_lesson_id,
                                documents: listPaths,
                            };

                            dispatch(courseAction.documentCreate(payload));
                        } else {
                            setSubjectContentPost({
                                ...subjectContentPost,
                                documents: _documents,
                            });
                        }
                    }
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
        if (fileReducer.action == fileTypeAction.BUNNY_CREATE_VIDEO) {
            if (fileReducer.status) {
                let sizeVideo = fileReducer.videoSize;
                let oneMB = 1024 * 1024;
                setSubjectContentVideo({
                    ...subjectContentVideo,
                    url_video: fileReducer.videoLink || '',
                });

                setSelectedVideoUploadId(fileReducer.productVideoId || null);
                setIsLoadingUploadBunny(false);
                setIsLoadingPreviewBunny(true);

                if (sizeVideo) {
                    if (sizeVideo < oneMB * 5) {
                        setTimeout(() => {
                            setIsLoadingPreviewBunny(false);
                        }, 15000);
                    } else if (sizeVideo >= oneMB * 5 && sizeVideo <= oneMB * 10) {
                        setTimeout(() => {
                            setIsLoadingPreviewBunny(false);
                        }, 35000);
                    } else {
                        setTimeout(() => {
                            setIsLoadingPreviewBunny(false);
                        }, 50000);
                    }
                }

                setTimeout(() => {
                    setIsLoadingPreviewBunny(false);
                }, 15000);
            } else {
                setIsLoadingUploadBunny(false)
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
    }, [fileReducer]);

    useEffect(() => {
        if (productVideoReducer.action == productVideoTypes.PRODUCT_LIST_VIDEO) {
            if (productVideoReducer.status) {
                if (productVideoReducer && productVideoReducer.videos.length > 0) {
                    setListVideos([...productVideoReducer.videos]);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productVideoReducer.message, 'OK');
            }
        }
    }, [productVideoReducer]);

    useEffect(() => {
        if (courseReducer.action == courseTypes.COURSE_MODULE_CREATE) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                dispatch(courseAction.moduleList({ product_id: productInfo.product_id }));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.COURSE_MODULE_LIST) {
            if (courseReducer.status) {
                if (courseReducer.courses) {
                    setListChapters(courseReducer.courses);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.COURSE_MODULE_DELETE) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                window.LadiUI.closeModal('confirm-delete-chapter');
                dispatch(courseAction.moduleList({ product_id: productInfo.product_id }));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.COURSE_MODULE_UPDATE) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                setIsEditTitleChapter(false);
                dispatch(courseAction.moduleList({ product_id: productInfo.product_id }));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.COURSE_LESSON_CREATE) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                if (courseReducer.selectedCourseLessonId) {
                    if (isShowBlockAddPost) {
                        let _subjectContentPost = { ...subjectContentPost };
                        _subjectContentPost.course_lesson_id = courseReducer.selectedCourseLessonId;
                        setSubjectContentPost(_subjectContentPost);
                        setDefaultContentLesson(JSON.stringify(_subjectContentPost));
                    }
                    if (isShowBlockAddVideo) {
                        let _subjectContentVideo = { ...subjectContentVideo };
                        _subjectContentVideo.course_lesson_id = courseReducer.selectedCourseLessonId;
                        setSubjectContentVideo(_subjectContentVideo);
                        setDefaultContentLesson(JSON.stringify(_subjectContentVideo));
                    }
                }
                dispatch(courseAction.moduleList({ product_id: productInfo.product_id }));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.COURSE_LESSON_DELETE) {
            if (courseReducer.status) {
                if (isShowBlockAddPost) {
                    if (selectedDeleteCourseLessonId == subjectContentPost.course_lesson_id) {
                        setIsShowBlockAddPost(false);
                    }
                }

                if (isShowBlockAddVideo) {
                    if (selectedDeleteCourseLessonId == subjectContentVideo.course_lesson_id) {
                        setIsShowBlockAddVideo(false);
                    }
                }

                window.LadiUI.toastCustom('success', '', courseReducer.message);
                window.LadiUI.closeModal('confirm-delete-lesson');
                dispatch(courseAction.moduleList({ product_id: productInfo.product_id }));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.COURSE_LESSON_UPDATE) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                dispatch(courseAction.moduleList({ product_id: productInfo.product_id }));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.COURSE_DOCUMENT_DELETE) {
            if (courseReducer.status) {
                if (lessonType == 'Video') {
                    let _documents = [...subjectContentVideo.documents];

                    _documents = _documents.filter((item) => item.course_document_id !== courseReducer.courseDocumentId);

                    setSubjectContentVideo({
                        ...subjectContentVideo,
                        documents: _documents,
                    });
                    setDefaultContentLesson(
                        JSON.stringify({
                            ...subjectContentVideo,
                            documents: _documents,
                        })
                    );
                } else {
                    let _documents = [...subjectContentPost.documents];
                    _documents = _documents.filter((item) => item.course_document_id !== courseReducer.courseDocumentId);

                    setSubjectContentPost({
                        ...subjectContentPost,
                        documents: _documents,
                    });
                    setDefaultContentLesson(
                        JSON.stringify({
                            ...subjectContentPost,
                            documents: _documents,
                        })
                    );
                }
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                // dispatch(courseAction.moduleList({ product_id: productInfo.product_id }));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.REPLICATION_MODULE) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                dispatch(courseAction.moduleList({ product_id: productInfo.product_id }));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.REPLICATION_LESSON) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                dispatch(courseAction.moduleList({ product_id: productInfo.product_id }));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.MODULE_UPDATE_POSITION) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                dispatch(courseAction.moduleList({ product_id: productInfo.product_id }));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.LESSON_UPDATE_POSITION) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                dispatch(courseAction.moduleList({ product_id: productInfo.product_id }));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.COURSE_DOCUMENT_CREATE) {
            if (courseReducer.status) {
                if (courseReducer.document && courseReducer.document.length > 0) {
                    if (lessonType == 'Video') {
                        let _documents = [...subjectContentVideo.documents, ...courseReducer.document];

                        setSubjectContentVideo({
                            ...subjectContentVideo,
                            documents: _documents,
                        });
                        setDefaultContentLesson(
                            JSON.stringify({
                                ...subjectContentVideo,
                                documents: _documents,
                            })
                        );
                    } else {
                        let _documents = [...subjectContentPost.documents, ...courseReducer.document];

                        setSubjectContentPost({
                            ...subjectContentPost,
                            documents: _documents,
                        });

                        setDefaultContentLesson(
                            JSON.stringify({
                                ...subjectContentPost,
                                documents: _documents,
                            })
                        );
                    }
                }
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                // dispatch(courseAction.moduleList({ product_id: productInfo.product_id }));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
    }, [courseReducer]);

    useEffect(() => {
        let payload = {
            search: {
                keyword: valueSearchVideo,
                product_id: productInfo.product_id ? productInfo.product_id : null,
            },
            page: 1,
        };

        dispatch(productVideoAction.listVideo(payload));
    }, [valueSearchVideo]);

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {};
        },
    }));

    const handleAddChapter = () => {
        let chapterDefault = {
            product_id: productInfo.product_id,
            position: listChapters.length + 1,
            name: `Chương #${listChapters.length + 1}`,
        };
        dispatch(courseAction.moduleCreate(chapterDefault));
    };

    const handleDeleteChapter = (courseModuleID) => {
        if (courseModuleID) {
            setSelectedDeleteCourseModuleId(courseModuleID);
            // dispatch(courseAction.moduleDelete({ course_module_id: courseModuleID }));
            window.LadiUI.showModal('confirm-delete-chapter');
        }
    };

    const deleteChapter = () => {
        dispatch(courseAction.moduleDelete({ course_module_id: selectedDeleteCourseModuleId }));
    };

    const handleSetBlockAddContent = (item) => {
        setSelectedCourseModuleID(item.course_module_id);
        if (isShowBlockAddVideo || isShowBlockAddPost) {
            if (lessonType == 'Video') {
                if (defaultContentLesson != JSON.stringify(subjectContentVideo)) {
                    window.LadiUI.showModal('confirm-save-data');
                } else {
                    setIsShowBlockAddContentLesson(true);
                    setIsShowBlockAddVideo(false);
                }
            }
            if (lessonType == 'Post') {
                if (defaultContentLesson != JSON.stringify(subjectContentPost)) {
                    window.LadiUI.showModal('confirm-save-data');
                } else {
                    setIsShowBlockAddContentLesson(true);
                    setIsShowBlockAddPost(false);
                }
            }
        } else {
            setIsShowBlockAddContentLesson(true);
        }
    };

    const handleEditChapter = (item) => {
        setIsEditTitleChapter(true);
        setChapterEditID(item.course_module_id);
    };

    const onChangeInputTitleChapter = (event, chapter) => {
        let { value } = event.target;
        let chapterEdit = produce(listChapters, (draft) => {
            map(draft, (_draft) => {
                if (_draft.course_module_id == chapter.course_module_id) {
                    _draft.name = value;
                }
            });
        });

        setListChapters(chapterEdit);
    };

    const handleEditTitleChapter = (chapter) => {
        let dataUpdate = {
            course_module_id: chapter.course_module_id,
            name: chapter.name,
            position: chapter.position,
        };

        dispatch(courseAction.moduleUpdate(dataUpdate));
    };

    const handleDeleteLesson = (lessonID) => {
        if (lessonID) {
            setSelectedDeleteCourseLessonId(lessonID);
            // dispatch(courseAction.lessonDelete({ course_lesson_id: lessonID }));
            window.LadiUI.showModal('confirm-delete-lesson');
        }
    };

    const deleteLesson = () => {
        dispatch(courseAction.lessonDelete({ course_lesson_id: selectedDeleteCourseLessonId }));
    };

    const handleEditLesson = (lesson) => {
        setDocumentLink({
            name: "",
            src: ""
        })
        setSelectedCourseModuleID(lesson.course_module_id);
        setSelectedLessonEdit({
            ...lesson,
            release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
        });

        if (isShowBlockAddVideo) {
            if (defaultContentLesson !== JSON.stringify(subjectContentVideo)) {
                window.LadiUI.showModal('confirm-save-data-edit');
            } else {
                if (lesson.lesson_type == 'Video') {
                    setSubjectContentVideo({
                        ...lesson,
                        release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
                    });
                    setIsShowBlockAddVideo(true);
                    setSubjectContentPost({});
                    setIsShowBlockAddPost(false);
                    setLessonType(lesson.lesson_type);

                    setDefaultContentLesson(
                        JSON.stringify({
                            ...lesson,
                            release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
                        })
                    );
                    setIsShowBlockAddContentLesson(false);
                } else {
                    setSubjectContentPost({
                        ...lesson,
                        release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
                    });
                    setIsShowBlockAddPost(true);
                    setSubjectContentVideo({});
                    setIsShowBlockAddVideo(false);
                    setLessonType(lesson.lesson_type);

                    setDefaultContentLesson(
                        JSON.stringify({
                            ...lesson,
                            release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
                        })
                    );
                    setIsShowBlockAddContentLesson(false);
                }
            }
        } else if (isShowBlockAddPost) {
            if (defaultContentLesson != JSON.stringify(subjectContentPost)) {
                window.LadiUI.showModal('confirm-save-data-edit');
            } else {
                if (lesson.lesson_type == 'Video') {
                    setSubjectContentVideo({
                        ...lesson,
                        release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
                    });
                    setIsShowBlockAddVideo(true);
                    setSubjectContentPost({});
                    setIsShowBlockAddPost(false);
                    setLessonType(lesson.lesson_type);

                    setDefaultContentLesson(
                        JSON.stringify({
                            ...lesson,
                            release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
                        })
                    );
                    setIsShowBlockAddContentLesson(false);
                } else {
                    setSubjectContentPost({
                        ...lesson,
                        release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
                    });
                    setIsShowBlockAddPost(true);
                    setSubjectContentVideo({});
                    setIsShowBlockAddVideo(false);
                    setLessonType(lesson.lesson_type);

                    setDefaultContentLesson(
                        JSON.stringify({
                            ...lesson,
                            release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
                        })
                    );
                    setIsShowBlockAddContentLesson(false);
                }
            }
        } else {
            if (lesson.lesson_type == 'Video') {
                setSubjectContentVideo({
                    ...lesson,
                    release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
                });
                setIsShowBlockAddVideo(true);
                setSubjectContentPost({});
                setIsShowBlockAddPost(false);
                setLessonType('Video');

                setDefaultContentLesson(
                    JSON.stringify({
                        ...lesson,
                        release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
                    })
                );
                setIsShowBlockAddContentLesson(false);
            } else {
                setSubjectContentPost({
                    ...lesson,
                    release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
                });
                setIsShowBlockAddPost(true);
                setSubjectContentVideo({});
                setIsShowBlockAddVideo(false);
                setLessonType('Post');

                setDefaultContentLesson(
                    JSON.stringify({
                        ...lesson,
                        release_date: lesson.release_date ? baseHelper.momentToDate(moment(lesson.release_date)) : null,
                    })
                );
                setIsShowBlockAddContentLesson(false);
            }
        }
    };

    const getStyleSelectedLesson = (item) => {
        let className = '';
        if (isShowBlockAddPost) {
            if (subjectContentPost.course_lesson_id && subjectContentPost.course_lesson_id == item.course_lesson_id) {
                className = 'selected';
            }
        }
        if (isShowBlockAddVideo) {
            if (subjectContentVideo.course_lesson_id && subjectContentVideo.course_lesson_id == item.course_lesson_id) {
                className = 'selected';
            }
        }

        return className;
    };

    const fetchListLesson = (lessons) => {
        if (lessons && lessons.length > 0) {
            return lessons.map((item, index) => {
                return (
                    <Draggable key={index} draggableId={`${item.course_lesson_id}_${index}`} index={index}>
                        {(provided, snapshot) => (
                            <div
                                className={`lesson-item ${getStyleSelectedLesson(item)}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            // style={getStyleSelectedLesson(item)}
                            >
                                <div className='lesson-name flex'>
                                    <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg' className='mr-8' alt='' />
                                    <i className={`mr-8 ${item.lesson_type == 'Video' ? 'ldicon-lesson-video' : 'ldicon-lesson-post'}`}></i>
                                    <span className='cursor-pointer' onClick={() => handleEditLesson(item)}>
                                        {item.name}
                                    </span>
                                </div>
                                <div className='action flex'>
                                    {item.is_trial == 1 && (
                                        <span className='mr-18' style={{ color: '#1C00C2' }}>
                                            Miễn phí
                                        </span>
                                    )}
                                    <div className='ladiui btn-group'>
                                        <div className='ladiui dropdown hide-mt ba-c'>
                                            <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                <i className='ladiui icon icon-ldp-dot'></i>
                                            </button>
                                            <ul className='ladiui dropdown-menu r-0'>
                                                <li onClick={() => handleEditLesson(item)}>
                                                    <a className='ladiui dropdown-item'>Chỉnh sửa</a>
                                                </li>
                                                <li onClick={() => handleReplicationLesson(item.course_lesson_id)}>
                                                    <a className='ladiui dropdown-item'>{t('ACTIONS.REPLICATION')}</a>
                                                </li>
                                                <li onClick={() => handleDeleteLesson(item.course_lesson_id)}>
                                                    <a className='ladiui dropdown-item'>{t('ACTIONS.DELETE')}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Draggable>
                );
            });
        }
    };

    const handleReplicationModule = (courseModuleID) => {
        if (courseModuleID) dispatch(courseAction.replicationModule({ course_module_id: courseModuleID }));
    };

    const handleReplicationLesson = (courseLessonID) => {
        if (courseLessonID) dispatch(courseAction.replicationLesson({ course_lesson_id: courseLessonID }));
    };

    const fetchListChapter = () => {
        if (listChapters && listChapters.length > 0) {
            return listChapters.map((item, index) => {
                return (
                    <Draggable key={index} draggableId={'' + index} index={index}>
                        {(provided, snapshot) => (
                            <div
                                className='chapter-list-item'
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                    ...provided.draggableProps.style,
                                    userSelect: 'none',
                                    background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                }}
                            >
                                <div className='title-chapter'>
                                    <div className='icon-title flex'>
                                        <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg' className='mr-8' alt='' />
                                        <i className='ldicon-stack mr-8'></i>
                                        {isEditTitleChapter && chapterEditID == item.course_module_id ? (
                                            <>
                                                <Input
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    name='name'
                                                    placeholder='Nhập tên chương'
                                                    value={item.name}
                                                    onChange={(event) => onChangeInputTitleChapter(event, item)}
                                                    validations={{ isRequired: true }}
                                                    style={{ width: 'auto' }}
                                                />
                                                <a className='ml-8' onClick={() => setIsEditTitleChapter(false)}>
                                                    Hủy
                                                </a>
                                                <a className='ml-8' onClick={() => handleEditTitleChapter(item)}>
                                                    Lưu
                                                </a>
                                            </>
                                        ) : (
                                            <span>{item.name}</span>
                                        )}
                                        <i className='icon-edit-chapter ml-8' onClick={() => handleEditChapter(item)}></i>
                                    </div>
                                    <div className='ladiui btn-group'>
                                        <div className='ladiui dropdown hide-mt ba-c'>
                                            <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                <i className='ladiui icon icon-ldp-dot'></i>
                                            </button>
                                            <ul className='ladiui dropdown-menu r-0'>
                                                <li onClick={() => handleReplicationModule(item.course_module_id)}>
                                                    <a className='ladiui dropdown-item'>{t('ACTIONS.REPLICATION')}</a>
                                                </li>
                                                <li onClick={() => handleDeleteChapter(item.course_module_id)}>
                                                    <a className='ladiui dropdown-item'>{t('ACTIONS.DELETE')}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {item.lessons && item.lessons.length > 0 && (
                                    <div className='block-list-lessons'>
                                        <Droppable
                                            droppableId={`course-module-${item.course_module_id}`}
                                            type={`droppableSubItem-${item.course_module_id}`}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    className='list-lesson'
                                                    {...provided.droppableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                                    }}
                                                >
                                                    {fetchListLesson(item.lessons)}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                )}
                                <div className='add-lesson'>
                                    <a className='btn-add-lesson' onClick={() => handleSetBlockAddContent(item)}>
                                        <i className='cta-add mr-8'></i>
                                        Thêm bài giảng mới
                                    </a>
                                </div>
                            </div>
                        )}
                    </Draggable>
                );
            });
        }
    };

    //hàm giúp thực hiện việc sắp xếp khi kéo thả
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEndChapters = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.type == 'droppableItem') {
            const items = reorder(listChapters, result.source.index, result.destination.index);
            let chapterListIds = [];

            items.map((item, index) => {
                if (item.course_module_id) {
                    chapterListIds.push(item.course_module_id);
                }
            });
            setListChapters(items);

            dispatch(courseAction.moduleUpdatePosition({ course_module_ids: chapterListIds }));
        } else if (result.type.includes('droppableSubItem')) {
            const parentCode = result.type.split('-')[1];
            const itemSubItemMap = find(listChapters, (item) => item.course_module_id == parentCode);
            const reorderedSubItems = reorder(itemSubItemMap.lessons, result.source.index, result.destination.index);

            let newItems = produce(listChapters, (draft) => {
                draft.map((item) => {
                    if (item.course_module_id == parentCode) {
                        item.lessons = reorderedSubItems;
                    }
                    return item;
                });
            });

            let lessonListIds = [];

            reorderedSubItems.map((item, index) => {
                if (item.course_lesson_id) {
                    lessonListIds.push(item.course_lesson_id);
                }
            });

            setListChapters(newItems);
            dispatch(courseAction.lessonUpdatePosition({ course_lesson_ids: lessonListIds }));
        }
    };

    const handleSetBlockAddVideo = () => {
        setIsShowBlockAddVideo(true);
        setIsShowBlockAddPost(false);
        setIsShowBlockAddContentLesson(false);
        setSubjectContentVideo({
            name: '',
            url_thumbnail: '',
            url_video: '',
            description: '',
            is_trial: false,
            documents: [],
            video_platform: 'Youtube',
        });
        setLessonType('Video');
    };

    const handleSetblockAddPost = () => {
        setIsShowBlockAddPost(true);
        setIsShowBlockAddVideo(false);
        setIsShowBlockAddContentLesson(false);
        setSubjectContentPost({
            name: '',
            post_content: '',
            description: '',
            is_trial: false,
            documents: [],
        });
        setLessonType('Post');
    };

    const fileChangedHandler = (event) => {
        const files = event.target.files;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadThumbnail(form));
            }
        }
    };

    const handleConfirmSaveData = () => {
        setIsShowBlockAddPost(false);
        setIsShowBlockAddVideo(false);
        setIsShowBlockAddContentLesson(true);
        setSubjectContentVideo({});
        setSubjectContentPost({});
        window.LadiUI.closeModal('confirm-save-data');
    };

    const handleConfirmSaveDataEdit = () => {
        if (selectedLessonEdit) {
            setDefaultContentLesson(JSON.stringify(selectedLessonEdit));
            if (selectedLessonEdit.lesson_type == 'Video') {
                setIsShowBlockAddVideo(true);
                setIsShowBlockAddPost(false);
                setSubjectContentVideo({
                    ...selectedLessonEdit,
                });
                window.LadiUI.closeModal('confirm-save-data-edit');
            } else {
                setIsShowBlockAddPost(true);
                setIsShowBlockAddVideo(false);
                setSubjectContentPost({
                    ...selectedLessonEdit,
                });
                window.LadiUI.closeModal('confirm-save-data-edit');
            }
        }
    };

    const onChangeSubjectContentVideo = (event) => {
        setSubjectContentVideo(
            produce(subjectContentVideo, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangeSubjectContentPost = (event) => {
        setSubjectContentPost(
            produce(subjectContentPost, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const handleChangeUploadFileLesson = (event) => {
        const { files } = event.target;
        if (files && files.length > 0) {
            // const form = baseHelper.getFormDataUploadFile(files, 32);
            const form = baseHelper.getFormDataUploadFile(files);
            if (form) {
                dispatch(fileActions.uploadFileLesson(form));
            }
        }
    };

    const deletePathVideo = (item, index) => {
        if (item.course_document_id) {
            dispatch(
                courseAction.documentDelete({
                    course_document_id: item.course_document_id,
                })
            );
        } else {
            let listPaths = [...subjectContentVideo.documents];
            if (listPaths && listPaths.length > 0) {
                listPaths.splice(index, 1);

                setSubjectContentVideo({
                    ...subjectContentVideo,
                    documents: listPaths,
                });
            }
        }
    };

    const deletePathPost = (item, index) => {
        if (item.course_document_id) {
            dispatch(
                courseAction.documentDelete({
                    course_document_id: item.course_document_id,
                })
            );
        } else {
            let listPaths = [...subjectContentPost.documents];
            if (listPaths && listPaths.length > 0) {
                listPaths.splice(index, 1);

                setSubjectContentPost({
                    ...subjectContentPost,
                    documents: listPaths,
                });
            }
        }
    };

    const handleAddDocumentLinkVideo = async () => {
        let errors = [];
        const _inputsRef = compact(Array.from(DocumentInputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        let listDocuments = subjectContentVideo.documents ? [...subjectContentVideo.documents] : [];
        let data = [];
        if (documentLink) {
            if (subjectContentVideo.course_lesson_id) {
                data.push({
                    src: documentLink.src,
                    name: documentLink.name,
                });
                dispatch(
                    courseAction.documentCreate({
                        course_lesson_id: subjectContentVideo.course_lesson_id,
                        documents: data,
                    })
                );
            } else {
                listDocuments.push({
                    src: documentLink.src,
                    name: documentLink.name,
                });

                await setSubjectContentVideo({
                    ...subjectContentVideo,
                    documents: listDocuments,
                });
            }
        }

        setDocumentLink('');
    };

    const handleAddDocumentLinkPost = async () => {
        let errors = [];
        const _inputsRef = compact(Array.from(DocumentInputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        let listDocuments = subjectContentPost.documents ? [...subjectContentPost.documents] : [];

        let data = [];
        if (documentLink) {
            if (subjectContentPost.course_lesson_id) {
                data.push({
                    src: documentLink.src,
                    name: documentLink.name,
                });
                dispatch(
                    courseAction.documentCreate({
                        course_lesson_id: subjectContentPost.course_lesson_id,
                        documents: data,
                    })
                );
            } else {
                listDocuments.push({
                    src: documentLink.src,
                    name: documentLink.name,
                });

                await setSubjectContentPost({
                    ...subjectContentPost,
                    documents: listDocuments,
                });
            }
        }

        setDocumentLink('');
    };

    const handleChangeDocumentLink = (event) => {
        setDocumentLink({
            ...documentLink,
            [event.target.name]: event.target.value,
        });
    };

    const handleSaveLesson = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        if (lessonType == 'Video') {
            if (subjectContentVideo.course_lesson_id) {
                let payload = {
                    course_lesson_id: subjectContentVideo.course_lesson_id,
                    name: subjectContentVideo.name,
                    position: subjectContentVideo.position,
                    video_platform: subjectContentVideo.video_platform,
                    url_video: subjectContentVideo.url_video,
                    url_thumbnail: subjectContentVideo.url_thumbnail,
                    description: subjectContentVideo.description,
                    is_trial: subjectContentVideo.is_trial,
                    lesson_time: Number(subjectContentVideo.lesson_time),
                    release_date: subjectContentVideo.release_date,
                    documents: subjectContentVideo.documents,
                };
                setDefaultContentLesson(JSON.stringify(payload));
                setSubjectContentVideo(payload);
                dispatch(courseAction.lessonUpdate(payload));
            } else {
                let payload = {
                    ...subjectContentVideo,
                    lesson_time: Number(subjectContentVideo.lesson_time),
                    lesson_type: lessonType,
                    course_module_id: selectedCourseModuleID,
                };
                setDefaultContentLesson(JSON.stringify(payload));
                setSubjectContentVideo(payload);
                dispatch(courseAction.lessonCreate(payload));
            }
        } else {
            if (subjectContentPost.course_lesson_id) {
                let payload = {
                    name: subjectContentPost.name,
                    course_lesson_id: subjectContentPost.course_lesson_id,
                    post_content: subjectContentPost.post_content,
                    content: subjectContentPost.content,
                    position: subjectContentPost.position,
                    lesson_type: subjectContentPost.lesson_type,
                    description: subjectContentPost.description,
                    is_trial: subjectContentPost.is_trial,
                    lesson_time: Number(subjectContentPost.lesson_time),
                    release_date: subjectContentPost.release_date,
                    documents: subjectContentPost.documents,
                };
                setDefaultContentLesson(JSON.stringify(payload));
                setSubjectContentPost(payload);
                dispatch(courseAction.lessonUpdate(payload));
            } else {
                let payload = {
                    ...subjectContentPost,
                    lesson_time: Number(subjectContentPost.lesson_time),
                    lesson_type: lessonType,
                    course_module_id: selectedCourseModuleID,
                };
                setDefaultContentLesson(JSON.stringify(payload));
                setSubjectContentPost(payload);
                dispatch(courseAction.lessonCreate(payload));
            }
        }
    };

    const onChangeDateTimeContentVideo = (value) => {
        setSubjectContentVideo({
            ...subjectContentVideo,
            release_date: value,
        });
    };

    const onChangeDateTimeContentPost = (value) => {
        setSubjectContentPost({
            ...subjectContentPost,
            release_date: value,
        });
    };

    const onSelectVideo = (video) => {
        let payloadSubmitVideo = {};
    };

    const handleChangeSearchInputVideo = (event) => {
        let { value } = event.target;

        setValueSearchVideo(value);
    };

    const handleUploadVideo = () => {
        document.getElementById('input-upload-video').click();
    };
    const fileChangedUploadVideo = (event) => {
        const { files } = event.target;
        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUploadVideo(
                files,
                productInfo.product_id,
                selectedCourseModuleID,
                subjectContentVideo.url_video
            );
            if (form) {
                dispatch(fileActions.bunnyCreateVideo(form));
                setIsLoadingUploadBunny(true);
            }
        }
    };

    const handleCopyVideoLink = (videoLink) => {
        let videoId = '';
        let _splitUrl = videoLink.split('/');
        videoId = _splitUrl[_splitUrl.length - 1];
        if (videoId) {
            navigator.clipboard.writeText(_splitUrl[_splitUrl.length - 1]);
            window.LadiUI.toastCustom('success', '', 'Đã copy vào clipboard!');
        }
    };

    const handleGetListVideo = () => {
        let payload = {
            search: {
                keyword: valueSearchVideo,
                product_id: productInfo.product_id ? productInfo.product_id : null,
            },
            page: 1,
        };

        dispatch(productVideoAction.listVideo(payload));

        setIsShowModalAddVideo(true);
    };

    const setVideoLinkSelected = (videoLink) => {
        setSubjectContentVideo({
            ...subjectContentVideo,
            url_video: videoLink,
        });

        setIsShowModalAddVideo(false);
    };

    const getVideoIdByUrl = (url) => {
        let videoId = '';

        let _splitUrl = url.split('/');

        if (_splitUrl && _splitUrl.length > 0) {
            videoId = _splitUrl[_splitUrl.length - 1];
        }

        return videoId;
    };

    const handlePreviewVideo = () => {
        setIsShowModalPreviewVideo(true);
    };

    const handleSetVideoPlatForm = (data) => {
        if (data.type == appConfig.LIST_COMMUNICATION.LADIVIDEO_STREAM.type) {
            if (currentStoreInfo && currentStoreInfo.is_bunny_active == 0) {
                // setIsShowModalActiveBunny(true);
                window.LadiUI.showModal('confirm-bunny-active');
                return false;
            }
        }
        setSubjectContentVideo({
            ...subjectContentVideo,
            video_platform: data.type,
            url_video: '',
        });
    };

    const getRemainingCapacity = () => {
        let total = 0;
        let total_uploaded = 0;
        if (currentStoreInfo) {
            total = currentStoreInfo.bunny_total_size / (1024 * 1024 * 1024);
            total_uploaded = currentStoreInfo.bunny_total_uploaded_size / (1024 * 1024 * 1024);
        }

        return baseHelper.formatNumber(total_uploaded) + '/' + baseHelper.formatNumber(total);
    };

    return (
        <div className='block-content-product-course' id='block-content-product-course'>
            {isLoadingUploadBunny && <LoadingScene blur={true} />}
            <div className='ladiui-product-course-left block-product-content-left mt-24'>
                <h3 className='title'>Nội dung khóa học</h3>
                <DragDropContext onDragEnd={onDragEndChapters}>
                    <Droppable droppableId='droppable' type='droppableItem'>
                        {(provided, snapshot) => (
                            <div
                                className='list-chapters'
                                ref={provided.innerRef}
                                style={{
                                    background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                }}
                            >
                                {fetchListChapter()}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <button
                    className='ladiui btn btn-outline-primary flex mt-24'
                    style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                    onClick={() => handleAddChapter()}
                >
                    Thêm chương mới
                </button>
            </div>
            <div className='ladiui-product-course-right mt-24'>
                {isShowBlockAddVideo && (
                    <div className='title flex space-between mb-24'>
                        <h3 className='title'>Nội dung bài giảng</h3>
                        <button type='button' className='ladiui btn btn-primary' onClick={handleSaveLesson}>
                            {!subjectContentVideo.course_lesson_id ? 'Tạo mới bài học' : 'Lưu bài học'}
                        </button>
                    </div>
                )}
                {isShowBlockAddPost && (
                    <div className='title flex space-between mb-24'>
                        <h3 className='title'>Nội dung bài giảng</h3>
                        <button type='button' className='ladiui btn btn-primary' onClick={handleSaveLesson}>
                            {!subjectContentPost.course_lesson_id ? 'Tạo mới bài học' : 'Lưu bài học'}
                        </button>
                    </div>
                )}
                {isShowBlockAddContentLesson && (
                    <div className='choose-content'>
                        <h3 className='title'>Chọn loại nội dung chính của bài giảng</h3>
                        <div className='list-box-content'>
                            <div className='box-content mr-24' onClick={handleSetBlockAddVideo}>
                                <img src='https://w.ladicdn.com/ladiui/icons/ldicon-video.svg' alt='' />
                                <span className='box-name'>Video</span>
                            </div>
                            <div className='box-content ml-24' onClick={handleSetblockAddPost}>
                                <img src='https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg' alt='' />
                                <span className='box-name'>Bài viết</span>
                            </div>
                        </div>
                    </div>
                )}
                {isShowBlockAddVideo && (
                    <div className='block-content-video'>
                        <div className='ladiui form-group m-0'>
                            <label className='ladiui-label'>Tên bài giảng</label>
                            <Input
                                ref={(ref) => inputsRef.add(ref)}
                                name='name'
                                validationName={'tên bài giảng'}
                                placeholder='Nhập vào tên bài giảng'
                                value={subjectContentVideo.name}
                                onChange={onChangeSubjectContentVideo}
                                validations={{ isRequired: true }}
                            />
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Video bài giảng</label>
                            <div className='flex' style={{ height: '180px' }}>
                                <div className='thumbnail mr-24'>
                                    <img
                                        className='image'
                                        src={
                                            subjectContentVideo.url_thumbnail
                                                ? CDN + subjectContentVideo.url_thumbnail
                                                : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                        }
                                        alt=''
                                    />
                                    <div className='ladiui-button-upload block-upload-image'>
                                        <div className='block-overlay'>
                                            <div className='text'>
                                                <i className='ldicon-upload mb-8' />
                                                <p>Tải ảnh Thumbnail</p>
                                                <p>Kích thước: 240x135 (px)</p>
                                            </div>

                                            <input
                                                type='file'
                                                name='file'
                                                accept='image/*'
                                                onChange={fileChangedHandler}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='group-input'>
                                    <div className='ladiui form-group m-0'>
                                        <label className='ladiui-label'>Chọn nền tảng</label>
                                        <SelectOption
                                            wrapperClassName='select-assignee w100'
                                            innerClassName='ellipsis'
                                            innerStyle={{ width: '100%', minWidth: '100%' }}
                                            customStyle={{ padding: '0px', width: '100%' }}
                                            currentKey={subjectContentVideo.video_platform}
                                            _key='type'
                                            _value='name'
                                            items={appConfig.LIST_COMMUNICATION || []}
                                            onSelectItem={(data) => handleSetVideoPlatForm(data)}
                                        />
                                    </div>
                                    {subjectContentVideo &&
                                        subjectContentVideo.video_platform != appConfig.LIST_COMMUNICATION.LADIVIDEO_STREAM.type && (
                                            <div className='ladiui form-group m-0'>
                                                <label className='ladiui-label'>Đường dẫn</label>
                                                <Input
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    name='url_video'
                                                    validationName={'đường dẫn video'}
                                                    placeholder='Nhập vào đường dẫn video của bài giảng'
                                                    value={subjectContentVideo.url_video}
                                                    onChange={onChangeSubjectContentVideo}
                                                    validations={{ isRequired: true }}
                                                />
                                            </div>
                                        )}
                                    {subjectContentVideo &&
                                        subjectContentVideo.video_platform == appConfig.LIST_COMMUNICATION.LADIVIDEO_STREAM.type && (
                                            <div className='ladiui form-group m-0'>
                                                <input
                                                    type='file'
                                                    name='file'
                                                    accept='video/mp4,video/x-m4v,video/*'
                                                    onChange={fileChangedUploadVideo}
                                                    id='input-upload-video'
                                                    onClick={(event) => {
                                                        event.target.value = null;
                                                    }}
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                />
                                                <div className='flex mt-16'>
                                                    <div className='choose-type-item flex mr-24'>
                                                        <input
                                                            type='radio'
                                                            name='radio-group-type'
                                                            className='ladiui form-check-input'
                                                            checked={selectedTypeUploadVideo == 1}
                                                            onClick={() => setSelectedTypeUploadVideo(1)}
                                                        />
                                                        <span>Chọn từ thư viện</span>
                                                    </div>
                                                    <div className='choose-type-item flex mr-24'>
                                                        <input
                                                            type='radio'
                                                            name='radio-group-type'
                                                            className='ladiui form-check-input'
                                                            checked={selectedTypeUploadVideo == 2}
                                                            onClick={() => {
                                                                setSelectedTypeUploadVideo(2);
                                                            }}
                                                        />
                                                        <span>Tải lên từ máy tính</span>
                                                    </div>
                                                </div>
                                                <div className='flex mt-16'>
                                                    <div className='show-link-video flex w100 space-between'>
                                                        <span>Video ID: {getVideoIdByUrl(subjectContentVideo.url_video)}</span>
                                                        {subjectContentVideo.url_video && (
                                                            <button
                                                                style={{ backgroundColor: 'transparent' }}
                                                                className={`flex ${isLoadingPreviewBunny && 'loader'}`}
                                                                onClick={() => handlePreviewVideo(subjectContentVideo.url_video)}
                                                                disabled={isLoadingPreviewBunny}
                                                            >
                                                                {!isLoadingPreviewBunny && (
                                                                    <img
                                                                        className='cursor-pointer'
                                                                        src='https://w.ladicdn.com/ladiui/icons/ldicon-video.svg'
                                                                    />
                                                                )}
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className='btn-choose-video ml-24'>
                                                        {selectedTypeUploadVideo == 1 ? (
                                                            <button
                                                                className='ladiui btn btn-outline-primary btn-sm'
                                                                onClick={() => handleGetListVideo()}
                                                            >
                                                                Chọn video
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className='ladiui btn btn-outline-primary btn-sm'
                                                                onClick={() => handleUploadVideo()}
                                                            >
                                                                Tải video
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='label-show-remaining-capacity mt-8'>
                                                    Bạn đã sử dụng {getRemainingCapacity()} GB dung lượng tải video
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>

                        <div className='flex'>
                            <div className='ladiui item-form switch'>
                                <label className='ladiui switch m-0'>
                                    <input
                                        type='checkbox'
                                        checked={subjectContentVideo.is_trial}
                                        onChange={() => {
                                            setSubjectContentVideo({
                                                ...subjectContentVideo,
                                                is_trial: !subjectContentVideo.is_trial,
                                            });
                                        }}
                                    />
                                    <span className='ladiui slider round'></span>
                                </label>
                            </div>
                            <div className='ml-8'>
                                <span className='ladiui title-text-form-lever2 mb-0'>Bài học thử</span>
                            </div>
                        </div>

                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Thời lượng bài giảng (phút)</label>
                            <Input
                                ref={(ref) => inputsRef.add(ref)}
                                type='number'
                                name='lesson_time'
                                validationName={'thời lượng bài giảng'}
                                placeholder='Nhập vào thời lượng của bài giảng'
                                value={subjectContentVideo.lesson_time}
                                onChange={onChangeSubjectContentVideo}
                                validations={{ isRequired: false }}
                            />
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Thời gian kích hoạt bài giảng</label>
                            <div className='flex mt-8'>
                                <div className='release-date-item flex mr-24'>
                                    <input
                                        type='radio'
                                        name='radio-group-release'
                                        className='ladiui form-check-input'
                                        checked={!subjectContentVideo.release_date || subjectContentVideo.release_date == null}
                                        onClick={() => {
                                            setSubjectContentVideo({
                                                ...subjectContentVideo,
                                                release_date: null,
                                            });
                                        }}
                                    />
                                    <span>Phát hành ngay</span>
                                </div>
                                <div className='release-date-item flex mr-24'>
                                    <input
                                        type='radio'
                                        name='radio-group-release'
                                        className='ladiui form-check-input'
                                        checked={subjectContentVideo.release_date || subjectContentVideo.release_date != null}
                                        onClick={() => {
                                            setSubjectContentVideo({
                                                ...subjectContentVideo,
                                                release_date: new Date(),
                                            });
                                        }}
                                    />
                                    <span>Đặt lịch phát hành</span>
                                </div>
                                {subjectContentVideo.release_date ? (
                                    <DatePicker
                                        name='start_date'
                                        minDate={baseHelper.momentToDate(moment())}
                                        selected={subjectContentVideo.release_date}
                                        onChange={(value) => onChangeDateTimeContentVideo(value)}
                                        showTimeSelect
                                        timeFormat={appConfig.DEFAUT_TIME}
                                        timeIntervals={15}
                                        dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                                        timeCaption='Time'
                                        customPlaceholder='Chọn thời gian'
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Mô tả bài giảng</label>
                            <Editor
                                value={subjectContentVideo.description}
                                init={{
                                    height: 250,
                                    plugins: 'preview fullpage autosave visualblocks fullscreen table lists link image code paste',
                                    paste_as_text: true,
                                    extended_valid_elements: 'a[data|href|class|style]',
                                    toolbar:
                                        'bold italic underline | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image |  numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat',
                                    toolbar_drawer: 'sliding',
                                    language_url: '../../../../language/tinymce-vi',
                                    valid_elements: '*[*]',
                                }}
                                onEditorChange={(value) => {
                                    setSubjectContentVideo({
                                        ...subjectContentVideo,
                                        description: value,
                                    });
                                }}
                            />
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Tài liệu bài giảng</label>
                            <div className='block-choose-type-upload'>
                                <div className='ladiui radio'>
                                    <input
                                        type='radio'
                                        className='ladiui form-check-input'
                                        onChange={() => setFileType(0)}
                                        id='exampleCheck'
                                        checked={fileType == 0}
                                        name='radio-group'
                                    />
                                    <span>Tải từ máy tính</span>
                                    <input
                                        type='radio'
                                        className='ladiui form-check-input ml-24'
                                        onChange={() => setFileType(1)}
                                        id='exampleCheck'
                                        name='radio-group'
                                        checked={fileType == 1}
                                    />
                                    <span>Chèn link</span>
                                </div>
                            </div>
                            {fileType == 0 ? (
                                <div className='ladiui form-group'>
                                    <div className='input-upload-file relative w100'>
                                        <Input
                                            className='limit-text-upload'
                                            name='file'
                                            placeholder='Tải file từ máy tính (Dung lượng tối đa 25MB)'
                                            disabled={true}
                                            value={''}
                                        />
                                        <div className='ladiui-button-upload'>
                                            <button type='button' className='flex'>
                                                <i className='icon-export mr-4'></i>
                                                <span>Tải lên</span>
                                            </button>
                                            <input
                                                type='file'
                                                name='file'
                                                accept='.doc, .docx, .pdf, .xsl, .xlsx, .ppt, .pptx, .tiff, .cdr, .svg, .psd, .pdf, .ai, .mp4, .mov, .avi, .ladipage, .ladiflow'
                                                multiple='multiple'
                                                onChange={handleChangeUploadFileLesson}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className='ladiui form-group'>
                                        <Input
                                            ref={(ref) => DocumentInputsRef.add(ref)}
                                            name='name'
                                            className='ladiui form-control'
                                            validationName={'tên tài liệu'}
                                            onChange={handleChangeDocumentLink}
                                            placeholder='Nhập tên tài liệu'
                                            value={documentLink.name}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>
                                    <div className='ladiui form-group'>
                                        <Input
                                            ref={(ref) => DocumentInputsRef.add(ref)}
                                            name='src'
                                            className='ladiui form-control'
                                            onChange={handleChangeDocumentLink}
                                            validationName={'đường dẫn tài liệu'}
                                            placeholder='Nhập link tài liệu của bạn'
                                            value={documentLink.src}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>
                                    <div className='ladiui form-group flex space-between w100' style={{ backgroundColor: 'transparent' }}>
                                        <span className='flex' style={{ color: '#6d6d6d' }}>
                                            <img
                                                src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-not-print-order.svg'
                                                alt=''
                                                className='mr-8'
                                            />
                                            Tải file tài liệu từ URL có sẵn của bạn
                                        </span>
                                        <button className='ladiui btn btn-outline-primary ml-24' onClick={handleAddDocumentLinkVideo}>
                                            Chèn link
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className='note'>
                            <p>
                                <strong>Lưu ý:</strong> Tài liệu là các giáo trình hỗ trợ việc học tập của học sinh. File tài liệu không
                                được vượt quá 25MB.
                            </p>
                        </div>
                        {subjectContentVideo.documents && (
                            <div className='block-list-path'>
                                {subjectContentVideo.documents.map((item, index) => {
                                    return (
                                        <div key={index} className='path-item flex space-between'>
                                            <div className='flex'>
                                                <i className='ldicon-file mr-8'></i>
                                                <span className='path-name'>{item.name}</span>
                                            </div>
                                            <a onClick={() => deletePathVideo(item, index)} className='ml-8'>
                                                <i className='ladi-icon icon-bin'></i>
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
                {isShowBlockAddPost && (
                    <div className='block-content-video'>
                        <div className='ladiui form-group m-0'>
                            <label className='ladiui-label'>Tên bài giảng</label>
                            <Input
                                ref={(ref) => inputsRef.add(ref)}
                                name='name'
                                validationName={'tên bài giảng'}
                                placeholder='Nhập vào tên bài giảng'
                                value={subjectContentPost.name}
                                onChange={onChangeSubjectContentPost}
                                validations={{ isRequired: true }}
                            />
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Nội dung bài giảng</label>
                            <Editor
                                value={subjectContentPost.post_content}
                                init={{
                                    height: 250,
                                    plugins: 'preview fullpage autosave visualblocks fullscreen table lists link image code paste',
                                    paste_as_text: true,
                                    extended_valid_elements: 'a[data|href|class|style]',
                                    toolbar:
                                        'bold italic underline | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image |  numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat',
                                    toolbar_drawer: 'sliding',
                                    language_url: '../../../../language/tinymce-vi',
                                    valid_elements: '*[*]',
                                }}
                                onEditorChange={(value) => {
                                    setSubjectContentPost({
                                        ...subjectContentPost,
                                        post_content: value,
                                    });
                                }}
                            />
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Thời lượng bài giảng (phút)</label>
                            <Input
                                ref={(ref) => inputsRef.add(ref)}
                                type='number'
                                name='lesson_time'
                                validationName={'thời lượng bài giảng'}
                                placeholder='Nhập vào thời lượng của bài giảng'
                                value={subjectContentPost.lesson_time}
                                onChange={onChangeSubjectContentPost}
                                validations={{ isRequired: false }}
                                style={{ width: '300px' }}
                            />
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Thời gian kích hoạt bài giảng</label>
                            <div className='flex mt-8'>
                                <div className='release-date-item flex mr-24'>
                                    <input
                                        type='radio'
                                        name='radio-group-release'
                                        className='ladiui form-check-input'
                                        checked={!subjectContentPost.release_date || subjectContentPost.release_date == null}
                                        onClick={() => {
                                            setSubjectContentPost({
                                                ...subjectContentPost,
                                                release_date: null,
                                            });
                                        }}
                                    />
                                    <span>Phát hành ngay</span>
                                </div>
                                <div className='release-date-item flex mr-24'>
                                    <input
                                        type='radio'
                                        name='radio-group-release'
                                        className='ladiui form-check-input'
                                        checked={subjectContentPost.release_date || subjectContentPost.release_date != null}
                                        onClick={() => {
                                            setSubjectContentPost({
                                                ...subjectContentPost,
                                                release_date: new Date(),
                                            });
                                        }}
                                    />
                                    <span>Đặt lịch phát hành</span>
                                </div>
                                {subjectContentPost.release_date ? (
                                    <DatePicker
                                        name='start_date'
                                        minDate={baseHelper.momentToDate(moment())}
                                        selected={subjectContentPost.release_date}
                                        onChange={(value) => onChangeDateTimeContentPost(value)}
                                        showTimeSelect
                                        timeFormat={appConfig.DEFAUT_TIME}
                                        timeIntervals={15}
                                        dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                                        timeCaption='Time'
                                        customPlaceholder='Chọn thời gian'
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Mô tả bài giảng</label>
                            <Editor
                                value={subjectContentPost.description}
                                init={{
                                    height: 250,
                                    plugins: 'preview fullpage autosave visualblocks fullscreen table lists link image code paste',
                                    paste_as_text: true,
                                    extended_valid_elements: 'a[data|href|class|style]',
                                    toolbar:
                                        'bold italic underline | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image |  numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat',
                                    toolbar_drawer: 'sliding',
                                    language_url: '../../../../language/tinymce-vi',
                                    valid_elements: '*[*]',
                                }}
                                onEditorChange={(value) => {
                                    setSubjectContentPost({
                                        ...subjectContentPost,
                                        description: value,
                                    });
                                }}
                            />
                        </div>
                        <div className='flex'>
                            <div className='ladiui item-form switch'>
                                <label className='ladiui switch m-0'>
                                    <input
                                        type='checkbox'
                                        checked={subjectContentPost.is_trial}
                                        onChange={() => {
                                            setSubjectContentPost({
                                                ...subjectContentPost,
                                                is_trial: !subjectContentPost.is_trial,
                                            });
                                        }}
                                    />
                                    <span className='ladiui slider round'></span>
                                </label>
                            </div>
                            <div className='ml-8'>
                                <span className='ladiui title-text-form-lever2 mb-0'>Bài học thử</span>
                            </div>
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Tài liệu bài giảng</label>
                            <div className='block-choose-type-upload'>
                                <div className='ladiui radio'>
                                    <input
                                        type='radio'
                                        className='ladiui form-check-input'
                                        onChange={() => setFileType(0)}
                                        id='exampleCheck'
                                        checked={fileType == 0}
                                        name='radio-group'
                                    />
                                    <span>Tải từ máy tính</span>
                                    <input
                                        type='radio'
                                        className='ladiui form-check-input ml-24'
                                        onChange={() => setFileType(1)}
                                        id='exampleCheck'
                                        name='radio-group'
                                        checked={fileType == 1}
                                    />
                                    <span>Chèn link</span>
                                </div>
                            </div>
                            {fileType == 0 ? (
                                <div className='ladiui form-group'>
                                    <div className='input-upload-file relative w100'>
                                        <Input
                                            className='limit-text-upload'
                                            name='file'
                                            placeholder='Tải file từ máy tính (Dung lượng tối đa 25MB)'
                                            disabled={true}
                                            value={''}
                                        />
                                        <div className='ladiui-button-upload'>
                                            <button type='button' className='flex'>
                                                <i className='icon-export mr-4'></i>
                                                <span>Tải lên</span>
                                            </button>
                                            <input
                                                type='file'
                                                name='file'
                                                accept='.doc, .docx, .pdf, .xsl, .xlsx, .ppt, .pptx, .tiff, .cdr, .svg, .psd, .pdf, .ai, .mp4, .mov, .avi, .ladipage, .ladiflow'
                                                multiple='multiple'
                                                onChange={handleChangeUploadFileLesson}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className='ladiui form-group'>
                                        <Input
                                            ref={(ref) => DocumentInputsRef.add(ref)}
                                            validationName={'tên tài liệu'}
                                            name='name'
                                            className='ladiui form-control'
                                            onChange={handleChangeDocumentLink}
                                            placeholder='Nhập tên tài liệu'
                                            value={documentLink.name}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>
                                    <div className='ladiui form-group'>
                                        <Input
                                            ref={(ref) => DocumentInputsRef.add(ref)}
                                            validationName={'đường dẫn tài liệu'}
                                            name='src'
                                            className='ladiui form-control'
                                            onChange={handleChangeDocumentLink}
                                            placeholder='Nhập link tài liệu của bạn'
                                            value={documentLink.src}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>
                                    <div className='ladiui form-group flex space-between w100' style={{ backgroundColor: 'transparent' }}>
                                        <span className='flex' style={{ color: '#6d6d6d' }}>
                                            <img
                                                src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-not-print-order.svg'
                                                alt=''
                                                className='mr-8'
                                            />
                                            Tải file tài liệu từ URL có sẵn của bạn
                                        </span>
                                        <button className='ladiui btn btn-outline-primary ml-24' onClick={handleAddDocumentLinkPost}>
                                            Chèn link
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className='note'>
                            <p>
                                <strong>Lưu ý:</strong> Tài liệu là các giáo trình hỗ trợ việc học tập của học sinh. File tài liệu không
                                được vượt quá 25MB.
                            </p>
                        </div>
                        {subjectContentPost.documents && (
                            <div className='block-list-path'>
                                {subjectContentPost.documents.map((item, index) => {
                                    return (
                                        <div key={index} className='path-item flex space-between'>
                                            <div className='flex'>
                                                <i className='ldicon-file mr-8'></i>
                                                <span className='path-name'>{item.name}</span>
                                            </div>
                                            <a onClick={() => deletePathPost(item, index)} className='ml-8'>
                                                <i className='ladi-icon icon-bin'></i>
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>

            {isShowModalAddVideo && (
                <ModalAddVideo
                    onClose={() => setIsShowModalAddVideo(false)}
                    isShow={isShowModalAddVideo}
                    listVideos={listVideos || []}
                    selectedVideos={''}
                    innerStyle={{ display: 'none' }}
                    selectVariant={onSelectVideo}
                    searchVideoValue={valueSearchVideo || ''}
                    onChangeInputSearch={(event) => handleChangeSearchInputVideo(event)}
                    setVideoLinkSelected={setVideoLinkSelected}
                    handleUploadVideo={handleUploadVideo}
                />
            )}

            {isShowModalPreviewVideo && (
                <ModalPreviewVideoBunny
                    onCancel={() => setIsShowModalPreviewVideo(false)}
                    visible={isShowModalPreviewVideo}
                    selectedUrlVideo={subjectContentVideo.url_video || ''}
                    selectedVideoId={selectedVideoUploadId}
                />
            )}

            <ConfirmModal
                id='confirm-save-data'
                title={'Thông báo'}
                content={'Bài giảng của bạn chưa được lưu? Thao tác này có thể khiến bạn mất nội dung bài giảng hiện tại.'}
                cancelText={'Tiếp tục chỉnh sửa'}
                okText={t('ACTIONS.OK')}
                onOk={handleConfirmSaveData}
            />

            <ConfirmModal
                id='confirm-save-data-edit'
                title={'Thông báo'}
                content={'Bài giảng đang sửa của bạn chưa được lưu? Thao tác này có thể khiến bạn mất nội dung bài giảng hiện tại.'}
                cancelText={'Tiếp tục chỉnh sửa'}
                okText={t('ACTIONS.OK')}
                onOk={handleConfirmSaveDataEdit}
            />

            <ConfirmModal
                id='confirm-delete-lesson'
                title={'Xóa bài giảng?'}
                content={'Toàn bộ nội dung bài giảng này sẽ bị xóa. Bạn có chắc chắn muốn xóa bài giảng này ?'}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('Xóa bài giảng')}
                onOk={deleteLesson}
            />

            <ConfirmModal
                id='confirm-delete-chapter'
                title={'Xóa Chương?'}
                content={'Toàn bộ bài giảng thuộc chương này cũng sẽ bị xóa. Bạn có chắc chắn muốn xóa chương học này ?'}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('Xóa chương')}
                onOk={deleteChapter}
            />

            <ConfirmModal
                id='confirm-bunny-active'
                title={'Thông báo'}
                content={
                    'Dung lượng lưu trữ hiện tại của bạn là: 0 GB. Bạn cần mua dung lượng lưu trữ trước khi tải video lên từ máy tính. Vui lòng liên hệ livechat để được hỗ trợ!'
                }
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.CLOSE')}
                isBtnPrimary={true}
                isHiddenBtnCancel={true}
                onOk={() => {
                    window.LadiUI.closeModal('confirm-bunny-active');
                }}
            />
        </div>
    );
});

PanelProductContentCourse.propTypes = {
    titlePage: PropTypes.string,
    descriptionPage: PropTypes.string,
    img_publish: PropTypes.string,
    favicon: PropTypes.string,
};

export default PanelProductContentCourse;
