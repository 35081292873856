import React from 'react';
import PropTypes from 'prop-types';
import { find, remove, map } from 'lodash';
import { withTranslation } from 'react-i18next';
import Modal from './Modal';
import Input from './Input';
import { cloneDeep, compact } from 'lodash';
import baseHelper from '../helpers/BaseHelper';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import productVideoActions from "../redux/futures/product_video/actions";
import * as productVideoTypes from "../redux/futures/product_video/types";
import { Editor } from '@tinymce/tinymce-react';

class ModalPreviewVideoBunny extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func,
        visible: PropTypes.bool,
        selectedUrlVideo: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            video_url: props.selectedUrlVideo || '',
            durationPreviewVideo: 0
        };

        this.inputsRef = new Set();
    }
    async componentDidMount() {
        if (this.props.selectedUrlVideo) {
            this.setState({
                video_url: this.props.selectedUrlVideo,
            });
        }

        if (this.props.selectedVideoId) {
            this.props.handleShowVideo({
                product_video_id: this.props.selectedVideoId
            })
        }
    }
    async componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.productVideoReducer != nextProps.productVideoReducer) {
            if (nextProps.productVideoReducer == productVideoTypes.PRODUCT_SHOW_VIDEO) {
                if (nextProps.productVideoReducer.status) {
                    this.setState({
                        durationPreviewVideo: nextProps.productVideoReducer.duration_upload_video
                    })
                }
            }
        }
    }

    render() {
        const { t, visible, onCancel } = this.props;
        const { video_url, durationPreviewVideo } = this.state;
        return (
            <Modal
                id='modal-preview-video-bunny'
                visible={visible}
                onCancel={onCancel}
                title='Video xem trước'
                width={600}
                footer={
                    <div className='ladiui-modal-footer'>
                        <button onClick={onCancel} className='ladiui btn btn-secondary'>
                            {t('ACTIONS.CLOSE')}
                        </button>
                    </div>
                }
            >
                <div>
                    <div className='block-preview-video'>
                        {
                            durationPreviewVideo && durationPreviewVideo > 0
                                ?
                                <iframe
                                    width='100%'
                                    height='315'
                                    src={video_url}
                                    title='YouTube video player'
                                    frameborder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowfullscreen
                                ></iframe>
                                :
                                <div className='block-progress-video'>
                                    <div className='content'>
                                        <h3>Video đang xử lý. Vui lòng thử lại sau ít phút.</h3>
                                        <p>Bạn có thể tiếp tục chỉnh sửa các nội dung khác của khóa học trong thời gian xử lý video.</p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleShowVideo: (data) => dispatch(productVideoActions.showVideo(data))
    }
};
const mapStateToProps = (state) => ({
    productVideoReducer: { ...state.productVideo },
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation', { withRef: true })(ModalPreviewVideoBunny))
);
