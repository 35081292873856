import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import appConfig from "../../../config/app";
import TagSelect from "../../../components/TagSelect";
import Input from "../../../components/Input";
// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function FilterConditionProduct(props) {
  const { t, conditionProp, filterActionProp, allTags, allCategories } = props;

  const [attributeFilter, setAttributeFilter] = useState(null);
  const [conditionFilter, setConditionFilter] = useState(null);
  const [valueFilter, setValueFilter] = useState(null);
  const [listFilter, setListFilter] = useState([]);
  const [allConditionSelect, setAllConditionSelect] = useState([]);
  const [showListFilter, setShowListFilter] = useState(false);

  const LIST_PRODUCT_TYPE = [
    {
      name: appConfig.PRODUCT_TYPE.PHYSICAL.NAME,
      value: appConfig.PRODUCT_TYPE.PHYSICAL.CODE,
    },
    {
      name: appConfig.PRODUCT_TYPE.EVENT.NAME,
      value: appConfig.PRODUCT_TYPE.EVENT.CODE,
    },
    {
      name: appConfig.PRODUCT_TYPE.SERVICE.NAME,
      value: appConfig.PRODUCT_TYPE.SERVICE.CODE,
    },
    {
      name: appConfig.PRODUCT_TYPE.F_B.NAME,
      value: appConfig.PRODUCT_TYPE.F_B.CODE,
    },
    {
      name: appConfig.PRODUCT_TYPE.COMBO.NAME,
      value: appConfig.PRODUCT_TYPE.COMBO.CODE,
    },
    {
      name: appConfig.PRODUCT_TYPE.DIGITAL.NAME,
      value: appConfig.PRODUCT_TYPE.DIGITAL.CODE,
    },
  ];
  const LIST_RULE_TYPE = [
    {
      name: appConfig.DISCOUNT.RULE_TYPE.CODE.NAME,
      value: appConfig.DISCOUNT.RULE_TYPE.CODE.CODE,
    },
    {
      name: appConfig.DISCOUNT.RULE_TYPE.PROMOTION.NAME,
      value: appConfig.DISCOUNT.RULE_TYPE.PROMOTION.CODE,
    },
  ];

  const LIST_TAGS = [];
  if (allTags && allTags.length > 0) {
    allTags.map((item, index) => {
      let tag = {
        name: item.name,
        value: item.product_tag_id,
      };
      LIST_TAGS.push(tag);
    });
  }

  const LIST_CATEGORIES = [];
  if (allCategories && allCategories.length > 0) {
    allCategories.map((item, index) => {
      let tag = {
        name: item.name,
        value: item.product_category_id,
      };
      LIST_CATEGORIES.push(tag);
    });
  }

  const eventFilterCondition = () => {
    if (!attributeFilter || (!valueFilter && !listFilter)) {
      return;
    }
    const allCondition = allConditionSelect.filter((value) => value.attributeFilter.value !== attributeFilter.value);

    const newAllConditionFilter = [...allCondition, ...[{ attributeFilter, valueFilter, listFilter }]];
    setAllConditionSelect(newAllConditionFilter);
    filterActionProp(newAllConditionFilter);
    eventCloseFilterCondition();
  };

  const eventCloseFilterCondition = () => {
    setAttributeFilter(null);
    setConditionFilter(null);
    setValueFilter(null);
    setShowListFilter(false);
    setListFilter([]);
  };

  const eventRemoveFilter = (index) => {
    // const allCondition = allConditionSelect.filter((value) => value.attributeFilter.value !== attributeFilterValue);
    allConditionSelect.splice(index, 1);

    setAllConditionSelect(allConditionSelect);
    // delete conditionProp[attributeFilterValue];

    filterActionProp(allConditionSelect);
  };

  document.addEventListener("click", (event) => {
    const box = document.getElementById("filter-condtion-area");
    if (box && !box.contains(event.target)) {
      // eventCloseFilterCondition();
      setAttributeFilter(null);
      setConditionFilter(null);
      setValueFilter(null);
      setShowListFilter(false);
    }
  });

  const renderSelectValue = (title, listValue) => {
    return (
      <div className="ladiui flex-row">
        <div className="ladiui btn-group">
          <div className="ladiui dropdown">
            <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
              <i className="ladiui icon"></i>
              <span className="ladiui dropdown-text">{title}</span>
            </button>
            <ul className="ladiui dropdown-menu">
              {listValue.map((item) => (
                <li key={item.value} onClick={() => setValueFilter({ name: item.name, value: item.value })}>
                  <a className="ladiui dropdown-item" href="#">
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderMultiSelect = (title, listValue) => {
    return (
      <div className="ladiui flex-row">
        <div className="ladiui btn-group">
          <div className="ladiui dropdown">
            <TagSelect
              allTags={listValue}
              selectedTags={listFilter}
              setSelected={setListFilter}
              title={title}
              placeholder={title}
              className={"w-400"}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderInputSearch = (title) => {
    return (
      <div className="ladiui flex-row">
        <div className="ladiui btn-group">
          <Input
            value={valueFilter ? valueFilter.value : ""}
            onChange={(e) => setValueFilter({ value: e.target.value, name: e.target.value })}
          />
        </div>
      </div>
    );
  };

  const renderDetailCondition = (condition) => {
    switch (condition) {
      case "tag_ids":
        return renderMultiSelect(t("TAGS.SELECT_TAG"), LIST_TAGS);
        break;
      case "product_category_ids":
        return renderMultiSelect("Chọn danh mục", LIST_CATEGORIES);
        break;
      // case 'rule_type': return renderSelectValue('Chọn hình thức khuyến mãi', LIST_RULE_TYPE);
      case "product_types":
        return renderSelectValue(t("PRODUCTS.CHOOSE_PRODUCT_TYPE"), LIST_PRODUCT_TYPE);
        break;
      default:
        return "";
    }
  };

  return (
    <>
      <div
        id="filter-condtion-area"
        className={allConditionSelect.length > 0 ? "filter-condtion-area mb-24 mr-24" : "filter-condtion-area mr-24"}
      >
        <button className="ladiui btn btn-outline-light" onClick={() => setShowListFilter(!showListFilter)}>
          <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-filter.svg" style={{ marginBottom: "2px", marginRight: "10px" }} />
          {t("ACTIONS.ADD_FILTER")}
        </button>
        <div className={showListFilter ? "list-filter-condition show" : "list-filter-condition"}>
          <div className="ladiui flex-row mt-0">
            <div className="mr-8">
              <div className="ladiui btn-group">
                <div className="ladiui dropdown">
                  <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                    <i className="ladiui icon"></i>
                    <span className="ladiui dropdown-text">{attributeFilter ? attributeFilter.name : "Chọn tiêu chí"}</span>
                  </button>
                  <ul className="ladiui dropdown-menu">
                    <li
                      onClick={() => {
                        setAttributeFilter({ name: "Tag", value: "tag_ids" });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        Tags
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({ name: "Danh mục", value: "product_category_ids" });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        Danh mục
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setAttributeFilter({
                          name: t("PRODUCTS.PRODUCT_TYPE"),
                          value: "product_types",
                        });
                        setListFilter([]);
                      }}
                    >
                      <a className="ladiui dropdown-item" href="#">
                        {t("PRODUCTS.PRODUCT_TYPE")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {attributeFilter ? renderDetailCondition(attributeFilter.value) : ""}
          </div>
          <div className="ladiui footer-list-filter-condition flex-row mt-24">
            <button type="button" className="ladiui btn btn-secondary btn-sm mr-8" onClick={() => eventCloseFilterCondition()}>
              {t("ACTIONS.CANCEL")}
            </button>
            <button type="button" className="ladiui btn btn-primary btn-sm" onClick={() => eventFilterCondition()}>
              {t("ACTIONS.APPLY")}
            </button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="ladiui result-filter-condition flex-row mb-24">
          {allConditionSelect.map((item, index) => (
            <span className="ladiui btn-tag" key={index}>
              {item.attributeFilter.name}: {item.valueFilter ? item.valueFilter.name : ""}{" "}
              {item.listFilter.length > 0 ? JSON.stringify(item.listFilter.map((item) => item.name)) : ""}
              {/* <img
                className="cursor-pointer"
                style={{ marginLeft: "8px" }}
                src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-close-no-bg.svg"}
                onClick={() => eventRemoveFilter(index)}
              /> */}
              <i style={{ marginLeft: "8px" }} className="cursor-pointer  icon-close-no-bg-16" onClick={() => eventRemoveFilter(index)} />
            </span>
          ))}
        </div>
      </div>
    </>
  );
}

export default withTranslation()(FilterConditionProduct);
