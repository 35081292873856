import React from "react";

import Product from "./pages/products/Product";
import ProductCategory from "./pages/products/Category";
import Order from "./pages/orders/Order";
import Checkout from "./pages/checkouts/Checkout";
import CheckoutEdit from "./pages/checkouts/CheckoutEdit";
import MyOrder from "./pages/orders/MyOrder";
import OrderCreate from "./pages/orders/OrderCreate/OrderCreate";
import OrderEdit from "./pages/orders/OrderEdit/OrderEdit";
import Customer from "./pages/customers/Customer";
import Discount from "./pages/discounts/Discount";
import PageCheckout from "./pages/page_checkouts/PageCheckout";
import PageCheckoutUpdate from "./pages/page_checkouts/components/PageCheckoutUpdate";
import PageCheckoutCreate from "./pages/page_checkouts/components/PageCheckoutCreate";
import PageCheckoutCreateSDK from "./pages/page_checkouts/components/PageCheckoutCreateSDK";
import PageCheckoutUpdateSDK from "./pages/page_checkouts/components/PageCheckoutUpdateSDK";
import Report from "./pages/reports/Report";
import AssigneeOverTime from "./pages/reports/AssigneeOverTime";
import OrderOverTime from "./pages/reports/OrderOverTime";
import Setting from "./pages/settings/Setting";
import Shipping from "./pages/shippings/Shipping";
import Tag from "./pages/tags/Tag";
import ShippingEdit from "./pages/shippings/ShippingEdit";
import ProductCreate from "./pages/products/components/ModalCreate/ProductCreate";
import ProductCourseCreate from "./pages/courses/components/ModalCreate/ProductCourseCreate";
import ProductCourseUpdate from "./pages/courses/components/ModalEdit/ModalEditCourse";
import ProductUpdate from "./pages/products/components/ModalEdit/ModalEditProduct";
import EventCreate from "./pages/products/components/ModalCreate/EventCreate";
import EventUpdate from "./pages/events/components/ModalEvent";

import ServiceCreate from "./pages/products/components/ModalCreate/ServiceCreate";
import ServiceUpdate from "./pages/services/components/ModalService";

import ProductDigitalUpdate from "./pages/product_digitals/components/ModalService";

import Checkin from "./pages/products/Checkin";
import CheckQrcode from "./pages/products/CheckQrCode";
import CheckinInfoHandle from "./pages/products/CheckinInfoHandle";

import PageCourse from "./pages/courses/PageCourse";

import SidebarItem from "./components/SidebarItem";
import { map } from "lodash";
import Upgrade from "./pages/upgrades/Upgrade";
import Inventory from "./pages/products/Inventory";
import Integrate from "./pages/integrates/Integrate";

import Course from "./pages/courses/Course";
import PageStudentManagement from "./pages/courses/PageStudentManagement";
import CourseWelcome from "./pages/courses/components/Welcome";
import { Link } from "react-router-dom";

import i18n from "./i18n";

const allRoutes = {
  "/orders": [
    { path: "/orders", exact: true, name: `${i18n.t("MENU.ORDERS")}`, component: Order },
    { path: "/my-orders", exact: true, name: `${i18n.t("MENU.MY_ORDERS")}`, component: MyOrder },
    { path: "/checkouts", exact: true, name: `${i18n.t("MENU.CHECKOUTS")}`, component: Checkout },
    { path: "/checkouts/:checkoutID", exact: true, component: CheckoutEdit },
    { path: "/orders/create", exact: true, component: OrderCreate },
    { path: "/orders/:orderID", exact: true, component: OrderEdit },
    { path: "/shippings", exact: true, name: `${i18n.t("MENU.SHIPPINGS")}`, component: Shipping },
    { path: "/shippings/:shippingID", exact: true, name: `${i18n.t("MENU.SHIPPINGS")}`, component: ShippingEdit },
  ],
  "/products": [
    { path: "/products", exact: true, name: `${i18n.t("MENU.PRODUCTS")}`, component: Product },
    { path: "/products/create", exact: true, name: `${i18n.t("MENU.PRODUCTS")}`, component: ProductCreate },
    { path: "/products/update/:productID", exact: true, name: `${i18n.t("MENU.PRODUCTS")}`, component: ProductUpdate },
    { path: "/inventory", exact: true, name: `${i18n.t("MENU.INVENTORY")}`, component: Inventory },
    { path: "/product-category", exact: true, name: `${i18n.t("MENU.CATEGORY_PRODUCT")}`, component: ProductCategory },
    // { path: "/checkin", exact: true, name: `${i18n.t("MENU.PRODUCTS")}`, component: Checkin },
  ],
  "/courses": [
    { path: "/courses", exact: true, name: `${i18n.t("MENU.COURSES")}`, component: Course },
    { path: "/courses/create", exact: true, name: `${i18n.t("MENU.COURSES")}`, component: ProductCourseCreate },
    { path: "/courses/update/:productID", exact: true, name: `${i18n.t("MENU.COURSES")}`, component: ProductCourseUpdate },
    { path: "/page-course", exact: true, name: `${i18n.t("MENU.PAGE_COURSE")}`, component: PageCourse },
    { path: "/student-management", exact: true, name: `${i18n.t("COURSES.STUDENT_MANAGEMENT")}`, component: PageStudentManagement },
    { path: "/course-category", exact: true, name: `${i18n.t("MENU.CATEGORY_COURSE")}`, component: ProductCategory },
  ],
  "/events": [
    { path: "/events/create", exact: true, name: `${i18n.t("MENU.PRODUCTS")}`, component: EventCreate },
    { path: "/events/update/:productID", exact: true, name: `${i18n.t("MENU.PRODUCTS")}`, component: EventUpdate },
  ],
  "/services": [
    { path: "/services/create", exact: true, name: `${i18n.t("MENU.PRODUCTS")}`, component: ServiceCreate },
    { path: "/services/update/:productID", exact: true, name: `${i18n.t("MENU.PRODUCTS")}`, component: ServiceUpdate },
  ],
  "/digital": [
    { path: "/digital/create", exact: true, name: `${i18n.t("MENU.PRODUCTS")}`, component: ServiceCreate },
    { path: "/digital/update/:productID", exact: true, name: `${i18n.t("MENU.PRODUCTS")}`, component: ProductDigitalUpdate },
  ],
  "/customers": [
    {
      path: "/customers",
      exact: true,
      name: `${i18n.t("MENU.CUSTOMERS")}`,
      component: Customer,
    },
  ],
  "/discounts": [{ path: "/discounts", exact: true, name: `${i18n.t("MENU.DISCOUNTS")}`, component: Discount }],
  "/checkin": [{ path: "/checkin", exact: true, name: `${i18n.t("MENU.DISCOUNTS")}`, component: Checkin }],
  "/check-qrcode": [{ path: "/check-qrcode", exact: true, name: `${i18n.t("MENU.DISCOUNTS")}`, component: CheckQrcode }],
  "/checkin/info-handle": [
    { path: "/checkin/info-handle", exact: true, name: `${i18n.t("MENU.DISCOUNTS")}`, component: CheckinInfoHandle },
  ],
  "/reports": [
    { path: "/reports", exact: true, name: `${i18n.t("MENU.REPORTS")}`, component: Report },
    { path: "/reports/orders_over_time", exact: true, component: OrderOverTime },
    { path: "/reports/assignees_over_time", exact: true, component: AssigneeOverTime },
  ],
  "/settings": [{ path: "/settings", exact: true, name: `${i18n.t("MENU.SETTINGS")}`, component: Setting }],
  "/upgrade": [{ path: "/upgrade", exact: true, name: `${i18n.t("MENU.UPGRADE")}`, component: Upgrade }],
  "/page-checkout": [
    { path: "/page-checkout", exact: true, name: `${i18n.t("MENU.PAGE_CHECKOUT")}`, component: PageCheckout },
    { path: "/page-checkout/update/:pageCheckoutID", exact: true, name: `${i18n.t("MENU.PAGE_CHECKOUT")}`, component: PageCheckoutUpdate },
    { path: "/page-checkout/create", exact: true, name: `${i18n.t("MENU.PAGE_CHECKOUT")}`, component: PageCheckoutCreate },
    { path: "/page-checkout/sdk-create", exact: true, name: `${i18n.t("MENU.PAGE_CHECKOUT")}`, component: PageCheckoutCreateSDK },
    {
      path: "/page-checkout/sdk-update/:pageCheckoutID",
      exact: true,
      name: `${i18n.t("MENU.PAGE_CHECKOUT")}`,
      component: PageCheckoutUpdateSDK,
    },
  ],
  "/page-course/welcome": [{ path: "/page-course/welcome", exact: true, name: `${i18n.t("MENU.PAGE_COURSE")}`, component: CourseWelcome }],
  "/integrate": [{ path: "/integrate", exact: true, name: `${i18n.t("MENU.INTEGRATE")}`, component: Integrate }],
};

const menuItems = {
  "/orders": {
    keyStr: ["orders", "shippings", "my-orders", "checkouts", ""],
    key: "orders",
    classLi: "ladiui menu-item",
    // to: '/orders',
    classNav: "ladiui flex-row-menu-item dropdown-toggle",
    classIcon: "ladi-icon icon icon-menu-orders",
    title: "MENU.ORDERS",
    children: [
      {
        classLi: "ladiui menu-child-item",
        to: "/my-orders",
        classLink: "ladiui subtitle-2",
        title: "MENU.MY_ORDERS",
      },
      {
        classLi: "ladiui menu-child-item",
        to: "/orders",
        classLink: "ladiui subtitle-2",
        title: "MENU.ALL_ORDERS",
      },
      {
        classLi: "ladiui menu-child-item",
        to: "/checkouts",
        classLink: "ladiui subtitle-2",
        title: "MENU.UNFINISH_ORDERS",
      },
      {
        classLi: "ladiui menu-child-item",
        to: "/shippings",
        classLink: "ladiui subtitle-2",
        title: "MENU.SHIPPINGS",
      },
    ],
    // <li className="ladiui menu-child-item">
    //   <Link to="/my-orders" className="ladiui subtitle-2">
    //     {i18n.t("MENU.MY_ORDERS")}
    //   </Link>
    // </li>
    // <li className="ladiui menu-child-item">
    //   <Link to="/orders" className="ladiui subtitle-2">
    //     {i18n.t("MENU.ALL_ORDERS")}
    //   </Link>
    // </li>
    // <li className="ladiui menu-child-item">
    //   <Link to="/checkouts" className="ladiui subtitle-2">
    //     {i18n.t("MENU.UNFINISH_ORDERS")}
    //   </Link>
    // </li>
    // <li className="ladiui menu-child-item">
    //   <Link to="/shippings" className="ladiui subtitle-2">
    //     {i18n.t("MENU.SHIPPINGS")}
    //   </Link>
    // </li>
  },
  "/products": {
    keyStr: ["products", "inventory", "product-category"],
    key: "products",
    classLi: "ladiui menu-item",
    // to: '/products',
    classNav: "ladiui flex-row-menu-item dropdown-toggle",
    classIcon: "ladi-icon icon icon-menu-products",
    title: "MENU.PRODUCTS",
    children: [
      {
        classLi: "ladiui menu-child-item",
        to: "/products",
        classLink: "ladiui subtitle-2",
        title: "MENU.SUB_MENU_PRODUCTS",
      },
      {
        classLi: "ladiui menu-child-item",
        to: "/inventory",
        classLink: "ladiui subtitle-2",
        title: "MENU.INVENTORY",
      },
      {
        classLi: "ladiui menu-child-item",
        to: "/product-category?type=Product",
        classLink: "ladiui subtitle-2",
        title: "MENU.CATEGORY",
      },
    ],
  },
  "/page-checkout": {
    keyStr: ["page-checkout"],
    key: "page-checkout",
    classLi: "ladiui menu-item",
    // to: "/page_checkout",
    classNav: "ladiui flex-row-menu-item dropdown-toggle",
    classIcon: "ladi-icon icon icon-menu-payments",
    title: "MENU.DISPLAY",
    children: [
      {
        classLi: "ladiui menu-child-item",
        to: "/page-checkout",
        classLink: "ladiui subtitle-2",
        title: "MENU.PAGE_SALES",
      }
    ],
  },
  "/courses": {
    keyStr: ["courses", "page-course", "student-management", "course-category"],
    key: "courses",
    classLi: "ladiui menu-item",
    // to: "/page_checkout",
    classNav: "ladiui flex-row-menu-item dropdown-toggle",
    classIcon: "ladi-icon icon icon-menu-courses",
    title: "MENU.COURSES",
    children: [
      {
        classLi: "ladiui menu-child-item",
        to: "/courses",
        classLink: "ladiui subtitle-2",
        title: "Danh sách khóa học",
      },
      {
        classLi: "ladiui menu-child-item",
        to: "/course-category?type=Course",
        classLink: "ladiui subtitle-2",
        title: "Danh mục",
      },
      {
        classLi: "ladiui menu-child-item",
        to: "/student-management",
        classLink: "ladiui subtitle-2",
        title: "Quản lý học viên",
      },
      {
        classLi: "ladiui menu-child-item",
        to: "/page-course",
        classLink: "ladiui subtitle-2",
        title: "Thiết lập giao diện",
      },
    ],
  },
  "/customers": {
    keyStr: ["customers"],
    key: "customers",
    classLi: "ladiui menu-item",
    to: "/customers",
    classNav: "ladiui flex-row-menu-item",
    classIcon: "ladi-icon icon  icon icon-menu-customers",
    title: "MENU.CUSTOMERS",
  },
  "/discounts": {
    keyStr: ["discounts"],
    key: "discounts",
    classLi: "ladiui menu-item",
    to: "/discounts",
    classNav: "ladiui flex-row-menu-item",
    classIcon: "ladi-icon  icon icon-menu-discounts",
    title: "MENU.DISCOUNTS",
  },
  "/integrate": {
    keyStr: ["integrate"],
    key: "integrate",
    classLi: "ladiui menu-item",
    to: "/integrate",
    classNav: "ladiui flex-row-menu-item",
    classIcon: "ladi-icon  icon icon-menu-integrates",
    title: "MENU.INTEGRATE",
  },
  "/reports": {
    keyStr: ["reports"],
    key: "reports",
    classLi: "ladiui menu-item",
    to: "/reports",
    classNav: "ladiui flex-row-menu-item",
    classIcon: "ladi-icon  icon icon-menu-reports",
    title: "MENU.REPORTS",
  },
  // '/tags': {
  //     keyStr: ['tags'],
  //     key: 'tags',
  //     classLi: 'ladiui menu-item',
  //     to: '/tags',
  //     classNav: 'ladiui flex-row-menu-item',
  //     classIcon: 'ladi-icon  icon icon-menu-apps',
  //     title: 'MENU.TAG',
  // },
  "/settings": {
    keyStr: ["settings"],
    key: "settings",
    classLi: "ladiui menu-item",
    to: "/settings",
    classNav: "ladiui flex-row-menu-item",
    classIcon: "ladi-icon  icon icon-menu-settings",
    title: "MENU.SETTINGS",
  },
};

class Routes {
  getRoutesByScopes(scopes = {}) {
    const routes = [];
    // if (scopes.admin) {
    map(allRoutes, (values, key) => {
      routes.push(...values);
    });
    return routes;
    // }

    routes.push(...allRoutes["/"]);

    map(scopes, (actions, scope) => {
      if (actions.length == 1 && actions[0] == "*") {
        routes.push(...allRoutes[`/${scope}`]);
      }
    });
    return routes;
  }

  getItemByScopes(scopes = {}) {
    // if (scopes.admin) {
    return menuItems;
    // }

    const items = [];

    map(scopes, (actions, scope) => {
      if (actions.length == 1 && actions[0] == "*") {
        items.push(menuItems[`/${scope}`]);
      }
    });

    return items;
  }
}

export default new Routes();
